import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { navigate } from '../../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button,Input, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined,ExclamationCircleOutlined} from '@ant-design/icons'
import PenggunaanStokOvkComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/transaksi-stok-ovk-component/penggunaan-stok-ovk-component'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import {detailTransaksi,updateTransaksiPenggunaan,deleteTransaksiStokOvk} from '../stok-ovk-aksi-page/query-stok-ovk'

function TransaksiStokOvkPage(props) {
    const {confirm} = Modal
    const {idStock,satuanRecordingOvk,penggunaan,fetchDataPenggunaan,loading,setLoading,t} = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [idData, setIdData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex,title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
            <Input
                placeholder={`${t('general.search')} ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                >
                {t('general.search')}
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
                </Button>
            </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        ),
    })
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }
    /**End handle seacrh in row antd design */
    
    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(detailTransaksi(idData))
                .then(res => {        
                    setDetail(res.data.data.ovkTransaction)
                    setVisible(true);
                }).catch( ( error ) =>  {
                    console.log(error)
                    setDetail(null)
                    setVisible(false)
                })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value)
    },[idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData =  (values,date,ovkStock,stok) => {
        API.post(updateTransaksiPenggunaan(idData,date,ovkStock._id,stok))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.updateOvkTransaction._id){
                validationMessage('success', `${t('pages.stok.ovk.response.sukses.editTrans')}`)
                fetchDataPenggunaan(idStock)
            }
        }).catch( ( error ) =>  {
            console.log(error)
            validationMessage('error', `${t('pages.stok.ovk.response.gagal.editTrans')}`)
            setLoading(false)
        })
    }

    const handleSubmit = (values) => {
        updatedData(values,values['date_picker'].format('MM/DD/YYYY'),detail.ovkStock, values.jumlahStok)
        setVisible(false)
        setIdData(null)
    }
    /**End handle updated data */

     /**Handle delete data */
     const deleteData = (_id) => {
        setLoading(true);
        API.delete(deleteTransaksiStokOvk(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false)
            }
            else if(res.data.data.deleteOvkTransaction.deleted === true){
                validationMessage('success', `${t('pages.stok.ovk.response.sukses.hapusTrans')}`)
                fetchDataPenggunaan(idStock)
            }
        }).catch( ( error ) =>  {
            console.log(error)
            validationMessage('error', `${t('pages.stok.ovk.response.gagal.hapusTrans')}`)
            setLoading(false)
        })
    }
    
    const hapusConfirm = (_id)=> {
        confirm({
            title: `${t('pages.stok.ovk.detail.penggunaan.textHapus')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
    /**End handle delete data */
    const columns = [
        {
            title: `${t('pages.stok.ovk.detail.penggunaan.table.tanggal')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            ...getColumnSearchProps('tanggal', `${t('pages.stok.ovk.detail.penggunaan.table.tanggal')}`),
        },
        {
            title: `${t('pages.stok.ovk.detail.penggunaan.table.jumlah')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            ...getColumnSearchProps('jumlah', `${t('pages.stok.ovk.detail.penggunaan.table.jumlah')}`),
            render: (value,row,index) => (
                <NumberFormat value={row.jumlah} displayType={'text'} thousandSeparator={true} suffix={' ' + satuanRecordingOvk}/> 
            )
        },
        {
            title: `${t('pages.stok.ovk.detail.penggunaan.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 200,
            align: 'center',
            render: (value,row,index) => (
            <Space size="middle">
                <Tooltip placement="top" title={t('pages.stok.ovk.detail.penggunaan.button.edit')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FFA903',
                        borderRadius:'5px'
                    }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={t('general.del')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FF0303',
                        borderRadius:'5px'
                    }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                </Tooltip>
            </Space>
            )
        }
    ]
    const data = penggunaan.map( ({_id,numberRecording,storeTime}, index) => ({
        key: _id,
        jumlah : numberRecording,
        tanggal : moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
    }))

    return ( 
        <PenggunaanStokOvkComponent
            navigate={props.navigate}
            columns = {columns}
            data = {data}
            loading = {loading}
            visible = {visible}
            detail = {detail}
            handleCancel = {handleCancel}
            handleSubmit = {handleSubmit}
            satuanRecordingOvk = {satuanRecordingOvk}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    idStock : state.stokOvk.idStokOvk,
    satuanRecordingOvk : state.stokOvk.satuanRecordingOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TransaksiStokOvkPage)
export default page