import React from 'react';
import { Layout, Row, Col,Table } from 'antd';
import Loading from '../../../../../common/component/loading/loading-container';
import ModalEditPenggunaanStokPakan from '../../../../../app/dashboard-peternak/stok-page/stok-pakan-page/transaksi-stok-pakan-page/edit-penggunaan-stok-pakan';

function PenggunaanStokPakanComponent(props) {
    const { columns,data,loading,changePagination,detail,visible,idData,handleSubmit,handleCancel,locales} = props;
    return ( 
        <Layout style={{backgroundColor:"white"}}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table 
                            columns={columns} 
                            dataSource={data}
                            bordered 
                            pagination={{
                                onChange : (pageSize) =>
                                    changePagination(pageSize) 
                            }}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
                <ModalEditPenggunaanStokPakan visible= {visible} handleSubmit={handleSubmit} idData={idData} handleCancel ={handleCancel} detail={detail} locales={locales}/>
            </Row>
        </Layout>
    );
}
 
export default PenggunaanStokPakanComponent;