import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button, Input, Tooltip, Modal } from 'antd'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import DetailOvkComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-ovk-component'
import { getDetailRecordOvk, deleteRecordOvk, updateRecordOvk } from '../rearing-record-aksi-page/query-rearing-record'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"

function DetailOvkPage(props) {
    const { dateRearing, ovk, fetchDataOvk, loading, setLoading, idRearingRecord, monthlyOvk } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState("")
    const [suffix, setSuffix] = useState(null)
    const { t } = useTranslation()

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteRecordOvk(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteOvkTransaction.deleted === true) {
                    validationMessage('success', `${t("pages.recording.detail.ovk.response.sukses.hapus")}`)
                    fetchDataOvk(idRearingRecord)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.detail.ovk.response.gagal.hapus")}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.recording.detail.ovk.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(getDetailRecordOvk(idData))
                    .then(res => {
                        setDetail(res.data.data.ovkTransaction)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value, satuan) => {
        if (value === idData) {
            setVisible(true)
            setSuffix(satuan)
        }
        else {
            setIdData(value)
            setSuffix(satuan)
        }
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateRecordOvk(idData, values, dateRearing))
            .then(res => {
                if (res.data.data.updateOvkTransaction._id) {
                    validationMessage('success', `${t("pages.recording.detail.ovk.response.sukses.edit")}`)
                    fetchDataOvk(idRearingRecord)
                } else if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.detail.ovk.response.gagal.edit")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const columns = [
        {
            title: `${t("pages.recording.detail.ovk.table.stok")}`,
            dataIndex: 'stok',
            key: 'stok',
            ...getColumnSearchProps('stok', `${t("pages.recording.detail.ovk.table.stok")}`),
        },
        {
            title: `${t("pages.recording.detail.ovk.table.ovk")}`,
            dataIndex: 'ovk',
            key: 'ovk',
            ...getColumnSearchProps('ovk', `${t("pages.recording.detail.ovk.table.ovk")}`),
        },
        {
            title: `${t("pages.recording.detail.ovk.table.jumlah")}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
        },
        {
            title: `${t("pages.recording.detail.ovk.table.jumlahPengadaan")}`,
            dataIndex: 'jumlahPengadaan',
            key: 'jumlahPengadaan',
        },
        {
            title: `Harga (Rp)`,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
        },
        {
            title: `Total (Rp)`,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
        },
        {
            title: `${t("pages.recording.detail.ovk.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.recording.detail.ovk.button.edit")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showEdit(row.key, row.satuan)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.recording.detail.ovk.button.hapus")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = ovk.map(({ _id, numberRecording, number, ovkStock, priceOvkTransaction }, index) => ({
        key: _id,
        stok: ovkStock.name,
        ovk: ovkStock.ovk.name,
        satuan: ovkStock.ovk.ovkUnitRecording.name,
        satuanPengadaan: ovkStock.ovk.ovkUnit.name,
        jumlah: <NumberFormat value={numberRecording.toFixed(2)} displayType={'text'} thousandSeparator={true} suffix={" (" + ovkStock.ovk.ovkUnitRecording.name + ")"} />,
        jumlahPengadaan: <NumberFormat value={number.toFixed(2)} displayType={'text'} thousandSeparator={true} suffix={" (" + ovkStock.ovk.ovkUnit.name + ")"} />,
        price: <NumberFormat value={priceOvkTransaction} displayType={'text'} thousandSeparator={true}  />,
        nilai: priceOvkTransaction * number,
        total: <NumberFormat value={(priceOvkTransaction * number).toFixed(2)} displayType={'text'} thousandSeparator={true}  />,
    }))

    return (
        <DetailOvkComponent
            navigate={props.navigate}
            columns={columns}
            loading={loading}
            data={data}
            setLoading={setLoading}
            showEdit={showEdit}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            visible={visible} detail={detail}
            suffix={suffix} monthlyOvk={monthlyOvk}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    dateRearing: state.rearingRecord.dateRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailOvkPage)
export default page