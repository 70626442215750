import React from 'react';
import { Layout, Row, Col, Table, Typography } from 'antd';
import Loading from '../../../../common/component/loading/loading-container';
import NumberFormat from 'react-number-format'

import EditPakan from '../../../../app/dashboard-peternak/rearing-record-page/edit-detail-rearing-page/edit-pakan-page';

function DetailPakanComponent(props) {
    const { Content } = Layout;
    const { data, columns, loading, visible, handleSubmit, handleCancel, detail, feed } = props;
    const { Text } = Typography
    return (
        <Layout>
            <Content className="dashboard-section-content background-white">
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <div className="dashboard-section-table-dashboard">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    className="table-dashboard"
                                    bordered
                                    scroll={{ y: '70vh' }}
                                    pagination={false}
                                    summary={pageData => {
                                        let totalNilai = 0
                                        pageData.forEach(({
                                            nilai,
                                        }) => {
                                            totalNilai += parseFloat(nilai)
                                        });
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0} colSpan={4}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                        <Text><NumberFormat value={totalNilai.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                        </Loading>
                    </Col>
                </Row>
                <EditPakan visible={visible} handleSubmit={handleSubmit} detail={detail} handleCancel={handleCancel} feed={feed} />
            </Content>
        </Layout>
    );
}

export default DetailPakanComponent;