import * as actionType from './auth-action-type';

const initialState = {
    dataUser: {},
    loading : false,
}

const handler = (currentState) => {
    const setDataUser = {
        startGetDataUsers : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataUsers : () => ({
            ...currentState,
            loading: false,
        }),

        getDataUsers : data => ({
            ...currentState,
            dataUser: data
        })
    }

    return {
        ...setDataUser
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataUsers();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataUsers();
        case actionType.SET_DATA_USER:
            return handler(state).getDataUsers(payload);

        default:
            return state;
    }
}