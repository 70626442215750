import * as actionType from './profil-action-type';

const initialState = {
    dataProfil: [],
    loading : false,
}

const handler = (currentState) => {
    const setDataProfil = {
        startGetDataProfils : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataProfils : () => ({
            ...currentState,
            loading: false,
        }),

        getDataProfils : data => ({
            ...currentState,
            dataProfil: data
        }),
    }

    return {
        ...setDataProfil
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataProfils();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataProfils();
        case actionType.SET_DATA_PROFIL:
            return handler(state).getDataProfils(payload);

        default:
            return state;
    }
}