import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { API } from '../../../../common/api'
import { getMonthlyStockOvks } from './ovk-aksi-page/query-stock-flow-ovk'
import OvkComponent from '../../../../modules/dashboard-peternak/stock-flow-component/ovk-component/ovk-component'

import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'

function OvkPage(props) {
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [idStockflow, setId] = useState(null)
    const [month, setMonth] = useState(moment().format('MMMM'))
    const [year, setYear] = useState(moment().format('YYYY'))
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockOvks('', 0, 0, month, year))
            .then(res => {
                setObject(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
                setId(res.data.data.monthlyStockOvks.calculateMonthlyStocks[0].idMonthlyStockOvk)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(month, year)
    }, [month, year])

    const columns = [
        {
            title: `${t('pages.stockflow.ovk.table.ovk')}`,
            dataIndex: 'ovk',
            key: 'ovk',
            fixed: true,
            width: 200,
            ...getColumnSearchProps('ovk', `${t('pages.stockflow.ovk.table.ovk')}`),
        },
        {
            title: `${t('pages.stockflow.ovk.table.saldoAwal.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAwal.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyAwal',
                    key: 'qtyAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAwal.hs')}`,
                    className: 'right',
                    dataIndex: 'hargaSatuanAwal',
                    key: 'hargaSatuanAwal',
                    width: 100
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAwal.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalAwal',
                    key: 'hargaTotalAwal',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.masuk.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.masuk.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPengadaan',
                    key: 'qtyPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.masuk.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPengadaan',
                    key: 'hsPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.masuk.nilai')}`,
                    className: 'right',
                    dataIndex: 'totalPengadaan',
                    key: 'totalPengadaan',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.keluar.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.keluar.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenggunaan',
                    key: 'qtyPenggunaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.keluar.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPenggunaan',
                    key: 'hsPenggunaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.keluar.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalPenggunaan',
                    key: 'hargaTotalPenggunaan',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.penyesuaian.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.penyesuaian.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenyesuaian',
                    key: 'qtyPenyesuaian',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.penyesuaian.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPenyesuaian',
                    key: 'hsPenyesuaian',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.penyesuaian.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalPenyesuaian',
                    key: 'hargaTotalPenyesuaian',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.saldoAkhir.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAkhir.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyStokAkhir',
                    key: 'qtyStokAkhir',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAkhir.hs')}`,
                    className: 'right',
                    dataIndex: 'hsStokAkhir',
                    key: 'hsStokAkhir',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAkhir.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalStokAkhir',
                    key: 'hargaTotalStokAkhir',
                    width: 130,
                },
            ],
        },
    ]
    const filter1 = object.filter(a => a !== null)
    const filter2 = filter1.filter(a => Math.abs(parseInt(a.sisaStok)) !== 0 || a.nilaiSisaStok !== 0)

    const data = filter2.map(({ _id, idMonthlyStockOvk, namaOvk, satuan, addStock, stockAwalBulan, hargaAwalBulan, hargaTotalAwalBulan,
        outStock, sisaStok, qty, hargaPengeluaran, nilaiSisaStok, hargaTotalPenyesuaian, nilaiPengadaan, hargaPengadaan, nilaiPengeluaran }, index) => ({
            key: _id,
            id: idMonthlyStockOvk,
            ovk: namaOvk + ' (' + satuan + ')',

            stokAwal: stockAwalBulan,
            hargaAwalBulan: hargaAwalBulan,
            hargaTotalAwalBulan: hargaTotalAwalBulan,
            qtyAwal: stockAwalBulan < 0 ? <NumberFormat value={Math.abs(stockAwalBulan.toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={stockAwalBulan.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
            hargaSatuanAwal: <NumberFormat value={hargaAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaTotalAwal: stockAwalBulan < 0 && hargaAwalBulan !== 0 ? <NumberFormat value={Math.abs(hargaTotalAwalBulan.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={hargaTotalAwalBulan.toFixed(0)} displayType={'text'} thousandSeparator={true} />,

            pengadaan: addStock,
            hargaPengadaan: hargaPengadaan,
            totalHargaPengadaan: nilaiPengadaan,
            qtyPengadaan: <NumberFormat value={(addStock).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
            hsPengadaan: <NumberFormat value={hargaPengadaan} displayType={'text'} thousandSeparator={true} />,
            totalPengadaan: <NumberFormat value={nilaiPengadaan} displayType={'text'} thousandSeparator={true} />,

            penggunaan: outStock,
            hargaPenggunaan: hargaPengeluaran,
            totalHargaPenggunaan: nilaiPengeluaran,
            qtyPenggunaan: <NumberFormat value={outStock.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
            hsPenggunaan: <NumberFormat value={hargaPengeluaran < 0 ? Math.abs(hargaPengeluaran) : hargaPengeluaran}
                displayType={'text'} thousandSeparator={true}  prefix={hargaPengeluaran < 0 ? '(' : ''} suffix={hargaPengeluaran < 0 ? ')' : ''}/>,
            hargaTotalPenggunaan: <NumberFormat value={nilaiPengeluaran < 0 ? Math.abs(nilaiPengeluaran.toFixed(0)) : nilaiPengeluaran.toFixed(0)}
                displayType={'text'} thousandSeparator={true} prefix={nilaiPengeluaran < 0 ? '(' : ''} suffix={nilaiPengeluaran < 0 ? ')' : ''} />,

            penyesuaian: qty,
            hargaPenyesuaian: qty !== 0 ? hargaPengeluaran : 0,
            totalHargaPenyesuaian: hargaTotalPenyesuaian,
            qtyPenyesuaian: qty < 0 ? <NumberFormat value={Math.abs(qty.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={qty.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
            hsPenyesuaian: qty !== 0 ? <NumberFormat value={hargaPengeluaran < 0 ? Math.abs(hargaPengeluaran) : hargaPengeluaran}
                displayType={'text'} thousandSeparator={true} prefix={hargaPengeluaran < 0 ? '(' : ''} suffix={hargaPengeluaran < 0 ? ')' : ''} /> : 0,
            hargaTotalPenyesuaian: hargaTotalPenyesuaian < 0 ? <NumberFormat value={Math.abs(hargaTotalPenyesuaian)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={hargaTotalPenyesuaian} displayType={'text'} thousandSeparator={true} />,

            stokAkhir: sisaStok,
            hargaStokAkhir: hargaPengeluaran,
            totalHargaStokAkhir: nilaiSisaStok,
            qtyStokAkhir: sisaStok < 0 ? <NumberFormat value={Math.abs(sisaStok.toFixed(2))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={parseInt(sisaStok) === 0 ? sisaStok.toFixed(2) : (sisaStok.toFixed(0))} displayType={'text'} thousandSeparator={true} />,
            hsStokAkhir: <NumberFormat value={hargaPengeluaran < 0 ? Math.abs(hargaPengeluaran) : hargaPengeluaran}
                displayType={'text'} thousandSeparator={true} prefix={hargaPengeluaran < 0 ? '(' : ''} suffix={hargaPengeluaran < 0 ? ')' : ''} />,
            hargaTotalStokAkhir: nilaiSisaStok < 0 ? <NumberFormat value={Math.abs(nilaiSisaStok)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={nilaiSisaStok} displayType={'text'} thousandSeparator={true} />,
        }))

    const handleTanggalRange = (dateStrings) => {
        setMonth(moment(dateStrings).format('MMMM'))
        setYear(moment(dateStrings).format('YYYY'))
    }

    const disabledDate = (current) => {
        return current > moment().endOf('day')
    }
    return (
        <OvkComponent
            navigate={props.navigate}
            loading={loading}
            setLoading={setLoading}
            data={data}
            columns={columns}
            month={month}
            year={year}
            idStockflow={idStockflow}
            disabledDate={disabledDate}
            handleTanggalRange={handleTanggalRange}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(OvkPage)
export default page