/**
 * @author spindyzel
 * @since 28 Desember 2019
*/

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createHashHistory';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

// store app
import homeReducer from '../../modules/home/store/home-reducer';
import authReducer from '../../modules/auth/store/auth-reducer';
import ovkReducer from '../../modules/dashboard-peternak/master-component/ovk-component/store/ovk-reducer';
import pakanReducer from '../../modules/dashboard-peternak/master-component/pakan-component/store/pakan-reducer';
import materialReducer from '../../modules/dashboard-peternak/master-component/material-component/store/material-reducer';
import gudangPakanReducer from '../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-reducer';
import gudangOvkReducer from '../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-reducer';
import stokPakanReducer from '../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-reducer';
import stokOvkReducer from '../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-reducer';
import stokMaterialReducer from '../../modules/dashboard-peternak/stok-component/stok-material-component/store/stok-material-reducer';
import adjustmentPakanReducer from '../../modules/dashboard-peternak/adjustment-stock-component/pakan-component/store/adjustment-pakan-reducer';
import adjustmentOvkReducer from '../../modules/dashboard-peternak/adjustment-stock-component/ovk-component/store/adjustment-ovk-reducer';
import adjustmentMaterialReducer from '../../modules/dashboard-peternak/adjustment-stock-component/material-component/store/adjustment-material-reducer';
import gudangMaterialReducer from '../../modules/dashboard-peternak/master-component/gudang-material-component/store/gudang-material-reducer';
import layoutReducer from '../../modules/dashboard-peternak/layout-component/store/layout-reducer';
import rearingRecordReducer from '../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-reducer';
import IotReducer from '../../app/dashboard-peternak/Iot-page/store/iot-reducer';
import profileReducer from '../../modules/dashboard-peternak/profil-component/store/profil-reducer';
import strainReducer from '../../modules/dashboard-peternak/master-component/strain-component/store/strain-reducer';
import penyakitReducer from '../../modules/dashboard-peternak/master-component/penyakit-component/store/penyakit-reducer';
import periodeReducer from '../../modules/dashboard-peternak/rearing-component/store/rearing-reducer';

const history = createHistory();
const browserHistory = createBrowserHistory();

const persistConfig = {
    key: 'your-apps',
    storage,
    stateReconciler: autoMergeLevel1,
    whitelist: ['home','auth', 'gudangOvk','gudangPakan','gudangMaterial', 'strain','ovk', 'pakan',
                'material', 'layout','iot','stokPakan','stokOvk','stokMaterial','rearingRecord','profil',
                'adjustmentPakan','adjustmentOvk','adjustmentMaterial','periode','penyakit',],
};

const reducers = combineReducers({
    router: connectRouter(browserHistory),
    home: homeReducer,
    auth : authReducer,
    ovk : ovkReducer,
    pakan : pakanReducer,
    material : materialReducer,
    gudangOvk : gudangOvkReducer,
    gudangPakan : gudangPakanReducer,
    gudangMaterial : gudangMaterialReducer,
    stokPakan :stokPakanReducer,
    stokOvk : stokOvkReducer,
    stokMaterial : stokMaterialReducer,
    layout : layoutReducer,
    rearingRecord : rearingRecordReducer,
    iot : IotReducer,
    profil : profileReducer,
    strain : strainReducer,
    adjustmentPakan : adjustmentPakanReducer,
    adjustmentOvk  :adjustmentOvkReducer,
    adjustmentMaterial : adjustmentMaterialReducer,
    penyakit : penyakitReducer,
    periode : periodeReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = routerMiddleware(browserHistory);
const store = createStore(persistedReducer, applyMiddleware(middleware, thunk));

const persistor = persistStore(store);

export { history, store, persistor, browserHistory};