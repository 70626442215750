import React from 'react';
import { Layout, Breadcrumb, Row, Col, Tabs} from 'antd';
import { Link } from 'react-router-dom';
import PenambahanPakan from '../../../../../app/dashboard-peternak/stok-page/stok-pakan-page/transaksi-stok-pakan-page/penambahan-stok-pakan-page';
import PenggunaanPakan from '../../../../../app/dashboard-peternak/stok-page/stok-pakan-page/transaksi-stok-pakan-page/penggunaan-stok-pakan-page';

function TransaksiStokPakanComponent(props) {
    const { Content } = Layout;
    const { TabPane } = Tabs;
    const { changeKey,loading,setLoading,activeKey,fetchDataPenambahan,penambahan,penggunaan,fetchDataPenggunaan,namaPakan,locales } = props;
    return ( 
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item><Link to='/peternak/stok-pakan'><span>{locales('pages.stok.pakan.title')}</span></Link></Breadcrumb.Item>
                    <Breadcrumb.Item><span>{locales('pages.stok.pakan.detail.title')} {namaPakan}</span></Breadcrumb.Item>
                </Breadcrumb>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={locales('pages.stok.pakan.detail.penambahan.title')} key="1">
                                    <PenambahanPakan activeKey= {activeKey} loading = {loading} setLoading = {setLoading} penambahan = {penambahan} fetchDataPenambahan={fetchDataPenambahan} locales={locales}/>
                                </TabPane>
                                <TabPane tab={locales('pages.stok.pakan.detail.penggunaan.title')} key="2">
                                    <PenggunaanPakan activeKey= {activeKey} loading = {loading} setLoading = {setLoading} penggunaan = {penggunaan} fetchDataPenggunaan = {fetchDataPenggunaan} locales={locales}/>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
 
export default TransaksiStokPakanComponent;