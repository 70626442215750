import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { Tag } from 'antd'
import TablePerformanceComponent from '../../../../modules/dashboard-peternak/dashboard-component/dashboard-content-component/table-performance-component'
import { API } from '../../../../common/api'
import { getRearingRecord } from '../../rearing-record-page/rearing-record-aksi-page/query-rearing-record'
/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */
import { useTranslation } from "react-i18next"

function TablePerformancePage(props) {
    const { idPeriode } = props
    const [loading, setLoading] = useState(false)
    const [rearing, setRearing] = useState([])
    const { t } = useTranslation()

    const fetchDataRearingRecord = async (idPeriode) => {
        setLoading(true)
        await API.get(getRearingRecord('', 0, 0, idPeriode))
            .then(res => {
                if (res.data.data.calculatedRearingRecords !== null) {
                    rearingObject(res.data.data.calculatedRearingRecords.calculatedArr)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                rearingObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchDataRearingRecord(idPeriode)
        // eslint-disable-next-line
    }, [idPeriode])

    const rearingObject = (object) => {
        let rearing = []
        let cumFeed = 0
        let cumOvk = 0
        let cumMaterial = 0
        let cumOverhead = 0
        let totalBiaya = 0
        let total = 0
        for (let i = 0; i < object.length; i++) {
            cumFeed += object[i].hppPakan
            cumOvk += object[i].ovkTotal
            cumMaterial += object[i].hppMaterial
            cumOverhead = object[i].bopKandang
            totalBiaya = object[i].totalDoc + cumFeed + cumOvk + cumMaterial + cumOverhead
            total = + totalBiaya
            rearing.push({
                umur: object[i].umur,
                _id: object[i]._id,
                tanggal: object[i].tanggal,

                persenKematian: (parseFloat(object[i].persenKematian)).toFixed(2),
                standardDp: object[i].standardDp,
                saldo: object[i].saldo,
                ketDeplesi: object[i].ketDeplesi,

                bw: object[i].bw,
                standardBw: object[i].standardBw,
                abw: object[i].abw,
                adg: i === 0 ? object[i].adg : object[i].bw - object[i - 1].bw,
                standardDg: object[i].standardDg,
                fcr: object[i].fcr,
                standardFcr: object[i].standardFcr,
                indeksPerformance: object[i].indeksPerformance,
                hppPerKg: parseFloat(((total / (object[i].saldo + object[i].cumPanen)) / (object[i].bw / 1000)).toFixed(0)),

            })
        }
        setRearing(rearing)
    }

    const columns = [
        {
            title: `${t("pages.dashboard.table.date")}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            className: 'center',
            width: 120,
            fixed: 'left',
        },
        {
            title: `${t("pages.dashboard.table.umur")}`,
            dataIndex: 'umur',
            key: 'umur',
            className: 'center',
            width: 50,
            fixed: 'left',
        },
        {
            title: `${t("pages.dashboard.table.populasi")}`,
            dataIndex: 'populasi',
            key: 'populasi',
            className: 'center',
            width: 80,
        },
        {
            title: `${t("pages.dashboard.table.deplesi.title")}`,
            className: 'deplesi',
            children: [
                {
                    title: 'CUM (%)',
                    dataIndex: 'deplesi',
                    key: 'deplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: 'Std (%)',
                    dataIndex: 'stddeplesi',
                    key: 'stddeplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t("pages.dashboard.table.deplesi.ket")}`,
                    dataIndex: 'ketDeplesi',
                    key: 'ketDeplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
            ],
        },
        {
            title: 'Bw',
            className: 'bw',
            children: [
                {
                    title: `${t("pages.dashboard.table.bw.sampling")}`,
                    dataIndex: 'bw',
                    key: 'bw',
                    className: 'center-bw',
                    width: 90,
                },
                {
                    title: `STD (Gr/${t("pages.dashboard.table.ekor")})`,
                    dataIndex: 'stdbw',
                    key: 'stdbw',
                    className: 'center-bw',
                    width: 90,
                },
                {
                    title: `${t("pages.dashboard.table.bw.ket")}`,
                    dataIndex: 'ketBw',
                    key: 'ketBw',
                    className: 'center-bw',
                    width: 70,
                },
                {
                    title: 'Adg (Gr)',
                    dataIndex: 'adg',
                    key: 'adg',
                    className: 'center-bw',
                    width: 70,
                },
                {
                    title: 'Std Adg (Gr)',
                    dataIndex: 'stdadg',
                    key: 'stdadg',
                    className: 'center-bw',
                    width: 70,
                },
            ],
        },
        {
            title: 'Fcr',
            className: 'fcr',
            children: [
                {
                    title: 'Act Cum (Gr)',
                    dataIndex: 'fcr',
                    key: 'fcr',
                    className: 'center-fcr',
                    width: 70,
                },
                {
                    title: 'STD (Gr)',
                    dataIndex: 'stdfcr',
                    key: 'stdfcr',
                    className: 'center-fcr',
                    width: 70,
                },
                {
                    title: `${t("pages.dashboard.table.fcr.ket")}`,
                    dataIndex: 'ketFcr',
                    key: 'ketFcr',
                    className: 'center-fcr',
                    width: 70,
                },
            ],
        },
        {
            title: 'IP',
            dataIndex: 'indeksPerformance',
            key: 'indeksPerformance',
            className: 'center',
            width: 70,
        },
        {
            title: 'Hpp/Kg (Rp)',
            dataIndex: 'hpp',
            key: 'hpp',
            className: 'center',
            width: 100,
        },
    ]
    const data = rearing.map(({ _id, umur, tanggal, bw, fcr, saldo, persenKematian, adg, ketDeplesi,
        indeksPerformance, hppPerKg, standardDp, standardBw, standardFcr, standardDg, }, index) => ({
            key: _id,
            id: _id,
            umur: umur,
            tanggal: moment(new Date(parseInt(tanggal))).format("DD MMMM YYYY"),
            populasi: <NumberFormat value={saldo} displayType={'text'} thousandSeparator={true} />,
            deplesi: persenKematian,
            stddeplesi: <NumberFormat value={standardDp?.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
            ketDeplesi: <Tag color={ketDeplesi === 'Aman' ? "#027d17" : "#ff5100"}>{ketDeplesi}</Tag>,
            fcr: <NumberFormat value={fcr?.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
            ketFcr: fcr < standardFcr ? <Tag color="#027d17">Aman</Tag> : <Tag color="#ff5100">Warning</Tag>,
            stdfcr: standardFcr.toFixed(2),
            bw: <NumberFormat value={bw} displayType={'text'} thousandSeparator={true} />,
            stdbw: <NumberFormat value={standardBw} displayType={'text'} thousandSeparator={true} />,
            ketBw: standardBw < bw ? <Tag color="#027d17">Aman</Tag> : <Tag color="#ff5100">Warning</Tag>,
            adg: <NumberFormat value={adg.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
            stdadg: standardDg,
            indeksPerformance: <NumberFormat value={indeksPerformance} displayType={'text'} thousandSeparator={true} />,
            hpp: <NumberFormat value={hppPerKg} displayType={'text'} thousandSeparator={true} />,
        }))

    return (
        <TablePerformanceComponent
            navigate={props.navigate}
            setLoading={setLoading}
            columns={columns}
            data={data}
            loading={loading}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TablePerformancePage)
export default page