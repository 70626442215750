import React from 'react'
import { Layout, Breadcrumb, Row, Col, Tabs, Dropdown, Button } from 'antd'
import { Link } from 'react-router-dom'
import MasterPetugas from '../../../../app/dashboard-peternak/master-page/petugas-page/petugas-page'
import MasterManajemen from '../../../../app/dashboard-peternak/master-page/petugas-page/manajemen-page'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TransaksiStokOvkComponent(props) {
    const { Content } = Layout
    const { TabPane } = Tabs
    const { changeKey, loading, setLoading, activeKey, fetchDataPetugas, petugas, handleButtonClick,
        menu, manajemen, fetchDataManajemen, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item>
                                <Link to='/peternak/stok-ovk'>
                                    <span>{t('pages.master.pengguna.title')}</span>
                                </Link>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#008ad4',
                                borderRadius: '5px',
                                float: "right",
                            }} onClick={() => handleButtonClick()}>
                                {t('pages.master.pengguna.modal.tambahPengguna')}
                                <FontAwesomeIcon icon={faPlusSquare} style={{ marginLeft: "10px" }} />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={t('pages.master.pengguna.titlePetugas')} key="1">
                                    <MasterPetugas activeKey={activeKey} loading={loading} setLoading={setLoading}
                                        petugas={petugas} fetchDataPetugas={fetchDataPetugas} t={t} />
                                </TabPane>
                                <TabPane tab={t('pages.master.pengguna.titleManajemen')} key="2">
                                    <MasterManajemen activeKey={activeKey} loading={loading} setLoading={setLoading}
                                        manajemen={manajemen} fetchDataManajemen={fetchDataManajemen} t={t} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default TransaksiStokOvkComponent