export const getStandar = (keyword,limit,skip,strain) => {
    return (`
        query{
            standards(keyword:"${keyword}",limit:${limit},skip:${skip}, strain:"${strain}"){
              totalCount,
              standards{
                  day
                  bw
                  dg
                  adg
                  fi
                  fcr
                  dep
              }
            }
        }
    `)
}

export const createStandar = (value,strain) => {
    return {  
        query : 
        `mutation{
            createStandar(standarInput:{
              day: ${parseInt(value.hari)}
              bw :  ${parseInt(value.bw)}
              dg :  ${parseInt(value.dg)}
              adg :  ${parseInt(value.adg)}
              fcr :  ${parseInt(value.fcr)}
              deplesi :  ${parseInt(value.deplesi)}
              strain : "${strain}"
            }){
              _id
            }
          }
          `
    }
}

export const detailStandar = (_id) => {
    return (`
    query{
        standar(_id:"${_id}"){
          _id
          day
          bw
          fcr
          adg
          dg
          deplesi
        }
      }
    `)
}

export const updateStandar = (_id,value) => {
    return {
        query : `
        mutation{
            updateStandar(_id: "${_id}",updateStandarInput:{
              day: ${parseInt(value.hari)}
              bw :  ${parseInt(value.bw)}
              dg :  ${parseInt(value.dg)}
              adg :  ${parseInt(value.adg)}
              fcr :  ${parseInt(value.fcr)}
              deplesi :  ${parseInt(value.deplesi)}
            }){
              _id
            }
          }
          `
    }
}

export const deleteStandar = (_id) => {
    return (`
        mutation{
            deleteStandar(_id:"${_id}"){
            deleted
            }
        }
    `)
}