import React from 'react';
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd';
import Loading from '../../../../common/component/loading/loading-container';
import ModalCreateCustomer from '../../../../app/dashboard-peternak/master-page/supplier-page/supplier-aksi-page/create-supplier-page';
import ModalEditCustomer from '../../../../app/dashboard-peternak/master-page/supplier-page/supplier-aksi-page/edit-supplier-page';

function CustomerComponent(props) {
    const { Content } = Layout;
    const { columns, data, changePagination, handleOk, handleCancel, fetchData, visible,
        loading, detail, handleSubmit, total, setLoading, t } = props;
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.supplier.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateCustomer fetchData={fetchData} total={total} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={{
                                    onChange: (pageSize) =>
                                        changePagination(pageSize)
                                }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalEditCustomer visible={visible} handleOk={handleOk} detail={detail}
                        handleCancel={handleCancel} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    );
}

export default CustomerComponent;