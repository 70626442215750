import React from 'react'
import Tour from 'reactour'
import '../../../assets/scss/dashboard.scss'
import { Route, NavLink, Redirect } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Layout, Menu, Avatar, Row, Col, Dropdown, Select, Button, Skeleton, Divider, List, Badge, Modal, Switch, } from 'antd'
import Loading from '../../../common/component/loading/loading-container'
import { MenuUnfoldOutlined, MenuFoldOutlined, BellFilled } from '@ant-design/icons'
import CONSTANTS from '../../../common/utils/Constants'
import { faLaptop, faShoppingBasket, faCalculator, faClipboardList, faWifi, faStickyNote, faUser, faSignOutAlt, faCubes, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import InfiniteScroll from 'react-infinite-scroll-component'

import DashboardPeternakPage from '../../../app/dashboard-peternak/dashboard-page/dashboard-page'
import DetailPage from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/detail-page'
import RearingPage from '../../../app/dashboard-peternak/rearing-page/rearing-page'
import DetailRearingClosePage from '../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/detail-rearing-close-page'
import RearingRecordPage from '../../../app/dashboard-peternak/rearing-record-page/rearing-record-page'
import DetailRearingRecordPage from '../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-rearing-record-page'
import StokPakanPage from '../../../app/dashboard-peternak/stok-page/stok-pakan-page/stok-pakan-page'
import TransaksiStokPakanPage from '../../../app/dashboard-peternak/stok-page/stok-pakan-page/transaksi-stok-pakan-page/transaksi-stok-pakan-page'
import StokMaterialPage from '../../../app/dashboard-peternak/stok-page/stok-material-page/stok-material-page'
import TransaksiStokMaterialPage from '../../../app/dashboard-peternak/stok-page/stok-material-page/transaksi-stok-material-page/transaksi-stok-material-page'
import StokOvkPage from '../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-page'
import CreateStokOvkPage from '../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-aksi-page/create-stok-ovk-page'
import TransaksiStokOvkPage from '../../../app/dashboard-peternak/stok-page/stok-ovk-page/transaksi-stok-ovk-page/transaksi-stok-ovk-page'
import MonitorPage from '../../../app/dashboard-peternak/Iot-page/iot-page'
import EwsPage from '../../../app/dashboard-peternak/Iot-page/ews-page'
import DetailMonitorPage from '../../../app/dashboard-peternak/Iot-page/detail-iot-page/detail-iot-page'
import DetailEwsPage from '../../../app/dashboard-peternak/Iot-page/detail-iot-page/detail-ews-page'
import MasterKandangPage from '../../../app/dashboard-peternak/master-page/kandang-page/kandang-page'
import MasterGudangOvkPage from '../../../app/dashboard-peternak/master-page/gudang-ovk-page/gudang-ovk-page'
import MasterGudangPakanPage from '../../../app/dashboard-peternak/master-page/gudang-pakan-page/gudang-pakan-page'
import MasterGudangMaterialPage from '../../../app/dashboard-peternak/master-page/gudang-material-page/gudang-material-page'
import MasterMaterialPage from '../../../app/dashboard-peternak/master-page/material-page/material-page'
import MasterPakanPage from '../../../app/dashboard-peternak/master-page/pakan-page/pakan-page'
import MasterOvkPage from '../../../app/dashboard-peternak/master-page/ovk-page/ovk-page'
import CreateMasterOvkPage from '../../../app/dashboard-peternak/master-page/ovk-page/ovk-aksi-page/create-ovk-page'
import EditMasterOvkPage from '../../../app/dashboard-peternak/master-page/ovk-page/ovk-aksi-page/edit-ovk-page'
import MasterPenggunaPage from '../../../app/dashboard-peternak/master-page/petugas-page/pengguna-page'
import CreateKandangFirst from '../../../app/dashboard-peternak/layout-page/create-kandang-first-page'
import StrainPage from '../../../app/dashboard-peternak/master-page/strain-page/strain-page'
import StandarPage from '../../../app/dashboard-peternak/master-page/standar-page/standar-page'
import StockFlowPeriode from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-page'
import StockFlowLiveBird from '../../../app/dashboard-peternak/stock-flow-page/live-bird-page/live-bird-page'
import StockFlowPakan from '../../../app/dashboard-peternak/stock-flow-page/pakan-page/pakan-page'
import StockFlowOvk from '../../../app/dashboard-peternak/stock-flow-page/ovk-page/ovk-page'
import StockFlowMaterial from '../../../app/dashboard-peternak/stock-flow-page/material-page/material-page'
import StockFlowResume from '../../../app/dashboard-peternak/stock-flow-page/resume-page/resume-page'
import PeriodeFeed from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-aksi-page/detail-pakan'
import PeriodeOvk from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-aksi-page/detail-ovk'
import PeriodeMaterial from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-aksi-page/detail-material'
import PeriodeLiveBird from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-aksi-page/detail-livebird'
import AdjustmentFeed from '../../../app/dashboard-peternak/adjustment-stock-page/pakan-page/pakan-page'
import AdjustmentOvk from '../../../app/dashboard-peternak/adjustment-stock-page/ovk-page/ovk-page'
import AdjustmentMaterial from '../../../app/dashboard-peternak/adjustment-stock-page/material-page/material-page'
import PenyakitPage from "../../../app/dashboard-peternak/master-page/penyakit-page/penyakit-page"
import ProfilePage from '../../../app/dashboard-peternak/profil-page/profil-page'
import CostPage from '../../../app/dashboard-peternak/master-page/cost-page/cost-page'
import OverheadPage from '../../../app/dashboard-peternak/master-page/overhead-page/overhead-page'
import PemakaianUmumPage from '../../../app/dashboard-peternak/pemakaian-umum-page/pemakaian-umum-page'
import CreatePemakaianUmumPage from '../../../app/dashboard-peternak/pemakaian-umum-page/pemakaian-umum-aksi/create-pemakaian-umum-page'
import CreatePenyakitRecordingPage from '../../../app/dashboard-peternak/rearing-record-page/create-detail-rearing-page/create-penyakit-page'
import EditPenyakitRecordingPage from '../../../app/dashboard-peternak/rearing-record-page/edit-detail-rearing-page/edit-penyakit-page'
import FotoPenyakitRecordingPage from '../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-foto-penyakit-page'
import StandarLingkunganPage from '../../../app/dashboard-peternak/master-page/standar-lingkungan-page/standar-lingkungan-page'
import CustomerPage from '../../../app/dashboard-peternak/master-page/customer-page/customer-page'
import SupplierPage from '../../../app/dashboard-peternak/master-page/supplier-page/supplier-page'

function LayoutComponent(props) {
    const { Header, Sider } = Layout
    const { SubMenu } = Menu
    const { Option } = Select

    const {
        current, loading, clickedMenu, handleLogout, collapsed, toggle,
        kandang, periode, width, idKandang, idPeriode, handleChangePeriode, handleChangeKandang,
        visible, handleSubmit, handleCancel, isTourOpen, setIsTour, handleAdd, setVisible,
        gudangPakan, gudangMaterial, gudangOvk, idGudangMaterial, idGudangPakan, idGudangOvk,
        handleChangeGudangPakan, handleChangeGudangOvk, handleChangeGudangMaterial, setCurrent, location,
        role, locales, handleLocales, lang,

        isNotif, setIsNotif, notifications,
        loadMoreNotif, notifCount, unreadNotif, clearUnreadNotif, limitNotif, changeReceiveNotif, loadReceiveNotif
    } = props

    const sortAlphaNum = (a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })
    const logo = require(`../../../assets/images/logo_horizontal_hq.svg`)
    let collapse = collapsed ? 'none' : 'inline'
    let css = collapsed ? 'collapsed-sidebar' : 'not-collapsed-sidebar'
    const profil = (
        <Menu>
            <Menu.Item onClick={() => props.navigate(CONSTANTS.PROFILE_MENU_KEY)}>
                <FontAwesomeIcon icon={faUser} /><span className="black font-content font-medium ml-10">{locales('pages.profil.title')}</span>
            </Menu.Item>
            <Menu.Item onClick={() => handleLogout()}>
                <FontAwesomeIcon icon={faSignOutAlt} /><span className="black font-content font-medium ml-10">Logout</span>
            </Menu.Item>
        </Menu>
    )
    const steps = [
        {
            selector: '.dashboard',
            content: () => (
                <div className="font-medium mt-10">
                    Selamat datang di Broiler X, ini merupakan tampilan pertama dari Broiler X.
                </div>
            ),
            style: {
                textAlign: "center"
            }
        },
        {
            selector: '.header',
            content: () => (
                <div className="font-medium mt-10">
                    Di sebelah atas dapat dilihat menu untuk memilih kandang serta periode
                </div>
            ),
        },
        {
            selector: '.section-menu-dashboard',
            content: () => (
                <div className="font-medium mt-10">
                    Di sebelah kiri dapat dilihat menu-menu yang ada di Broiler X
                </div>
            ),
        },
        {
            selector: '.master',
            content: () => (
                <div className="font-medium mt-10">
                    Untuk memulai langkah pertama adalah pada menu master,
                    pada master semua data seperti pakan, gudang, ovk, kandang, ada pada menu master.
                </div>
            ),
        },
        {
            selector: '.stok',
            content: () => (
                <div className="font-medium mt-10">Selanjutnya ada menu stok, dimana manajemen stok pakan,ovk,serta material dan kegiatan pengadaan ada di sini</div>
            ),

        },
        {
            selector: '.monitor',
            content: () => (
                <div className="font-medium mt-10">Selanjutnya ada menu monitor, di sini alat monitor kandang bisa dilihat serta dapat mengetahui keadaan kandang sekarang</div>
            ),

        },
        {
            selector: '.periode',
            content: () => (
                <div className="font-medium mt-10">Selanjutnya ada menu periode, di sini peternak bisa membuat periode pemeliharaan yang dia inginkan. </div>
            ),

        },
        {
            selector: '.recording',
            content: () => (
                <div className="font-medium mt-10">Kemudian ada menu Recording, di sini peternak bisa membuat pencatatan pemeliharaan dalam 1 periode di setiap harinya. </div>
            ),

        },
        {
            selector: '.dashboards',
            content: () => (
                <div className="font-medium mt-10">Yang terkahir ada menu dashboard, di sini peternak bisa melihat rekapan data yang ada mulai dari total ayam yang hidup. </div>
            ),

        },
        {
            selector: '.dashboard',
            content: () => (
                <div className="font-medium mt-10">
                    Sudah siap menggunakan Broiler X ? Mari membuat kandang terlebih dahulu, silahkan klik tombol berikut untuk membuat kandang
                    <br />
                    <Button style={{
                        color: 'white',
                        backgroundColor: '#0097c4',
                        borderRadius: '5px'
                    }} onClick={() => handleAdd()} className="mt-10">Buat Kandang</Button>
                </div>
            ),
            style: {
                textAlign: "center"
            }
        },
    ]
    return (
        <Loading loading={loading}>
            <Tour
                steps={steps}
                isOpen={isTourOpen}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                nextButton={
                    <Button style={{
                        color: 'white',
                        backgroundColor: '#0097c4',
                        borderRadius: '5px'
                    }} className="mt-10">Next</Button>
                }
                prevButton={
                    <Button style={{
                        color: 'white',
                        backgroundColor: '#ff6d4d',
                        borderRadius: '5px'
                    }} className="mt-10">Back</Button>
                }
                onRequestClose={() => setIsTour()}
                disableDotsNavigation={true}
            />
            <CreateKandangFirst visible={visible} setVisible={setVisible} handleSubmit={handleSubmit} handleCancel={handleCancel} />
            <Layout style={{ minHeight: '100vh' }} className="landing-container">
                <Sider width={260} theme="light" trigger={null} collapsible collapsed={collapsed} className="sidebar-normal"
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        transition: 'all 0.2s ease-in-out'
                    }}
                >
                    <div className="section-dashboard-logo">
                        <img src={logo} className={'section-dashboard-image-logo'} alt="Broiler logo" width="70%" />
                    </div>
                    <div className="section-menu-dashboard">
                        <Menu mode="inline" defaultSelectedKeys={['dashboard']} selectedKeys={[current]}>
                            <Menu.Item key="dashboard" onClick={clickedMenu} className="dashboards">
                                <NavLink to="/peternak/dashboard">
                                    <FontAwesomeIcon icon={faLaptop} />
                                    <span className="section-title-dashboard" style={{ display: collapse }}>{locales("sidebar.dashboard")}</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="rearing" onClick={clickedMenu} className="periode" style={{ display: role === "management" ? 'block' : 'none' }}>
                                <NavLink to="/peternak/rearing">
                                    <FontAwesomeIcon icon={faClipboardList} />
                                    <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "17px" }}>{locales("sidebar.periodePemeliharaan")}</span>
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="recording" onClick={clickedMenu} className="recording">
                                <NavLink to="/peternak/recording">
                                    <FontAwesomeIcon icon={faCalculator} />
                                    <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "17px" }}>{locales("sidebar.recording")}</span>
                                </NavLink>
                            </Menu.Item>
                            <SubMenu
                                key="stok-menu"
                                className="stok"
                                style={{ display: role === "management" ? 'block' : 'none' }}
                                title={
                                    <div>
                                        <FontAwesomeIcon icon={faShoppingBasket} />
                                        <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "14px" }}>{locales("sidebar.stok")}</span>
                                    </div>
                                }
                            >
                                <Menu.Item key="stok-pakan" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stok-pakan">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenustok.pakan")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="stok-ovk" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stok-ovk">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenustok.ovk")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="stok-material" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stok-material">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenustok.material")}</span>
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>

                            <SubMenu
                                key="monitor"
                                className="monitor"
                                hidden={role === 'staff' ? true : false}
                                title={
                                    <div>
                                        <FontAwesomeIcon icon={faWifi} />
                                        <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "14px" }} >{locales('sidebar.monitor')}</span>
                                    </div>
                                }
                            >
                                <Menu.Item key="device" onClick={clickedMenu}>
                                    <NavLink to="/peternak/device">
                                        <span className="section-title-dashboard">{locales('sidebar.subMenuMonitor.device')}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="ews" onClick={clickedMenu}>
                                    <NavLink to="/peternak/ews">
                                        <span className="section-title-dashboard">{locales('sidebar.subMenuMonitor.ews')}</span>
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="pemakaian-umum" onClick={clickedMenu} className="pemakaian-umum" style={{ display: role === "management" ? 'block' : 'none' }}>
                                <NavLink to="/peternak/pemakaian-umum">
                                    <FontAwesomeIcon icon={faShoppingBag} />
                                    <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "17px" }}>{locales("sidebar.pemakaianUmum")}</span>
                                </NavLink>
                            </Menu.Item>
                            <SubMenu
                                key="stok-flow-menu"
                                className="stok"
                                style={{ display: role === "management" ? 'block' : 'none' }}
                                title={
                                    <div>
                                        <FontAwesomeIcon icon={faCubes} />
                                        <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "14px" }}>{locales("sidebar.stokFlow")}</span>
                                    </div>
                                }
                            >
                                <Menu.Item key="stock-flow-periode" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stock-flow-periode">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuStokFlow.periode")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="stock-flow-live-bird" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stock-flow-live-bird">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuStokFlow.liveBird")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="stock-flow-pakan" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stock-flow-pakan">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuStokFlow.pakan")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="stock-flow-ovk" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stock-flow-ovk">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuStokFlow.ovk")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="stock-flow-material" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stock-flow-material">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuStokFlow.material")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="stock-flow-resume" onClick={clickedMenu}>
                                    <NavLink to="/peternak/stock-flow-resume">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuStokFlow.resume")}</span>
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu
                                key="master-menu"
                                className="master"
                                style={{ display: role === "management" ? 'block' : 'none' }}
                                title={
                                    <div>
                                        <FontAwesomeIcon icon={faStickyNote} />
                                        <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "19px" }}>{locales("sidebar.master")}</span>
                                    </div>
                                }
                            >
                                <Menu.Item key="master-kandang" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-kandang">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.kandang")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-strain" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-strain">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.strain")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="standar-lingkungan" onClick={clickedMenu}>
                                    <NavLink to="/peternak/standar-lingkungan">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.lingkungan")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-gudang-pakan" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-gudang-pakan">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.gudangPakan")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-pakan" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-pakan">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.pakan")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-gudang-ovk" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-gudang-ovk">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.gudangOvk")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-ovk" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-ovk">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.ovk")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-gudang-material" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-gudang-material">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.gudangMaterial")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-material" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-material">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.material")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-biaya" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-biaya">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.biaya")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-overhead" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-overhead">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.overhead")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-penyakit" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-penyakit">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.penyakit")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-customer" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-customer">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.kostumer")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-supplier" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-supplier">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.supplier")}</span>
                                    </NavLink>
                                </Menu.Item>
                                <Menu.Item key="master-pengguna" onClick={clickedMenu}>
                                    <NavLink to="/peternak/master-pengguna">
                                        <span className="section-title-dashboard">{locales("sidebar.subMenuMaster.pengguna")}</span>
                                    </NavLink>
                                </Menu.Item>
                            </SubMenu>
                        </Menu>
                    </div>
                </Sider>
                <Layout className={css} style={{ width: `${window.innerWidth - width}px` }}>
                    <Header style={{ background: '#F0F7F7', padding: 0, position: 'fixed', zIndex: 99, width: `${window.innerWidth - width}px` }} className="header">
                        <Row gutter={[16, 16]}>
                            <Col span={2} >
                                <div className="section-header-page-dashboard">
                                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                        className: 'trigger',
                                        onClick: toggle,
                                    })}
                                </div>
                            </Col>
                            <Col span={8} >
                                {current === 'dashboard' || location.pathname === '/peternak/recording' ? (
                                    <div className="section-header-page">
                                        <Select
                                            showSearch
                                            hidden={current === 'dashboard' ? true : false}
                                            labelInValue
                                            placeholder="Pilih Kandang"
                                            optionFilterProp="children"
                                            style={{ width: '100%' }}
                                            className="select-kategori black font-regular"
                                            value={{ key: idKandang }}
                                            onChange={handleChangeKandang}

                                        >
                                            {
                                                kandang.sort(sortAlphaNum).map(data =>
                                                    <Option
                                                        key={data.name.toString()}
                                                        value={data._id}
                                                    >{data.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </div>
                                ) : (
                                    location.pathname === '/peternak/stok-pakan' ? (
                                        <div className="section-header-page">
                                            <Select
                                                showSearch
                                                labelInValue
                                                placeholder="Pilih Gudang"
                                                optionFilterProp="children"
                                                style={{ width: '100%' }}
                                                className="select-kategori black font-regular"
                                                value={{ key: idGudangPakan }}
                                                onChange={handleChangeGudangPakan}
                                            >
                                                {
                                                    gudangPakan.map(data =>
                                                        <Option
                                                            key={data.name.toString()}
                                                            value={data._id}
                                                        >{data.name}</Option>
                                                    )
                                                }
                                            </Select>
                                        </div>
                                    ) : (
                                        location.pathname === '/peternak/stok-material' ? (
                                            <div className="section-header-page">
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder="Pilih Gudang Material"
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                    value={{ key: idGudangMaterial }}
                                                    onChange={handleChangeGudangMaterial}
                                                >
                                                    {
                                                        gudangMaterial.map(data =>
                                                            <Option
                                                                key={data.name.toString()}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        ) : (
                                            location.pathname === '/peternak/stok-ovk' ? (
                                                <div className="section-header-page">
                                                    <Select
                                                        showSearch
                                                        labelInValue
                                                        placeholder="Pilih Gudang Ovk"
                                                        optionFilterProp="children"
                                                        style={{ width: '100%' }}
                                                        className="select-kategori black font-regular"
                                                        value={{ key: idGudangOvk }}
                                                        onChange={handleChangeGudangOvk}
                                                    >
                                                        {
                                                            gudangOvk.map(data =>
                                                                <Option
                                                                    key={data.name.toString()}
                                                                    value={data._id}
                                                                >{data.name}</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </div>
                                            ) : (
                                                <></>
                                            ))))}
                            </Col>

                            <Col span={5} >
                                <div className="section-header-page"
                                    style={current === 'dashboard' || location.pathname === '/peternak/recording' ? { display: "block" } : { display: "none" }}>
                                    <Select
                                        showSearch
                                        hidden={current === 'dashboard' ? true : false}
                                        labelInValue
                                        placeholder={locales('pages.dashboard.phPeriode')}
                                        optionFilterProp="children"
                                        style={{ width: '100%' }}
                                        className="select-kategori black font-regular"
                                        value={{ key: idPeriode }}
                                        onChange={handleChangePeriode}
                                    >
                                        {
                                            periode.map(data =>
                                                <Option
                                                    key={data.name.toString()}
                                                    value={data._id}
                                                >{data.name}</Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </Col>
                            <Col span={9} style={{ float: "right" }}>
                                <div className="section-header-page-avatar">
                                    <Select defaultValue={lang} bordered={false} onChange={(e) => handleLocales(e)}>
                                        <Option value="id">IND</Option>
                                        <Option value="en">EN</Option>
                                    </Select>
                                    <Badge size="small" offset={[-15, 15]}
                                        count={unreadNotif} >
                                        <Avatar size={50}
                                            icon={<BellFilled />}
                                            onClick={() => setIsNotif(!isNotif)}
                                            style={{ backgroundColor: '#f0f7f7', color: 'grey' }}
                                        />
                                    </Badge>
                                    <Dropdown overlay={profil} trigger={['click']}>
                                        <Avatar size={40} icon={<UserOutlined />} shape="square" className="avatar-header-dashboard" />
                                    </Dropdown>
                                </div>
                                <Modal className='notif-card' visible={isNotif}
                                    onCancel={() => {
                                        setIsNotif(false)
                                        clearUnreadNotif(limitNotif)
                                    }}
                                    width={400}
                                    title={
                                        <>
                                            <span className=''>Notifikasi</span>
                                            <Switch className='float-right'
                                                checked={profil.receivedNotification}
                                                loading={loadReceiveNotif}
                                                onChange={(e) => changeReceiveNotif(e)} />
                                        </>
                                    }
                                    closable={false}
                                    maskClosable={true} mask={false} footer={null}
                                >
                                    <div style={{ height: '80vh', overflow: 'auto', }} id='scrollableDiv'>
                                        <InfiniteScroll
                                            dataLength={limitNotif}
                                            next={loadMoreNotif}
                                            hasMore={limitNotif < notifCount}
                                            loader={<Skeleton paragraph={{ rows: 1 }} active />}
                                            endMessage={<Divider plain>Tidak ada pemberitahuan lain</Divider>}
                                            scrollableTarget="scrollableDiv"
                                        >
                                            <List
                                                dataSource={notifications}
                                                locale={{ emptyText: 'Tidak ada pemberitahuan terbaru' }}
                                                renderItem={item => (
                                                    <List.Item key={item._id}>
                                                        <List.Item.Meta
                                                            title={<p className='font-semi-bold mb-0'>{item.title}</p>}
                                                            description={<>
                                                                <p className='font-regular black mb-5'>{item.message}</p>
                                                                <p className='font-sub mb-0'>{item.createdAt}</p>
                                                            </>
                                                            }
                                                        />
                                                        {item.read === false && <Badge status="error" />}
                                                    </List.Item>
                                                )}
                                            />
                                        </InfiniteScroll>
                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Header>
                    <Route
                        path='/peternak/dashboard'
                        exact
                        render={(props) => <DashboardPeternakPage {...props} />}
                    />
                    <Route
                        path='/peternak/dashboard/detail-rearing'
                        exact
                        render={(props) => <DetailPage {...props} />}
                    />
                    <Route
                        path='/peternak/rearing'
                        exact
                        render={(props) => <RearingPage {...props} />}
                    />
                    <Route
                        path='/peternak/rearing/detail-rearing'
                        exact
                        render={(props) => <DetailRearingClosePage {...props} />}
                    />
                    <Route
                        path='/peternak/recording'
                        exact
                        render={(props) => <RearingRecordPage {...props} />}
                    />
                    <Route
                        path='/peternak/recording/detail'
                        exact
                        render={(props) => <DetailRearingRecordPage {...props} />}
                    />
                    <Route
                        path='/peternak/recording/detail/tambah-penyakit'
                        exact
                        render={(props) => <CreatePenyakitRecordingPage {...props} />}
                    />
                    <Route
                        path='/peternak/recording/detail/edit-penyakit'
                        exact
                        render={(props) => <EditPenyakitRecordingPage {...props} />}
                    />
                    <Route
                        path='/peternak/recording/detail/foto-penyakit'
                        exact
                        render={(props) => <FotoPenyakitRecordingPage {...props} />}
                    />
                    <Route
                        path='/peternak/stok-pakan'
                        exact
                        render={(props) => <StokPakanPage {...props} />}
                    />
                    <Route
                        path='/peternak/stok-pakan/transaksi'
                        exact
                        render={(props) => <TransaksiStokPakanPage {...props} />}
                    />
                    <Route
                        path='/peternak/stok-ovk'
                        exact
                        render={(props) => <StokOvkPage {...props} setCurrent={setCurrent} />}
                    />
                    <Route
                        path='/peternak/buat-stok'
                        exact
                        render={(props) => <CreateStokOvkPage {...props} />}
                    />
                    <Route
                        path='/peternak/stok-ovk/transaksi'
                        exact
                        render={(props) => <TransaksiStokOvkPage {...props} />}
                    />
                    <Route
                        path='/peternak/stok-material'
                        exact
                        render={(props) => <StokMaterialPage {...props} />}
                    />
                    <Route
                        path='/peternak/stok-material/transaksi'
                        exact
                        render={(props) => <TransaksiStokMaterialPage {...props} />}
                    />
                    <Route
                        path='/peternak/pemakaian-umum'
                        exact
                        render={(props) => <PemakaianUmumPage {...props} />}
                    />
                    <Route
                        path='/peternak/pemakaian-umum/buat-pemakaian-umum'
                        exact
                        render={(props) => <CreatePemakaianUmumPage {...props} />}
                    />
                    <Route
                        path='/peternak/device'
                        exact
                        render={(props) => role === 'staff' ? <Redirect to='/403' /> :
                            <MonitorPage {...props} />}
                    />
                    <Route
                        path='/peternak/ews'
                        exact
                        render={(props) => role === 'staff' ? <Redirect to='/403' /> :
                            <EwsPage {...props} />}
                    />
                    <Route
                        path='/peternak/device/detail-device'
                        exact
                        render={(props) => <DetailMonitorPage {...props} />}
                    />
                    <Route
                        path='/peternak/ews/detail-ews'
                        exact
                        render={(props) => role === 'staff' ? <Redirect to='/403' /> :
                            <DetailEwsPage {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode'
                        exact
                        render={(props) => <StockFlowPeriode {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-live-bird'
                        exact
                        render={(props) => <StockFlowLiveBird {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-pakan'
                        exact
                        render={(props) => <StockFlowPakan {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-ovk'
                        exact
                        render={(props) => <StockFlowOvk {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-material'
                        exact
                        render={(props) => <StockFlowMaterial {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-resume'
                        exact
                        render={(props) => <StockFlowResume {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode/pakan'
                        exact
                        render={(props) => <PeriodeFeed {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode/ovk'
                        exact
                        render={(props) => <PeriodeOvk {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode/material'
                        exact
                        render={(props) => <PeriodeMaterial {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode/livebird'
                        exact
                        render={(props) => <PeriodeLiveBird {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode/penyesuaian-pakan'
                        exact
                        render={(props) => <AdjustmentFeed {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode/penyesuaian-ovk'
                        exact
                        render={(props) => <AdjustmentOvk {...props} />}
                    />
                    <Route
                        path='/peternak/stock-flow-periode/penyesuaian-material'
                        exact
                        render={(props) => <AdjustmentMaterial {...props} />}
                    />
                    <Route
                        path='/peternak/master-kandang'
                        exact
                        render={(props) => <MasterKandangPage {...props} />}
                    />
                    <Route
                        path='/peternak/standar-lingkungan'
                        exact
                        render={(props) => <StandarLingkunganPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-gudang-pakan'
                        exact
                        render={(props) => <MasterGudangPakanPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-gudang-ovk'
                        exact
                        render={(props) => <MasterGudangOvkPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-gudang-material'
                        exact
                        render={(props) => <MasterGudangMaterialPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-material'
                        exact
                        render={(props) => <MasterMaterialPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-ovk'
                        exact
                        render={(props) => <MasterOvkPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-ovk/create-ovk'
                        exact
                        render={(props) => <CreateMasterOvkPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-ovk/edit-ovk'
                        exact
                        render={(props) => <EditMasterOvkPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-pakan'
                        exact
                        render={(props) => <MasterPakanPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-biaya'
                        exact
                        render={(props) => <CostPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-overhead'
                        exact
                        render={(props) => <OverheadPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-pengguna'
                        exact
                        render={(props) => <MasterPenggunaPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-strain'
                        exact
                        render={(props) => <StrainPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-strain/standar-strain'
                        exact
                        render={(props) => <StandarPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-penyakit'
                        exact
                        render={(props) => <PenyakitPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-customer'
                        exact
                        render={(props) => <CustomerPage {...props} />}
                    />
                    <Route
                        path='/peternak/master-supplier'
                        exact
                        render={(props) => <SupplierPage {...props} />}
                    />
                    <Route
                        path='/peternak/profil'
                        exact
                        render={(props) => <ProfilePage {...props} />}
                    />
                </Layout>
            </Layout>
        </Loading >
    )
}

export default LayoutComponent