export const createAdjustmentOvk = (value,idMonthlyStockOvk) => {
    return {
        query: `mutation{
                createAdjustmentOvk(adjustmentOvkInput:{
                    qty: ${value.jumlah}
                    ovk: "${value.ovk.value}"
                    monthlyStockOvk : "${idMonthlyStockOvk}"
                }){
                    _id
                }
            }`
    }
}

export const getAdjustmentOvk = (keyword,limit,skip,_id) => {
    return (
        `query{
            adjustmentOvks(keyword:"${keyword}",limit:${limit}, skip:${skip}, _id:"${_id}"){
                totalCount,
                adjustmentOvks{
                  _id
                  qty
                  ovk{
                      name
                      ovkUnit{
                          name
                      }
                  }
                }
              }
            }
        `
    );
}

export const deleteAdjustmentOvk = (_id) => {
    return (
        `mutation{
            deleteAdjustmentOvk(_id : "${_id}"){
                deleted
            }
            }
        `
    );
}

export const updateAdjustmentOvk = (_id,value) => {
    return {
        query: `                      
        mutation{
            updateAdjustmentOvk(_id: "${_id}",updatedAdjustmentOvkInput:{
                qty: ${parseFloat(value.jumlah)}
                ovk: "${value.ovk.value}"
            }){
              _id
            }
            }
        `
    }   
}

export const detailAdjustmentOvk = (_id) => {
    return (
        `query{
            adjustmentOvk(_id: "${_id}"){
                _id
                qty
                ovk{
                    value: _id
                    name
                    ovkUnit{
                        name
                    }
                }
            }
          }
        `
    );
}