import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreatePertumbuhanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-pertumbuhan-component'
import { createRecordPertumbuhan } from '../rearing-record-aksi-page/query-rearing-record'
import { getListGrowingParams } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { getGrowingParams } from '../rearing-record-aksi-page/query-rearing-record'
import { useTranslation } from "react-i18next"

function CreatePertumbuhanPage(props) {
    const { idRearingRecord, setLoading, fetchDataPertumbuhan, getListGrowingParams } = props
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        getListGrowingParams(getGrowingParams("", 0, 0))
    }, [getListGrowingParams])

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createRecordPertumbuhan(values, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createGrowing._id) {
                    validationMessage('success', `${t("pages.recording.detail.pertumbuhan.response.sukses.tambah")}`)
                    fetchDataPertumbuhan(idRearingRecord)
                } else {
                    validationMessage('error', `${t("pages.recording.detail.pertumbuhan.response.gagal.tambah")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.detail.pertumbuhan.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreatePertumbuhanComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            growingParam={props.growingParam}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    growingParam: state.rearingRecord.dataGrowingParams,
    dateRearing: state.rearingRecord.dateRearingRecord,
    idRearingRecord: state.rearingRecord.idRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getListGrowingParams
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePertumbuhanPage)
export default page