import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import { Modal,Form,Row,Col,Input,InputNumber } from 'antd';

function EditOverheadPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props;
    const [data, setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData();
    }, [detail]);

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={t('pages.master.overhead.modal.titleEdit')}
                okText="Edit"
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        name: data.name,
                        price: data.defaultPrice,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.overhead.modal.nama')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.overhead.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='name'
                                    placeholder="paper chick, BBM genset ..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.overhead.modal.price')}</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `${t('pages.master.overhead.modal.msgPrice')}` }]}
                            >
                                <InputNumber
                                    name='price'
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditOverheadPage);
export default page