import * as actionType from './iot-action-type';

const initialState = {
    dataTipeIot: [],
    loading: false,
    idStdEnv: null,
    nameStdEnv: null,
}

const handler = (currentState) => {
    const setDataTipeIot = {
        startGetDataTipeIots: () => ({
            ...currentState,
            loading: true,
        }),
        finishGetDataTipeIots: () => ({
            ...currentState,
            loading: false,
        }),
        getDataTipeIots: data => ({
            ...currentState,
            dataTipeIot: data
        }),
        setIdStdEnv: data => ({
            ...currentState,
            idStdEnv: data
        }),
        setNameStdEnv: data => ({
            ...currentState,
            nameStdEnv: data
        })
    }

    return {
        ...setDataTipeIot
    }
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataTipeIots()
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataTipeIots()
        case actionType.SET_DATA_TIPE_IOT:
            return handler(state).getDataTipeIots(payload)
        case actionType.SET_ID_STD_ENV:
            return handler(state).setIdStdEnv(payload)
        case actionType.SET_NAME_STD_ENV:
            return handler(state).setNameStdEnv(payload)

        default:
            return state
    }
}