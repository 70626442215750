export const createAdjustmentPakan = (value,idMonthlyStockFeed) => {
    return {
        query: `mutation{
                createAdjustmentFeed(adjustmentFeedInput:{
                    qty: ${value.jumlah}
                    feed: "${value.pakan.value}"
                    monthlyStockFeed : "${idMonthlyStockFeed}"
                }){
                    _id
                }
            }`
    }
}

export const getAdjustmentPakan = (keyword,limit,skip,_id) => {
    return (
        `query{
            adjustmentFeeds(keyword:"${keyword}",limit:${limit}, skip:${skip}, _id:"${_id}"){
                totalCount,
                adjustmentFeeds{
                  _id
                  qty
                  typeAdjustment
                  storeTime
                  feed{
                      name
                  }
                }
              }
            }
        `
    );
}

export const deleteAdjustmentPakan = (_id) => {
    return (
        `mutation{
            deleteAdjustmentFeed(_id : "${_id}"){
                deleted
            }
            }
        `
    );
}

export const updateAdjustmentPakan = (_id,value) => {
    return {
        query: `                      
        mutation{
            updateAdjustmentFeed(_id: "${_id}",updatedAdjustmentFeedInput:{
                qty: ${parseFloat(value.jumlah)}
                feed: "${value.pakan.value}"
            }){
              _id
            }
            }
        `
    }   
}

export const detailAdjustmentPakan = (_id) => {
    return (
        `query{
            adjustmentFeed(_id: "${_id}"){
                _id
                qty
                typeAdjustment
                storeTime
                feed{
                    value: _id
                    name
                }
            }
          }
        `
    );
}