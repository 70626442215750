import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreateRearingComponent from '../../../../modules/dashboard-peternak/rearing-component/rearing-aksi-component/create-rearing-component'
import { createPeriode, getPeriodeKandang } from './query-rearing'
import { getListPeriodeKandang } from '../../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { getStrain } from '../../master-page/strain-page/strain-aksi-page/query-strain'
import { useTranslation } from "react-i18next"

function CreateRearingPage(props) {
    const { fetchDataActive, getListPeriodeKandang, setLoading } = props
    const [visible, setVisible] = useState(false)
    const [strain, setStrain] = useState([])
    const { t } = useTranslation()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const fetchDataStrain = () => {
        API.get(getStrain())
            .then(res => {
                setStrain(res.data.data.strains.strains)
            }).catch((error) => {
                console.log(error)
                setStrain(null)
            })
    }

    useEffect(() => {
        fetchDataStrain()
    }, [])

    const postData = (values, year) => {
        API.post(createPeriode(values, year))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createRearing._id) {
                    validationMessage('success', `${t("pages.periode.periodeBerjalan.response.sukses.tambah")}`)
                    getListPeriodeKandang(getPeriodeKandang('', 0, 0, props.idKandang))
                    fetchDataActive()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.periode.periodeBerjalan.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'])
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateRearingComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            kandang={props.kandang}
            strain={strain}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    kandang: state.layout.dataKandang,
    idKandang: state.layout.idKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListPeriodeKandang,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateRearingPage)
export default page