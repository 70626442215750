import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import { navigate } from '../../../../common/store/action'

function EditStandardEnv(props) {
    const { visible, handleCancel, detail, handleSubmit, kandang, t } = props
    const [data, setData] = useState(detail)
    const { Option } = Select

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t("pages.monitor.editStandardEnv")}
                okText={t("general.edit")}
                width={800}
                cancelText={t("general.cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        name: data.name,
                        kandang: data.house,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.monitor.envName")}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t("pages.monitor.msgEnvName")}` }]}
                            >
                                <Input
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.monitor.house")}</span>
                            <Form.Item
                                name="kandang" rules={[{ required: true, message: `${t("pages.monitor.msgHouse")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.monitor.phHouse")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        kandang?.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditStandardEnv)
export default page