import * as actionType from './stok-pakan-action-type';
import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';

const startGetDataStokPakans = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataStokPakans = () => ({
    type : actionType.FINISH_GET_DATA
});

const setIdStokPakan = payload => ({
    type : actionType.SET_ID_STOK_PAKAN,
    payload,
});

const setDataStokPakan = payload => ({
    type : actionType.SET_STOK_PAKAN,
    payload,
});

const setDataNamaPakan = payload => ({
    type : actionType.SET_NAMA_PAKAN,
    payload,
});

export const getIdStokPakan = (id_stok) => (dispatch) => {
    dispatch(setIdStokPakan(id_stok))
}

export const getNamaPakan = (nama_pakan) => (dispatch) => {
    dispatch(setDataNamaPakan(nama_pakan))
}

export const getListPakan = (params) => (dispatch) => {
    dispatch(startGetDataStokPakans())
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.feedStocks.feedStocks){
            dispatch(setDataStokPakan(res.data.data.feedStocks.feedStocks))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataStokPakans())
    })
}