import React from 'react'
import { Layout, Row, Col,Table,  } from 'antd'
import '../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import Loading from '../../../common/component/loading/loading-container'

function RearingComponent(props) {
    const { columns, data,loading } = props
    return ( 
        <Layout style={{backgroundColor:"white"}}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table 
                            columns={columns} 
                            dataSource={data} 
                            bordered
                            pagination={false}
                            scroll={{ y: '60vh' }}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
            </Row>
        </Layout>
    )
}
 
export default RearingComponent