import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import TambahStokPakanComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/stok-pakan-aksi-component/tambah-stok-pakan-component';

import { useTranslation } from "react-i18next";

function TambahStokPakanPage(props) {
    const {visible,handleCancel,handleSubmit} = props;
    const {t} = useTranslation()

    return ( 
        <TambahStokPakanComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            locales={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(TambahStokPakanPage);
export default page