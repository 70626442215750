import React from 'react'
import { Table,  } from 'antd'
import '../../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import Loading from '../../../../common/component/loading/loading-container'

function TablePerformanceComponent(props) {
    const { columns, data, loading} = props
    return ( 
        <Loading loading={loading}>
            <Table 
                columns={columns} 
                dataSource={data} 
                bordered
                pagination={false}
                className="table-rearing-record"
                scroll={{ x: 1000, y: 300}}
            />
        </Loading>
    )
}
 
export default TablePerformanceComponent