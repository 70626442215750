import React, { useState,useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreateStokPakanComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/stok-pakan-aksi-component/create-stok-pakan-component'

import {createStokPakan} from './query-stok-pakan'

import { useTranslation } from "react-i18next"

function CreateStokPakanPage(props) {
    const {fetchData,setLoading} = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    },[])

    const { t } = useTranslation()

    const postData = (values,year) => {
        API.post(createStokPakan(values,year))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false)
            }else if(res.data.data.createFeedStock._id){
                validationMessage('success', `${t("pages.stok.pakan.response.sukses.tambah")}`)
                fetchData()
            }
        }).catch( ( error ) =>  {
            console.log(error)
            validationMessage('error', `${t("pages.stok.pakan.response.gagal.tambah")}`)
            setLoading (false)
        })
    }

    const handleSubmit = (values) => {
        postData(values,values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setLoading(true)
    }

    return ( 
        <CreateStokPakanComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            pakan = {props.pakan}
            gudangPakan = {props.gudangPakan}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    pakan : state.pakan.dataPakan,
    gudangPakan : state.gudangPakan.dataGudangPakan
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStokPakanPage)
export default page