import React from 'react'
import { Modal, Form, Row, Col, Input, DatePicker, Select } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'

function CreateStokMaterial(props) {
    const { visible, handleSubmit, setVisible, showModal, material, gudang, t } = props
    const { Option } = Select
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t("pages.stok.material.button.tambahBaru")}
                okText={t("general.create")}
                width={800}
                cancelText={t("general.cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.stok.material.tambah.gudang')}</span>
                            <Form.Item
                                name="gudang" rules={[{ required: true, message: `${t('pages.stok.material.tambah.msgGudang')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.stok.material.tambah.phGudang')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudang.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">Material</span>
                            <Form.Item
                                name="material" rules={[{ required: true, message: `${t('pages.stok.material.tambah.msgMaterial')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.stok.material.tambah.phMaterial')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        material.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.material.tambah.pengadaan')}</span>
                            <Form.Item
                                name="namaPengadaan" rules={[{ required: true, message: `${t('pages.stok.material.tambah.msgNamaPengadaan')}` }]}
                            >
                                <Input
                                    name='namaPengadaan'
                                    placeholder="Pengadaan..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.material.tambah.tglPengadaan')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, message: `${t('pages.stok.material.tambah.msgTglPengadaan')}` }]}
                            >
                                <DatePicker
                                    placeholder={t('pages.stok.material.tambah.phTglPengadaan')}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.material.tambah.harga')}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, message: `${t('pages.stok.material.tambah.msgHarga')}` }]}
                            >
                                <Input
                                    name='harga'
                                    placeholder="1000..."
                                    prefix="Rp"
                                    className="input-price mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.material.tambah.jumlah')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message: `${t('pages.stok.material.tambah.msgStok')}` }]}
                            >
                                <Input
                                    name='jumlahStok'
                                    placeholder="5..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={t('pages.stok.material.button.tambahBaru')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

export default CreateStokMaterial