import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { API } from '../../../../common/api'
import ResumeComponent from '../../../../modules/dashboard-peternak/stock-flow-component/resume-component/resume-component'
import { getResumeMonthlyStocks } from './resume-aksi-page/query-resume'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { useTranslation } from 'react-i18next'

function ResumePage(props) {
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [month, setMonth] = useState(moment().format('MMMM'))
    const [year, setYear] = useState(moment().format('YYYY'))

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    /**Handle Get Data */
    const fetchData = (month, year) => {
        setLoading(true);
        API.get(getResumeMonthlyStocks('', 0, 0, month, year))
            .then(res => {
                setObject(res.data.data.resumeMonthlyStocks.resume)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(month, year)
    }, [month, year])

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            fixed: 'left',
            width: 100,
            ...getColumnSearchProps('item', 'item'),
        },
        {
            title: `${t('pages.stockflow.ovk.table.saldoAwal.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAwal.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyAwal',
                    key: 'qtyAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAwal.hs')}`,
                    className: 'right',
                    dataIndex: 'hargaSatuanAwal',
                    key: 'hargaSatuanAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAwal.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalAwal',
                    key: 'hargaTotalAwal',
                    width: 150,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.masuk.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.masuk.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPengadaan',
                    key: 'qtyPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.masuk.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPengadaan',
                    key: 'hsPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.masuk.nilai')}`,
                    className: 'right',
                    dataIndex: 'totalPengadaan',
                    key: 'totalPengadaan',
                    width: 150,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.keluar.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.keluar.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenggunaan',
                    key: 'qtyPenggunaan',
                    width: 110,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.keluar.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPenggunaan',
                    key: 'hsPenggunaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.keluar.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalPenggunaan',
                    key: 'hargaTotalPenggunaan',
                    width: 150,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.penyesuaian.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.penyesuaian.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenyesuaian',
                    key: 'qtyPenyesuaian',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.penyesuaian.hs')}`,
                    className: 'right',
                    dataIndex: 'hsAdj',
                    key: 'hsAdj',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.penyesuaian.nilai')}`,
                    className: 'right',
                    dataIndex: 'totalPenyesuaian',
                    key: 'totalPenyesuaian',
                    width: 150,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.ovk.table.saldoAkhir.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAkhir.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyStokAkhir',
                    key: 'qtyStokAkhir',
                    width: 110,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAkhir.hs')}`,
                    className: 'right',
                    dataIndex: 'hsStokAkhir',
                    key: 'hsStokAkhir',
                    width: 140,
                },
                {
                    title: `${t('pages.stockflow.ovk.table.saldoAkhir.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalStokAkhir',
                    key: 'hargaTotalStokAkhir',
                    width: 160,
                },
            ],
        },
    ]

    const data = object.map(({ unit, bgnQty, bgnPrice, bgnTotal, inTotal, priceIn, totalPrice, outTotal, priceOut, totalOut, qtyAdj, hsAdj, totalAdj, sisaStok, nilaiSisaStok, hargaSisaStok }, index) => ({
        key: unit,
        item: unit,

        bgnQty: bgnQty,
        bgnTotal: bgnTotal,
        qtyAwal: bgnQty < 0 ? <NumberFormat value={Math.abs(bgnQty)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={bgnQty} displayType={'text'} thousandSeparator={true} />,
        hargaSatuanAwal: <NumberFormat value={bgnPrice < 0 ? Math.abs(bgnPrice?.toFixed(0)) : bgnPrice?.toFixed(0)} displayType={'text'} thousandSeparator={true}
            prefix={bgnPrice < 0 ? '(' : ''} suffix={bgnPrice < 0 ?  ')' : ''} />,
        hargaTotalAwal: bgnTotal < 0 ? <NumberFormat value={Math.abs(bgnTotal.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={bgnTotal.toFixed(0)} displayType={'text'} thousandSeparator={true} />,

        inTotal: inTotal,
        totalPrice: totalPrice,
        qtyPengadaan: inTotal < 0 ? <NumberFormat value={Math.abs(inTotal)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={inTotal} displayType={'text'} thousandSeparator={true} />,
        hsPengadaan: <NumberFormat value={priceIn < 0 ? Math.abs(priceIn?.toFixed(0)) : priceIn?.toFixed(0)} displayType={'text'} thousandSeparator={true}
            prefix={priceIn < 0 ? '(' : ''} suffix={priceIn < 0 ? ')' : ''} />,
        totalPengadaan: totalPrice < 0 ? <NumberFormat value={Math.abs(totalPrice.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={totalPrice.toFixed(0)} displayType={'text'} thousandSeparator={true} />,

        outTotal: outTotal,
        totalOut: totalOut,
        qtyPenggunaan: outTotal < 0 ? <NumberFormat value={Math.abs(outTotal)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={outTotal} displayType={'text'} thousandSeparator={true} />,
        hsPenggunaan: <NumberFormat value={priceOut < 0 ? Math.abs(priceOut?.toFixed(0)) : priceOut?.toFixed(0)} displayType={'text'} thousandSeparator={true}
            prefix={priceOut < 0 ? '(' : ''} suffix={priceOut < 0 ? ')' : ''} />,
        hargaTotalPenggunaan: totalOut < 0 ? <NumberFormat value={Math.abs(totalOut.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={totalOut.toFixed(0)} displayType={'text'} thousandSeparator={true} />,

        qtyAdj: qtyAdj,
        totalAdj: totalAdj,
        qtyPenyesuaian: qtyAdj < 0 ? <NumberFormat value={Math.abs(qtyAdj)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={qtyAdj} displayType={'text'} thousandSeparator={true} />,
        hsAdj: <NumberFormat value={hsAdj?.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
        totalPenyesuaian: totalAdj < 0 ? <NumberFormat value={Math.abs(totalAdj.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={totalAdj.toFixed(0)} displayType={'text'} thousandSeparator={true} />,

        sisaStok: sisaStok,
        nilaiSisaStok: nilaiSisaStok,
        qtyStokAkhir: sisaStok < 0 ? <NumberFormat value={Math.abs(sisaStok)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={sisaStok} displayType={'text'} thousandSeparator={true} />,
        hsStokAkhir: <NumberFormat value={hargaSisaStok < 0 ? Math.abs(hargaSisaStok?.toFixed(0)) : hargaSisaStok?.toFixed(0)} displayType={'text'} thousandSeparator={true}
            prefix={hargaSisaStok < 0 ? '(' : ''} suffix={hargaSisaStok < 0 ? ')' : ''} />,
        hargaTotalStokAkhir: nilaiSisaStok < 0 ? <NumberFormat value={Math.abs(nilaiSisaStok.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
            <NumberFormat value={nilaiSisaStok.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
    }))

    const handleTanggalRange = (dateStrings) => {
        setMonth(moment(dateStrings).format('MMMM'))
        setYear(moment(dateStrings).format('YYYY'))
    }

    const disabledDate = (current) => {
        return current > moment().endOf('day')
    }

    return (
        <ResumeComponent
            navigate={props.navigate}
            setLoading={setLoading}
            data={data}
            columns={columns}
            loading={loading}
            month={month} year={year}
            handleTanggalRange={handleTanggalRange}
            disabledDate={disabledDate}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(ResumePage)
export default page