import React from 'react';
import { Form, Row, Col, Select, Layout, Button, Breadcrumb, Card, InputNumber } from 'antd';
import { Link } from 'react-router-dom';
import CONSTANTS from '../../../../common/utils/Constants';
import Loading from '../../../../common/component/loading/loading-container';

/*Import Moment Js */
import moment from 'moment-timezone';
import 'moment-timezone';
import 'moment/locale/id';
/*End Import Moment Js */

function CreatePemakaianUmumOvk(props) {
    const { handleSubmit, kandang, handleFailed, navigate, handleChangeKandang, periode, handlePeriode,
        handleRearingRecord, rearingRecord, stokOvk, loading, handleOvk, form, t } = props
    const { Option } = Select
    const { Content } = Layout
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/pemakaian-umum"> <span>{t('pages.pemakaianUmum.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.pemakaianUmum.tambah.title')}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title={t('pages.pemakaianUmum.tambah.title')} bordered={false}>
                                <Form onFinish={handleSubmit} form={form} onFinishFailed={handleFailed} name="basic" initialValues={{ remember: true }}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.pemakaianUmum.tambah.kandang')}</span>
                                            <Form.Item
                                                name="kandang" rules={[{ required: true, message: `${t('pages.pemakaianUmum.tambah.msgKandang')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.pemakaianUmum.tambah.phKandang')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    onChange={value => handleChangeKandang(value)}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        kandang.map(data =>
                                                            <Option
                                                                key={data.name.toString()}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.pemakaianUmum.tambah.periode')}</span>
                                            <Form.Item
                                                name="periode" rules={[{ required: true, message: `${t('pages.pemakaianUmum.tambah.msgPeriode')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.pemakaianUmum.tambah.phPeriode')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    onChange={value => handlePeriode(value)}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        periode.map(data =>
                                                            <Option
                                                                key={data.name.toString()}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.pemakaianUmum.tambah.tanggal')}</span>
                                            <Form.Item
                                                name="tanggal" rules={[{ required: true, message: `${t('pages.pemakaianUmum.tambah.msgTgl')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.pemakaianUmum.tambah.phTgl')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    onChange={value => handleRearingRecord(value)}
                                                    className="select-kategori black font-regular mt-5"
                                                >
                                                    {
                                                        rearingRecord.map(data =>
                                                            <Option
                                                                key={data._id.toString()}
                                                                value={data._id}
                                                            >{moment(new Date(parseInt(data.tanggal))).format("DD-MM-YYYY")}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >OVK</span>
                                            <Form.Item
                                                name="ovk" rules={[{ required: true, message: `${t('pages.pemakaianUmum.tambah.msgOvk')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.pemakaianUmum.tambah.phOvk')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    onChange={value => handleOvk(value)}
                                                    className="select-kategori black font-regular mt-5"
                                                >
                                                    {
                                                        stokOvk.map(data =>
                                                            <Option
                                                                value={data._id}
                                                            >{data.ovk.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.pemakaianUmum.tambah.jumlah')}</span>
                                            <Form.Item
                                                name="jumlah" rules={[{ required: true, message: `${t('pages.pemakaianUmum.tambah.msgJumlah')}` }]}
                                            >
                                                <InputNumber
                                                    name='jumlah'
                                                    placeholder="1000..."
                                                    className="input-number mt-5"
                                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">Harga</span>
                                            <Form.Item
                                                name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                                            >
                                                <InputNumber
                                                    name='price'
                                                    placeholder="1000..."
                                                    className="input-number mt-5"
                                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button
                                        onClick={() => navigate(CONSTANTS.PEMAKAIAN_UMUM_MENU_KEY)}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ marginLeft: 8, marginTop: 0 }}
                                    >
                                        {t('general.create')}
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    );
}

export default CreatePemakaianUmumOvk;