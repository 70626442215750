import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CONSTANTS from '../../../../common/utils/Constants'
import EditPenyakitComponent from '../../../../modules/dashboard-peternak/rearing-record-component/edit-detail-rearing-component/edit-penyakit-new-component'
import { useTranslation } from "react-i18next"

function EditPenyakitPage(props) {
    const {detail,idRearingRecord} = props
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        async function getDetailData() {
            if(props.detailPenyakit){
                setLoading(true)  
                setLoading(false)            
            }
        }
      getDetailData()
    }, [props])

    const postData =  async (values) => {
        const params = new FormData()
        params.set('disease',values.penyakit.value)
        params.set('number',values.jumlahAyam)
        params.set('rearingRecord',idRearingRecord)
        setLoading(true)
        await API.updateRest(`/update-penyakit/${props.detailPenyakit._id}`,params)
        .then(res => {
            if(res.status === 200){
                if(res.data.success === true){
                    props.navigate(CONSTANTS.RECORDING_MENU_KEY)
                    validationMessage('success', 'Berhasil Mengubah Data Penyakit')
                }else{
                    validationMessage('error', 'Gagal Mengubah Data Penyakit')
                    setLoading(false)
                }
            }else{
                validationMessage('error', 'Gagal Mengubah Data Penyakit')
                setLoading(false)
            }
            setLoading(false)
        }).catch((error) => {
            validationMessage('error', 'Gagal Mengubah Data Penyakit')
            setLoading(false)
        })
    }

    const handleSubmit = (values) => {
        postData(values)
    }

    return ( 
        <EditPenyakitComponent
            navigate={props.navigate}
            handleSubmit = {handleSubmit}
            detail = {detail}
            setLoading = {setLoading}
            loading = {loading}
            penyakit = {props.penyakit}
            umur = {props.age}
            locales = {t}
            detailPenyakit = {props.detailPenyakit}
        />
    )
}

const mapStateToProps = state => ({
    penyakit : state.penyakit.dataPenyakit,
    age : state.rearingRecord.age,
    idRearingRecord : state.rearingRecord.idRearingRecord,
    detailPenyakit : state.rearingRecord.detailDiseaseRecord,
})
const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPenyakitPage)
export default page