import React, { Component } from 'react';

class ErrorPage extends Component {
    state = {  }
    render() { 
        return ( 
            <h1>Error Page 403</h1>
        );
    }
}
 
export default ErrorPage;