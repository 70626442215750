import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CONSTANTS from '../../../../common/utils/Constants'
import CreatePenyakitComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-penyakit-new-component'
import { getPenyakit } from '../../../../app/dashboard-peternak/master-page/penyakit-page/penyakit-aksi-page/query-penyakit'
import { getListDisease } from '../../../../modules/dashboard-peternak/master-component/penyakit-component/store/penyakit-action'
import { useTranslation } from "react-i18next"

function CreatePenyakitPage(props) {
    const { idRearingRecord, getListDisease } = props
    const [fileList, setFileList] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        getListDisease(getPenyakit("", 0, 0));
    }, [getListDisease])

    const postData = (values, fileUpload) => {
        const params = new FormData()
        params.set('disease', values.penyakit.value)
        params.set('number', values.jumlahAyam)
        params.set('rearingRecord', idRearingRecord)
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                params.append(`file[${i}]`, fileUpload[i])
            }
        }
        setLoading(true)
        API.postRest('/upload-penyakit', params)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.success === true) {
                        props.navigate(CONSTANTS.RECORDING_MENU_KEY)
                        validationMessage('success', `${t("pages.recording.detail.penyakit.response.sukses.tambah")}`)
                        setLoading(false)
                    } else {
                        validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.tambah")}`)
                        setLoading(false)
                    }
                } else {
                    validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.tambah")}`)
                    setLoading(false)
                }
                setLoading(false)
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, fileUpload)
    }

    const propsPicture = {
        name: 'file',
        onChange(info) {
            if (info.file.status === 'done') {
                let arrayPicture = []
                for (let i = 0; i < info.fileList.length; i++) {
                    arrayPicture.push(info.fileList[i].originFileObj)
                }
                setFileList(info.fileList)
                setFileUpload(arrayPicture)
            } else if (info.file.status === 'error') {
                console.log('err', info.file, info.fileList)
            }
        }
    }

    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }

    return (
        <CreatePenyakitComponent
            navigate={props.navigate}
            handleSubmit={handleSubmit}
            penyakit={props.penyakit}
            dummyRequest={dummyRequest}
            fileList={fileList}
            propsPicture={propsPicture}
            umur={props.age}
            loading={loading}
            idRearingRecord={props.idRearingRecord}
            onPreview={onPreview}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    penyakit: state.penyakit.dataPenyakit,
    age: state.rearingRecord.age
})

const mapDispatchToProps = (dispatch => ({
    navigate, getListDisease
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePenyakitPage)
export default page