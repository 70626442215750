export const getPetugas = (keyword, limit, skip) => {
    return (
        `query{
            staffs(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              staffs{
                    _id
                    name
                    email
                    username
                    address
                    phone
                    accessshouse{
                      _id
                      name
                    }
                }
            }
          }
        `
    );
}

export const getManajemen = (keyword, limit, skip) => {
  return (
      `query{
          managements(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
            managements{
                  _id
                  name
                  email
                  username
                  address
                  phone
              }
          }
        }
      `
  );
}

export const deletePetugas = (_id) => {
  return (
    `mutation{
        deleteUser(_id : "${_id}"){
            deleted
      }
    }
  ` 
  );
} 

export const createPetugas = (value) => {
  return {
    query: `mutation{
          createOfficer(userInput: {
                name : "${value.namaPetugas}"
                username : "${value.username}"
                password :"${value.password}"
                email : "${value.email}"
                phone : "${value.nomorTelefon}"
                address : "${value.alamat}"
          },
          accessInput: {
                house : "${value.kandang}"
          }
          ){
            _id
          }
        }
      `
    }
}

export const createManajemen = (value) => {
  return {
    query: `mutation{
            createManagement(userInput: {
                name : "${value.namaManajemen}"
                username : "${value.username}"
                password :"${value.password}"
                email : "${value.email}"
                phone : "${value.nomorTelefon}"
                address : "${value.alamat}"
          }){
            _id
          }
        }
      `
    }
}

export const detailPetugasKandang = (_id) => {
  return (
    `query{
        offcier(_id : "${_id}"){
            _id
            name
            email
            username
            password
            foto
            accessshouse{
              _id
              name
            }
            address
            phone
            value : role
        }
      }
    `
  );
}

export const detailManajemen = (_id) => {
  return (
    `query{
        user(_id : "${_id}"){
            _id
            name
            email
            username
            password
            foto
            value : role
            address
            phone
        }
      }
    `
  );
}

export const updatePetugasKandang = (_id, value) => {
    return {
    query: `mutation{
              updateOfficer(_id : "${_id}", updateOfficerInput : {
                    name : "${value.namaPetugas}",
                    username : "${value.username}",
                    email : "${value.email}",
                    phone : "${value.nomorTelefon}",
                    address : "${value.alamat}",
                    house : "${value.kandang}",
                    role : "${value.role.value}",
              }){
                  _id
              }
            }
        `
    }
}

export const updateManajemen = (_id, value) => {
  return {
  query: `mutation{
              updateManagement(_id : "${_id}", updateManagementInput : {
                  name : "${value.namaManajemen}",
                  username : "${value.username}",
                  email : "${value.email}",
                  phone : "${value.nomorTelefon}",
                  address : "${value.alamat}",
                  role : "${value.role.value}",
            }){
                _id
            }
          }
      `
  }
}