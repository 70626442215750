import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditAdjustmentComponent from '../../../../../modules/dashboard-peternak/adjustment-stock-component/material-component/material-aksi-component/edit-adjustment-material-component'

function EditAdjustmentMaterialPage(props) {
    const {visible,handleCancel,detail,handleSubmit,object,t} = props
    const [data,setData] = useState(detail)
    const [hs, setHs] = useState(null)
    const [qty, setQty] = useState(null)
    const [dif, setDif] = useState(null)
    const hsSak = object.filter(d => d?.idMaterial === data?.material?.value)[0]?.hargaPengeluaran
    const qtySak = object.filter(d => d?.idMaterial === data?.material?.value)[0]?.sisaStok
    const difference = data?.qty-qty

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
                setHs(hsSak)
                setQty(qtySak)
                setDif(difference)
            }
        }   
        setDetailData()
    }, [detail,hsSak,qtySak,difference])

    return ( 
        <EditAdjustmentComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            object={object}
            hs={hs}
            qty={qty} 
            dif={dif}
            materials = {props.material}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    material : state.material.dataMaterial,
    idMonthlyStockMaterial : state.adjustmentMaterial.idMonthlyStockMaterial,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditAdjustmentMaterialPage)
export default page