export const getPeriode = (keyword,limit,skip) => {
    return (
        `query{
            rearings(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
                rearings{
                  _id
                  name
                  chickInDate
                  parentAge
                }
              }
          }
      `
    )
}

export const getPeriodeKandang = (keyword,limit,skip,house) => {
  return (
    `query{
      rearingHouses(keyword : "${keyword}", limit : ${limit}, skip : ${skip}, house : "${house}"){
          totalCount
            rearings{
              _id
              name
              chickInDate
              parentAge
            }
          }
      }
  `
)
}
export const getRearings = (keyword,limit,skip) => {
  return (
      `query{
          rearings(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
              rearings{
                  _id
                  name
                  age
                  fcr
                  totalBw
                  hpp
                  hppEkor
                  saldo
                  parentAge
                  house{
                      houseId: _id
                      warehouseName: name
                  }
                  updatedAt
              }
            }
        }
    `
  )
}
export const getPeriodeAll = (keyword,limit,skip) => {
  return (
      `query{
          rearings(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
              rearings{
                  _id
                  name
                  chickInDate
                  chickInWeight
                  lastDate
                  population
                  populationHpp
                  otherInformation
                  price
                  age
                  adg
                  deplesi
                  feedIntake
                  fcr
                  totalBw
                  ip
                  ppl
                  parentAge
                  house{
                      houseId: _id
                      warehouseName: name
                      capacity
                      address
                  }
                  strain{
                    name
                  }
              }
            }
        }
    `
  )
}

export const getPeriodeCloseAll = (keyword,limit,skip) => {
  return (
      `query{
        rearingClose(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
              rearings{
                  _id
                  name
                  chickInDate
                  chickInWeight
                  population
                  populationHpp
                  price
                  otherInformation
                  parentAge
                  house{
                      houseId: _id
                      warehouseName: name
                      capacity
                      address
                  }
                  strain{
                    name
                  }
              }
            }
        }
    `
  )
}

export const createPeriode = (value,year) => {
  return {
    query: `mutation{
              createRearing(rearingInput:{
                chickInDate: "${year}"
                name: "${value.nama_periode}"
                chickInWeight: ${parseInt(value.bobot)}
                population: ${parseInt(value.populasiAwal)}
                populationHpp: ${parseInt(value.populasiHpp)}
                otherInformation: "${value.keterangan}"
                price : ${parseFloat(value.harga)}
                strain : "${value.strain.value}"
                ppl : "${value.ppl}"
                house: "${value.kandang.value}"
                parentAge: ${value.parentAge}
              }){
                _id
              }
            }
        `
}
}

export const closePeriode = (_id,value) => {
    return {
        query: `                      
            mutation{
              closeRearing(_id: "${_id}",updateRearingInput:{
                chickCloseDate: "${value}"
                }){
                  _id
                }
            }
        `
    }
}
export const openPeriode = (_id) => {
  return {
      query: `                      
          mutation{
            openRearing(_id: "${_id}",updateRearingInput:{
              }){
                _id
              }
          }
      `
  }
}

export const detailPeriode = (_id) => {
  return (
    `query{
        rearing(_id: "${_id}"){
            _id
            name
            chickInDate
            lastDate
            chickInWeight
            population
            populationHpp
            otherInformation
            price
            ppl
            parentAge
            house{
              value: _id
              key: name
            }
            strain{
              value: _id
              key: name
            }
          }
        }
    `
  )
}

export const updatePeriode = (_id,value,year) => {
    return {
      query: `                      
          mutation{
              updateRearing(_id: "${_id}",updateRearingInput:{
                name: "${value.nama_periode}"
                chickInDate: "${year}"
                chickInWeight: ${parseInt(value.bobot)}
                population: ${parseInt(value.populasiAwal)}
                populationHpp: ${parseInt(value.populasiHpp)}
                otherInformation: "${value.keterangan}"
                price : ${parseFloat(value.harga)}
                house  :"${value.kandang.value}"
                ppl : "${value.ppl}"
                parentAge: ${value.parentAge}
              }){
                _id
              }
          }
      `
  }
}

export const deletePeriode = (_id) => {
  return (
    `mutation{
        deleteRearing(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}
