import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import _ from 'lodash'
import './styles.scss'
import { withTranslation } from 'react-i18next'

am4core.useTheme(am4themes_animated)

class GaugeSuhu extends Component {
    static propTypes = {
        chartId: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
            }).isRequired,
        ).isRequired,
        rtl: PropTypes.bool,
    }

    static defaultProps = {
        rtl: false
    }

    componentDidMount() {
        this.initChart()
    }


    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            if (this.chart._super) {
                this.chart.dispose()
            }
            this.initChart()
        }
    }

    componentWillUnmount() {
        if (this.chart._super) {
            this.chart.dispose()
        }
    }

    initChart() {
        const { chartId, data, chartMin, chartMax, fontSize, suffix, fill1, fill2, fixed } = this.props
        this.chart = am4core.create(chartId, am4charts.GaugeChart)
        this.chart.fontSize = 4
        this.chart.innerRadius = am4core.percent(82)

        let axis = this.chart.xAxes.push(new am4charts.ValueAxis())
        axis.min = chartMin
        axis.max = chartMax
        axis.strictMinMax = true
        axis.renderer.radius = am4core.percent(94)
        axis.renderer.inside = true
        axis.renderer.line.stroke = am4core.color(fill1)
        axis.renderer.ticks.template.stroke = am4core.color(fill1)
        axis.renderer.line.strokeOpacity = 0.5
        axis.renderer.ticks.template.disabled = false
        axis.renderer.ticks.template.strokeOpacity = 0.5
        axis.renderer.labels.template.fontSize = "1.5em"
        axis.renderer.ticks.template.length = 3
        axis.renderer.grid.template.disabled = true
        axis.renderer.labels.template.radius = 20
        axis.renderer.labels.template.fill = am4core.color(fill1)
        axis.renderer.labels.template.adapter.add("text", function (text) {
            return text + suffix
        })

        let axis2 = this.chart.xAxes.push(new am4charts.ValueAxis())
        axis2.min = chartMin
        axis2.max = chartMax
        axis2.strictMinMax = true
        axis2.renderer.radius = am4core.percent(120)
        axis2.renderer.labels.template.disabled = true
        axis2.renderer.ticks.template.disabled = true
        axis2.renderer.grid.template.disabled = true

        let range0 = axis2.axisRanges.create()
        range0.value = chartMin
        range0.endValue = data
        range0.axisFill.fillOpacity = 1
        range0.grid.strokeOpacity = 0
        range0.axisFill.fill = fill1

        let range1 = axis2.axisRanges.create()
        range1.value = data
        range1.endValue = chartMax
        range1.axisFill.fillOpacity = 1
        range1.grid.strokeOpacity = 0
        range1.axisFill.fill = fill2

        let label = this.chart.radarContainer.createChild(am4core.Label)
        label.isMeasured = false
        label.fontSize = fontSize
        label.x = am4core.percent(50)
        label.y = am4core.percent(100)
        label.horizontalCenter = "middle"
        label.verticalCenter = "bottom"
        label.fill = fill1
        label.text = `${data?.toFixed(fixed)} ${suffix}`


        // setInterval(function () {
        //     let value = data.latest.temperature
        //     hand.showValue(value, 1000, am4core.ease.cubicOut)
        // }, 3000)
    }

    render() {
        return <div id={this.props.chartId} className={this.props.className} />
    }
}

export default withTranslation()(GaugeSuhu)