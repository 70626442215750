import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from "@amcharts/amcharts4/themes/animated"
import _ from 'lodash'
import './styles.scss'
import { withTranslation } from 'react-i18next'

am4core.useTheme(am4themes_animated)

class ThiChart extends Component {
    static propTypes = {
        chartId: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
            }).isRequired,
        ).isRequired,
        rtl: PropTypes.bool,
    }

    static defaultProps = {
        rtl: false
    }

    componentDidMount() {
        this.initChart()
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            if (this.chart._super) {
                this.chart.dispose()
            }
            this.initChart()
        }
    }

    componentWillUnmount() {
        if (this.chart._super) {
            this.chart.dispose()
        }
    }

    initChart() {
        const { chartId, t } = this.props

        this.chart = am4core.create(chartId, am4charts.XYChart)
        this.chart.scrollbarX = new am4core.Scrollbar()
        this.chart.exporting.menu = new am4core.ExportMenu()
        this.chart.exporting.menu.align = "left"
        this.chart.exporting.menu.verticalAlign = "top"

        // Create value axis
        let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
        dateAxis.baseInterval = {
            "timeUnit": "minute",
            "count": 1
        }
        dateAxis.renderer.minGridDistance = 60
        dateAxis.title.text = `${t('pages.dashboard.iot.date')}`

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.baseValue = 0
        valueAxis.title.text = `${t('pages.dashboard.iot.value')}`

        // Create series
        let series = this.chart.series.push(new am4charts.LineSeries())
        series.dataFields.valueY = "value"
        series.dataFields.valueZ = "timestamp"
        series.dataFields.valueA = "status"
        series.dataFields.dateX = "ts"
        series.strokeWidth = 3
        series.tooltip.pointerOrientation = "horizontal"

        this.chart.cursor = new am4charts.XYCursor()
        this.chart.cursor.snapToSeries = series
        this.chart.cursor.xAxis = dateAxis
        dateAxis.keepSelection = true


        let bullet = series.bullets.push(new am4charts.Bullet())
        bullet.tooltipText = "THI: [bold]{valueY}[/]\nstatus: [bold]{valueA}[/]\n{valueZ}"

        bullet.adapter.add("fill", function (fill, target) {
            if (target.dataItem.valueY > 74 && target.dataItem.valueY <= 78) {
                return am4core.color("#f5e942")
            } else if (target.dataItem.valueY > 78 && target.dataItem.valueY <= 83) {
                return am4core.color("#fcad35")
            } else if (target.dataItem.valueY > 83) {
                return am4core.color("#FF0000")
            } else if (target.dataItem.valueY <= 74) {
                return am4core.color("#02db93")
            }
            return fill
        })
        let range = valueAxis.createSeriesRange(series)
        range.value = 1000
        range.endValue = 83.01
        range.contents.stroke = am4core.color("#FF0000")
        range.contents.fill = range.contents.stroke

        let range2 = valueAxis.createSeriesRange(series)
        range2.value = 83
        range2.endValue = 78.01
        range2.contents.stroke = am4core.color("#fcad35")
        range2.contents.fill = range2.contents.stroke

        let range3 = valueAxis.createSeriesRange(series)
        range3.value = 78
        range3.endValue = 74.01
        range3.contents.stroke = am4core.color("#f5e942")
        range3.contents.fill = range3.contents.stroke

        let range4 = valueAxis.createSeriesRange(series)
        range4.value = 74
        range4.endValue = -1000
        range4.contents.stroke = am4core.color("#02db93")
        range4.contents.fill = range4.contents.stroke

        // add data
        this.chart.data = this.props.data
    }

    render() {
        return <div id={this.props.chartId} className={this.props.className} />
    }
}

export default withTranslation()(ThiChart)