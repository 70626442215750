import React from 'react';
import { Layout, Breadcrumb, Row, Col, Table } from 'antd';
import Loading from '../../../../common/component/loading/loading-container';
import ModalCreateStandarLingkungan from '../../../../app/dashboard-peternak/master-page/standar-lingkungan-page/standar-lingkungan-aksi-page/create-standar-lingkungan-page';
import ModalEditStandarLingkungan from '../../../../app/dashboard-peternak/master-page/standar-lingkungan-page/standar-lingkungan-aksi-page/edit-standar-lingkungan-page';

function StandarLingkunganComponent(props) {
    const { Content } = Layout;
    const { columns, data, loading, fetchData, setLoading, visible, handleOk, handleCancel, handleSubmit, detail, setVisible, t } = props;
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.lingkungan.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateStandarLingkungan fetchData={fetchData} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                className="table-dashboard"
                                pagination={false}
                                scroll={{ y: '70vh' }}
                            />
                        </Loading>
                    </Col>
                    <ModalEditStandarLingkungan visible={visible} handleOk={handleOk}
                        detail={detail} handleCancel={handleCancel} setVisible={setVisible}
                        handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    );
}

export default StandarLingkunganComponent;