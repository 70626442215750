import React, {useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../common/store/action';
import { API } from '../../common/api';
import { validationMessage } from '../../common/component/notification/notification';
import ChangePasswordComponent from '../../modules/forgot-password/change-password-component';
import CONSTANTS from '../../common/utils/Constants';

function ChangePasswordPage(props) {
    const [password,setPassword] = useState('');
    const [loading,setLoading] = useState(false);
    const [current, setCurrent] = useState('');

    useEffect(() => {
        let pathArray = window.location.pathname.split('/');
        let pathName = pathArray[2];
        pathName === '' ? setCurrent('/password-reset') : setCurrent(pathName);
    },[props]);

    const handleSubmit = (value) => {
        console.log(value)
        const query = {
            query: 
            `mutation{
                resetPassword(resetPasswordToken : "${current}",resetPasswordInput:{
                    newPassword : "${value.kataSandi}"
                 }){
                    name
                    password
              }
            }
            `,
        };
        if(password !== null){
            const postData =  async () => {
                setLoading(true);
                await API.post(query)
                .then(res => {        if(res.data.data === null){
                        validationMessage('error', res.data.errors[0].message)
                        setLoading(false);
                    }else{
                        try {
                            if(res.data.data.resetPassword.name){
                                validationMessage('success', 'Berhasil Mengubah Password Anda');
                                props.navigate(CONSTANTS.HOME_MENU_KEY);
                                setLoading(false);
                            }
                            else{
                                validationMessage('error', 'Gagal Mengirimkan Email')
                                setLoading(false);
                            }
                        } catch (error) {
                            console.log(error);
                            validationMessage('error', 'Gagal Mengirimkan Email');
                            setLoading(false);
                        }
                    }
                })
            }
            postData();
        }else{
            validationMessage('error','Password Belum Dimasukkan!');
        }
    };

    return ( 
        <ChangePasswordComponent
            navigate={props.navigate}
            handleSubmit ={handleSubmit}
            password = {password}
            setPassword = {setPassword}
            loading = {loading}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage);
export default page