import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import * as actionType from './iot-action-type'

const startGetDataTipeIots = () => ({
    type: actionType.START_GET_DATA
})

const finishGetDataTipeIots = () => ({
    type: actionType.FINISH_GET_DATA
})

const setDataTipeIot = payload => ({
    type: actionType.SET_DATA_TIPE_IOT,
    payload,
})
const setIdStdEnv = payload => ({
    type : actionType.SET_ID_STD_ENV,
    payload,
})
const setNameStdEnv = payload => ({
    type : actionType.SET_NAME_STD_ENV,
    payload,
})

export const getIdStdEnv = (data) => (dispatch) => {
    dispatch(setIdStdEnv(data))
}
export const getNameStdEnv = (data) => (dispatch) => {
    dispatch(setNameStdEnv(data))
}
export const getListTipeIot = (params) => (dispatch) => {
    dispatch(startGetDataTipeIots())
    API.get(params)
        .then(res => {
            if (res === undefined) { validationMessage('error', 'Connection error') }
            else if (res.data.data.deviceTypes) {
                dispatch(setDataTipeIot(res.data.data.deviceTypes.deviceTypes))
            } else {
                validationMessage('error', 'Data Error')
            }
            dispatch(finishGetDataTipeIots())
        }).catch(e => {
            validationMessage('error', e.message)
        })
}