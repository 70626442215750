import * as actionType from './adjustment-material-action-type';

const setIdMonthlyStockMaterial = payload => ({
    type : actionType.SET_ID_MONTHLY_STOCK_MATERIAL,
    payload,
})

export const getIdMonthlyStock = (idMonthlyStock) => (dispatch) => {
    dispatch(setIdMonthlyStockMaterial(idMonthlyStock))
}
