import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreatePetugasComponent from '../../../../../modules/dashboard-peternak/master-component/petugas-component/petugas-aksi-component/create-petugas-component'
import { createPetugas } from './query-petugas'

function CreatePetugasPage(props) {
    const { fetchDataPetugas, total, setLoading, t } = props
    const [visible, setVisible] = useState(false)
    const [visibleFree, setVisibleFree] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createPetugas(values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOfficer._id) {
                    validationMessage('success', `${t('pages.master.pengguna.petugas.response.sukses.tambah')}`)
                    fetchDataPetugas()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.master.pengguna.petugas.response.gagal.tambah')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreatePetugasComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            tipeUser={props.tipeUser}
            visibleFree={visibleFree}
            setVisibleFree={setVisibleFree}
            total={total}
            kandang={props.kandang} t={t}
        />
    )
}

const mapStateToProps = state => ({
    tipeUser: state.auth.dataUser.typeUser,
    kandang: state.layout.dataKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePetugasPage)
export default page