import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../common/store/action'
import { API } from '../../../common/api'
import DashboardComponent from '../../../modules/dashboard-peternak/dashboard-component/dashboard-component'
import { getSummaryReport, getReportChart, getDiseaseReport, getThiDevice, getTaredDevice, getFeedUsage } from './query-dashboard-page'
import { getRearings } from '../rearing-page/rearing-aksi-page/query-rearing'
import { getIdDevice, getDeviceType, getIdPeriode, getPeriode, getKandang, getIdKandang } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { useTranslation } from "react-i18next"
import { validationMessage } from '../../../common/component/notification/notification'

import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
import socketIOClient from 'socket.io-client'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { getIotHouse } from '../Iot-page/iot-aksi-page/query-iot'
import NumberFormat from 'react-number-format'

function DashboardPage(props) {
    const { idPeriod, period, cage, idKandang,
        idDevice, getIdDevice, getDeviceType, deviceType,
        getPeriode, getIdPeriode, getKandang, getIdKandang } = props
    const [idPeriode, setId] = useState(idPeriod)
    const [periode, setPeriod] = useState(period)
    const [kandang, setCage] = useState(cage)
    const [now] = useState(moment().format("YYYY-MM-DD"))
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [report, setReport] = useState({})
    const [active, setActive] = useState(false)
    const [disease, setDisease] = useState([])
    const [rearingActive, setRearingActive] = useState([])

    const [data, setData] = useState([])
    const [envReverse, setEnvReverse] = useState([])
    const [range, setRange] = useState([moment().add(-2, 'hours'), moment()])
    const [devices, setDevices] = useState([])
    const [thiData, setThiData] = useState([])
    const [env, setEnv] = useState({ temperature: 0, humidity: 0, amonia: 0, co2: 0, voc: 0, kph: 0, ms: 0 })
    const endpoint = process.env.REACT_APP_SOCKET_HOST
    const timenow = moment().format('DDMMYYYY hhmmss')
    const [load, setLoad] = useState(false)

    const [feedUsages, setFeedUsages] = useState([])
    const [ages, setAges] = useState([])
    const [chartData, setChartData] = useState([])

    const { t } = useTranslation()
    const cookies = new Cookies()

    useEffect(() => {
        function setIdData() {
            if (idPeriod === null) {
                setId("")
                setPeriod("")
                setCage("")
            }
            else {
                setId(idPeriod)
                setPeriod(period)
                setCage(cage)
            }
        }
        setIdData()
    }, [idPeriod, period, cage])

    const fetchDataSumaryReport = (idPeriode) => {
        setActive(true)
        if (idPeriode) {
            API.get(getSummaryReport(idPeriode))
                .then(res => {
                    if (res.data.data.dashboardCalculate !== null) {
                        setReport(res.data.data.dashboardCalculate)
                        setActive(false)
                    } else {
                        setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0, stdDeplesi: 0, stdFcr: 0, persenDeplesi: 0, fcr: 0 })
                        setActive(false)
                    }
                }).catch((error) => {
                    setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0, stdDeplesi: 0, stdFcr: 0, persenDeplesi: 0, fcr: 0 })
                    setActive(false)
                })
        } else {
            setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0, stdDeplesi: 0, stdFcr: 0, persenDeplesi: 0, fcr: 0 })
            setActive(false)
        }
    }
    useEffect(() => {
        async function getData() {
            fetchDataSumaryReport(idPeriode)
            fetchDataDiseaseReport(idPeriode)
        }
        getData()
        // eslint-disable-next-line 
    }, [idPeriode])

    useEffect(() => {
        if (idPeriode) {
            fetchDataRearingRecord(idPeriode)
            fetchDataFeedUsage(idPeriode)
        }
        if (idDevice) {
            fetchDataThi(idDevice, range)
            fetchDataEnv(idDevice, range)
        }
        if (idKandang) {
            fetchDevices(idKandang)
        }
        if (report && disease && object) {
            fetchDataActive()
        }
        // eslint-disable-next-line 
    }, [idPeriode, idDevice, idKandang, range])

    const fetchDataDiseaseReport = (idPeriode) => {
        setLoading(true)
        if (idPeriode) {
            API.get(getDiseaseReport(idPeriode))
                .then(res => {
                    if (res.data.data.diseaseDashboardRecords !== null) {
                        setDisease(res.data.data.diseaseDashboardRecords.calculatedDisease)
                        setLoading(false)
                    }
                }).catch((error) => {
                    setDisease([])
                    setLoading(false)
                })
        } else {
            setDisease([])
            setLoading(false)
        }
    }

    const fetchDataRearingRecord = (idPeriode) => {
        setLoading(true)
        if (idPeriode && disease && report) {
            API.get(getReportChart(idPeriode))
                .then(res => {
                    if (res.data.data.calculateDashboardRecords !== null) {
                        rearingObject(res.data.data.calculateDashboardRecords.calculatedArr)
                        setLoading(false)
                    }
                }).catch((error) => {
                    setObject([])
                    setLoading(false)
                })
        } else {
            setObject([])
            setLoading(false)
        }
    }

    const fetchDataActive = () => {
        setLoading(true)
        API.get(getRearings('', 0, 0))
            .then(res => {
                setRearingActive(res.data.data.rearings.rearings)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setRearingActive([])
                setLoading(false)
            })
    }

    const rearingObject = (object) => {
        let rearing = []
        for (let i = 0; i < object.length; i++) {
            rearing.push({
                bw: object[i].bw,
                fcr: object[i].fcr,
                saldo: object[i].saldo,
                feedIntake: object[i].feedIntake,
                persenDeplesiHarian: object[i].persenDeplesiHarian,
                day: object[i].day,
                tanggal: object[i].tanggal,
                standardBw: object[i].standardBw,
                standardDp: object[i].standardDp,
                standardFcr: object[i].standardFcr,
                standardFi: object[i].standardFi
            })
        }
        setObject(rearing)
    }

    const performance = object.map(({ bw, fcr, saldo, feedIntake, persenDeplesiHarian, day, tanggal,
        standardBw, standardDp, standardFcr, standardFi }, index) => ({
            key: day,
            umur: day,
            tanggal: tanggal,
            deplesi: persenDeplesiHarian,
            saldo: saldo,
            bw: bw,
            fcr: fcr,
            feedIntake: feedIntake,
            standardBw: standardBw,
            standardDp: standardDp,
            standardFcr: standardFcr,
            standardFi: standardFi
        }))

    const listDisease = disease.map(penyakit => ({
        penyakit: penyakit.disease,
        jumlah: penyakit.number,
    }))

    //DEVICE
    useEffect(() => {
        const socket = socketIOClient(endpoint)
        const emitData = {}
        emitData.deviceId = idDevice
        emitData.deviceType = deviceType
        socket.emit('device_id', emitData)
        socket.on("environment_device", data => {
            setEnv(data)
        })
    }, [endpoint, idDevice, deviceType])

    const fetchDataThi = async (deviceId, range) => {
        setLoading(true)
        await API.get(getThiDevice(deviceId, range[0], range[1]))
            .then(res => {
                if (res.data.data.thiDevice !== null) {
                    setThiData(res.data.data.thiDevice.thiDevice)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setThiData([])
                setLoading(false)
            })
    }

    const fetchDataEnv = async (deviceId, range) => {
        setLoading(true)
        await API.get(getTaredDevice(deviceId, range[0], range[1]))
            .then(res => {
                if (res.data.data.taredDevice !== null) {
                    setData(res.data.data.taredDevice.envDevice)
                    setEnvReverse(res.data.data.taredDevice.envReverse)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setData([])
                setLoading(false)
            })
    }

    const fetchDevices = async (idKandang) => {
        setLoading(true)
        await API.get(getIotHouse('', 0, 0, idKandang))
            .then(res => {
                if (res.data.data.deviceHouses !== null) {
                    setDevices(res.data.data.deviceHouses.devices)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setDevices([])
                setLoading(false)
            })
    }

    const onDevice = useCallback((id, type) => {
        getDeviceType(type)
        getIdDevice(id)
    }, [getDeviceType, getIdDevice])

    const suhu = data.map(({ voc, amonia, humidity, temperature, co2, kph, ms, ts, timestamp }, index) => ({
        ts: new Date(ts),
        timestamp: timestamp,
        voc: voc.toFixed(0),
        co2: co2.toFixed(0),
        amonia: amonia.toFixed(2),
        humidity: humidity.toFixed(2),
        temperature: temperature.toFixed(2),
        kph: kph.toFixed(2),
        ms: ms.toFixed(2),
    }))

    const suhuReverse = envReverse.map(({ voc, amonia, humidity, co2, temperature, kph, ms, ts, timestamp }, index) => ({
        ts: new Date(ts),
        timestamp: timestamp,
        voc: voc.toFixed(0),
        co2: co2.toFixed(0),
        amonia: amonia.toFixed(2),
        humidity: humidity.toFixed(2),
        temperature: temperature.toFixed(2),
        kph: kph.toFixed(2),
        ms: ms.toFixed(2),
    }))

    const thiChart = thiData.map(({ status, timestamp, ts, value }, index) => ({
        value: value,
        status: status,
        ts: new Date(ts),
        timestamp: timestamp
    }))

    const exportData = async () => {
        const params = new FormData()
        params.set('deviceId', idDevice)
        params.set('start', range[0])
        params.set('end', range[1])
        setLoad(true)
        let token = cookies.get('token')
        await axios
            .post(`${process.env.REACT_APP_API_URL}/excel-env-device`, params, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoad(false)
                    const link = document.createElement("a")
                    link.href = window.URL.createObjectURL(res.data)
                    link.download = `Data logging ${timenow}.xlsx`
                    link.click()
                    validationMessage('success', `Berhasil export data lingkungan device ke file Excel`)
                }
            }).catch((error) => {
                validationMessage('error', `Gagal export data lingkungan device ke file Excel`, error.message)
                console.log(error.message)
                setLoad(false)
            })
    }

    const handleExport = () => {
        exportData()
    }

    const handleChangeKandang = useCallback((value) => {
        getIdKandang(value.value)
        getKandang(value.label)
    }, [getIdKandang, getKandang])

    //FEED USAGE
    const feedUsage = feedUsages.map(({ _id, feed, firstAge, lastAge, total }, index) => ({
        key: _id,
        id: _id,
        feed: feed,
        umur: firstAge + '-' + lastAge + `  ${t('pages.dashboard.feed.days')}`,
        total: <NumberFormat value={total} displayType={'text'} thousandSeparator={true} suffix={` kg`} />,
    }))

    const fetchDataFeedUsage = async () => {
        setLoading(true)
        await API.get(getFeedUsage(idPeriode))
            .then(res => {
                if (res.data.data.feedUsage !== null) {
                    setFeedUsages(res.data.data.feedUsage.feedUsage)
                    setAges(res.data.data.feedUsage.ages)
                    setChartData(res.data.data.feedUsage.chart)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setFeedUsages([])
                setAges([])
                setChartData([])
                setLoading(false)
            })
    }

    const onPeriode = useCallback((id, name, cage) => {
        getIdPeriode(id)
        getPeriode(name)
        getKandang(cage)
    }, [getIdPeriode, getPeriode, getKandang])

    return (
        <DashboardComponent
            navigate={props.navigate}
            setLoading={setLoading}
            setId={setId} setCage={getKandang} setPeriod={getPeriode}
            loading={loading}
            object={object}
            performance={performance}
            active={active}
            report={report}
            idPeriode={idPeriode} kandang={kandang} periode={periode}
            now={now}
            listDisease={listDisease}
            rearingActive={rearingActive}
            fetchDataActive={fetchDataActive}
            locales={t}

            //device
            setRange={setRange}
            devices={devices}
            env={env}
            fetchDataThi={fetchDataThi}
            fetchDataEnv={fetchDataEnv}
            fetchDevices={fetchDevices}
            onDevice={onDevice}
            suhu={suhu}
            suhuReverse={suhuReverse}
            thiChart={thiChart}
            handleExport={handleExport}
            load={load}
            deviceType={deviceType}
            idDevice={idDevice}

            feedUsage={feedUsage}
            chartData={chartData}
            ages={ages}
            dataPeriode={props.dataPeriode}
            onPeriode={onPeriode}
            cage={cage} period={period}
            idKandang={idKandang}
            dataKandang={props.dataKandang}
            handleChangeKandang={handleChangeKandang}
        />
    )
}

const mapStateToProps = state => ({
    idPeriod: state.layout.idPeriode,
    chickInDateFirst: state.layout.chickInDate,
    cage: state.layout.kandang,
    period: state.layout.periode,
    dataPeriode: state.layout.dataPeriode,
    idDevice: state.layout.idDevice,
    deviceType: state.layout.deviceType,
    idKandang: state.layout.idKandang,
    dataKandang: state.layout.dataKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdDevice, getDeviceType, getIdPeriode, getPeriode, getKandang, getIdKandang
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
export default page