import * as actionType from './pakan-action-type';

const initialState = {
    dataPakan: [],
    loading : false,
}

const handler = (currentState) => {
    const setDataPakan = {
        startGetDataPakans : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataPakans : () => ({
            ...currentState,
            loading: false,
        }),

        getDataPakans : data => ({
            ...currentState,
            dataPakan: data
        })
    }

    return {
        ...setDataPakan
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataPakans();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataPakans();
        case actionType.SET_DATA_PAKAN:
            return handler(state).getDataPakans(payload);

        default:
            return state;
    }
}