import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreateAdjustmentPakanComponent from '../../../../../modules/dashboard-peternak/adjustment-stock-component/pakan-component/pakan-aksi-component/create-adjustment-pakan-component'
import { createAdjustmentPakan } from './query-adjustment-pakan-page'

function CreateStokPakanPage(props) {
    const { setLoading, idMonthlyStockFeed, fetchData, object, locked, t } = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values, idMonthlyStockFeed) => {
        API.post(createAdjustmentPakan(values, idMonthlyStockFeed))
            .then(res => {
                if (res.data.data.createAdjustmentFeed._id) {
                    validationMessage('success', `${t('pages.stockflow.periode.penyesuaian.pakan.response.sukses.tambah')}`)
                    fetchData(idMonthlyStockFeed)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.penyesuaian.pakan.response.gagal.tambah')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, idMonthlyStockFeed)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateAdjustmentPakanComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            pakan={props.pakan}
            locked={locked}
            object={object}
            t={t}

        />
    )
}

const mapStateToProps = state => ({
    pakan: state.pakan.dataPakan,
    idMonthlyStockFeed: state.adjustmentPakan.idMonthlyStockFeed,
   
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStokPakanPage)
export default page