export const getMonthlyStockOvks = (keyword,limit,skip,month,year) => {
    return (
        `query{
            monthlyStockOvks(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}"){
            totalCount
            locked
            calculateMonthlyStocks{
                _id
                idOvk
                idMonthlyStockOvk
                bulan
                tahun
                namaOvk
                satuan
        
                stockAwalBulan
                hargaAwalBulan
                totalHargaAwal
                hargaTotalAwalBulan
        
                addStock
                hargaPengadaan
                nilaiPengadaan
        
                outStock
                hargaPengeluaran
                nilaiPengeluaran
        
                sisaStok
                nilaiSisaStok
                hargaTotalPenyesuaian
        
                qty
                price
                total
            }
          }
        }
      `
    )
}