import React from 'react';
import { Modal,Form,Row,Col,Input } from 'antd';

function EditPenyakit(props) {
    const {visible,handleSubmit,handleCancel, data,t} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t('pages.master.penyakit.modal.titleEdit')}
            okText="Edit"
            width={400}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        namaPenyakit : data.name,
                    }}
                >
                    <Row gutter={[16,8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.penyakit.modal.nama')}</span>
                            <Form.Item
                                name="namaPenyakit" rules={[{ required: true, message: `${t('pages.master.penyakit.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='namaPenyakit'
                                    placeholder="Cekrek..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default EditPenyakit;