import React from 'react';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Modal,Form,Row,Col,Input,Select } from 'antd';
import ButtonDashboard from '../../../../../common/component/button/button-dashboard';

function CreatePakan(props) {
    const { Option } = Select;
    const {visible,handleSubmit,setVisible,showModal,t} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t('pages.master.material.modal.titleTambah')}
            okText={t('general.create')}
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.material.modal.nama')}</span>
                            <Form.Item
                                name="namaMaterial" rules={[{ required: true, message: `${t('pages.master.material.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='namaMaterial'
                                    placeholder={t('pages.master.material.modal.phNama')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.material.modal.kode')}</span>
                            <Form.Item
                                name="kodeMaterial" rules={[{ required: true, message: `${t('pages.master.material.modal.msgKode')}`}]}
                            >
                                <Input
                                    name='kodeMaterial'
                                    placeholder={t('pages.master.material.modal.phKode')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.material.modal.jenis')}</span>
                            <Form.Item
                                name="jenisMaterial" rules={[{ required: true, message: `${t('pages.master.material.modal.msgJenis')}`}]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.material.modal.phJenis')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >  
                                    <Option key="Sekam" value="Sekam">Sekam</Option>
                                    <Option key="Lpg" value="Lpg">LPG</Option>
                                    <Option key="Garam" value="Garam">Garam</Option>
                                    <Option key="Kapur" value="Kapur">Kapur</Option>
                                    <Option key="PaperChick" value="PaperChick">PaperChick</Option>
                                    <Option key="SodaApi" value="SodaApi">Soda Api</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.material.modal.perusahaan')}</span>
                            <Form.Item
                                name="perusahaan" rules={[{ required: true, message: `${t('pages.master.material.modal.msgPerusahaan')}` }]}
                            >
                                <Input
                                    name='perusahaan'
                                    placeholder="Pertamina..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <Row gutter={[16, 16]}>
                <Col lg={20} md={24} sm={24}>
                    {/* <Search
                        placeholder="Masukkan data yang ingin dicari...."
                        onSearch={value => console.log(value)}
                        style={{ width: '100%', border:"1px #00923F solid", borderRadius:"5px" }}
                        className="search-button"
                    /> */}
                </Col>
                <Col lg={4} md={24} sm={24}>
                    <ButtonDashboard
                        text={t('pages.master.material.button.tambah')}
                        height={20}
                        backgroundColor="#008ad4"
                        borderRadius ="5px"
                        className="font-semi-medium font-content-title button-add"
                        textColor="white"
                        icon={faPlusSquare}
                        onClick={() => showModal()}
                    />
                </Col>
            </Row>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </div>
    );
}
 
export default CreatePakan;