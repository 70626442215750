import React from 'react'
import { Layout, Breadcrumb, Row, Col, Tabs } from 'antd'
import { Link } from 'react-router-dom'
import PenambahanOvk from '../../../../../app/dashboard-peternak/stok-page/stok-ovk-page/transaksi-stok-ovk-page/penambahan-stok-ovk-page'
import PenggunaanOvk from '../../../../../app/dashboard-peternak/stok-page/stok-ovk-page/transaksi-stok-ovk-page/penggunaan-stok-ovk-page'

function TransaksiStokOvkComponent(props) {
    const { Content } = Layout
    const { TabPane } = Tabs
    const { changeKey, loading, setLoading, activeKey, fetchDataPenambahan,
        penambahan, penggunaan, fetchDataPenggunaan, satuanOvk, satuanRecordingOvk, namaOvk, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to='/peternak/stok-ovk'><span>{t('pages.stok.ovk.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.stok.ovk.detail.title')} {namaOvk}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={t('pages.stok.ovk.detail.penambahan.title')} key="1">
                                    <PenambahanOvk activeKey={activeKey} loading={loading} setLoading={setLoading}
                                        penambahan={penambahan} fetchDataPenambahan={fetchDataPenambahan} satuanOvk={satuanOvk} t={t} />
                                </TabPane>
                                <TabPane tab={t('pages.stok.ovk.detail.penggunaan.title')} key="2">
                                    <PenggunaanOvk activeKey={activeKey} loading={loading} setLoading={setLoading}
                                        penggunaan={penggunaan} fetchDataPenggunaan={fetchDataPenggunaan} satuanRecordingOvk={satuanRecordingOvk} t={t} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default TransaksiStokOvkComponent