import React from 'react'
import { Layout, Breadcrumb, Row, Col, Card } from 'antd'
import PerformanceChart from '../../../../common/component/chart/chart-performance'
import TableRecording from '../../../../app/dashboard-peternak/rearing-record-page/rearing-record-page'
import { Link } from 'react-router-dom';

function DashboardComponent(props) {
    const { performance, periodeKandang, locales } = props
    const { Content } = Layout
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/rearing"><span>{locales("pages.periode.title")}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{locales("pages.periode.detail.title")} {periodeKandang}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row className="chart-die-section" gutter={[16, 16]} style={{ maxHeight: "100vh" }}>
                    <Col span={24} >
                        <Card title={locales("pages.periode.detail.grafikTitle")} bordered={false}>
                            <PerformanceChart
                                data={performance}
                                chartId="berat-chart"
                            />
                            <Col span={24} style={{ textAlign: "center" }}>
                                <span className="font-title-medium black font-medium">{locales("pages.periode.detail.tableTitle")}</span>
                            </Col>
                            <TableRecording />
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default DashboardComponent