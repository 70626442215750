export const getCustomer = (keyword,limit,skip) => {
    return (
        `query{
            customers(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
                totalCount
                customers{
                    _id
                    name
                    address
                    contact
                    otherInformation
                }
            }
        }
        `
    );
};

export const createCustomer = (value) => {
    console.log('va',value)
    return{
        query: `mutation{
            createCustomer(customerInput: {
                name : "${value.name}"
                address : "${value.address}"
                contact : "${value.contact}"
                otherInformation : "${value.otherInformation}"
              }){
                _id
              }
            }`
    }
}

export const deleteCustomer = (_id) => {
    return (
        `mutation{
            deleteCustomer(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const detailCustomer = (_id) => {
    return (
        `query{
            customer(_id : "${_id}"){
                    _id
                    name
                    address
                    contact
                    otherInformation
                }
            }
        `
    );
}

export const updateCustomer = (_id,value) => {
    return {
        query: `mutation{
            updateCustomer(_id : "${_id}", updateCustomerInput : {
                name : "${value.name}"
                address : "${value.address}"
                contact : "${value.contact}"
                otherInformation : "${value.otherInformation}"
            }){
              _id
              name
            }
          }
        `
    }
}


