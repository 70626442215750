import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import * as actionType from './material-action-type';

const startGetDataMaterials = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataMaterials = () => ({
    type : actionType.FINISH_GET_DATA
})

const setDataMaterial = payload => ({
    type : actionType.SET_DATA_MATERIAL,
    payload,
});

export const getListMaterial = (params) => (dispatch) => {
    dispatch(startGetDataMaterials());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.materials.materials){
            dispatch(setDataMaterial(res.data.data.materials.materials))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataMaterials());
    })
}