import React from 'react'
import { Modal, Form, Row, Col, Input, Select, InputNumber } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'

function CreateKandang(props) {
    const { TextArea } = Input
    const { Option } = Select
    const { visible, setVisible, handleSubmit, showModal, gudangPakan, gudangOvk, gudangMaterial, confirmLoading, t } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.kandang.modal.tambah')}
                okText={t('general.create')}
                okButtonProps={{ id: 'create-master-kandang' }}
                width={800}
                confirmLoading={confirmLoading}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                            console.log(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        gudangPakan: '',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.kandang.modal.nama')}</span>
                            <Form.Item
                                name="namaKandang" rules={[{ required: true, message: `${t('pages.master.kandang.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='namaKandang'
                                    placeholder="Kandang 1 Lantai 1..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.kandang.modal.kapasitas')}</span>
                            <Form.Item
                                name="kapasistasKandang" rules={[{ required: true, message: `${t('pages.master.kandang.modal.msgKapasitas')}` }]}
                            >
                                <InputNumber
                                    name='kapasistasKandang'
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.kandang.modal.gudangPakan')}</span>
                            <Form.Item
                                name="selectGudangPakan" rules={[{ required: true, message: `${t('pages.master.kandang.modal.msgGudangPakan')}` }]}
                            >
                                <Select
                                    showSearch
                                    name="selectGudangPakan"
                                    labelInValue
                                    placeholder={t('pages.master.kandang.modal.phGudangPakan')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudangPakan.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.kandang.modal.gudangOvk')}</span>
                            <Form.Item
                                name="selectGudangOvk" rules={[{ required: true, message: `${t('pages.master.kandang.modal.msgGudangOvk')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    name="selectGudangOvk"
                                    placeholder={t('pages.master.kandang.modal.phGudangOvk')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudangOvk.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.kandang.modal.gudangMaterial')}</span>
                            <Form.Item
                                name="selectGudangMaterial" rules={[{ required: true, message: `${t('pages.master.kandang.modal.msgGudangMaterial')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    name="selectGudangMaterial"
                                    placeholder={t('pages.master.kandang.modal.phGudangMaterial')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudangMaterial.map(data =>
                                            <Option name='optGudangMaterial'
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.kandang.modal.alamat')}</span>
                            <Form.Item
                                name="alamat" rules={[{ required: true, message: `${t('pages.master.kandang.modal.msgAlamat')}` }]}
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }} name="alamat"
                                    placeholder={t('pages.master.kandang.modal.phAlamat')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.kandang.modal.keterangan')}</span>
                            <Form.Item
                                name="keterangan" rules={[{ required: false }]}
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    name="keterangan"
                                    placeholder={t('pages.master.kandang.modal.phKet')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={t('pages.master.kandang.modal.tambah')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

export default CreateKandang