import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { navigate } from '../../../../../common/store/action';
import CreatePenyakitComponent from '../../../../../modules/dashboard-peternak/master-component/penyakit-component/penyakit-aksi-component/create-penyakit-component';
import {getListDisease} from '../../../../../modules/dashboard-peternak/master-component/penyakit-component/store/penyakit-action';

import {createPenyakit,getPenyakit} from './query-penyakit';

function CreatePenyakitPage(props) {
    const {fetchData,setLoading,getListDisease,t} = props;
    const [visible, setVisible] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    },[]);

    const postData = (values) => {
        API.post(createPenyakit(values))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading (false);
            }else if(res.data.data.createDisease._id){
                validationMessage('success', `${t('pages.master.penyakit.response.sukses.tambah')}`)
                fetchData();
                getListDisease(getPenyakit('',0,0));
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.penyakit.response.gagal.tambah')}`);
            setLoading (false);
        })
    }

    const handleSubmit = (values) => {
        postData( values );
        setVisible(false);
        setLoading(true);
    };

    return ( 
        <CreatePenyakitComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListDisease
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePenyakitPage);
export default page