import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import { validationMessage } from '../../../../../common/component/notification/notification';
import CONSTANTS from '../../../../../common/utils/Constants';
import { API } from '../../../../../common/api';
import EditOvkComponent from '../../../../../modules/dashboard-peternak/master-component/ovk-component/ovk-aksi-component/edit-ovk-new-component';

import {udpateOvk} from './query-ovk';

import { useTranslation } from 'react-i18next'

function EditOvkPage(props) {
    const [loading,setLoading] = useState(false);
    const [suffix,setSuffix] = useState('');

    const { t } = useTranslation()

    const updatedData =  (values,year) => {
        API.post(udpateOvk(props.idOvk,values,year))
        .then(res => {
            console.log('res',res)
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.updateOvk._id){
                validationMessage('success', `${t('pages.master.ovk.response.sukses.edit')}`)
                props.navigate(CONSTANTS.MASTER_OVK_MENU_KEY)
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.ovk.response.gagal.edit')}`)
            setLoading(false);
        })
    }

    const handleSubmit = (values) => {
        updatedData(values,values['date_picker'].format('MM/DD/YYYY'));
        setLoading(true);
    };

    return ( 
        <EditOvkComponent
            navigate={props.navigate}
            handleSubmit = {handleSubmit}
            loading = {loading}
            ovkType = {props.ovkType}
            ovkMedia = {props.ovkMedia}
            ovkUnit = {props.ovkUnit}
            ovkShape = {props.ovkShape}
            ovkUnitRecording = {props.ovkUnitRecording}
            detailOvk = {props.detailOvk}
            setSuffix = {setSuffix}
            suffix = {suffix}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    ovkType : state.ovk.dataOvkType,
    ovkUnit : state.ovk.dataOvkUnit,
    ovkMedia : state.ovk.dataOvkMedia,
    ovkShape : state.ovk.dataOvkShape,
    ovkUnitRecording : state.ovk.dataOvkUnitRecording,
    detailOvk : state.ovk.detailOvk,
    idOvk : state.ovk.idOvk,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditOvkPage);
export default page