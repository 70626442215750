import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { API } from '../../../../common/api'
import { Link } from 'react-router-dom';

import { Row, Col,Card, Skeleton, Breadcrumb, Layout, Divider } from 'antd';
import NumberFormat from 'react-number-format';

import { getSummaryReport, getReportChart, getDiseaseReport } from '../query-dashboard-page'
import TablePerformance from './table-detail-page';
import PerformanceChart from '../../../../common/component/chart/chart-performance';
import DiseaseChart from '../../../../common/component/chart/chart-disease';
/*Import Moment Js */
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { useTranslation } from "react-i18next"

function DetailPage(props) {
    const { idRearing, kandang, periode } = props
    const [object, setObject] = useState([])
    const [report, setReport] = useState({})
    const [active, setActive] = useState(false)
    const [disease, setDisease] = useState([])

    const {  t } = useTranslation()
    const { Content } = Layout

    const fetchDataRearingRecord = (idRearing) => {
        if (idRearing) {
            API.get(getReportChart(idRearing))
                .then(res => {
                    if (res.data.data.calculateDashboardRecords !== null) {
                        rearingObject(res.data.data.calculateDashboardRecords.calculatedArr)
                    }
                }).catch((error) => {
                    setObject([]);
                })
        } else {
            setObject([]);
        }
    }

    useEffect(() => {
        fetchDataRearingRecord(idRearing);
        // eslint-disable-next-line
    }, [idRearing])

    const fetchDataSumaryReport = (idRearing) => {
        setActive(true)
        if (idRearing) {
            API.get(getSummaryReport(idRearing))
                .then(res => {
                    if (res.data.data.dashboardCalculate !== null) {
                        setReport(res.data.data.dashboardCalculate);
                        setActive(false);
                    } else {
                        setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0, stdDeplesi: 0, stdFcr: 0, persenDeplesi: 0, fcr: 0 });
                        setActive(false);
                    }
                }).catch((error) => {
                    setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0, stdDeplesi: 0, stdFcr: 0, persenDeplesi: 0, fcr: 0 });
                    setActive(false);
                })
        } else {
            setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0, stdDeplesi: 0, stdFcr: 0, persenDeplesi: 0, fcr: 0 });
            setActive(false);
        }
    }

    const fetchDataDiseaseReport = (idRearing) => {
        if (idRearing) {
            API.get(getDiseaseReport(idRearing))
                .then(res => {
                    if (res.data.data.diseaseDashboardRecords !== null) {
                        setDisease(res.data.data.diseaseDashboardRecords.calculatedDisease)
                    }
                }).catch((error) => {
                    setDisease([]);
                })
        } else {
            setDisease([]);
        }
    }

    useEffect(() => {
        fetchDataSumaryReport(idRearing)
        fetchDataDiseaseReport(idRearing)
    }, [idRearing])

    /**End handle get data */

    const rearingObject = (object) => {
        let rearing = [];
        for (let i = 0; i < object.length; i++) {
            rearing.push({
                bw: object[i].bw,
                fcr: object[i].fcr,
                saldo: object[i].saldo,
                persenDeplesiHarian: object[i].persenDeplesiHarian,
                day: object[i].day,
                tanggal: object[i].tanggal,
                standardBw: object[i].standardBw,
                standardDp: object[i].standardDp,
                standardFcr: object[i].standardFcr,
            })
        }
        setObject(rearing);
    }

    const performance = object.map(({ bw, fcr, saldo, persenDeplesiHarian, day, tanggal, 
        standardBw, standardDp, standardFcr }, index) => ({
        key: day,
        umur: day,
        tanggal: tanggal,
        deplesi: persenDeplesiHarian,
        saldo: saldo,
        bw: bw,
        fcr: fcr,
        standardBw: standardBw,
        standardDp: standardDp,
        standardFcr: standardFcr,
    }));

    const listDisease = disease.map(penyakit => ({
        penyakit: penyakit.disease,
        jumlah: penyakit.number,
    }))
    return (
        <Layout style={{ overflow: "hidden" }}>
        <Content className="dashboard-container">
            <Row className="dashboard-section">
                <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/dashboard"><span>{t("pages.dashboard.title")}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>Detail {kandang} {periode}</span></Breadcrumb.Item>
                    </Breadcrumb>
            </Row>
            <Row className="card-report-section" gutter={[16, 16]}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card title={t("pages.dashboard.cardStokAyam")} bordered={false} className="card-bird-live">
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {
                                    active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                        <div>
                                            <NumberFormat value={report.liveBird} displayType={'text'} thousandSeparator={true} />
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card title={t("pages.dashboard.cardCulling")} bordered={false} className="card-bird-culling">
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {
                                    active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                        <div>
                                            <NumberFormat value={report.culling} displayType={'text'} thousandSeparator={true} /> (<NumberFormat value={report.persenCulling} displayType={'text'} thousandSeparator={true} suffix={' %'} />)
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card title={t("pages.dashboard.cardDeplesi")} bordered={false} className="card-bird-dead">
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                {
                                    active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                        <span>
                                            <NumberFormat value={report.mati} displayType={'text'} thousandSeparator={true} />
                                        (<NumberFormat value={report.persenDeplesi} displayType={'text'} thousandSeparator={true} suffix={' %'} />)
                                    </span>
                                }
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                {
                                    active === true ?
                                        <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                        <NumberFormat value={report.stdDeplesi} displayType={'text'} thousandSeparator={true} prefix={'Std: '} suffix={' %'} />
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Card title={t("pages.dashboard.cardFCR")} bordered={false} className="card-bird-feed">
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                {active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> : <NumberFormat value={report.fcr} displayType={'text'} thousandSeparator={true} />}
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                {active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> : <NumberFormat value={report.stdFcr} displayType={'text'} thousandSeparator={true} prefix={'Std: '} />}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="chart-die-section" gutter={[16, 16]}>
                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }} >
                        <Card title={t("pages.dashboard.recordPerformaKandang")} bordered={false}>
                            <PerformanceChart
                                data={performance}
                                chartId="berat-chart"
                            />
                            <Divider/>
                            <Row>
                                <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }} style={{ textAlign: "center" }}>
                                    <span className="font-title-medium black font-medium">{t("pages.dashboard.tabelPerformaKandang")}</span>
                                </Col>
                            </Row>
                            <TablePerformance />
                        </Card>
                    </Col>
                </Row>
            <Row className="chart-disease-section" gutter={[16, 16]}>
                <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }} >
                    <Card title={t("pages.dashboard.recordNekorpsi")} bordered={false}>
                        <DiseaseChart
                            data={listDisease}
                            chartId="heatmap-chart"
                        />
                    </Card>
                </Col>
            </Row>
        </Content>
    </Layout>

    )
}

const mapStateToProps = state => ({
    idRearing : state.layout.idRearing,
    kandang: state.layout.kandang,
    periode: state.layout.periode
})

const mapDispatchToProps = (dispatch => ({
    navigate,

}))()

const detailPage = connect(mapStateToProps, mapDispatchToProps)(DetailPage)
export default detailPage