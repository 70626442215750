import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { API } from '../../../../common/api'
import DetailRearingCloseComponent from '../../../../modules/dashboard-peternak/rearing-component/rearing-aksi-component/detail-rearing-close-component'
import { getReportChart } from '../../dashboard-page/query-dashboard-page'
import { useTranslation } from "react-i18next"

function DetailRearingClosePage(props) {
    const { idPeriode } = props
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const { t } = useTranslation()

    const fetchDataRearingRecord = () => {
        setLoading(true);
        API.get(getReportChart(idPeriode))
            .then(res => {
                if (res.data.data.calculatedRearingRecords !== null) {
                    rearingObject(res.data.data.calculateDashboardRecords.calculatedArr)
                    setLoading(false)
                }
            }).catch((error) => {
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchDataRearingRecord()
        // eslint-disable-next-line
    }, [idPeriode])

    const rearingObject = (object) => {
        let rearing = []
        for (let i = 0; i < object.length; i++) {
            rearing.push({
                bw: object[i].bw,
                fcr: object[i].fcr,
                saldo: object[i].saldo,
                feedIntake: object[i].feedIntake,
                standardFi: object[i].standardFi,
                persenDeplesiHarian: (parseFloat(object[i].persenDeplesiHarian)).toFixed(2),
                day: object[i].day,
                standardBw: object[i].standardBw,
                standardDp: object[i].standardDp,
                standardFcr: object[i].standardFcr,
            })
        }
        setObject(rearing)
    }

    const performance = object.map(({ bw, fcr, saldo, persenDeplesiHarian, day, feedIntake, standardFi, standardBw, standardDp, standardFcr }, index) => ({
        key: day,
        umur: day,
        deplesi: persenDeplesiHarian,
        saldo: saldo,
        bw: bw,
        feedIntake: feedIntake,
        standardFi: standardFi,
        fcr: fcr,
        standardBw: standardBw,
        standardDp: standardDp,
        standardFcr: standardFcr,
    }))

    return (
        <DetailRearingCloseComponent
            navigate={props.navigate}
            loading={loading}
            idPeriode={idPeriode}
            object={object}
            performance={performance}
            periodeKandang={props.periodeKandang}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
    periodeKandang: state.periode.periodeKandang
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailRearingClosePage)
export default page