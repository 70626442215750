export const queryKandang = (keyword, limit, skip) => {
    return (
        `query{
            houses(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              houses {
                  _id
                  name
                  capacity
                  address
              }
            }
          }
        `
    );
}

export const queryListKandang = (keyword, limit, skip) => {
    return (
      `query{
          houses(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
            houses {
                _id
                name
                rearing{
                  _id
                  name
                  chickInDate
                }
            }
          }
        }
      `
  );
}

export const deleteKandang = (_id) => {
  return (
    `mutation{
      deleteHouse(_id : "${_id}"){
        deleted
      }
    }
  ` 
  );
} 

export const createKandang = (value) => {
  return {
    query: `mutation{
          createHouse(houseInput: {
            name : "${value.namaKandang}"
            capacity : ${parseInt(value.kapasistasKandang)}
            address: "${value.alamat}"
            feedWarehouse: "${value.selectGudangPakan.key}"
            ovkWarehouse: "${value.selectGudangOvk.key}"
            materialWarehouse : "${value.selectGudangMaterial.key}"
            otherInformation : "${value.keterangan}"
          }){
            _id
          }
        }
      `
    }
}

export const detailKandang = (_id) => {
  return (
    `query{
        house(_id : "${_id}"){
            _id
            name
            capacity
            address
            ovkWarehouse {
                value : _id
            }
            materialWarehouse{
                value : _id
            }
            feedWarehouse {
                value : _id
            }
        }
      }
    `
  );
}

export const updateKandang = (_id, value) => {
    return {
      query: `mutation{
                  updateHouse(_id : "${_id}", updateHouseInput : {
                      name : "${value.namaKandang}",
                      capacity : ${value.kapasistasKandang},
                      address : "${value.alamat}",
                      feedWarehouse : "${value.selectGudangPakan.value}"
                      ovkWarehouse : "${value.selectGudangOvk.value}"
                      materialWarehouse : "${value.selectGudangMaterial.value}"
                      otherInformation : "${value.keterangan}"
                  }){
                      _id
                  }
                }
        `
    }
}