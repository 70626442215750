import React, { useState, useEffect,useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../common/store/action'
import RearingComponent from '../../../modules/dashboard-peternak/rearing-component/rearing-component'
import {getPeriodeAll,getPeriodeCloseAll} from './rearing-aksi-page/query-rearing'
import { API } from '../../../common/api'
import { useTranslation } from "react-i18next"

function RearingPage(props) {
    const [loading, setLoading] = useState(false)
    const [activeKey, setActiveKey] = useState("1")
    const [rearingActive, setRearingActive] = useState([])
    const [rearingClose, setRearingClose] = useState([])
    const { t } = useTranslation()

    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    const fetchDataActive = () => {
        setLoading(true);
        API.get(getPeriodeAll('',0,0))
        .then(res => {
            setRearingActive(res.data.data.rearings.rearings)
            setLoading(false)
        }).catch( ( error ) =>  {
            console.log(error)
            setRearingActive([])
            setLoading (false)
        })
    }

    const fetchDataClose = () => {
        setLoading(true);
        API.get(getPeriodeCloseAll('',0,0))
        .then(res => {
            setRearingClose(res.data.data.rearingClose.rearings)
            setLoading(false)
        }).catch( ( error ) =>  {
            console.log(error)
            setRearingClose([])
            setLoading (false)
        })
    }

    useEffect(() => {
        if(activeKey === "1"){
            fetchDataActive()
        }else{
            fetchDataClose()
        }
    }, [activeKey])

    return ( 
        <RearingComponent
            navigate={props.navigate}
            loading = {loading}
            rearingActive = {rearingActive}
            fetchDataActive = {fetchDataActive}
            rearingClose = {rearingClose}
            fetchDataClose = {fetchDataClose}
            setLoading = {setLoading}
            changeKey  = {changeKey}
            locales  = {t}
        />
    )
}

const mapStateToProps = state => ({  
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RearingPage)
export default page