export const getGudangOvk = (keyword,limit,skip) => {
    return (
        `query{
            ovkWarehouses(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
                ovkWarehouses {
                    _id
                    name
                }
            }
        }
        `
    );
};

export const createGudangOvk = (value) => {
    return{
        query: `mutation{
            createOvkWarehouse(ovkWarehouseInput: {
                name : "${value}"
              }){
                _id
              }
            }
        `
    }
}

export const deleteGudangOvk = (_id) => {
    return (
        `mutation{
            deleteOvkWarehouse(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const detailGudangOvk = (_id) => {
    return (
        `query{
            ovkWarehouse(_id : "${_id}"){
                _id
                name
                }
            }
        `
    );
}

export const updateGudangOvk = (_id,value) => {
    return {
        query: `mutation{
            updateOvkWarehouse(_id : "${_id}", updateOvkWarehouseInput : {
              name : "${value}",
            }){
              _id
              name
            }
          }
        `
    }
}


