import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import EditPenambahanStokComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/transaksi-stok-ovk-component/edit-penambahan-stok-component';

function EditPenambahanStokPage(props) {
    const {visible,handleCancel,detail,handleSubmit,satuanOvk,t} = props;
    const [data,setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData();
    }, [detail]);

    return ( 
        <EditPenambahanStokComponent
            navigate={props.navigate}
            visible = {visible}
            satuanOvk = {satuanOvk}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditPenambahanStokPage);
export default page