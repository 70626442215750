import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { Layout, Breadcrumb, Row, Col, Table, DatePicker, Typography, Button } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { FileExcelOutlined } from '@ant-design/icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import Cookies from 'universal-cookie'
import axios from 'axios'

function OvkComponent(props) {
  const { Content } = Layout
  const { Text } = Typography
  const { columns, data, loading, handleTanggalRange, month, year, disabledDate, idStockflow, t } = props
  const [load, setLoad] = useState(false)
  const cookies = new Cookies()

  const exportData = async (idStockflow) => {
    setLoad(true)
    let token = cookies.get('token')
    await axios
      .get(`${process.env.REACT_APP_API_URL}/excel-ovk-stockflow/${idStockflow}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        responseType: 'blob'
      })
      .then(res => {
        if (typeof (res.data === 'blob')) {
          setLoad(false)
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(res.data)
          link.download = `Stockflow OVK ${month} ${year}.xlsx`
          link.click()
          validationMessage('success', `Berhasil export stockflow OVK ke file Excel`)
        }
      }).catch((error) => {
        console.log('err', error)
        validationMessage('error', `Gagal export stockflow OVK ke file Excel`)
        setLoad(false)
      })
  }

  const handleExport = () => {
    exportData(idStockflow)
  }

  return (
    <Layout>
      <Content className="dashboard-container">
        <Row gutter={[16, 16]}>
          <Col span={12} >
            <Breadcrumb separator=">">
              <Breadcrumb.Item><span>{t('pages.stockflow.ovk.title')} - {month} {year}</span></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={4} offset={4}>
            <Button style={{
              float: 'right', backgroundColor: '#00a266', color: 'white',
              borderRadius: 5, boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
            }}
              loading={load} onClick={() => handleExport()}>
              <FileExcelOutlined />Export Excel</Button>
          </Col>
          <Col span={4} lg={4} md={6} sm={6}>
            <DatePicker
              allowClear={false}
              picker='month'
              format={'MMMM'}
              onChange={handleTanggalRange}
              disabledDate={disabledDate}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24}>
            <Loading loading={loading}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                className="table-stock-flow"
                scroll={{ x: 500, y: '70vh' }}
                summary={pageData => {
                  let totalStokAwal = 0
                  let nilaiAwal = 0
                  let totalIn = 0
                  let totalNilaiIn = 0
                  let totalOut = 0
                  let totalNilaiOut = 0
                  let totalPenyesuaian = 0
                  let totalNilaiPenyesuaian = 0
                  let totalSaldoAkhir = 0
                  let totalNilaiSaldoAkhir = 0
                  pageData.forEach(({
                    stokAwal, hargaTotalAwalBulan,
                    pengadaan, totalHargaPengadaan,
                    penggunaan, totalHargaPenggunaan,
                    penyesuaian, totalHargaPenyesuaian,
                    stokAkhir, totalHargaStokAkhir
                  }) => {
                    totalStokAwal += parseFloat(stokAwal)
                    nilaiAwal += hargaTotalAwalBulan

                    totalIn += parseFloat(pengadaan)
                    totalNilaiIn += parseFloat(totalHargaPengadaan)

                    totalOut += parseFloat(penggunaan)
                    totalNilaiOut += parseFloat(totalHargaPenggunaan)

                    totalPenyesuaian += parseFloat(penyesuaian)
                    totalNilaiPenyesuaian += parseFloat(totalHargaPenyesuaian)

                    totalSaldoAkhir += parseFloat(stokAkhir)
                    totalNilaiSaldoAkhir += parseFloat(totalHargaStokAkhir)
                  });
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text>{totalStokAwal < 0 ? <NumberFormat value={Math.abs(totalStokAwal.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                            <NumberFormat value={totalStokAwal.toFixed(0)} displayType={'text'} thousandSeparator={true} />}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(nilaiAwal / totalStokAwal).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={nilaiAwal.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalIn.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={isNaN(totalNilaiIn / totalIn) ? 0 : (totalNilaiIn / totalIn).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right" >
                          <Text><NumberFormat value={totalNilaiIn.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalOut.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={isNaN(totalNilaiOut / totalOut) ? 0 : (totalNilaiOut / totalOut).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiOut.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalPenyesuaian < 0 ? Math.abs(totalPenyesuaian.toFixed(0)) : totalPenyesuaian.toFixed(0)}
                            displayType={'text'} thousandSeparator={true} prefix={totalPenyesuaian < 0 ? '(' : ''} suffix={totalPenyesuaian < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={isNaN(totalNilaiPenyesuaian / totalPenyesuaian) ? 0 : (totalNilaiPenyesuaian / totalPenyesuaian).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiPenyesuaian < 0 ? Math.abs(totalNilaiPenyesuaian.toFixed(0)) : totalNilaiPenyesuaian.toFixed(0)}
                            displayType={'text'} thousandSeparator={true} prefix={totalNilaiPenyesuaian < 0 ? '(' : ''} suffix={totalNilaiPenyesuaian < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalSaldoAkhir.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(totalNilaiSaldoAkhir / totalSaldoAkhir).toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiSaldoAkhir < 0 ? Math.abs(totalNilaiSaldoAkhir.toFixed(0)) : totalNilaiSaldoAkhir.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              />
            </Loading>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default OvkComponent