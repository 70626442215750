import React from 'react';
import { Form, Row, Col, Input, DatePicker, Select, Layout, Button, Breadcrumb, Card } from 'antd';
import { Link } from 'react-router-dom';
import CONSTANTS from '../../../../../common/utils/Constants';
import Loading from '../../../../../common/component/loading/loading-container';
import moment from 'moment';

function CreateOvk(props) {
    const { handleSubmit, ovkType, ovkMedia, ovkUnit, ovkShape, handleFailed, navigate, loading,
        suffix, setSuffix, ovkUnitRecording, detailOvk, t } = props
    const { Option } = Select
    const { Content } = Layout
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/master-ovk"> <span>{t('pages.master.ovk.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.master.ovk.modal.titleEdit')}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title={t('pages.master.ovk.modal.titleEdit')} bordered={false}>
                                <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="basic" initialValues={{
                                    remember: true,
                                    ovkShape: detailOvk.ovkShape,
                                    ovkType: detailOvk.ovkType,
                                    ovkUnit: detailOvk.ovkUnit,
                                    ovkMedia: detailOvk.ovkMedia,
                                    kodeOvk: detailOvk.code,
                                    namaOvk: detailOvk.name,
                                    dosisOvk: detailOvk.dosis,
                                    penggunaan: detailOvk.recommendedUse,
                                    kandunganOvk: detailOvk.ingredient,
                                    distributor: detailOvk.producer,
                                    date_picker: moment(new Date(parseInt(detailOvk.expired))),
                                    ovkUnitRecording: detailOvk.ovkUnitRecording === null ? "default" : detailOvk.ovkUnitRecording,
                                    qtyUnit: detailOvk.qtyUnit
                                }}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.modal.bentuk')}</span>
                                            <Form.Item
                                                name="ovkShape" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgBentuk')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.master.ovk.modal.phBentuk')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        ovkShape.map(data =>
                                                            <Option
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.modal.tipe')}</span>
                                            <Form.Item
                                                name="ovkType" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgTipe')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder="Pilih Tipe"
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        ovkType.map(data =>
                                                            <Option
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.modal.satuanPengadaan')}</span>
                                            <Form.Item
                                                name="ovkUnit" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgSatuanPengadaan')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder="Pilih Satuan"
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        ovkUnit.map(data =>
                                                            <Option
                                                                key={data.name}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.modal.media')}</span>
                                            <Form.Item
                                                name="ovkMedia" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgMedia')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder="Pilih Media"
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        ovkMedia.map(data =>
                                                            <Option
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.kode')}</span>
                                            <Form.Item
                                                name="kodeOvk" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgKode')}` }]}
                                            >
                                                <Input
                                                    name='kodeOvk'
                                                    placeholder="12453..."
                                                    className="input-form mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.nama')}</span>
                                            <Form.Item
                                                name="namaOvk" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgNama')}` }]}
                                            >
                                                <Input
                                                    name='namaOvk'
                                                    placeholder="Nupros..."
                                                    className="input-form mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.dosis')}</span>
                                            <Form.Item
                                                name="dosisOvk" rules={[{ required: true, message: `${t('pages.master.ovk.modal.kode')}` }]}
                                            >
                                                <Input
                                                    name='dosisOvk'
                                                    placeholder="0,01..."
                                                    className="input-suffix mt-5"
                                                    suffix="%"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.penggunaan')}</span>
                                            <Form.Item
                                                name="penggunaan" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgPenggunaan')}` }]}
                                            >
                                                <Input
                                                    name='penggunaan'
                                                    placeholder="3 hari..."
                                                    className="input-form mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.kandungan')}</span>
                                            <Form.Item
                                                name="kandunganOvk" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgKandungan')}` }]}
                                            >
                                                <Input
                                                    name='kandunganOvk'
                                                    placeholder={t('pages.master.ovk.modal.phKandungan')}
                                                    className="input-form mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.supplier')}</span>
                                            <Form.Item
                                                name="distributor" rules={[{ required: true, message: `${t('pages.master.ovk.modal.supplier')}` }]}
                                            >
                                                <Input
                                                    name='distributor'
                                                    placeholder="kimia farma..."
                                                    className="input-form mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.satuanPenggunaan')}</span>
                                            <Form.Item
                                                name="ovkUnitRecording" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgSatuanPenggunaan')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.master.ovk.modal.phSatuanPenggunaan')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular mt-5"
                                                    onChange={(value) => setSuffix(value.label)}
                                                >
                                                    {
                                                        ovkUnitRecording.map(data =>
                                                            <Option
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.modal.kuantitas')}</span>
                                            <Form.Item
                                                name="qtyUnit" rules={[{ required: true, message: `${t('pages.master.ovk.modal.msgKuantitas')}` }]}
                                            >
                                                <Input
                                                    type='number'
                                                    name='qtyUnit'
                                                    placeholder="50..."
                                                    className="input-form mt-5"
                                                    suffix={suffix}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.modal.kedaluwarsa')}</span>
                                            <Form.Item
                                                name="date_picker" rules={[{ type: 'object', required: true, message: `${t('pages.master.ovk.modal.msgKedaluwarsa')}` }]}
                                            >
                                                <DatePicker
                                                    placeholder={t('pages.master.ovk.modal.phKedaluwarsa')}
                                                    className="select-input-date mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button
                                        onClick={() => navigate(CONSTANTS.MASTER_OVK_MENU_KEY)}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ marginLeft: 8, marginTop: 0 }}
                                    >
                                        Edit
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    );
}

export default CreateOvk;