import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action/index'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { API } from '../../../../common/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import CONSTANTS from '../../../../common/utils/Constants'
import { faInfoCircle, faLock, faLockOpen, faSlidersH, faTrash } from '@fortawesome/free-solid-svg-icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import PeriodePakanComponent from '../../../../modules/dashboard-peternak/stock-flow-component/periode-component/periode-pakan-component'
import { createStockflowFeed, newStockflowFeed, lockPeriodeStokFeed, reLockPeriodeStokFeed, delStockflowFeed } from './periode-aksi-page/query-periode'
import { getIdMonthlyStock } from '../../../../modules/dashboard-peternak/adjustment-stock-component/pakan-component/store/adjustment-pakan-action'
import { getMonthPeriode, getYearPeriode} from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'

function PeriodePakanPage(props) {
    const { confirm } = Modal
    const { setLoading, loading, pakan, fetchDataPakan, getIdMonthlyStock,
        getMonthPeriode, getYearPeriode,
        yearRangePakan, yearPakan, setYearPakan, t } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }
    const createData = () => {
        setLoading(true)
        API.get(createStockflowFeed())
            .then(res => {
                if (res.data.data.createMonthlyStockFeed._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.pakan.response.sukses.create')}`)
                    fetchDataPakan(yearPakan)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.pakan.response.gagal.create')}`)
                setLoading(false)
            })
    }
  const createNewData = (date) => {
        setLoading(true)
        API.get(newStockflowFeed(date))
            .then(res => {
                if (res.data.data.newMonthlyStockFeed._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.pakan.response.sukses.create')}`)
                    fetchDataPakan(yearPakan)
                    setVisible(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.pakan.response.gagal.create')}`)
                setLoading(false)
                setVisible(false)
            })
    }
    const createConfirm = () => {
        confirm({
            title: `${t('pages.stockflow.periode.pakan.textCreate')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                createData()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const detailPeriode = useCallback((m, y) => {
        getMonthPeriode(m)
        getYearPeriode(y)
        props.navigate(CONSTANTS.DETAIL_PERIODE_PAKAN_KEY)
    }, [props, getMonthPeriode, getYearPeriode])

    const closePeriode = (month, periode) => {
        confirm({
            title: `${t('pages.stockflow.periode.pakan.textClose')}${periode}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                close(month)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const close = (_id) => {
        setLoading(true)
        API.post(lockPeriodeStokFeed(_id))
            .then(res => {
                console.log(res)
                if (res.data.data.lockMonthlyStockFeed._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.pakan.response.sukses.close')}`)
                    fetchDataPakan(yearPakan)
                } else if (res.data.data.lockMonthlyStockFeed.ok !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.pakan.response.sukses.reclose')}`)
                    fetchDataPakan(yearPakan)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.pakan.response.gagal.close')}`)
                setLoading(false)
            })
    }

    const openPeriode = (_id, periode) => {
        confirm({
            title: `${t('pages.stockflow.periode.pakan.textOpen')}${periode}?`,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                open(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const open = (_id) => {
        setLoading(true)
        API.post(reLockPeriodeStokFeed(_id))
            .then(res => {
                if (res.data.data.reOpenMonthlyStockFeed._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.pakan.response.sukses.open')}`)
                    fetchDataPakan(yearPakan)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.pakan.response.gagal.open')}`)
                setLoading(false)
            })
    }
    const deleteData = (_id) => {
        setLoading(true)
        API.delete(delStockflowFeed(_id))
            .then(res => {
                if (res.data.data.deleteMonthlyStockFeed.deleted === true) {
                    validationMessage('success', `${t('pages.stockflow.periode.pakan.response.sukses.delete')}`)
                    fetchDataPakan(yearPakan)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.pakan.response.gagal.delete')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.stockflow.periode.pakan.textDel')}${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const adjustmentFeed = useCallback((value, m, y, date) => {
        getIdMonthlyStock(value)
        getMonthPeriode(m)
        getYearPeriode(y)
        props.navigate(CONSTANTS.ADJUSMENT_FEED_MENU_KEY)
    }, [props, getIdMonthlyStock, getMonthPeriode, getYearPeriode])

    const columns = [
        {
            title: `${t('pages.stockflow.periode.table.periode')}`,
            dataIndex: 'periode',
            key: 'periode',
            ...getColumnSearchProps('periode', `${t('pages.stockflow.periode.table.periode')}`),
        },
        {
            title: `${t('pages.stockflow.periode.table.status')}`,
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status', `${t('pages.stockflow.periode.table.status')}`),
        },
        {
            title: `${t('pages.stockflow.periode.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.recording.button.detail")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }}
                            onClick={() => detailPeriode(row.month, row.year)}
                        ><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('pages.stockflow.periode.table.penyesuaian')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#02d456',
                            borderRadius: '5px'
                        }} onClick={() => adjustmentFeed(row.key, row.month, row.year)}>
                            <FontAwesomeIcon icon={faSlidersH} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={row.locked === false ?
                        `${t('pages.stockflow.periode.table.lock')}` :
                        `${t('pages.stockflow.periode.table.unlock')}`}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: row.locked === false ? '#ff4a3d' : '#03a5fc',
                            borderRadius: '5px',
                        }} onClick={row.locked === false ? () => closePeriode(row.key, row.periode) :
                            () => openPeriode(row.key, row.periode)} >
                            <FontAwesomeIcon icon={row.locked === false ? faLock : faLockOpen} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.periode)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const data = pakan.map(({ _id, locked, month, year, createdAt }, index) => ({
        key: _id,
        id: _id,
        periode: month + ' ' + year,
        tahun: year,
        locked: locked,
        month: month,
        year: year,
        status: locked === true ?
            `${t('pages.stockflow.periode.table.locked')}` :
            `${t('pages.stockflow.periode.table.unlocked')}`,
        createdAt: createdAt,
    }))

    return (
        <PeriodePakanComponent
            navigate={props.navigate}
            columns={columns}
            loading={loading}
            data={data}
            yearRangePakan={yearRangePakan}
            yearPakan={yearPakan}
            setYearPakan={setYearPakan}
            t={t}
            createConfirm={createConfirm}
            createNewData={createNewData}
            visible={visible} setVisible={setVisible}
        />
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdMonthlyStock,
    getMonthPeriode, getYearPeriode,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PeriodePakanPage)
export default page