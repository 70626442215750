export const TRANSLATIONS_ID = {
    "general": {
        "create": "Tambah",
        "search": "Cari",
        "del": "Hapus",
        "add": "Tambah",
        "edit": "Edit",
        "reset": "Reset",
        "send": "Kirim",
        "detail": "Detail",
        "open": "Buka",
        "close": "Tutup",
        "lock": "Kunci",
        "unlock": "Buka Kunci",
        "save": "Simpan",
        "next": "Lanjut",
        "back": "Kembali",
        "fin": "Selesai",
        "cancel": "Batal",
        "action": "Pilihan",
        "print": "Cetak",
        "yes": "Ya",
        "no": "Tidak",
        "errCon": "Koneksi bermasalah",
        "errData": "Data Error"
    },
    "sidebar": {
        "dashboard": "Dashboard",
        "periodePemeliharaan": "Periode Pemeliharaan",
        "recording": "Recording",
        "stok": "Stok",
        "subMenustok": {
            "pakan": "Pakan",
            "ovk": "OVK",
            "material": "Material"
        },
        "monitor": "Monitor",
        "subMenuMonitor": {
            "device": "Alat",
            "ews": "EWS"
        },
        "pemakaianUmum": "Pemakaian Umum",
        "stokFlow": "Stok Flow",
        "subMenuStokFlow": {
            "periode": "Periode",
            "liveBird": "Live Bird",
            "pakan": "Pakan",
            "ovk": "OVK",
            "material": "Material",
            "resume": "Rekapitulasi "
        },
        "master": "Master",
        "subMenuMaster": {
            "kandang": "Kandang",
            "strain": "Strain",
            "lingkungan": "Lingkungan",
            "gudangPakan": "Gudang Pakan",
            "pakan": "Pakan",
            "gudangOvk": "Gudang OVK",
            "ovk": "OVK",
            "gudangMaterial": "Gudang Material",
            "material": "Material",
            "biaya": "Biaya",
            "overhead": "Overhead",
            "penyakit": "Penyakit",
            "kostumer": "Pelanggan",
            "supplier": "Supplier",
            "pengguna": "Pengguna"
        }
    },
    "pages": {
        "auth": {
            "login": "Masuk",
            "welcome": "Selamat Datang",
            "loginTitle": "Masuk menggunakan akun Anda",
            "forgetTitle": "Lupa password Anda?",
            "forgetText": "Masukkan email Anda untuk mendapatkan notifikasi penggantian password.",
            "phUsername": "Masukkan username/email Anda",
            "phEmail": "Masukkan email Anda",
            "phPass": "Masukkan password Anda",
            "msgUsername": "Mohon masukkan username/email Anda!",
            "msgEmail": "Mohon masukkan email Anda!",
            "msgPass": "Mohon masukkan password Anda",
            "forget": "Lupa Password",
            "oauth": "Atau masuk menggunakan",
            "send": "Kirim",
            "response": {
                "sukses": {
                    "login": "Berhasil masuk"
                },
                "gagal": {
                    "login": "Gagal masuk"
                }
            }
        },
        "dashboard": {
            "title": "Dashboard Record Peternak",
            "phPeriode": "Pilih periode",
            "cardStokAyam": "Stok Ayam (ekor)",
            "cardCulling": "Culling (ekor)",
            "cardDeplesi": "Deplesi (ekor)",
            "cardFCR": "FCR",
            "recordLingkunganKandang": "Grafik Keadaan Lingkungan Kandang",
            "recordPerformaKandang": "Grafik Performa Kandang",
            "tabelPerformaKandang": "Tabel Performa Kandang",
            "nilai": "Nilai",
            "mingguke": "Minggu ke",
            "recordNekorpsi": "Hasil Nekropsi",
            "suhu": "Suhu",
            "standarSuhu": "Standar Suhu",
            "amonia": "Amonia(NH3)",
            "standarAmonia": "Standar Amonia",
            "kelembapan": "Kelembapan",
            "standarKelembapan": "Standar Kelembapan",
            "penyakit": "Penyakit",
            "jumlah": "Jumlah",
            "jam": "Jam",
            "hari": "Hari",
            "tanggal": "Tanggal",
            "mingguan": "Mingguan",
            "deplesi": "Deplesi",
            "standarDeplesi": "Standar Deplesi",
            "standarBw": "Standar Body Weight",
            "standarFcr": "Standar FCR",
            "standarFi": "Standar Feed Intake",
            "jumlahNekropsi": "jumlah",
            "table": {
                "ekor": "ekor",
                "umur": "Umur",
                "date": "Tanggal",
                "populasi": "Populasi (ekor)",
                "deplesi": {
                    "title": "Deplesi",
                    "ket": "Ket"
                },
                "bw": {
                    "sampling": "Sampling",
                    "ket": "Ket"
                },
                "fcr": {
                    "ket": "Ket"
                }
            },
            "performance": {
                "title": "Performa",
                "chartTitle": "Grafik Performa Kandang",
                "age": "Umur",
                "day": "hari",
                "chicken": "ekor",
                "population": "Populasi",
                "bw": "BW",
                "depletion": "Deplesi",
                "adg": "ADG",
                "fcr": "FCR",
                "value": "Nilai",
                "standarBw": "Standar Body Weight",
                "standarDepletion": "Standar Deplesi",
                "standarFcr": "Standar FCR",
                "standarFi": "Standar Feed Intake"
            },
            "iot": {
                "title": "Iklim",
                "chartTitle": "Grafik Keadaan Lingkungan Kandang",
                "thiChartTitle": "Grafik THI",
                "phParameter": "Pilih Parameter",
                "hour": "Jam",
                "day": "Hari",
                "phTool": "Pilih Alat ",
                "date": "Tanggal ",
                "humidity": "Kelembapan",
                "temperature": "Suhu (°C)",
                "airPressure": "Tekanan Udara",
                "value": "Nilai",
                "updatedAt": "Terakhir diperbarui:",
                "nodevice": "Anda belum punya device IoT BroilerX yang terdaftar",
                "notelemetry": "Silakan pilih alat Anda dahulu untuk melihat informasi selengkapnya",
                "selfHumidity": "Penskalaan kelembapan sendiri"
            },
            "feed": {
                "title": "Penggunaan Pakan",
                "chart": "Grafik Penggunaan Pakan",
                "age": "Umur (hari)",
                "qty": "Jumlah (kg)",
                "feed": "Pakan",
                "total": "Total",
                "day": "Hari",
                "days": "hari"
            }
        },
        "periode": {
            "title": "Periode Pemeliharaan",
            "periodeBerjalan": {
                "title": "Periode Berjalan",
                "table": {
                    "tanggal": "Tanggal",
                    "periode": "Periode",
                    "kandang": "Kandang",
                    "strain": "Strain",
                    "ppl": "Ppl",
                    "popHpp": "Populasi Awal HPP (ekor)",
                    "pop": "Populasi Awal Performa (ekor)",
                    "bobot": "Bobot (Gr)",
                    "harga": "Harga (Rp)",
                    "umur": "Umur",
                    "performa": {
                        "deplesi": "Deplesi"
                    },
                    "biaya": {
                        "title": "Biaya (Rp)",
                        "listrik": "Listrik",
                        "tenagaKerja": "Tenaga Kerja Langsung",
                        "penyusutan": "Penyusutan Kandang",
                        "bop": "Bop Kandang"
                    },
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Periode",
                    "edit": "Edit",
                    "tutup": "Tutup",
                    "hapus": "Hapus"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil Menambahkan Periode",
                        "hapus": "Berhasil Menghapus Periode",
                        "edit": "Berhasil Mengubah Periode",
                        "tutup": "Berhasil Menutup Periode"
                    },
                    "gagal": {
                        "tambah": "Gagal Menambahkan Periode",
                        "hapus": "Gagal Menghapus Periode",
                        "edit": "Gagal Mengubah Periode",
                        "tutup": "Gagal Menutup Periode"
                    }
                }
            },
            "periodeSelesai": {
                "title": "Periode Selesai",
                "table": {
                    "tanggal": "Tanggal",
                    "periode": "Periode",
                    "kandang": "Kandang",
                    "strain": "Strain",
                    "popHpp": "Populasi Awal HPP (ekor)",
                    "pop": "Populasi Awal Performa (ekor)",
                    "bobot": "Bobot (Gr)",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "detail": "Detail",
                    "hapus": "Hapus"
                }
            },
            "modal": {
                "titleTambah": "Tambah Periode Berjalan",
                "titleEdit": "Edit Periode berjalan",
                "create": "Tambah",
                "cancel": "Batal",
                "namaPeriode": "Nama Periode",
                "tanggalPeriode": "Tanggal Periode",
                "bobot": "Bobot",
                "populasiHpp": "Populasi Awal (HPP)",
                "populasi": "Populasi Awal (Performa)",
                "kandang": "Kandang",
                "strain": "Strain",
                "ppl": "Ppl",
                "listrik": "Listrik",
                "penyusutan": "Penyusutan Kandang",
                "tenagaKerja": "Tenaga Kerja Langsung",
                "bop": "Bop Kandang",
                "harga": "Harga DOC",
                "keterangan": "Keterangan",
                "phNamaPeriode": "Periode Juni...",
                "phTanggalPeriode": "Pilih tanggal periode",
                "phKandang": "Pilih kandang",
                "phStrain": "Pilih strain",
                "phKet": "Masukkan keterangan",
                "suffixPopulasi": "ekor",
                "msgNamaPeriode": "Mohon masukkan nama periode!",
                "msgTglPeriode": "Mohon masukkan tanggal periode!",
                "msgBobot": "Mohon masukkan bobot ayam DOC!",
                "msgPopulationHpp": "Mohon masukkan populasi awal untuk HPP!",
                "msgPopulation": "Mohon masukkan populasi awal untuk Performa!",
                "msgKandang": "Mohon pilih kandang!",
                "msgStrain": "Mohon pilih strain!",
                "msgPpl": "Mohon masukkan ppl kandang!",
                "msgListrik": "Mohon masukkan biaya listrik!",
                "msgPenyusutan": "Mohon masukkan penyusutan kandang!",
                "msgTenagaKerja": "Mohon masukkan biaya tenaga kerja langsung!",
                "msgBop": "Mohon masukkan biaya Bop Kandang!",
                "msgHarga": "Mohon masukkan harga ayam DOC!",
                "titleTutupPeriode": "Tutup Periode Pemeliharaan",
                "tglPenutupan": "Tanggal Penutupan",
                "phTglPenutupan": "Pilih tanggal penutupan",
                "msgTglPenutupan": "Mohon pilih tanggal penutupan!"
            },
            "detail": {
                "title": "Detail Periode Pemeliharaan ",
                "grafikTitle": "Grafik Performa Kandang",
                "tableTitle": "Tabel Performa Kandang",
                "minggu": "Minggu ke"
            },
            "textHapus": "Apakah anda yakin ingin menghapus periode?",
            "textOpen": "Apakah anda yakin ingin membuka periode?",
            "response": {
                "sukses": {
                    "hapus": "Berhasil Menghapus Periode",
                    "open": "Berhasil membuka kembali periode"
                },
                "gagal": {
                    "hapus": "Gagal Menghapus Periode",
                    "open": "Gagal membuka kembali periode"
                }
            }
        },
        "recording": {
            "title": "Recording Pemeliharaan",
            "table": {
                "pilihan": "Pilihan",
                "umur": "Umur",
                "tanggal": "Tanggal",
                "pakan": {
                    "title": "Pakan",
                    "pakan/hari": "pakan/hari (Kg)",
                    "cum": "CUM",
                    "fiAct": "FI Act (Gr/Ekor)",
                    "fiStd": "Fi Std (Gr/Ekor)",
                    "keterangan": "KET",
                    "actCum": "Act Cum (Gr/Ekor)",
                    "stdFiCum": "STD FI Cum (Gr/Ekor)",
                    "deviasi": "Deviasi CUM (%)"
                },
                "deplesi": {
                    "title": "Deplesi",
                    "culling": "Culling (ekor)",
                    "mati": "Mati (ekor)",
                    "jumlah": "Jumlah (ekor)",
                    "cum": "CUM (ekor)",
                    "persenHari": "Hari (%)",
                    "persenCum": "CUM (%)",
                    "std": "STD (%)",
                    "keterangan": "Ket"
                },
                "panen": {
                    "title": "Panen",
                    "total": "Total (ekor)",
                    "berat": "Berat (Kg)",
                    "sisaAyam": "Sisa Ayam (ekor)"
                },
                "bw": {
                    "sampling": "Sampling (Gr/ekor)",
                    "std": "STD (Gr/ekor)",
                    "deviasi": "Deviasi (%)",
                    "adg": "Adg (Gr)",
                    "totalBerat": "Total Berat (Kg)",
                    "abw": "ABW (Gr)"
                },
                "fcr": {
                    "actCum": "Act Cum(Gr)",
                    "std": "STD (Gr)"
                },
                "biaya": {
                    "pakan": "Pakan (Rp)",
                    "ovk": "OVK (Rp)",
                    "material": "Material (Rp)",
                    "penyusutan": "Penyusutan (Rp)",
                    "hpp": "Total Hpp (Rp)"
                }
            },
            "button": {
                "tambah": "Tambah Recording",
                "hapus": "Hapus",
                "detail": "Detail"
            },
            "detail": {
                "title": "Detail Recording Hari ke- ",
                "deplesi": {
                    "title": "Deplesi",
                    "table": {
                        "jenis": "Jenis",
                        "jumlah": "Jumlah (ekor)",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording Deplesi",
                        "hapus": "Hapus",
                        "edit": "Edit"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data deplesi ?",
                    "modal": {
                        "titleTambah": "Tambah Recording Deplesi",
                        "titleEdit": "Edit Recording Deplesi",
                        "jenis": "Jenis",
                        "jumlah": "Jumlah (ekor)",
                        "keterangan": "Keterangan",
                        "msgJenis": "Mohon masukkan jenis deplesi!",
                        "phJenis": "Masukkan jenis deplesi",
                        "msgJumlah": "Masukkan jumlah ayam",
                        "phKet": "Masukkan keterangan"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Deplesi",
                            "edit": "Berhasil Mengubah Data Deplesi",
                            "hapus": "Berhasil Menghapus Data Deplesi"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Deplesi",
                            "edit": "Gagal Mengubah Data Deplesi",
                            "hapus": "Gagal Menghapus Data Deplesi"
                        }
                    }
                },
                "panen": {
                    "title": "Panen",
                    "table": {
                        "berat": "Berat Ayam (kg)",
                        "jumlah": "Jumlah Ayam (ekor)",
                        "rataRata": "Rata-Rata Berat Ayam (kg/Ekor)",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording Panen",
                        "hapus": "Hapus",
                        "edit": "Edit"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data panen?",
                    "modal": {
                        "titleTambah": "Tambah Recording Panen",
                        "titleEdit": "Edit Recording Panen",
                        "berat": "Berat Ayam (kg)",
                        "jumlah": "Jumlah Ayam (ekor)",
                        "keterangan": "Keterangan",
                        "msgBerat": "Mohon masukkan berat ayam!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Panen",
                            "edit": "Berhasil Mengubah Data Panen",
                            "hapus": "Berhasil Menghapus Data Panen"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Panen",
                            "edit": "Gagal Mengubah Data Panen",
                            "hapus": "Gagal Menghapus Data Panen"
                        }
                    }
                },
                "pertumbuhan": {
                    "title": "Pertumbuhan",
                    "table": {
                        "parameter": "Parameter",
                        "nilai": "Nilai (Gram)",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording Pertumbuhan",
                        "hapus": "Hapus",
                        "edit": "Edit"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data recording pertumbuhan?",
                    "modal": {
                        "titleTambah": "Tambah Recording Pertumbuhan",
                        "titleEdit": "Edit Recording Pertumbuhan",
                        "berat": "Berat Ayam Sampling (Gram)",
                        "keterangan": "Keterangan"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Pertumbuhan",
                            "edit": "Berhasil Mengubah Data Pertumbuhan",
                            "hapus": "Berhasil Menghapus Data Pertumbuhan"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Pertumbuhan",
                            "edit": "Gagal Mengubah Data Pertumbuhan",
                            "hapus": "Gagal Menghapus Data Pertumbuhan"
                        }
                    }
                },
                "pakan": {
                    "title": "Pakan",
                    "table": {
                        "stok": "Stok Pakan",
                        "pakan": "Pakan",
                        "jumlah": "Jumlah (Kg)",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording Pakan",
                        "hapus": "Hapus",
                        "edit": "Edit"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data recording pakan?",
                    "modal": {
                        "titleTambah": "Tambah Recording Pakan",
                        "titleEdit": "Edit Recording Pakan",
                        "stok": "Stok Pakan",
                        "pakan": "Pakan",
                        "jumlah": "Jumlah (Kg)",
                        "msgPakan": "Mohon pilih pakan!",
                        "phPakan": "Pilih pakan",
                        "msgJumlah": "Mohon masukkan penggunaan pakan!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Recording Pakan",
                            "edit": "Berhasil Mengubah Data Recording Pakan",
                            "hapus": "Berhasil Menghapus Data Recording Pakan"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Recording Pakan",
                            "edit": "Gagal Mengubah Data Recording Pakan",
                            "hapus": "Gagal Menghapus Data Recording Pakan"
                        }
                    }
                },
                "ovk": {
                    "title": "OVK",
                    "table": {
                        "stok": "Stok OVK",
                        "ovk": "OVK",
                        "jumlah": "Jumlah",
                        "jumlahPengadaan": "Jumlah Pengadaan",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording OVK",
                        "hapus": "Hapus",
                        "edit": "Edit"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data recording ovk?",
                    "modal": {
                        "titleTambah": "Tambah Recording OVK",
                        "titleEdit": "Edit Recording OVK",
                        "stok": "Stok OVK",
                        "ovk": "OVK",
                        "jumlah": "Jumlah",
                        "msgOvk": "Mohon pilih OVK!",
                        "phOvk": "Pilih OVK",
                        "msgJumlah": "Mohon masukkan jumlah penggunaan OVK!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Recording OVK",
                            "edit": "Berhasil Mengubah Data Recording OVK",
                            "hapus": "Berhasil Menghapus Data Recording OVK"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Recording OVK",
                            "edit": "Gagal Mengubah Data Recording OVK",
                            "hapus": "Gagal Menghapus Data Recording OVK"
                        }
                    }
                },
                "material": {
                    "title": "Material",
                    "table": {
                        "stok": "Stok Material",
                        "material": "Material",
                        "jumlah": "Jumlah",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording Material",
                        "hapus": "Hapus",
                        "edit": "Edit"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data recording material?",
                    "modal": {
                        "titleTambah": "Tambah Recording Material",
                        "titleEdit": "Edit Recording Material",
                        "stok": "Stok Material",
                        "material": "Material",
                        "jumlah": "Jumlah",
                        "msgMaterial": "Mohon pilih material!",
                        "phMaterial": "Pilih material",
                        "msgJumlah": "Mohon masukkan jumlah penggunaan material!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Recording Material",
                            "edit": "Berhasil Mengubah Data Recording Material",
                            "hapus": "Berhasil Menghapus Data Recording Material"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Recording Material",
                            "edit": "Gagal Mengubah Data Recording Material",
                            "hapus": "Gagal Menghapus Data Recording Material"
                        }
                    }
                },
                "penyakit": {
                    "title": "Penyakit",
                    "table": {
                        "penyakit": "Penyakit",
                        "jumlah": "Jumlah Ayam (ekor)",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording Penyakit",
                        "hapus": "Hapus",
                        "edit": "Edit",
                        "foto": "Foto"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data recording penyakit?",
                    "edit": {
                        "title": "Edit Data Penyakit",
                        "penyakit": "Penyakit",
                        "jumlah": "Jumlah Ayam (ekor)",
                        "keterangan": "Keterangan"
                    },
                    "tambah": {
                        "title": "Tambah Data Penyakit",
                        "penyakit": "Penyakit",
                        "jumlah": "Jumlah Ayam (ekor)",
                        "foto": "Foto Penyakit",
                        "keterangan": "Keterangan",
                        "msgPenyakit": "Mohon masukkan penyakit!",
                        "phPenyakit": "Masukkan penyakit"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Penyakit",
                            "edit": "Berhasil Mengubah Data Penyakit",
                            "hapus": "Berhasil Menghapus Data Penyakit"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Penyakit",
                            "edit": "Gagal Mengubah Data Penyakit",
                            "hapus": "Gagal Menghapus Data Penyakit"
                        }
                    }
                },
                "overhead": {
                    "title": "Overhead",
                    "table": {
                        "overhead": "Overhead",
                        "jumlah": "Jumlah",
                        "price": "Harga (Rp)",
                        "pilihan": "Pilihan"
                    },
                    "button": {
                        "tambah": "Tambah Recording Overhead",
                        "hapus": "Hapus",
                        "edit": "Edit"
                    },
                    "textHapus": "Apakah anda yakin ingin menghapus data recording overhead?",
                    "modal": {
                        "titleTambah": "Tambah Recording Overhead",
                        "titleEdit": "Edit Recording Overhead",
                        "overhead": "Overhead",
                        "jumlah": "Jumlah",
                        "price": "Harga (Rp)",
                        "phOverhead": "Pilih overhead",
                        "msgOverhead": "Mohon pilih overhead!",
                        "msgPrice": "Mohon masukkan harga!",
                        "msgJumlah": "Mohon masukkan penggunaan overhead!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil Menambahkan Data Recording Overhead",
                            "edit": "Berhasil Mengubah Data Recording Overhead",
                            "hapus": "Berhasil Menghapus Data Recording Overhead"
                        },
                        "gagal": {
                            "tambah": "Gagal Menambahkan Data Recording Overhead",
                            "edit": "Gagal Mengubah Data Recording Overhead",
                            "hapus": "Gagal Menghapus Data Recording Overhead"
                        }
                    }
                }
            },
            "modal": {
                "title": "Tambah Recording Pemeliharaan",
                "tanggal": "Tanggal Recording",
                "phTgl": "Pilih tanggal recording",
                "msgTgl": "Mohon pilih tanggal recording!"
            },
            "response": {
                "sukses": {
                    "tambah": "Berhasil Menambahkan Recording Pemeliharaan",
                    "hapus": "Berhasil Menghapus Recording Pemeliharaan"
                },
                "gagal": {
                    "tambah": "Gagal Menambahkan Recording Pemeliharaan",
                    "hapus": "Gagal Menghapus Recording Pemeliharaan"
                }
            },
            "textHapus": "Apakah anda yakin ingin menghapus recording?"
        },
        "stok": {
            "pakan": {
                "title": "Stok Pakan",
                "table": {
                    "kode": "Kode Pakan",
                    "nama": "Nama Pakan",
                    "pengadaan": "Nama Pengadaan",
                    "supplier": "Supplier",
                    "saldo": "Saldo (Kg)",
                    "pilihan": "Pilihan"
                },
                "modal": {
                    "titleTambah": "Tambah Stok Pakan Baru",
                    "titleEdit": "Edit Stok Pakan",
                    "gudang": "Gudang Pakan",
                    "pakan": "Pakan",
                    "namaPengadaan": "Nama Pengadaan",
                    "tglPengadaan": "Tanggal Pengadaan",
                    "harga": "Harga Satuan",
                    "stok": "Jumlah Stok",
                    "phGudang": "Pilih gudang pakan",
                    "phPakan": "Pilih pakan",
                    "phNamaPengadaan": "Pengadaan Stater",
                    "phTglPengadaan": "Pilih tanggal pengadaan",
                    "phTglPenambahan": "Pilih tanggal penambahan",
                    "phTglPeriode": "Pilih tanggal periode",
                    "msgGudang": "Mohon pilih gudang pakan!",
                    "msgPakan": "Mohon pilih pakan!",
                    "msgNamaPengadaan": "Mohon masukkan nama pengadaan!",
                    "msgTglPengadaan": "Mohon pilih tanggal pengadaan!",
                    "msgTglPenambahan": "Mohon pilih tanggal penambahan!",
                    "msgTglPeriode": "Mohon pilih tanggal periode!",
                    "msgHarga": "Mohon masukkan harga pakan!",
                    "msgStok": "Mohon masukkan jumlah stok!"
                },
                "button": {
                    "tambahBaru": "Tambah Stok Pakan Baru",
                    "edit": "Edit Stok",
                    "detail": "Detail",
                    "hapus": "Hapus",
                    "tambah": "Tambah Stok"
                },
                "tambah": {
                    "title": "Tambah Stok Pakan",
                    "tanggal": "Tanggal Penambahan",
                    "jumlah": "Jumlah Stok",
                    "harga": "Harga Pakan"
                },
                "edit": {
                    "title": "Edit Stok Pakan",
                    "gudang": "Gudang Pakan",
                    "pakan": "Pakan",
                    "pengadaan": "Nama Pengadaan"
                },
                "textHapus": "Apakah anda yakin ingin menghapus stok pakan",
                "detail": {
                    "title": "Detail Transaksi Stok Pakan ",
                    "penambahan": {
                        "title": "Penambahan",
                        "table": {
                            "tanggal": "Tanggal Transaksi",
                            "harga": "Harga Pakan (Rp)",
                            "jumlah": "Jumlah (Kg)",
                            "pilihan": "Pilihan"
                        },
                        "button": {
                            "edit": "Edit Stok",
                            "hapus": "Hapus"
                        },
                        "textHapus": "Apakah anda yakin ingin menghapus transaksi pakan?",
                        "edit": {
                            "title": "Edit Transaksi Penambahan Stok Pakan",
                            "jumlah": "Jumlah Stok",
                            "tanggal": "Tanggal Stok",
                            "harga": "Harga"
                        }
                    },
                    "penggunaan": {
                        "title": "Penggunaan",
                        "table": {
                            "tanggal": "Tanggal Transaksi",
                            "jumlah": "Jumlah (Kg)",
                            "pilihan": "Pilihan"
                        },
                        "button": {
                            "edit": "Edit Stok",
                            "hapus": "Hapus"
                        },
                        "textHapus": "Apakah anda yakin ingin menghapus transaksi pakan?",
                        "edit": {
                            "title": "Edit Transaksi Penggunaan Stok Pakan",
                            "jumlah": "Jumlah Stok",
                            "tanggal": "Tanggal Stok"
                        }
                    }
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan stok pakan",
                        "edit": "Berhasil mengubah stok pakan",
                        "editTrans": "Berhasil mengubah transaksi pakan",
                        "hapus": "Berhasil menghapus stok pakan",
                        "hapusTrans": "Berhasil menghapus transaksi pakan"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan stok pakan",
                        "edit": "Gagal mengubah stok pakan",
                        "editTrans": "Gagal mengubah transaksi pakan",
                        "hapus": "Gagal menghapus stok pakan",
                        "hapusTrans": "Gagal menghapus transaksi pakan"
                    }
                }
            },
            "ovk": {
                "title": "Stok OVK",
                "table": {
                    "kode": "Kode OVK",
                    "nama": "Nama Pengadaan",
                    "pengadaan": "Nama Pengadaan",
                    "supplier": "Supplier",
                    "saldo": "Saldo",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambahBaru": "Tambah Stok OVK Baru",
                    "edit": "Edit Stok",
                    "detail": "Detail",
                    "hapus": "Hapus",
                    "tambah": "Tambah Stok"
                },
                "tambah": {
                    "title": "Tambah Stok OVK",
                    "gudang": "Gudang OVK",
                    "nama": "Nama Pengadaan",
                    "tglPengadaan": "Tanggal Pengadaan",
                    "tglPenambahan": "Tanggal Penambahan",
                    "jumlahPengadaan": "Jumlah Penambahan",
                    "jumlahStok": "Jumlah Stok",
                    "harga": "Harga OVK",
                    "phGudang": "Pilih gudang OVK",
                    "phOvk": "Pilih OVK",
                    "phTglPengadaan": "Pilih tanggal pengadaan",
                    "phTglPenambahan": "Pilih tanggal penambahan",
                    "phTglPeriode": "Pilih tanggal periode",
                    "msgGudang": "Mohon pilih gudang!",
                    "msgOvk": "Mohon pilih OVK!",
                    "msgNama": "Mohon masukkan nama pengadaan!",
                    "msgTglPengadaan": "Mohon pilih tanggal pengadaan!",
                    "msgTglPenambahan": "Mohon pilih tanggal penambahan!",
                    "msgTglPeriode": "Mohon pilih tanggal periode!",
                    "msgHarga": "Mohon masukkan harga OVK!",
                    "msgJumlahPengadaan": "Mohon masukkan jumlah pengadaan!",
                    "msgJumlahStok": "Mohon masukkan jumlah stok!"
                },
                "edit": {
                    "title": "Edit Stok OVK",
                    "gudang": "Gudang OVK",
                    "ovk": "OVK",
                    "pengadaan": "Nama Pengadaan"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan stok OVK",
                        "edit": "Berhasil mengubah stok OVK",
                        "editTrans": "Berhasil mengubah transaksi OVK",
                        "hapus": "Berhasil menghapus stok OVK",
                        "hapusTrans": "Berhasil menghapus transaksi OVK"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan stok OVK",
                        "edit": "Gagal mengubah stok OVK",
                        "editTrans": "Gagal mengubah transaksi OVK",
                        "hapus": "Gagal menghapus stok OVK",
                        "hapusTrans": "Gagal menghapus transaksi OVK"
                    }
                },
                "textHapus": "Apakah anda yakin ingin menghapus stok OVK ",
                "detail": {
                    "title": "Detail Transaksi Stok OVK ",
                    "penambahan": {
                        "title": "Penambahan",
                        "table": {
                            "tanggal": "Tanggal Transaksi",
                            "harga": "Harga OVK (Rp)",
                            "jumlah": "Jumlah",
                            "pilihan": "Pilihan"
                        },
                        "button": {
                            "edit": "Edit Stok",
                            "hapus": "Hapus"
                        },
                        "textHapus": "Apakah anda yakin ingin menghapus transaksi OVK?",
                        "edit": {
                            "title": "Edit Transaksi Penambahan Stok OVK",
                            "jumlah": "Jumlah Stok",
                            "tanggal": "Tanggal Stok",
                            "harga": "Harga"
                        }
                    },
                    "penggunaan": {
                        "title": "Penggunaan",
                        "table": {
                            "tanggal": "Tanggal Transaksi",
                            "jumlah": "Jumlah",
                            "pilihan": "Pilihan"
                        },
                        "button": {
                            "edit": "Edit Stok",
                            "hapus": "Hapus"
                        },
                        "textHapus": "Apakah anda yakin ingin menghapus transaksi OVK?",
                        "edit": {
                            "title": "Edit Transaksi Penggunaan Stok OVK",
                            "jumlah": "Jumlah Stok",
                            "tanggal": "Tanggal Stok"
                        }
                    }
                }
            },
            "material": {
                "title": "Stok Material",
                "table": {
                    "kode": "Kode Material",
                    "pengadaan": "Nama Pengadaan",
                    "supplier": "Supplier",
                    "saldo": "Saldo",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambahBaru": "Tambah Stok Material Baru",
                    "edit": "Edit Stok",
                    "detail": "Detail",
                    "hapus": "Hapus",
                    "tambah": "Tambah Stok"
                },
                "tambah": {
                    "title": "Tambah Stok Material",
                    "gudang": "Gudang Material",
                    "pengadaan": "Nama Pengadaan",
                    "tglPengadaan": "Tanggal Pengadaan",
                    "tglPenambahan": "Tanggal Penambahan",
                    "jumlah": "Jumlah Stok",
                    "harga": "Harga Material",
                    "phGudang": "Pilih gudang material",
                    "phMaterial": "Pilih material",
                    "phNamaPengadaan": "Pengadaan Stater",
                    "phTglPengadaan": "Pilih tanggal pengadaan",
                    "phTglPenambahan": "Pilih tanggal penambahan",
                    "phTglPenggunaan": "Pilih tanggal penggunaan",
                    "msgGudang": "Mohon pilih gudang material!",
                    "msgMaterial": "Mohon pilih material!",
                    "msgNamaPengadaan": "Mohon masukkan nama pengadaan!",
                    "msgTglPengadaan": "Mohon pilih tanggal pengadaan!",
                    "msgTglPenambahan": "Mohon pilih tanggal penambahan!",
                    "msgTglPengunaan": "Mohon pilih tanggal penggunaan!",
                    "msgHarga": "Mohon masukkan harga material!",
                    "msgStok": "Mohon masukkan jumlah stok!"
                },
                "edit": {
                    "title": "Edit Stok Material"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan stok material",
                        "edit": "Berhasil mengubah stok material",
                        "editTrans": "Berhasil mengubah transaksi material",
                        "hapus": "Berhasil menghapus stok material",
                        "hapusTrans": "Berhasil menghapus transaksi material"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan stok material",
                        "edit": "Gagal mengubah stok material",
                        "editTrans": "Gagal mengubah transaksi material",
                        "hapus": "Gagal menghapus stok material",
                        "hapusTrans": "Gagal menghapus transaksi material"
                    }
                },
                "textHapus": "Apakah anda yakin ingin menghapus stok material ",
                "detail": {
                    "title": "Detail Transaksi Stok Material ",
                    "penambahan": {
                        "title": "Penambahan",
                        "table": {
                            "tanggal": "Tanggal Transaksi",
                            "harga": "Harga Material (Rp)",
                            "jumlah": "Jumlah",
                            "pilihan": "Pilihan"
                        },
                        "button": {
                            "edit": "Edit Stok",
                            "hapus": "Hapus"
                        },
                        "textHapus": "Apakah anda yakin ingin menghapus transaksi material?",
                        "edit": {
                            "title": "Edit Transaksi Penambahan Stok Material",
                            "jumlah": "Jumlah Stok",
                            "tanggal": "Tanggal Stok",
                            "harga": "Harga"
                        }
                    },
                    "penggunaan": {
                        "title": "Penggunaan",
                        "table": {
                            "tanggal": "Tanggal Transaksi",
                            "jumlah": "Jumlah",
                            "pilihan": "Pilihan"
                        },
                        "button": {
                            "edit": "Edit Stok",
                            "hapus": "Hapus"
                        },
                        "textHapus": "Apakah anda yakin ingin menghapus transaksi material?",
                        "edit": {
                            "title": "Edit Transaksi Penggunaan Stok Material",
                            "jumlah": "Jumlah Stok",
                            "tanggal": "Tanggal Stok"
                        }
                    }
                }
            }
        },
        "monitor": {
            "title": "Sensor Alat",
            "serial": "Serial Number",
            "house": "Kandang",
            "tool": "Alat",
            "position": "Posisi",
            "add": "Tambah Sensor Alat",
            "battery": "Baterai",
            "type": "Tipe Alat",
            "phHouse": "Pilih kandang",
            "phType": "Pilih tipe alat",
            "phPosition": "Tengah..",
            "ews": "Sistem Peringatan Dini",
            "envName": "Nama Standar Lingkungan",
            "std": "Standar Lingkungan",
            "calibrate": "Kalibrasi",
            "day": "Hari",
            "maxTemp": "Suhu Maksimal",
            "minTemp": "Suhu Minimal",
            "maxHumidity": "Kelembapan Maksimal",
            "minHumidity": "Kelembapan Minimal",
            "maxCo2": "CO₂ Maksimal",
            "msgDay": "Mohon masukkan hari",
            "msgEnvName": "Mohon masukkan nama standar lingkungan!",
            "msgSerial": "Mohon masukkan nomor serial!",
            "msgHouse": "Mohon pilih kandang!",
            "msgType": "Mohon pilih tipe alat!",
            "msgPosition": "Mohon masukkan posisi!",
            "delConfirm": "Apakah anda yakin ingin menghapus alat?",
            "delStdConfirm": "Apakah anda yakin ingin menghapus standar lingkungan",
            "delStdDayConfirm": "Apakah anda yakin ingin menghapus standar lingkungan hari ke-",
            "edit": "Edit Sensor Alat",
            "addStandardEnv": "Tambah Standar Lingkungan",
            "editStandardEnv": "Edit Standar Lingkungan",
            "addTare": "Tambah Tara Alat",
            "latest": "Terbaru",
            "manual": "Manual",
            "tareTime": "Waktu Kalibrasi",
            "current": "Terkini",
            "tare": "Tara",
            "temp": "Suhu",
            "humidity": "Kelembapan",
            "windSpeed": "Kecepatan Angin",
            "weight": "Berat",
            "desc": "Keterangan",
            "phDesc": "Tambahkan keterangan..",
            "resAddSuccess": "Berhasil menambahkan alat",
            "resEditSuccess": "Berhasil mengubah alat",
            "resDelSuccess": "Berhasil menghapus alat",
            "resAddStdSuccess": "Berhasil menambahkan standar lingkungan",
            "resEditStdSuccess": "Berhasil mengubah standar lingkungan",
            "resDelStdSuccess": "Berhasil menghapus standar lingkungan",
            "resAddTareSuccess": "Berhasil menambahkan tara dan kalibrasi alat",
            "resAddFailed": "Gagal menambahkan alat",
            "resEditFailed": "Gagal mengubah alat",
            "resDelFailed": "Gagal menghapus alat",
            "resAddStdFailed": "Gagal menambahkan standar lingkungan",
            "resEditStdFailed": "Gagal mengubah standar lingkungan",
            "resDelStdFailed": "Gagal menghapus standar lingkungan",
            "resAddTareFailed": "Gagal menambahkan tara dan kalibrasi alat"
        },
        "pemakaianUmum": {
            "title": "Pemakaian Umum OVK",
            "table": {
                "kandang": "Kandang",
                "periode": "Periode",
                "ovk": "OVK",
                "jumlah": "Jumlah",
                "pilihan": "Pilihan"
            },
            "button": {
                "tambah": "Tambah Pemakaian Umum OVK",
                "edit": "Edit",
                "hapus": "Hapus"
            },
            "tambah": {
                "title": "Tambah Pemakaian Umum OVK",
                "kandang": "Kandang",
                "periode": "Periode",
                "tanggal": "Tanggal",
                "ovk": "OVK",
                "jumlah": "Jumlah",
                "keterangan": "Keterangan",
                "phKandang": "Pilih kandang",
                "phPeriode": "Pilih periode",
                "phTgl": "Pilih tanggal",
                "phOvk": "Pilih OVK",
                "phKet": "Masukkan keterangan",
                "msgKandang": "Mohon pilih kandang!",
                "msgPeriode": "Mohon pilih periode!",
                "msgTgl": "Mohon pilih tanggal!",
                "msgOvk": "Mohon pilih OVK!",
                "msgJumlah": "Mohon masukkan jumlah penggunaan!"
            },
            "edit": {
                "title": "Edit Pemakaian Umum OVK",
                "ovk": "OVK",
                "jumlah": "Jumlah ",
                "keterangan": "Keterangan"
            },
            "textHapus": "Apakah anda yakin ingin menghapus pemakaian umum OVK ini?",
            "response": {
                "sukses": {
                    "tambah": "Berhasil menambahkan data pemakaian umum OVK",
                    "edit": "Berhasil mengubah data pemakaian umum OVK",
                    "hapus": "Berhasil menghapus data pemakaian umum OVK"
                },
                "gagal": {
                    "tambah": "Gagal menambahkan data pemakaian umum OVK",
                    "edit": "Gagal mengubah data pemakaian umum OVK",
                    "hapus": "Gagal menghapus data pemakaian umum OVK"
                }
            }
        },
        "stockflow": {
            "periode": {
                "title": "Periode Stok Flow",
                "table": {
                    "periode": "Periode",
                    "tahun": "Tahun",
                    "status": "Status",
                    "pilihan": "Pilihan",
                    "penyesuaian": "Penyesuaian",
                    "lock": "Kunci",
                    "unlock": "Buka Kunci",
                    "locked": "Terkunci",
                    "unlocked": "Belum Dikunci",
                    "all": "Semua"
                },
                "penyesuaian": {
                    "jumlah": "Jumlah",
                    "difference": "Selisih",
                    "phSelect": "Pilih ",
                    "msgSelect": "Mohon pilih ",
                    "msgJumlah": "Mohon masukkan jumlah penyesuaian!",
                    "pakan": {
                        "title": "Penyesuaian Stock Flow Pakan",
                        "tambah": "Tambah Penyesuaian Pakan",
                        "edit": "Edit Penyesuaian Pakan",
                        "textHapus": "Apakah anda yakin ingin menghapus penyesuaian pakan ini?",
                        "response": {
                            "sukses": {
                                "tambah": "Berhasil menambahkan penyesuaian pakan",
                                "edit": "Berhasil mengubah penyesuaian pakan",
                                "hapus": "Berhasil menghapus penyesuaian pakan"
                            },
                            "gagal": {
                                "tambah": "Gagal menambahkan penyesuaian pakan",
                                "edit": "Gagal mengubah penyesuaian pakan",
                                "hapus": "Gagal menghapus penyesuaian pakan"
                            }
                        }
                    },
                    "ovk": {
                        "title": "Penyesuaian Stock Flow OVK",
                        "tambah": "Tambah Penyesuaian OVK",
                        "edit": "Edit Penyesuaian OVK",
                        "textHapus": "Apakah anda yakin ingin menghapus penyesuaian OVK ini?",
                        "response": {
                            "sukses": {
                                "tambah": "Berhasil menambahkan penyesuaian OVK",
                                "edit": "Berhasil mengubah penyesuaian OVK",
                                "hapus": "Berhasil menghapus penyesuaian OVK"
                            },
                            "gagal": {
                                "tambah": "Gagal menambahkan penyesuaian OVK",
                                "edit": "Gagal mengubah penyesuaian OVK",
                                "hapus": "Gagal menghapus penyesuaian OVK"
                            }
                        }
                    },
                    "material": {
                        "title": "Penyesuaian Stock Flow Material",
                        "tambah": "Tambah Penyesuaian Material",
                        "edit": "Edit Penyesuaian Material",
                        "textHapus": "Apakah anda yakin ingin menghapus penyesuaian material ini?",
                        "response": {
                            "sukses": {
                                "tambah": "Berhasil menambahkan penyesuaian material",
                                "edit": "Berhasil mengubah penyesuaian material",
                                "hapus": "Berhasil menghapus penyesuaian material"
                            },
                            "gagal": {
                                "tambah": "Gagal menambahkan penyesuaian material",
                                "edit": "Gagal mengubah penyesuaian material",
                                "hapus": "Fagal menghapus penyesuaian material"
                            }
                        }
                    },
                    "livebird": {
                        "title": "Penyesuaian Stock Flow Live Bird",
                        "tambah": "Tambah Penyesuaian Live Bird",
                        "edit": "Edit Penyesuaian Live Bird",
                        "textHapus": "Apakah anda yakin ingin menghapus penyesuaian live bird ini?",
                        "response": {
                            "sukses": {
                                "tambah": "Berhasil menambahkan penyesuaian live bird",
                                "edit": "Berhasil mengubah penyesuaian live bird",
                                "hapus": "Berhasil menghapus penyesuaian live bird"
                            },
                            "gagal": {
                                "tambah": "Gagal menambahkan penyesuaian live bird",
                                "edit": "Gagal mengubah penyesuaian live bird",
                                "hapus": "Gagal menghapus penyesuaian live bird"
                            }
                        }
                    }
                },
                "pakan": {
                    "title": "Pakan",
                    "titleInitialBalance": "Saldo Awal Pakan ",
                    "titleEdit": "Edit Saldo Awal Pakan",
                    "create": "Tambah Periode Pakan",
                    "qty": "Jumlah",
                    "price": "Harga (Rp)",
                    "date": "Bulan Periode Pakan",
                    "msgQty": "Mohon masukkan jumlah!",
                    "msgPrice": "Mohon masukkan harga!",
                    "textCreate": "Apakah Anda yakin ingin membuat periode pakan bulan selanjutnya?",
                    "textClose": "Apakah Anda yakin ingin menutup periode pakan bulan ",
                    "textOpen": "Apakah Anda yakin ingin membuka periode pakan bulan ",
                    "textDel": "Apakah Anda yakin ingin menghapus periode pakan bulan ",
                    "response": {
                        "sukses": {
                            "create": "Berhasil menambahkan periode pakan",
                            "close": "Berhasil menutup periode pakan",
                            "reclose": "Berhasil menutup periode pakan kembali",
                            "open": "Berhasil membuka periode pakan",
                            "edit": "Berhasil memperbarui saldo awal pakan",
                            "delete": "Berhasil menghapus periode pakan"
                        },
                        "gagal": {
                            "create": "Gagal menambahkan periode pakan",
                            "close": "Gagal menutup periode pakan",
                            "open": "Gagal membuka periode pakan",
                            "edit": "Gagal memperbarui saldo awal pakan",
                            "delete": "Gagal menghapus periode pakan"
                        }
                    }
                },
                "ovk": {
                    "title": "OVK",
                    "titleInitialBalance": "Saldo Awal OVK ",
                    "titleEdit": "Edit Saldo Awal OVK",
                    "create": "Tambah Periode OVK",
                    "qty": "Jumlah",
                    "price": "Harga (Rp)",
                    "date": "Bulan Periode OVK",
                    "msgQty": "Mohon masukkan jumlah!",
                    "msgPrice": "Mohon masukkan harga!",
                    "textCreate": "Apakah Anda yakin ingin menambah periode OVK bulan selanjutnya?",
                    "textClose": "Apakah Anda yakin ingin menutup periode OVK bulan ",
                    "textOpen": "Apakah Anda yakin ingin membuka periode OVK bulan ",
                    "textDel": "Apakah Anda yakin ingin menghapus periode OVK bulan ",
                    "response": {
                        "sukses": {
                            "create": "Berhasil menambahkan periode OVK",
                            "close": "Berhasil menutup periode OVK",
                            "reclose": "Berhasil menutup periode OVK kembali",
                            "open": "Berhasil membuka periode OVK",
                            "edit": "Berhasil memperbarui saldo awal OVK",
                            "delete": "Berhasil menghapus periode OVK"
                        },
                        "gagal": {
                            "create": "Gagal menambahkan periode OVK",
                            "close": "Gagal menutup periode OVK",
                            "open": "Gagal membuka periode OVK",
                            "edit": "Gagal memperbarui saldo awal OVK",
                            "delete": "Gagal menghapus periode OVK"
                        }
                    }
                },
                "material": {
                    "title": "Material",
                    "titleInitialBalance": "Saldo Awal Material ",
                    "titleEdit": "Edit Saldo Awal Material",
                    "create": "Tambah Periode Material",
                    "qty": "Jumlah",
                    "price": "Harga (Rp)",
                    "date": "Bulan Periode Material",
                    "msgQty": "Mohon masukkan jumlah!",
                    "msgPrice": "Mohon masukkan harga!",
                    "textCreate": "Apakah Anda yakin ingin menambah periode material bulan selanjutnya?",
                    "textClose": "Apakah anda yakin ingin menutup periode material bulan ",
                    "textOpen": "Apakah anda yakin ingin membuka periode material bulan ",
                    "textDel": "Apakah Anda yakin ingin menghapus periode material bulan ",
                    "response": {
                        "sukses": {
                            "create": "Berhasil menambahkan periode material",
                            "close": "Berhasil menutup periode material",
                            "reclose": "Berhasil menutup periode material kembali",
                            "open": "Berhasil membuka periode material",
                            "edit": "Berhasil memperbarui saldo awal material",
                            "delete": "Berhasil menghapus periode material"
                        },
                        "gagal": {
                            "create": "Gagal menambahkan periode material",
                            "close": "Gagal menutup periode material",
                            "open": "Gagal membuka periode material",
                            "edit": "Gagal memperbarui saldo awal material",
                            "delete": "Gagal menghapus periode material"
                        }
                    }
                },
                "livebird": {
                    "title": "Live Bird",
                    "titleInitialBalance": "Saldo Awal Live Bird ",
                    "titleEdit": "Edit Saldo Awal Live Bird",
                    "create": "Tambah Periode Live Bird",
                    "qty": "Jumlah",
                    "price": "Harga (Rp)",
                    "date": "Bulan Periode Live Bird",
                    "phDate": "Pilih bulan periode",
                    "msgDate": "Mohon masukkan bulan periode!",
                    "msgQty": "Mohon masukkan jumlah!",
                    "msgPrice": "Mohon masukkan harga!",
                    "textCreate": "Apakah Anda yakin ingin menambah periode live bird bulan selanjutnya?",
                    "textClose": "Apakah anda yakin ingin menutup periode live bird bulan ",
                    "textOpen": "Apakah anda yakin ingin membuka periode live bird bulan ",
                    "textDel": "Apakah Anda yakin ingin menghapus periode live bird bulan ",
                    "response": {
                        "sukses": {
                            "create": "Berhasil menambahkan periode live bird",
                            "close": "Berhasil menutup periode live bird",
                            "reclose": "Berhasil menutup periode live bird kembali",
                            "open": "Berhasil membuka periode live bird",
                            "edit": "Berhasil memperbarui saldo awal live bird",
                            "delete": "Berhasil menghapus periode live bird"
                        },
                        "gagal": {
                            "create": "Gagal menambahkan periode live bird",
                            "close": "Gagal menutup periode live bird",
                            "open": "Gagal membuka periode live bird",
                            "edit": "Gagal memperbarui saldo awal live bird",
                            "delete": "Gagal menghapus periode live bird"
                        }
                    }
                }
            },
            "livebird": {
                "title": "Stok Flow Live Bird ",
                "table": {
                    "kandang": "Kandang",
                    "saldoAwal": {
                        "title": "Saldo Awal",
                        "qty": "Qty (ekor)",
                        "hs": "HS (Rp/ekor)",
                        "nilai": "Nilai (Rp)"
                    },
                    "masuk": {
                        "title": "Masuk",
                        "qty": "Qty (ekor)",
                        "hs": "HS (Rp/ekor)",
                        "nilai": "Nilai (Rp)"
                    },
                    "deplesi": {
                        "title": "Deplesi",
                        "qty": "Qty (ekor)",
                        "hs": "HS (Rp/ekor)",
                        "nilai": "Nilai (Rp)"
                    },
                    "culling": {
                        "title": "Culling",
                        "qty": "Qty (ekor)",
                        "hs": "HS (Rp/ekor)",
                        "nilai": "Nilai (Rp)"
                    },
                    "panen": {
                        "title": "Panen",
                        "qty": "Qty (ekor)",
                        "hs": "HS (Rp/ekor)",
                        "nilai": "Nilai (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Saldo Akhir",
                        "qty": "Qty (ekor)",
                        "hs": "HS (Rp/ekor)",
                        "nilai": "Nilai (Rp)"
                    }
                }
            },
            "pakan": {
                "title": "Stok Flow Pakan ",
                "table": {
                    "pakan": "Pakan",
                    "saldoAwal": {
                        "title": "Saldo Awal",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "masuk": {
                        "title": "Masuk",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "keluar": {
                        "title": "Keluar",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "penyesuaian": {
                        "title": "Penyesuaian",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Saldo Akhir",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    }
                }
            },
            "ovk": {
                "title": "Stok Flow OVK ",
                "titleResume": "Rekapitulasi Stok Flow ",
                "table": {
                    "ovk": "OVK",
                    "resume": "Rekapitulasi",
                    "saldoAwal": {
                        "title": "Saldo Awal",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "masuk": {
                        "title": "Masuk",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "keluar": {
                        "title": "Keluar",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "penyesuaian": {
                        "title": "Penyesuaian",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Saldo Akhir",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    }
                }
            },
            "material": {
                "title": "Stok Flow Material ",
                "table": {
                    "material": "Material",
                    "saldoAwal": {
                        "title": "Saldo Awal",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "masuk": {
                        "title": "Masuk",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "keluar": {
                        "title": "Keluar",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "penyesuaian": {
                        "title": "Penyesuaian",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Saldo Akhir",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Nilai (Rp)"
                    }
                }
            }
        },
        "master": {
            "kandang": {
                "title": "Master Kandang",
                "table": {
                    "kandang": "Kandang",
                    "kapasitas": "Kapasitas (ekor)",
                    "alamat": "Alamat",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Kandang",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "modal": {
                    "tambah": "Tambah Master Kandang",
                    "edit": "Edit Master Kandang",
                    "nama": "Nama Kandang",
                    "kapasitas": "Kapasitas Kandang",
                    "gudangPakan": "Gudang Pakan",
                    "gudangOvk": "Gudang OVK",
                    "gudangMaterial": "Gudang Material",
                    "alamat": "Alamat Kandang",
                    "keterangan": "Keterangan",
                    "phGudangPakan": "Pilih gudang pakan",
                    "phGudangOvk": "Pilih gudang OVK",
                    "phGudangMaterial": "Pilih gudang material",
                    "phAlamat": "Masukkan alamat kandang",
                    "phKet": "Masukkan keterangan",
                    "msgNama": "Mohon masukkan nama kandang!",
                    "msgKapasitas": "Mohon masukkan kapasitas kandang!",
                    "msgGudangPakan": "Mohon pilih gudang pakan!",
                    "msgGudangOvk": "Mohon pilih gudang OVK!",
                    "msgGudangMaterial": "Mohon pilih gudang material!",
                    "msgAlamat": "Mohon masukkan alamat kandang!"
                },
                "textHapus": "Apakah anda yakin ingin menghapus ",
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan kandang",
                        "edit": "Berhasil mengubah kandang",
                        "hapus": "Berhasil menghapus kandang"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan kandang",
                        "edit": "Gagal mengubah kandang",
                        "hapus": "Gagal menghapus kandang"
                    }
                }
            },
            "strain": {
                "title": "Master Strain",
                "table": {
                    "nama": "Nama Strain",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "detail": "Detail"
                },
                "detail": {
                    "title": "Standar Strain",
                    "table": {
                        "hari": "Hari",
                        "deplesi": "Deplesi(%)"
                    }
                }
            },
            "lingkungan": {
                "title": "Standar Lingkungan",
                "table": {
                    "hari": "Hari",
                    "suhu": "Suhu",
                    "kelembapan": "Kelembapan",
                    "ammonia": "Ammonia",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Standar Lingkungan",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "modal": {
                    "titleTambah": "Tambah Master Standar Lingkungan",
                    "titleEdit": "Edit Master Standar Lingkungan",
                    "hari": "Hari ke-",
                    "suhu": "Suhu",
                    "kelembapan": "Kelembapan",
                    "ammonia": "Amonia",
                    "msgHari": "Mohon masukkan hari ke berapa!",
                    "msgSuhu": "Mohon masukkan suhu!",
                    "msgKelembapan": "Mohon masukkan kelembapan!",
                    "msgAmonia": "Mohon masukkan amonia!"
                },
                "textHapus": "Apakah anda yakin ingin menghapus standar lingkungan hari ke-",
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan standar lingkungan",
                        "edit": "Berhasil mengubah standar lingkungan",
                        "hapus": "Berhasil menghapus standar lingkungan"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan standar lingkungan",
                        "edit": "Gagal mengubah standar lingkungan",
                        "hapus": "Gagal menghapus standar lingkungan"
                    }
                }
            },
            "gudangPakan": {
                "title": "Master Gudang Pakan",
                "table": {
                    "nama": "Nama Gudang",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Gudang Pakan",
                    "edit": "Edit Master Gudang Pakan",
                    "hapus": "Hapus"
                },
                "modal": {
                    "gudang": "Gudang Pakan",
                    "msgGudang": "Mohon masukkan gudang pakan!"
                },
                "textHapus": "Apakah anda yakin ingin menghapus ",
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan gudang pakan",
                        "edit": "Berhasil mengubah gudang pakan",
                        "hapus": "Berhasil menghapus gudang pakan"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan gudang pakan",
                        "edit": "Gagal mengubah gudang pakan",
                        "hapus": "Gagal menghapus gudang pakan"
                    }
                }
            },
            "pakan": {
                "title": "Master Pakan",
                "table": {
                    "kode": "Kode",
                    "nama": "Nama Pakan",
                    "perusahaan": "Perusahaan",
                    "tahun": "Tahun Produksi",
                    "jenis": "Jenis Pakan",
                    "pilihan": "Pilihan"
                },
                "modal": {
                    "title": "Tambah Master Pakan",
                    "nama": "Nama Pakan",
                    "kode": "Kode Pakan",
                    "jenis": "Jenis Pakan",
                    "tahun": "Tahun Produksi",
                    "perusahaan": "Perusahaan",
                    "keterangan": "Keterangan",
                    "phJenis": "Pilih jenis pakan",
                    "phTahun": "Pilih tahun produksi",
                    "phKet": "Masukkan keterangan",
                    "msgNama": "Mohon masukkan nama pakan!",
                    "msgKode": "Mohon masukkan kode pakan!",
                    "msgJenis": "Mohon pilih jenis pakan!",
                    "msgTahun": "Mohon pilih tahun produksi!",
                    "msgPerusahaan": "Mohon masukkan perusahaan pakan!"
                },
                "button": {
                    "tambah": "Tambah Master Pakan",
                    "edit": "Edit Master Pakan",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah anda yakin ingin menghapus pakan ",
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan pakan",
                        "edit": "Berhasil mengubah pakan",
                        "hapus": "Berhasil menghapus pakan"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan pakan",
                        "edit": "Gagal mengubah pakan",
                        "hapus": "Gagal menghapus pakan"
                    }
                }
            },
            "gudangOvk": {
                "title": "Master Gudang OVK",
                "table": {
                    "nama": "Nama Gudang",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Gudang OVK",
                    "edit": "Edit Master Gudang OVK",
                    "hapus": "Hapus"
                },
                "modal": {
                    "gudang": "Gudang OVK",
                    "msgGudang": "Mohon masukkan gudang OVK!"
                },
                "textHapus": "Apakah anda yakin ingin menghapus ",
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan gudang OVK",
                        "edit": "Berhasil mengubah gudang OVK",
                        "hapus": "Berhasil menghapus gudang OVK"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan gudang OVK",
                        "edit": "Gagal mengubah gudang OVK",
                        "hapus": "Gagal menghapus gudang OVK"
                    }
                }
            },
            "ovk": {
                "title": "Master OVK",
                "table": {
                    "kode": "Kode OVK",
                    "nama": "Nama OVK",
                    "golongan": "Golongan",
                    "satuanPengadaan": "Satuan Pengadaan",
                    "bentuk": "Bentuk",
                    "kapasitas": "Kapasitas",
                    "dosis": "Dosis ",
                    "penggunaan": "Penggunaan",
                    "kandungan": "Kandungan",
                    "supplier": "Supplier",
                    "kedaluwarsa": "Kedaluwarsa",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master OVK",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah anda yakin ingin menghapus ovk ",
                "modal": {
                    "titleTambah": "Tambah Master OVK",
                    "titleEdit": "Edit Master OVK",
                    "bentuk": "Bentuk OVK",
                    "tipe": "Tipe OVK",
                    "satuanPengadaan": "Satuan Pengadaan",
                    "media": "Media OVK",
                    "kode": "Kode OVK",
                    "nama": "Nama OVK",
                    "dosis": "Dosis OVK",
                    "penggunaan": "Rekomendasi Penggunaan",
                    "kandungan": "Kandungan OVK",
                    "supplier": "Supplier",
                    "satuanPenggunaan": "Satuan Penggunaan",
                    "kuantitas": "Kuantitas Satuan",
                    "kedaluwarsa": "Tanggal Kedaluwarsa",
                    "phBentuk": "Pilih bentuk",
                    "phTipe": "Pilih tipe",
                    "phMedia": "Pilih media",
                    "phKandungan": "Masukkan kandungan",
                    "phSatuanPenggunaan": "Pilih satuan penggunaan",
                    "phSatuanPengadaan": "Pilih satuan pengadaan",
                    "phKedaluwarsa": "Pilih tanggal kedaluwarsa",
                    "msgBentuk": "Mohon pilih bentuk OVK!",
                    "msgTipe": "Mohon pilih tipe OVK!",
                    "msgSatuanPenggunaan": "Mohon pilih satuan penggunaan OVK!",
                    "msgSatuanPengadaan": "Mohon pilih satuan pengadaan OVK!",
                    "msgMedia": "Mohon pilih media OVK!",
                    "msgKode": "Mohon masukkan kode OVK!",
                    "msgNama": "Mohon masukkan nama OVK!",
                    "msgDosis": "Mohon masukkan dosis OVK!",
                    "msgPenggunaan": "Mohon masukkan penggunaan OVK!",
                    "msgKandungan": "Mohon masukkan kandungan OVK!",
                    "msgSupplier": "Mohon masukkan supplier OVK!",
                    "msgKuantitas": "Mohon masukkan kuantitas OVK!",
                    "msgKedaluwarsa": "Mohon pilih tanggal kedaluwarsa!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan OVK",
                        "edit": "Berhasil mengubah OVK",
                        "hapus": "Berhasil menghapus OVK"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan OVK",
                        "edit": "Gagal mengubah OVK",
                        "hapus": "Gagal menghapus OVK"
                    }
                }
            },
            "gudangMaterial": {
                "title": "Master Gudang Material",
                "table": {
                    "nama": "Nama Gudang",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Gudang Material",
                    "edit": "Edit Master Gudang Material",
                    "hapus": "Hapus"
                },
                "modal": {
                    "gudang": "Gudang Material",
                    "msgGudang": "Mohon masukkan gudang material!"
                },
                "textHapus": "Apakah anda yakin ingin menghapus ",
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan gudang material",
                        "edit": "Berhasil mengubah gudang material",
                        "hapus": "Berhasil menghapus gudang material"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan gudang material",
                        "edit": "Gagal mengubah gudang material",
                        "hapus": "Gagal menghapus gudang material"
                    }
                }
            },
            "material": {
                "title": "Master Material",
                "table": {
                    "kode": "Kode",
                    "nama": "Nama Material",
                    "perusahaan": "Perusahaan Material",
                    "jenis": "Jenis Material",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Material",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah anda yakin ingin menghapus material ",
                "modal": {
                    "titleTambah": "Tambah Master Material",
                    "titleEdit": "Edit Master Material",
                    "nama": "Nama Material",
                    "kode": "Kode Material",
                    "jenis": "Jenis Material",
                    "perusahaan": "Perusahaan",
                    "phNama": "Masukkan nama material",
                    "phKode": "Masukkan kode material",
                    "phJenis": "Pilih jenis material",
                    "msgNama": "Mohon masukkan nama material",
                    "msgKode": "Mohon masukkan kode material",
                    "msgJenis": "Mohon masukkan jenis material",
                    "msgPerusahaan": "Mohon masukkan perusahaan produksi"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan material",
                        "edit": "Berhasil mengubah material",
                        "hapus": "Berhasil menghapus material"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan material",
                        "edit": "Gagal mengubah material",
                        "hapus": "Gagal menghapus material"
                    }
                }
            },
            "biaya": {
                "title": "Master Biaya",
                "table": {
                    "nama": "Nama Biaya",
                    "biaya": "Biaya (Rp)",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Biaya",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah anda yakin ingin menghapus biaya ",
                "modal": {
                    "titleTambah": "Tambah Master Biaya",
                    "titleEdit": "Edit Master Biaya",
                    "nama": "Nama Biaya",
                    "biaya": "Biaya",
                    "msgNama": "Mohon masukkan nama biaya!",
                    "msgBiaya": "Mohon masukkan biaya!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan biaya",
                        "edit": "Berhasil mengubah biaya",
                        "hapus": "Berhasil menghapus biaya"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan biaya",
                        "edit": "Gagal mengubah biaya",
                        "hapus": "Gagal menghapus biaya"
                    }
                }
            },
            "overhead": {
                "title": "Master Overhead",
                "table": {
                    "nama": "Nama Overhead",
                    "price": "Harga (Rp)",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Overhead",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah anda yakin ingin menghapus overhead ",
                "modal": {
                    "titleTambah": "Tambah Master Overhead",
                    "titleEdit": "Edit Master Overhead",
                    "nama": "Nama Overhead",
                    "price": "Harga",
                    "msgNama": "Mohon masukkan nama overhead!",
                    "msgPrice": "Mohon masukkan overhead!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan overhead",
                        "edit": "Berhasil mengubah overhead",
                        "hapus": "Berhasil menghapus overhead"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan overhead",
                        "edit": "Gagal mengubah overhead",
                        "hapus": "Gagal menghapus overhead"
                    }
                }
            },
            "penyakit": {
                "title": "Master Penyakit",
                "table": {
                    "nama": "Nama Penyakit",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Penyakit",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah anda yakin ingin menghapus penyakit ",
                "modal": {
                    "titleTambah": "Tambah Master Penyakit",
                    "titleEdit": "Edit Master Penyakit",
                    "nama": "Nama Penyakit",
                    "penyakit": "Penyakit",
                    "msgNama": "Mohon masukkan nama penyakit!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan penyakit",
                        "edit": "Berhasil mengubah penyakit",
                        "hapus": "Berhasil menghapus penyakit"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan penyakit",
                        "edit": "Gagal mengubah penyakit",
                        "hapus": "Gagal menghapus penyakit"
                    }
                }
            },
            "konsumen": {
                "title": "Master Konsumen",
                "table": {
                    "nama": "Nama",
                    "alamat": "Alamat",
                    "kontak": "Kontak",
                    "informasi": "Informasi Lain",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Kustomer",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah Anda yakin ingin menghapus konsumen ",
                "modal": {
                    "titleTambah": "Tambah Master Kustomer",
                    "titleEdit": "Edit Master Kustomer",
                    "nama": "Nama",
                    "alamat": "Alamat",
                    "kontak": "Kontak",
                    "informasi": "Informasi Lain",
                    "phNama": "Masukkan nama kustomer",
                    "phAlamat": "Masukkan alamat kustomer",
                    "phInformasi": "Masukkan informasi lain",
                    "msgNama": "Mohon masukkan nama kustomer!",
                    "msgKontak": "Mohon masukkan kontak kustomer!",
                    "msgAlamat": "Mohon masukkan alamat kustomer!",
                    "msgInformasi": "Mohon masukkan informasi lain!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan kustomer",
                        "edit": "Berhasil mengubah kustomer",
                        "hapus": "Berhasil menghapus kustomer"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan kustomer",
                        "edit": "Gagal mengubah kustomer",
                        "hapus": "Gagal menghapus kustomer"
                    }
                }
            },
            "supplier": {
                "title": "Master Supplier",
                "table": {
                    "kode": "Kode Supplier",
                    "nama": "Nama",
                    "alamat": "Alamat",
                    "kontak": "Kontak",
                    "tipe": "Tipe Supplier",
                    "informasi": "Informasi Lain",
                    "pilihan": "Pilihan"
                },
                "button": {
                    "tambah": "Tambah Master Supplier",
                    "edit": "Edit",
                    "hapus": "Hapus"
                },
                "textHapus": "Apakah anda yakin ingin menghapus supplier ",
                "modal": {
                    "titleTambah": "Tambah Master Supplier",
                    "titleEdit": "Edit Master Supplier",
                    "nama": "Nama",
                    "alamat": "Alamat",
                    "kode": "Kode Supplier",
                    "tipe": "Tipe Supplier",
                    "kontak": "Kontak",
                    "informasi": "Informasi Lain",
                    "phNama": "Masukkan nama supplier",
                    "phAlamat": "Masukkan alamat supplier",
                    "phKode": "Masukkan kode supplier",
                    "phTipe": "Pilih tipe supplier",
                    "phInformasi": "Masukkan informasi lain",
                    "msgNama": "Mohon masukkan nama supplier!",
                    "msgAlamat": "Mohon masukkan alamat supplier!",
                    "msgTipe": "Mohon pilih tipe supplier!",
                    "msgKode": "Mohon masukkan kode supplier!",
                    "msgKontak": "Mohon masukkan kontak supplier!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Berhasil menambahkan supplier",
                        "edit": "Berhasil mengubah supplier",
                        "hapus": "Berhasil menghapus supplier"
                    },
                    "gagal": {
                        "tambah": "Gagal menambahkan supplier",
                        "edit": "Gagal mengubah supplier",
                        "hapus": "Gagal menghapus supplier"
                    }
                }
            },
            "pengguna": {
                "title": "Master Pengguna",
                "titlePetugas": "Petugas",
                "titleManajemen": "Manajemen",
                "modal": {
                    "tambahPengguna": "Tambah Data Pengguna",
                    "tambahPetugas": "Tambah Petugas Kandang",
                    "tambahManajemen": "Tambah Manajemen",
                    "editPetugas": "Edit Petugas Kandang",
                    "editManajemen": "Edit Manajemen",
                    "nama": "Nama",
                    "kandang": "Kandang",
                    "telepon": "Nomor Telepon",
                    "alamat": "Alamat",
                    "phNama": "Masukkan nama..",
                    "phKandang": "Pilih kandang",
                    "phAlamat": "Masukkan alamat ...",
                    "phEmail": "Masukkan email",
                    "phUsername": "Masukkan username",
                    "phPass": "Masukkan password",
                    "msgNama": "Mohon masukkan nama!",
                    "msgAlamat": "Mohon masukkan alamat!",
                    "msgKandang": "Mohon pilih kandang!",
                    "msgTel": "Mohon masukkan nomor telepon!",
                    "msgEmail": "Mohon masukkan email",
                    "msgUsername": "Mohon masukkan username",
                    "msgPass": "Mohon masukkan password"
                },
                "table": {
                    "namaPetugas": "Nama Petugas",
                    "namaManajemen": "Nama Manajemen",
                    "alamat": "Alamat",
                    "telepon": "Nomor Telepon",
                    "pilihan": "Pilihan"
                },
                "petugas": {
                    "textHapus": "Apakah anda yakin ingin menghapus petugas ",
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil menambahkan petugas",
                            "edit": "Berhasil mengubah petugas",
                            "hapus": "Berhasil menghapus petugas"
                        },
                        "gagal": {
                            "tambah": "Gagal menambahkan petugas",
                            "edit": "Gagal mengubah petugas",
                            "hapus": "Gagal menghapus petugas"
                        }
                    }
                },
                "manajemen": {
                    "textHapus": "Apakah anda yakin ingin menghapus manajemen ",
                    "response": {
                        "sukses": {
                            "tambah": "Berhasil menambahkan manajemen",
                            "edit": "Berhasil mengubah manajemen",
                            "hapus": "Berhasil menghapus manajemen"
                        },
                        "gagal": {
                            "tambah": "Gagal menambahkan manajemen",
                            "edit": "Gagal mengubah manajemen",
                            "hapus": "Gagal menghapus manajemen"
                        }
                    }
                }
            }
        },
        "profil": {
            "title": "Profil",
            "changeProfil": "Ubah Profil",
            "changePass": "Ubah Password",
            "nama": "Nama Lengkap",
            "tel": "Nomor Telepon",
            "perusahaan": "Nama Perusahaan",
            "alamat": "Alamat",
            "newPass": "Password Lama",
            "oldPass": "Password Baru",
            "phNama": "Masukkan nama lengkap Anda",
            "phEmail": "Masukkan email Anda",
            "phUsername": "Masukkan username Anda",
            "phTel": "Masukkan nomor telepon Anda",
            "phPerusahaan": "Masukkan nama perusahaan Anda",
            "phAlamat": "Masukkan alamat Anda",
            "phOldPass": "Masukkan password lama Anda",
            "phNewPass": "Masukkan password baru Anda",
            "msgNama": "Mohon masukkan nama lengkap Anda!",
            "msgEmail": "Mohon masukkan email Anda!",
            "msgUsername": "Mohon masukkan username Anda!",
            "msgTel": "Mohon masukkan nomor telepon Anda!",
            "msgPerusahaan": "Mohon masukkan perusahaan Anda!",
            "msgAlamat": "Mohon masukkan alamat Anda!",
            "msgOldPass": "Mohon masukkan password lama Anda!",
            "msgNewPass": "Mohon masukkan password baru Anda!",
            "response": {
                "sukses": {
                    "profil": "Berhasil memperbarui profil Anda",
                    "password": "Berhasil memperbarui password Anda"
                },
                "gagal": {
                    "profil": "Gagal memperbarui profil Anda",
                    "password": "Gagal memperbarui password Anda"
                }
            }
        }
    }
}