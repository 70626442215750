import React from 'react';
import { Modal,Form,Row,Col,Input } from 'antd';

function CreatePetugas(props) {
    const { TextArea } = Input;
    const {visible,handleSubmit,setVisible,showModal,t} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t('pages.master.pengguna.modal.tambahManajemen')}
            okText={t('general.create')}
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                   <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.pengguna.modal.nama')}</span>
                            <Form.Item
                                name="namaManajemen" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgNama')}`}]}
                            >
                                <Input
                                    name='namaManajemen'
                                    placeholder={t('pages.master.pengguna.modal.phNama')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">Username</span>
                            <Form.Item
                                name="username" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgUsername')}` }]}
                            >
                                <Input
                                    name='username'
                                    placeholder={t('pages.master.pengguna.modal.phUsername')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">Email</span>
                            <Form.Item
                                name="email" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgEmail')}` }]}
                            >
                                <Input
                                    name='email'
                                    placeholder={t('pages.master.pengguna.modal.phEmail')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">Password</span>
                            <Form.Item
                                name="password" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgPass')}` }]}
                            >
                                <Input.Password
                                    name='password'
                                    placeholder={t('pages.master.pengguna.modal.phPass')}
                                    className="input-password mt-5 "
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.pengguna.modal.telepon')}</span>
                            <Form.Item
                                name="nomorTelefon" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgTel')}`}]}
                            >
                                <Input
                                    name='nomorTelefon'
                                    placeholder="+62..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.pengguna.modal.alamat')}</span>
                            <Form.Item
                                name="alamat" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgAlamat')}` }]}
                            >
                                <TextArea
                                    name="alamat"
                                    style={{borderColor:"#BE375F"}}
                                    placeholder={t('pages.master.pengguna.modal.phAlamat')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                             </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <span onClick={() => showModal()}>{t('pages.master.pengguna.modal.tambahManajemen')}</span>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </div>
    );
}
 
export default CreatePetugas;