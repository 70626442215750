import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CONSTANTS from '../../../../../common/utils/Constants'
import CreateStokOvkComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/stok-ovk-aksi-component/create-stok-ovk-component'
import { createStokOvk } from './query-stok-ovk'
import { useTranslation } from 'react-i18next'

function CreateStokOvkPage(props) {
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values, year) => {
        API.post(createStokOvk(values, year))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOvkStock._id) {
                    validationMessage('success', `${t('pages.stok.ovk.response.sukses.tambah')}`)
                    props.navigate(CONSTANTS.STOK_OVK_MENU_KEY)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stok.ovk.response.gagal.tambah')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        setLoading(true)
        postData(values, values['date_picker'].format('MM/DD/YYYY'))
    }

    return (
        <CreateStokOvkComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            loading={loading}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            ovk={props.ovk}
            gudang={props.gudang}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    ovk: state.ovk.dataOvk,
    gudang: state.gudangOvk.dataGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStokOvkPage)
export default page