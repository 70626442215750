import React, { useState,useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { navigate } from '../../../../../common/store/action';
import CreateManajemenComponent from '../../../../../modules/dashboard-peternak/master-component/petugas-component/petugas-aksi-component/create-manajemen-component';

import {createManajemen} from './query-petugas';

function CreateManajemenPage(props) {
    const {fetchDataManajemen,total,setLoading,t} = props;
    const [visible, setVisible] = useState(false);
    const [visibleFree, setVisibleFree] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    },[]);

    const postData = (values) => {
        API.post(createManajemen(values))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading (false);
            }else if(res.data.data.createManagement._id){
                validationMessage('success', `${t('pages.master.pengguna.manajemen.response.sukses.tambah')}`)
                fetchDataManajemen();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.pengguna.manajemen.response.gagal.tambah')}`);
            setLoading (false);
        })
    }

    const handleSubmit = (values) => {
        postData(values);
        setVisible(false);
        setLoading(true);
    };

    return ( 
        <CreateManajemenComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            tipeUser = {props.tipeUser}
            visibleFree = {visibleFree}
            setVisibleFree = {setVisibleFree}
            total = {total}
            kandang = {props.kandang}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    tipeUser : state.auth.dataUser.typeUser,
    kandang : state.layout.dataKandang,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreateManajemenPage);
export default page