import React from 'react'
import { Layout, Breadcrumb, Row, Col,Table,  } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { Link } from 'react-router-dom'

function StandarComponent(props) {
    const { Content } = Layout
    const { columns, data, loading,t} = props
    return ( 
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to ="/peternak/master-strain" ><span>{t('pages.master.strain.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.master.strain.detail.title')}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table 
                                columns={columns} 
                                dataSource={data} 
                                bordered
                                pagination={false}
                                className="table-dashboard"
                                scroll={{  y: '70vh'}}
                            />
                        </Loading>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
 
export default StandarComponent