import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import { API } from '../../../../common/api';
import { Menu } from 'antd';

import {getPetugas,getManajemen} from './petugas-aksi-page/query-petugas';
import PenggunaComponent from '../../../../modules/dashboard-peternak/master-component/petugas-component/pengguna-component'

import CreatePetugas from '../../../dashboard-peternak/master-page/petugas-page/petugas-aksi-page/create-petugas-page';
import CreateManajemen from '../../../dashboard-peternak/master-page/petugas-page/petugas-aksi-page/create-manajemen-page';

import {useTranslation} from 'react-i18next'

function PenggunaPage(props) {
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState("1");
    const [petugas, setPetugas] = useState([]);
    const [manajemen, setManajemen] = useState([]);

    const {t} = useTranslation()

    /**Handle get data */
    const fetchDataPetugas = () => {
        setLoading(true);
        API.get(getPetugas('',0,0))
        .then(res => {
            setPetugas(res.data.data.staffs.staffs)
            setLoading(false);
        }).catch( ( error ) =>  {
            console.log(error);
            setPetugas([]);
            setLoading(false);
        });
    }

    const fetchDataManajemen = () => {
        setLoading(true);
        API.get(getManajemen('',0,0))
        .then(res => {
            setManajemen(res.data.data.managements.managements);
            setLoading(false);
        }).catch( ( error ) =>  {
            console.log(error);
            setManajemen([]);
            setLoading(false);
        });
    }

    useEffect(() => {
        if(activeKey === "1"){
            fetchDataPetugas();
        }else{
            fetchDataManajemen();
        }
    }, [activeKey]);
    /**End handle get data */
    
    const changeKey = useCallback((value) => {
        setActiveKey(value);
    }, []); 

    const handleButtonClick = useCallback((value) => {}, []);

    const handleMenuClick = useCallback((value) => {}, []);

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">
                <CreatePetugas loading={loading} setLoading = {setLoading} fetchDataPetugas = {fetchDataPetugas} t={t}/>
            </Menu.Item>
            <Menu.Item key="2">
                <CreateManajemen loading={loading} setLoading = {setLoading} fetchDataManajemen = {fetchDataManajemen} t={t}/>
            </Menu.Item>
        </Menu>
    );

    return ( 
        <PenggunaComponent
            navigate={props.navigate}
            petugas = {petugas}
            fetchDataPetugas = {fetchDataPetugas}
            manajemen = {manajemen}
            fetchDataManajemen = {fetchDataManajemen}
            setLoading = {setLoading}
            changeKey  = {changeKey}
            loading = {loading}
            handleButtonClick = {handleButtonClick}
            menu = {menu} t={t}
        />
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(PenggunaPage);
export default page