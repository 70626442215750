import * as actionType from './home-action-type';

const initialState = {
    loading: false,
    data: [],
};

const handler = (currentState) => {
    const getData = {
        startGetData: () => ({
            ...currentState,
            loading: true,
        }),
        finishGetData: () => ({
            ...currentState,
            loading: true,
        }),
        setData: data => ({
            ...currentState,
            data: data
        }),
    };

    return {
        ...getData,
    };
};

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetData();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetData();
        case actionType.SET_DATA:
            return handler(state).setData(payload);

        default:
            return state;
    }
};