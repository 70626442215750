import {API} from '../../../../common/api';
import { validationMessage } from '../../../../common/component/notification/notification';
import * as actionType from './profil-action-type';

const startGetDataProfils = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataProfils = () => ({
    type : actionType.FINISH_GET_DATA
})

const setDataProfil = payload => ({
    type : actionType.SET_DATA_PROFIL,
    payload,
});

export const getListProfil = (params) => (dispatch) => {
    dispatch(startGetDataProfils());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.getProfile){
            dispatch(setDataProfil(res.data.data.getProfile))
        } else {
            validationMessage('error', 'Data Error')
        }
        dispatch(finishGetDataProfils());
    })
}

export const updateProfil = (params, success, error) => (dispatch) => {
    dispatch(startGetDataProfils());
    API.post(params)
    .then(res => {
        
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.updateUser._id){
            // dispatch(setDataProfil(res.data.data.updateUser._id))
            validationMessage('success', success)
        } else {
            validationMessage('error', error)
        }
        dispatch(finishGetDataProfils());
    })
}

export const updatePassword = (params,success, error) => (dispatch) => {
    dispatch(startGetDataProfils());
    API.post(params)
    .then(res => {
        
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.changePassword){
            // dispatch(setDataProfil(res.data.data.updateUser._id))
            validationMessage('success', success)
        } else {
            validationMessage('error', error)
        }
        dispatch(finishGetDataProfils());
    })
}