import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Layout, Space, Button, Input, Table, Row, Col, Breadcrumb, Tooltip } from 'antd'
import NumberFormat from 'react-number-format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined } from '@ant-design/icons'
import { API } from '../../../../../common/api'
import { getMonthlyStockMaterials } from '../../material-page/material-aksi-page/query-stock-flow-material'
import { updateInitialBalanceMaterial } from './query-periode'
import Loading from '../../../../../common/component/loading/loading-container'
import Edit from './edit-periode-material'
import { validationMessage } from '../../../../../common/component/notification/notification'
import {Link} from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

function MaterialPage(props) {
    const { monthPeriode, yearPeriode} = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState([])
    const [visible, setVisible] = useState(false)
    const [locked, setLocked] = useState(false)
    const { Content } = Layout

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    /**Handle Get Data */
    const fetchData = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockMaterials('', 0, 0, month,year))
            .then(res => {
                console.log(res)
                setObject(res.data.data.monthlyStockMaterials.calculateMonthlyStocks)
                setLocked(res.data.data.monthlyStockMaterials.locked)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(monthPeriode, yearPeriode)
    }, [monthPeriode, yearPeriode])

    const showEdit = useCallback((id, qty, price) => {
        setVisible(true)
        setIdData(id)
        let arr = []
        arr.push({bgnQty:qty, bgnPrice:price})
        setDetail(arr[0])
    }, [])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        console.log(idData)
        API.get(updateInitialBalanceMaterial(idData, values.bgnQty, values.bgnPrice))
            .then(res => {
                if (res.data.data.updateInitialBalanceMaterial._id) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.edit')}`)
                    fetchData(monthPeriode, yearPeriode)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.material.response.gagal.edit')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const columns = [
        {
            title: `${t('pages.stockflow.material.table.material')}`,
            dataIndex: 'material',
            key: 'material',
            fixed: true,
            width: 100,
            ...getColumnSearchProps('material', `${t('pages.stockflow.material.table.material')}`),
        },
        {
            title: `${t('pages.stockflow.material.table.saldoAwal.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.material.table.saldoAwal.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyAwal',
                    key: 'qtyAwal',
                    width: 70,
                },
                {
                    title: `${t('pages.stockflow.material.table.saldoAwal.hs')}`,
                    className: 'right',
                    dataIndex: 'hargaSatuanAwal',
                    key: 'hargaSatuanAwal',
                    width: 70,
                },
                {
                    title: `${t('pages.stockflow.material.table.saldoAwal.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalAwal',
                    key: 'hargaTotalAwal',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.master.material.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 30,
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title='Edit'>
                        <Button
                         style={{
                            color: 'white',
                            backgroundColor: locked ? 'white smoke' : '#FFA903',
                            borderRadius: '5px'
                        }} 
                        disabled={locked ? true : false}
                        onClick={() => showEdit(row.key, row.stokAwal, row.hargaAwalBulan)}
                        ><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]
    const array = object.filter(a => a !== null)

    const data = array.map(({ _id, idMonthlyStockMaterial, namaMaterial, stockAwalBulan, hargaAwalBulan,
        hargaTotalAwalBulan }, index) => ({
            key: _id,
            id: idMonthlyStockMaterial,
            material: namaMaterial,

            stokAwal: stockAwalBulan,
            hargaAwalBulan: hargaAwalBulan,
            hargaTotalAwalBulan: hargaTotalAwalBulan,
            qtyAwal: stockAwalBulan < 0 ? <NumberFormat value={Math.abs(stockAwalBulan)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={stockAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaSatuanAwal: <NumberFormat value={hargaAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaTotalAwal: stockAwalBulan < 0 ?
                <NumberFormat value={Math.abs(hargaTotalAwalBulan)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={hargaTotalAwalBulan} displayType={'text'} thousandSeparator={true} />,
        }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/stock-flow-periode"><span>{t("pages.stockflow.periode.title")}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.stockflow.periode.material.titleInitialBalance')} {monthPeriode} {yearPeriode}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                                scroll={{y: '70vh'}}
                                bordered
                                className="table-stock-flow"
                            />
                        </Loading>
                    </Col>
                    <Edit visible={visible} handleCancel={handleCancel} handleSubmit={handleSubmit}
                        idData={idData} detail={detail} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    monthPeriode: state.rearingRecord.monthPeriode,
    yearPeriode: state.rearingRecord.yearPeriode,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(MaterialPage)
export default page