import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import EditStokComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-material-component/stok-material-aksi-component/edit-stok-material-component';

function EditStokPage(props) {
    const {visibleEdit,handleCancel,detail,handleSubmit,t} = props;
    const [data,setData] = useState("")

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData();
    }, [detail]);

    return ( 
        <EditStokComponent
            navigate={props.navigate}
            visibleEdit = {visibleEdit}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            data = {data}
            gudang = {props.gudang}
            material = {props.material}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    gudang : state.gudangMaterial.dataGudangMaterial,
    material : state.material.dataMaterial
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditStokPage);
export default page