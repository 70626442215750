import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action/index'
import { Modal, Form, Row, Col, Select, InputNumber } from 'antd'
import { useTranslation } from "react-i18next"

function EditMaterialPage(props) {
    const { visible, handleCancel, detail, handleSubmit, stokMaterial, monthlyMaterial } = props
    const [data, setData] = useState(detail)
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const { Option } = Select

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
                form.setFieldsValue({
                    material: detail.materialStock,
                    jumlah: detail.number,
                    price: detail.priceMaterial
                })
            }
        }
        setDetailData()
    }, [detail, form])

    const sak = (value) => {
        let idMaterial = stokMaterial.filter(d => d._id === value.value)[0].material._id
        let price = monthlyMaterial.filter(d => d.idMaterial === idMaterial)[0]?.hargaPengeluaran
        form.setFieldsValue({
            price: price
        })
    }

    return (
        <Modal
            visible={visible}
            title={t("pages.recording.detail.material.modal.titleEdit")}
            okText="Edit"
            width={500}
            cancelText={t('general.cancel')}
            onCancel={() => handleCancel()}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields()
                        handleSubmit(values)
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info)
                    })
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    _id: data._id,
                    material: data.materialStock,
                    jumlah: data.number,
                    price: data.priceMaterial
                }}
            >
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <span className="black font-semi-bold font-title-content" >{t("pages.recording.detail.material.modal.material")}</span>
                        <Form.Item
                            name="material" rules={[{ required: true, message: 'Silahkan pilih material!' }]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                onChange={(val) => sak(val)}
                                placeholder="Pilih Material"
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular mt-5"
                            >
                                {
                                    stokMaterial.map(data =>
                                        <Option
                                            key={data.material.name.toString()}
                                            value={data._id}
                                        >{data.material.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span className="font-semi-bold black font-title-medium">{t("pages.recording.detail.material.modal.jumlah")}</span>
                        <Form.Item
                            name="jumlah" rules={[{ required: true, message: `${t("pages.recording.detail.material.modal.msgJumlah")}` }]}
                        >
                            <InputNumber
                                name='jumlah'
                                placeholder="1000..."
                                className="input-number mt-5"
                                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span className="font-semi-bold black font-title-medium">Harga (Rp)</span>
                        <Form.Item
                            name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                        >
                            <InputNumber
                                placeholder="2500..."
                                className="input-number mt-5"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    stokMaterial: state.stokMaterial.dataStokMaterial,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditMaterialPage)
export default page