import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditPenggunaanStokComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/transaksi-stok-ovk-component/edit-penggunaan-stok-component'

function EditPenggunaanStokPage(props) {
    const {visible,handleCancel,detail,handleSubmit,satuanRecordingOvk,t} = props
    const [data,setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData()
    }, [detail])

    return ( 
        <EditPenggunaanStokComponent
            navigate={props.navigate}
            visible = {visible}
            satuanOvk = {satuanRecordingOvk}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPenggunaanStokPage)
export default page