import "./i18n"
import App from './app/App'
import ReactDOM from 'react-dom'
import './assets/scss/index.scss'
import React, { Suspense } from 'react'
import * as serviceWorker from './serviceWorker'
import Loading from './common/component/loading/loading-container'

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Loading loading={true} style={{ height: "100vh" }} />}>
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root'))

serviceWorker.unregister()