import React, {useState, useCallback, useEffect} from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal,Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import GudangPakanComponent from '../../../../modules/dashboard-peternak/master-component/gudang-pakan-component/gudang-pakan-component'
import {queryGudangPakan,deleteGudangPakan,detailGudangPakan,updateGudangPakan} from './gudang-pakan-aksi-page/query-gudang-pakan'
import { API } from '../../../../common/api'
import {useTranslation} from 'react-i18next'

function GudangPakanPage(props) {
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [pagination, setPagination] = useState(10)
    const [visible, setVisible] = useState(false)
    const [object, setObject] = useState([])
    const [detail, setDetail] = useState('')
    const [loading, setLoading] = useState(false)
    const [idData, setIdData] = useState(null)
    const [total, setTotal] = useState(null)

    const {t} = useTranslation()

    /**Handle seacrh in row antd design */
     const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`${t('general.search')} ${title}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {t('general.search')}
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }
    /**End handle seacrh in row antd design */

    /**Handle get data */
    const fetchData = () => {
        setLoading(true)
        API.get(queryGudangPakan('',0,0))
        .then(res => {
           setTotal(res.data.data.feedWarehouses.totalCount)
           setObject(res.data.data.feedWarehouses.feedWarehouses)
           setLoading(false)
        }).catch((error) => {
            setObject([])
            setTotal(null)
            setLoading(false)
        })
    }
    
    useEffect(() => {
       fetchData()
    }, [])
    /**End handle get data */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteGudangPakan(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false)
            }else if(res.data.data.deleteFeedWarehouse.deleted === true){
                validationMessage('success', `${t('pages.master.gudangPakan.response.sukses.hapus')}`)
                fetchData()
            }
        }).catch((error) => {
            validationMessage('error', `${t('pages.master.gudangPakan.response.gagal.hapus')}`)
            setLoading(false)
        })
    }

    const deleteConfirm = (_id,nama)=> {
        confirm({
            title: `${t('pages.master.gudangPakan.textHapus')}${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
    /**End handle delete data */

    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(detailGudangPakan(idData))
                .then(res => { 
                    setDetail(res.data.data.feedWarehouse)
                    setVisible(true)
                }).catch((error) => {
                    setDetail(null)
                    setVisible(false)
                })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value)
    },[idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData =  (values) => {
        API.post(updateGudangPakan(idData,values))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false)
            }else if(res.data.data.updateFeedWarehouse._id){
                validationMessage('success', `${t('pages.master.gudangPakan.response.sukses.edit')}`)
                fetchData()
            }
        }).catch((error) => {
            console.log(error)
            validationMessage('error', `${t('pages.master.gudangPakan.response.gagal.edit')}`)
            setLoading(false)
        })
    }

    const handleSubmit = (values) => {
        updatedData(values.gudangPakan)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }
     /**End handle updated data */
    
    /**Handle display data */
    const data = object.map( ({_id,name}, index) => ({
        key: _id,
        id : _id,
        gudang : name,
    }))

    const columns = [
        {
            title: `${t('pages.master.gudangPakan.table.nama')}`,
            dataIndex: 'gudang',
            key: 'gudang',
            ...getColumnSearchProps('gudang',`${t('pages.master.gudangPakan.table.nama')}`),
        },
        {
            title: `${t('pages.master.gudangPakan.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 130,
            render: (value,row,index) => (
            <Space size="middle">
                <Tooltip placement="top" title='Edit'>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FFA903',
                        borderRadius:'5px'
                    }} onClick={() => showModal(row.id)}><FontAwesomeIcon icon={faEdit} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={t('general.del')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FF0303',
                        borderRadius:'5px'
                    }} onClick={() => deleteConfirm(row.id,row.gudang)}><FontAwesomeIcon icon={faTrash} /></Button>
                </Tooltip>
            </Space>
            )
        }
    ]

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize)
    }, [])

     /**End handle display data */

    return ( 
        <GudangPakanComponent
            navigate={props.navigate}
            pagination = {pagination}
            changePagination = {changePagination}
            showModal = {showModal}
            idData = {idData}
            columns = {columns}
            visible = {visible}
            data = {data}
            detail = {detail}
            loading = {loading}
            handleSubmit = {handleSubmit}
            handleCancel ={handleCancel}
            fetchData = {fetchData}
            tipeUser = {props.tipeUser}
            total = {total}
            setLoading = {setLoading}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    tipeUser : state.auth.dataUser.typeUser
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(GudangPakanPage)
export default page