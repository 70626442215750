import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { Link } from 'react-router-dom'
import ModalCreateAdjusmentOvk from '../../../../app/dashboard-peternak/adjustment-stock-page/ovk-page/okv-aksi-page/create-adjustment-ovk-page'
import ModalEditAdjustmentOvk from '../../../../app/dashboard-peternak/adjustment-stock-page/ovk-page/okv-aksi-page/edit-adjustment-ovk-page'

function OvkComponent(props) {
    const { Content } = Layout
    const { columns, data, loading, fetchData, setLoading, visible, handleCancel,
        handleSubmit, idData, detail, satuan, object, locked, month, year, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={16}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><Link to='/peternak/stock-flow-periode'><span>{t('pages.stockflow.periode.title')}</span></Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{t('pages.stockflow.periode.penyesuaian.ovk.title')} - {month} {year}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={8}>
                        <ModalCreateAdjusmentOvk fetchData={fetchData} object={object} locked={locked} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                                bordered
                                className="table-dashboard"
                                scroll={{ y: '70vh' }}
                            />
                        </Loading>
                    </Col>
                </Row>
                <ModalEditAdjustmentOvk visible={visible} idData={idData} handleCancel={handleCancel} object={object} locked={locked}
                    detail={detail} handleSubmit={handleSubmit} fetchData={fetchData} satuan={satuan} t={t} />
            </Content>
        </Layout>
    )
}

export default OvkComponent