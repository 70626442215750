import * as actionType from './rearing-action-type';

const initialState = {
    periodeKandang: null,
    loading: false,
    idRearing: null,

}

const handler = (currentState) => {
    const setDataRearing = {
        startGetDataRearings: () => ({
            ...currentState,
            loading: true,
        }),

        finishGetDataRearings: () => ({
            ...currentState,
            loading: false,
        }),

        setPeriodeKandang: data => ({
            ...currentState,
            periodeKandang: data
        }),
        setIdRearing: data => ({
            ...currentState,
            idRearing: data
        }),

    }

    return {
        ...setDataRearing
    };
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataRearings();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataRearings();
        case actionType.SET_DETAIL_CLOSING:
            return handler(state).setPeriodeKandang(payload);
        case actionType.SET_ID_REARING:
            return handler(state).setIdRearing(payload);

        default:
            return state;
    }
}