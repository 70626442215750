import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import * as actionType from './gudang-ovk-action-type';

const startGetDataGudangOvks = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataGudangOvks = () => ({
    type : actionType.FINISH_GET_DATA
})

const setDataGudangOvk = payload => ({
    type : actionType.SET_DATA_GUDANG_OVK,
    payload,
});

const setIdGudangOvk = payload => ({
    type : actionType.SET_ID_GUDANG_OVK,
    payload,
});

export const getIdGudangOvk = (id_gudang) => (dispatch) => {
    dispatch(setIdGudangOvk(id_gudang))
}

export const getListGudangOvk = (params) => (dispatch) => {
    dispatch(startGetDataGudangOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovkWarehouses.ovkWarehouses){
            dispatch(setDataGudangOvk(res.data.data.ovkWarehouses.ovkWarehouses))
            dispatch(getIdGudangOvk(res.data.data.ovkWarehouses.ovkWarehouses[0]._id))
        } else {
            validationMessage('error', 'Data Error')
        }
        dispatch(finishGetDataGudangOvks());
    })
}