import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { navigate } from '../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { faEdit, faLock, faTrash } from '@fortawesome/free-solid-svg-icons'
import RearingActiveComponent from '../../../modules/dashboard-peternak/rearing-component/rearing-active-component'
import { deletePeriode, updatePeriode, closePeriode } from './rearing-aksi-page/query-rearing'
import { getPeriodeKandang } from '../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/query-rearing'
import { getListPeriodeKandang } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { validationMessage } from '../../../common/component/notification/notification'
import { API } from '../../../common/api'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { useTranslation } from "react-i18next"

function RearingActivePage(props) {
    const { confirm } = Modal
    const { rearingActive, fetchDataActive, setLoading, loading, getListPeriodeKandang, idKandang } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [idData, setIdData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [visibleClose, setVisibleClose] = useState(false)
    const [idClose, setIdClose] = useState(null)
    const [date, setDate] = useState(null)
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deletePeriode(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false);
                } else if (res.data.data.deleteRearing.deleted) {
                    validationMessage('success', `${t("pages.periode.periodeBerjalan.response.sukses.hapus")}`)
                    fetchDataActive();
                    getListPeriodeKandang(getPeriodeKandang('', 0, 0, idKandang))
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.periode.periodeBerjalan.response.gagal.hapus")}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.periode.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t("general.cancel")}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }


    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values, year) => {
        API.post(updatePeriode(idData, values, year))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateRearing._id) {
                    validationMessage('success', `${t("pages.periode.periodeBerjalan.response.sukses.edit")}`)
                    fetchDataActive()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.periode.periodeBerjalan.response.gagal.edit")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const showModalClose = useCallback((value,date) => {
        if (value === idClose) {
            setVisibleClose(true)
            setDate(date)
        } else {
            setIdClose(value)
            setVisibleClose(true)
            setDate(date)
        }
    }, [idClose])

    const handleCancelClose = useCallback(() => {
        setVisibleClose(false)
    }, [])

    const updatedDataCloseRearing = (values) => {
        API.post(closePeriode(idClose, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.closeRearing._id) {
                    validationMessage('success', `${t("pages.periode.periodeBerjalan.response.sukses.tutup")}`)
                    fetchDataActive()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.periode.periodeBerjalan.response.gagal.tutup")}`)
                setLoading(false)
            })
    }

    const handleSubmitClose = (values) => {
        updatedDataCloseRearing(values['date_picker'].format('MM/DD/YYYY'))
        setVisibleClose(false)
        setIdData(null)
        setLoading(true)
    }
    const columns = [
        {
            title: `${t("pages.periode.periodeBerjalan.table.tanggal")}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            width: 60,
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.periode")}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 50,
            ...getColumnSearchProps('nama', `${t("pages.periode.periodeBerjalan.table.periode")}`),
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.kandang")}`,
            dataIndex: 'kandang',
            key: 'kandang',
            width: 90,
            ...getColumnSearchProps('kandang', `${t("pages.periode.periodeBerjalan.table.kandang")}`),
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.strain")}`,
            dataIndex: 'strain',
            key: 'strain',
            width: 130,
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.ppl")}`,
            dataIndex: 'ppl',
            key: 'ppl',
            width: 80,
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.popHpp")}`,
            dataIndex: 'populasiHpp',
            key: 'populasiHpp',
            className: 'right',
            width: 60,
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.pop")}`,
            dataIndex: 'populasi',
            key: 'populasi',
            className: 'right',
            width: 60,
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.bobot")}`,
            dataIndex: 'bobot',
            className: 'right',
            key: 'bobot',
            width: 30,
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.harga")}`,
            dataIndex: 'harga',
            className: 'right',
            key: 'harga',
            width: 30,
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.umur")}`,
            dataIndex: 'umur',
            className: 'right',
            key: 'umur',
            width: 30,
        },
        {
            title: 'Performance',
            children: [
                {
                    title: 'ADG (Gr)',
                    dataIndex: 'adg',
                    key: 'adg',
                    className: 'right',
                    width: 40,
                },
                {
                    title: 'FCR',
                    dataIndex: 'fcr',
                    className: 'right',
                    key: 'fcr',
                    width: 30,
                },
                {
                    title: `${t("pages.periode.periodeBerjalan.table.performa.deplesi")}`,
                    dataIndex: 'deplesi',
                    className: 'right',
                    key: 'deplesi',
                    width: 40,
                },
                {
                    title: 'FI',
                    dataIndex: 'fi',
                    className: 'right',
                    key: 'fi',
                    width: 40,
                },
                {
                    title: 'IP',
                    dataIndex: 'ip',
                    className: 'right',
                    key: 'ip',
                    width: 20,
                },
            ],
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 100,
            align: 'center',
            fixed: 'right',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.periode.periodeBerjalan.button.edit")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.periode.periodeBerjalan.button.tutup")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#ff4a3d',
                            borderRadius: '5px'
                        }} onClick={() => showModalClose(row.key, row.lastDate)}><FontAwesomeIcon icon={faLock} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.periode.periodeBerjalan.button.hapus")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const data = rearingActive.map(({ _id, name, chickInDate, population, populationHpp, chickInWeight, house, strain, price, ppl,
        adg, fcr, ip, deplesi, feedIntake, age, lastDate }, index) => ({
            key: _id,
            id: _id,
            nama: name,
            strain: strain.name,
            umur: age,
            ppl: ppl,
            adg: adg,
            fcr: fcr,
            deplesi: deplesi,
            lastDate: moment(new Date(parseInt(lastDate))),
            fi: <NumberFormat value={feedIntake} displayType={'text'} thousandSeparator={true} />,
            ip: <NumberFormat value={ip} displayType={'text'} thousandSeparator={true} />,
            tanggal: moment(new Date(parseInt(chickInDate))).format("DD-MM-YYYY"),
            kandang: house.warehouseName,
            harga: <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />,
            populasi: <NumberFormat value={population} displayType={'text'} thousandSeparator={true} />,
            populasiHpp: <NumberFormat value={populationHpp} displayType={'text'} thousandSeparator={true} />,
            bobot: chickInWeight,
        }))

    return (
        <RearingActiveComponent
            navigate={props.navigate}
            showModal={showModal}
            handleCancel={handleCancel}
            columns={columns}
            idData={idData}
            visible={visible}
            setVisible={setVisible}
            data={data} date={date}
            loading={loading}
            fetchDataActive={fetchDataActive}
            handleSubmit={handleSubmit}
            handleSubmitClose={handleSubmitClose}
            handleCancelClose={handleCancelClose}
            visibleClose={visibleClose}
            setLoading={setLoading}
        />
    )
}

const mapStateToProps = state => ({
    idKandang: state.layout.idKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListPeriodeKandang
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RearingActivePage)
export default page