import React from 'react'
import { Modal, Form, Row, Col, Input, DatePicker } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

function EditPenggunaanPakan(props) {
    const { visible, handleSubmit, data, handleCancel } = props
    const { t } = useTranslation()
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.stok.pakan.detail.penggunaan.edit.title')}
                okText="Edit"
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        jumlahStok: data.number,
                        date_picker: moment(new Date(parseInt(data.storeTime))),
                        price: data.priceFeeding,
                        keterangan: data.otherInformation,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.pakan.detail.penambahan.edit.jumlah')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message: `${t('pages.stok.pakan.modal.msgStok')}` }]}
                            >
                                <Input type='number'
                                    name='jumlahStok'
                                    placeholder="1000..."
                                    className="input-suffix mt-5"
                                    suffix="Kg"
                                />
                            </Form.Item>
                            <Form.Item hidden name="price" >
                                <Input type='number' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.pakan.detail.penambahan.edit.tanggal')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t('pages.stok.pakan.modal.msgTglPeriode')}` }]}
                            >
                                <DatePicker
                                    placeholder={t('pages.stok.pakan.modal.phTglPeriode')}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

export default EditPenggunaanPakan