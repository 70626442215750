import React from 'react'
import { Layout, Row, Col, Table } from 'antd'
import '../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import Loading from '../../../common/component/loading/loading-container'
import ModalEditRearing from '../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/edit-rearing-page'
import ModalCloseRearing from '../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/close-rearing-page'

function RearingComponent(props) {
    const { columns, data, handleOk, handleCancel, visible, idData, date, setVisible,
        loading, handleSubmit, visibleClose, handleSubmitClose, handleCancelClose } = props

    return (
        <Layout style={{ backgroundColor: "white" }}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={false}
                            className="table-dashboard"
                            scroll={{ x: 2000, y: '60vh' }}
                        />
                    </Loading>
                </Col>
                <ModalEditRearing visible={visible} setVisible={setVisible} handleOk={handleOk} idData={idData}
                    handleCancel={handleCancel} handleSubmit={handleSubmit}/>
                <ModalCloseRearing visibleClose={visibleClose} handleCancelClose={handleCancelClose}
                    handleSubmitClose={handleSubmitClose} date={date} />
            </Row>
        </Layout>
    )
}

export default RearingComponent