import React from 'react';
import { Layout, Row, Col, Table } from 'antd';
import Loading from '../../../../common/component/loading/loading-container';

import EditDeplesi from '../../../../app/dashboard-peternak/rearing-record-page/edit-detail-rearing-page/edit-deplesi-page';

function DetailDeplesiComponent(props) {
    const { Content } = Layout;
    const {data, columns,loading,visible,handleSubmit,handleCancel,detail} = props;
    return ( 
        <Layout>
            <Content className="dashboard-section-content background-white">
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <div className="dashboard-section-table-dashboard">
                                <Table 
                                    columns={columns} 
                                    dataSource={data} 
                                    bordered
                                    className="table-dashboard"
                                />
                            </div>
                        </Loading>
                    </Col>
                </Row>
                <EditDeplesi visible ={visible} handleSubmit = {handleSubmit} detail={detail} handleCancel = {handleCancel}/>
            </Content>
        </Layout>
    );
}
 
export default DetailDeplesiComponent;