import React, { useState,useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { navigate } from '../../../../../common/store/action';
import CreateMaterialComponent from '../../../../../modules/dashboard-peternak/master-component/material-component/material-aksi-component/create-material-component';

import {createMaterial} from './query-material';

function CreateMaterialPage(props) {
    const {fetchData,setLoading,t} = props;
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    },[]);

    const postData = (values) => {
        API.post(createMaterial(values))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.createMaterial._id){
                setConfirmLoading(false);
                validationMessage('success', `${t('pages.master.material.response.sukses.tambah')}`)
                fetchData();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.material.response.gagal.tambah')}`);
            setLoading (false);
        })
    }

    const handleSubmit = (values) => {
        setConfirmLoading(true);
        postData(values );
        setVisible(false);
        setLoading(true);
    };

    return ( 
        <CreateMaterialComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            confirmLoading = {confirmLoading}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreateMaterialPage);
export default page