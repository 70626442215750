import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Loading from '../../../../common/component/loading/loading-container'

function TableFeedUsagePage(props) {
    const { feedUsage, loading, t } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const columns = [
        {
            title: `${t('pages.dashboard.feed.feed')}`,
            dataIndex: 'feed',
            key: 'feed',
            ...getColumnSearchProps('feed'),
        },
        {
            title: `${t('pages.dashboard.feed.total')}`,
            dataIndex: 'total',
            key: 'total',
            align: 'right'
        },
        {
            title: `${t('pages.dashboard.feed.day')}`,
            dataIndex: 'umur',
            key: 'umur',
            align: 'right'
        },
    ]

    return (
        <Loading loading={loading}>
            <Table
                columns={columns} bordered
                dataSource={feedUsage}
                pagination={false}
                className="table-dashboard"
                scroll={{ y: '50vh' }}
            />
        </Loading>
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TableFeedUsagePage)
export default page