export const createAdjustmentMaterial = (value,idMonthlyStockMaterial) => {
    return {
        query: `mutation{
                createAdjustmentMaterial(adjustmentMaterialInput:{
                    qty: ${value.jumlah}
                    material: "${value.material.value}"
                    monthlyStockMaterial : "${idMonthlyStockMaterial}"
                }){
                    _id
                }
            }`
    }
}

export const getAdjustmentMaterial = (keyword,limit,skip,_id) => {
    return (
        `query{
            adjustmentMaterials(keyword:"${keyword}",limit:${limit}, skip:${skip}, _id:"${_id}"){
                totalCount,
                adjustmentMaterials{
                  _id
                  qty
                  material{
                      name
                  }
                }
              }
            }
        `
    );
}

export const deleteAdjustmentMaterial = (_id) => {
    return (
        `mutation{
            deleteAdjustmentMaterial(_id : "${_id}"){
                deleted
            }
            }
        `
    );
}

export const updateAdjustmentMaterial = (_id,value) => {
    return {
        query: `                      
        mutation{
            updateAdjustmentMaterial(_id: "${_id}",updatedAdjustmentMaterialInput:{
                qty: ${parseFloat(value.jumlah)}
                material: "${value.material.value}"
            }){
              _id
            }
            }
        `
    }   
}

export const detailAdjustmentMaterial = (_id) => {
    return (
        `query{
            adjustmentMaterial(_id: "${_id}"){
                _id
                qty
                material{
                    value: _id
                    name
                }
            }
          }
        `
    );
}