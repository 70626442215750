import React from 'react'
import { Modal, Form, Row, Col, DatePicker } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'

function CreateRearing(props) {
    const { visible, handleSubmit, setVisible, showModal, kandang, locales } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={locales("pages.recording.modal.title")}
                okText={locales("general.create")}
                width={800}
                cancelText={locales("general.cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.modal.tanggal")}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, message: `${locales("pages.recording.modal.msgTgl")}` }]}
                            >
                                <DatePicker
                                    placeholder={locales("pages.recording.modal.phTgl")}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
    return (
        <span>
            <ButtonDashboard
                text={locales("pages.recording.button.tambah")}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                kandang={kandang}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </span>
    )
}

export default CreateRearing