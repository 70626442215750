import React from 'react';
import { Form, Row, Col, Input, DatePicker, Select, Layout, Button, Breadcrumb, Card } from 'antd';
import { Link } from 'react-router-dom';
import CONSTANTS from '../../../../../common/utils/Constants';
import Loading from '../../../../../common/component/loading/loading-container';

function CreateStokOvk(props) {
    const { handleSubmit, ovk, gudang, handleFailed, navigate, suffix, loading, locales } = props;
    const { Option } = Select;
    const { Content } = Layout;

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/stok-ovk"> <span>{locales('pages.stok.ovk.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{locales('pages.stok.ovk.button.tambahBaru')}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title='Tambah Stok' bordered={false}>
                                <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="basic" initialValues={{ remember: true }}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >{locales('pages.stok.ovk.tambah.gudang')}</span>
                                            <Form.Item
                                                name="gudangOvk" rules={[{ required: true, message: `${locales('pages.stok.ovk.tambah.msgGudang')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={locales('pages.stok.ovk.tambah.phGudang')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        gudang.map(data =>
                                                            <Option
                                                                key={data.name.toString()}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content">OVK</span>
                                            <Form.Item
                                                name="ovk" rules={[{ required: true, message: `${locales('pages.stok.ovk.tambah.msgOvk')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={locales('pages.stok.ovk.tambah.phOvk')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        ovk.map(data =>
                                                            <Option
                                                                key={data.name.toString()}
                                                                value={data._id}
                                                            >
                                                                {data.name}
                                                            </Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.ovk.tambah.nama')}</span>
                                            <Form.Item
                                                name="namaPengadaan" rules={[{ required: true, message: `${locales('pages.stok.ovk.tambah.msgNama')}` }]}
                                            >
                                                <Input
                                                    name='namaPengadaan'
                                                    placeholder="Pengadaan..."
                                                    className="input-form mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.ovk.tambah.tglPengadaan')}</span>
                                            <Form.Item
                                                name="date_picker" rules={[{ required: true, message: `${locales('pages.stok.ovk.tambah.msgTglPengadaan')}` }]}
                                            >
                                                <DatePicker
                                                    placeholder={locales('pages.stok.ovk.tambah.phTglPengadaan')}
                                                    className="select-input-date mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.ovk.tambah.harga')}</span>
                                            <Form.Item
                                                name="price" rules={[{ required: true, message: `${locales('pages.stok.ovk.tambah.msgHarga')}` }]}
                                            >
                                                <Input
                                                    name='price'
                                                    type='number'
                                                    placeholder="1000..."
                                                    prefix="Rp"
                                                    className="input-price mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.ovk.tambah.jumlahPengadaan')}</span>
                                            <Form.Item
                                                name="jumlahPengadaan" rules={[{ required: true, message: `${locales('pages.stok.ovk.tambah.msgJumlahPengadaan')}` }]}
                                            >
                                                <Input
                                                    name='jumlahPengadaan'
                                                    placeholder="50..."
                                                    className="input-suffix mt-5"
                                                    suffix={suffix}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button
                                        onClick={() => navigate(CONSTANTS.STOK_OVK_MENU_KEY)}
                                    >
                                        {locales('general.cancel')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ marginLeft: 8, marginTop: 0 }}
                                    >
                                        {locales('general.create')}
                                    </Button>

                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    );
}

export default CreateStokOvk;