import React from 'react'
import { Modal, Form, Row, Col, Input, DatePicker, Select } from 'antd'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

function CreateStokPakan(props) {
    const { Option } = Select
    const { visible, handleSubmit, setVisible, showModal, pakan, gudangPakan, locales } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={locales('pages.stok.pakan.modal.titleTambah')}
                okText={locales('general.create')}
                width={800}
                cancelText={locales('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content" >{locales('pages.stok.pakan.modal.gudang')}</span>
                            <Form.Item
                                name="gudangPakan" rules={[{ required: true, message: `${locales('pages.stok.pakan.modal.msgGudang')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={locales('pages.stok.pakan.modal.phGudang')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudangPakan.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">{locales('pages.stok.pakan.modal.pakan')}</span>
                            <Form.Item
                                name="pakan" rules={[{ required: true, message: `${locales('pages.stok.pakan.modal.msgPakan')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={locales('pages.stok.pakan.modal.phPakan')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        pakan.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.pakan.modal.namaPengadaan')}</span>
                            <Form.Item
                                name="namaPengadaan" rules={[{ required: true, message: `${locales('pages.stok.pakan.modal.msgNamaPengadaan')}` }]}
                            >
                                <Input
                                    name='namaPengadaan'
                                    placeholder={locales('pages.stok.pakan.modal.phNamaPengadaan')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.pakan.modal.tglPengadaan')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, message: `${locales('pages.stok.pakan.modal.msgTglPengadaan')}` }]}
                            >
                                <DatePicker
                                    placeholder={locales('pages.stok.pakan.modal.phTglPengadaan')}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.pakan.modal.harga')}</span>
                            <Form.Item
                                name="hargaSatuan" rules={[{ required: true, message: `${locales('pages.stok.pakan.modal.msgHarga')}` }]}
                            >
                                <Input
                                    name='hargaSatuan'
                                    placeholder="1000..."
                                    prefix="Rp"
                                    className="input-price mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.pakan.modal.stok')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message: `${locales('pages.stok.pakan.modal.msgStok')}` }]}
                            >
                                <Input
                                    name='jumlahStok'
                                    type='number'
                                    placeholder="1000..."
                                    className="input-suffix mt-5"
                                    suffix="Kg"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={locales("pages.stok.pakan.button.tambahBaru")}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

export default CreateStokPakan