import React, {useState} from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { Stage, Layer, Star, Text, Image } from 'react-konva'
import useImage from 'use-image'
import tempImage from '../../../../assets/svg/temp-hot-line.svg'

function generateShapes() {
    return [...Array(10)].map((_, i) => ({
        id: i.toString(),
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        rotation: Math.random() * 180,
        isDragging: false,
    }));
}

const INITIAL_STATE = generateShapes()

function DetailIotPage(props) {
    const [stars, setStars] = React.useState(INITIAL_STATE);
    const [image] = useImage(tempImage)
    const [x, setX] = useState(500)
    const [y, setY] = useState(300)

    const handleDragStart = (e) => {
        const id = e.target.id()
        const position = e.target.getStage().getPointerPosition()
        setX(position.x)
        setY(position.y)
        setStars(
            stars.map((star) => {
                return {
                    ...star,
                    isDragging: star.id === id,
                };
            })
        );
    };
    const handleDragEnd = (e) => {
        const position = e.target.getStage().getPointerPosition()
        setX(position.x)
        setY(position.y)
        setStars(
            stars.map((star) => {
                return {
                    ...star,
                    isDragging: false,
                };
            })
        );
    };


    return (
        <Stage width={window.innerWidth} height={window.innerHeight}>
            <Layer>
                <Image image={image}
                    x={x} y={y} width={100} height={100}
                    opacity={0.8}
                    shadowColor="black"
                    shadowBlur={10}
                    shadowOpacity={0.6}
                    shadowOffsetX={5}
                    shadowOffsetY={5}
                    draggable
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                />
                <Text draggable text="Temperature"
                    // x={x} y={y+10} 
                    />
                {stars.map((star) => (
                    <Star
                        key={star.id}
                        id={star.id}
                        x={star.x}
                        y={star.y}
                        numPoints={5}
                        innerRadius={20}
                        outerRadius={40}
                        fill="#89b717"
                        opacity={0.8}
                        draggable
                        rotation={star.rotation}
                        shadowColor="black"
                        shadowBlur={10}
                        shadowOpacity={0.6}
                        shadowOffsetX={star.isDragging ? 10 : 5}
                        shadowOffsetY={star.isDragging ? 10 : 5}
                        scaleX={star.isDragging ? 1.2 : 1}
                        scaleY={star.isDragging ? 1.2 : 1}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                    />
                ))}
            </Layer>
        </Stage>
    )
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailIotPage)
export default page