export const getSupplier = (keyword,limit,skip) => {
    return (
        `query{
            suppliers(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
                totalCount
                suppliers{
                    _id
                    name
                    address
                    contact
                    typeSupplier
                    codeSupplier
                    otherInformation
                }
            }
        }
        `
    );
};

export const createSupplier = (value) => {
    return{
        query: `mutation{
            createSupplier(supplierInput: {
                name : "${value.name}"
                address : "${value.address}"
                contact : "${value.contact}"
                typeSupplier : "${value.typeSupplier.value}"
                codeSupplier : "${value.codeSupplier}"
                otherInformation : "${value.otherInformation}"
              }){
                _id
              }
            }`
    }
}

export const deleteSupplier = (_id) => {
    return (
        `mutation{
            deleteSupplier(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const detailSupplier = (_id) => {
    return (
        `query{
            supplier(_id : "${_id}"){
                    _id
                    name
                    address
                    contact
                    value : typeSupplier
                    codeSupplier
                    otherInformation
                }
            }
        `
    );
}

export const updateSupplier = (_id,value) => {
    return {
        query: `mutation{
            updateSupplier(_id : "${_id}", updateSupplierInput : {
                name : "${value.name}"
                address : "${value.address}"
                contact : "${value.contact}"
                typeSupplier : "${value.typeSupplier.value}"
                codeSupplier : "${value.codeSupplier}"
                otherInformation : "${value.otherInformation}"
            }){
              _id
            }
          }
        `
    }
}


