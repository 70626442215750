import * as actionType from './stok-ovk-action-type';

const initialState = {
    idStokOvk: null,
    dataStokOvk: [],
    satuanOvk: null,
    satuanRecordingOvk: null,
    loading: false,
    namaOvk: null,
}

const handler = (currentState) => {
    const setDataStokOvk = {
        startGetDataStokOvks: () => ({
            ...currentState,
            loading: true,
        }),

        finishGetDataStokOvks: () => ({
            ...currentState,
            loading: false,
        }),

        setIdStokOvk: data => ({
            ...currentState,
            idStokOvk: data
        }),

        setDataStokOvk: data => ({
            ...currentState,
            dataStokOvk: data
        }),

        setDataSatuanOvk: data => ({
            ...currentState,
            satuanOvk: data
        }),
        setDataSatuanRecordingOvk: data => ({
            ...currentState,
            satuanRecordingOvk: data
        }),
        setDataNamaOvk: data => ({
            ...currentState,
            namaOvk: data
        }),
    }

    return {
        ...setDataStokOvk
    };
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataStokOvks();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataStokOvks();
        case actionType.SET_ID_STOK_OVK:
            return handler(state).setIdStokOvk(payload);
        case actionType.SET_STOK_OVK:
            return handler(state).setDataStokOvk(payload);
        case actionType.SET_SATUAN_OVK:
            return handler(state).setDataSatuanOvk(payload);
        case actionType.SET_SATUAN_RECORDING_OVK:
            return handler(state).setDataSatuanRecordingOvk(payload);
        case actionType.SET_NAMA_OVK:
            return handler(state).setDataNamaOvk(payload);

        default:
            return state;
    }
}