import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import Highlighter from 'react-highlight-words';
import { Space, Tooltip, Button,Input,Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import PenggunaanStokMaterialComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-material-component/transaksi-stok-material-component/penggunaan-stok-material-component';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';

/*Import Moment Js */
import moment from 'moment-timezone';
import 'moment-timezone';
import 'moment/locale/id';
/*End Import Moment Js */

import {detailTransaksi,updateTransaksi,deleteTransaksiStokMaterial} from '../stok-material-aksi-page/query-stok-material';

function PenggunaanStokMaterialPage(props) {
    const {confirm} = Modal;
    const {idStock,penggunaan,fetchDataPenggunaan,loading,setLoading,t} = props;
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('');
    const [pagination, setPagination] = useState(10);
    const [idData, setIdData] = useState(null); 
    const [visible, setVisible] = useState(false);
    const [detail, setDetail] = useState('');

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSeacrhedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    /**End handle seacrh in row antd design */
    
    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(detailTransaksi(idData))
                .then(res => {        
                    setDetail(res.data.data.materialTransaction)
                    setVisible(true);
                }).catch( ( error ) =>  {
                    console.log(error);
                    setDetail(null)
                    setVisible(false);
                });
            }
        }
        getDetailData();
    }, [idData]);

    const showModal = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value);
    },[idData]);

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const updatedData =  (values,year,materialStock) => {
        API.post(updateTransaksi(idData,values,year,materialStock))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.updateMaterialTransaction._id){
                validationMessage('success', `${t("pages.stok.material.response.sukses.editTrans")}`)
                fetchDataPenggunaan(idStock);
            }
        }).catch( ( error ) =>  {
            console.log(error);
            validationMessage('error', `${t("pages.stok.material.response.gagal.editTrans")}`);
            setLoading(false);
        });
    }

    const handleSubmit = (values) => {
        updatedData(values,values['date_picker'].format('MM/DD/YYYY'),detail.materialStock);
        setVisible(false);
        setIdData(null);
    };
    /**End handle updated data */

     /**Handle delete data */
     const deleteData = (_id) => {
        setLoading(true);
        API.delete(deleteTransaksiStokMaterial(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }
            else if(res.data.data.deleteMaterialTransaction.deleted === true){
                validationMessage('success', `${t("pages.stok.material.response.sukses.hapusTrans")}`)
                fetchDataPenggunaan(idStock);
            }
        }).catch( ( error ) =>  {
            console.log(error);
            validationMessage('error', `${t("pages.stok.material.response.gagal.hapusTrans")}`)
            setLoading(false);
        });
    }
    
    const hapusConfirm = (_id)=> {
        confirm({
            title: `${t("pages.stok.material.detail.penambahan.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    /**End handle delete data */

    const columns = [
        {
            title: `${t("pages.stok.material.detail.penggunaan.table.tanggal")}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            ...getColumnSearchProps('tanggal', `${t("pages.stok.material.detail.penggunaan.table.tanggal")}`),
        },
        {
            title: `${t("pages.stok.material.detail.penggunaan.table.jumlah")}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            ...getColumnSearchProps('jumlah',`${t("pages.stok.material.detail.penggunaan.table.tanggal")}`),
        },
        {
            title: `${t("pages.stok.material.detail.penggunaan.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 200,
            align: 'center',
            render: (value,row,index) => (
            <Space size="middle">
                <Tooltip placement="top" title={t("pages.stok.material.detail.penggunaan.button.edit")}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FFA903',
                        borderRadius:'5px'
                    }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={t('general.del')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FF0303',
                        borderRadius:'5px'
                    }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                </Tooltip>
            </Space>
            ),
        },
    ];

    const data = penggunaan.map( ({_id,number,storeTime,typeTransaction}, index) => ({
        key: _id,
        jumlah : <NumberFormat value={number} displayType={'text'} thousandSeparator={true}/>,
        tanggal : moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
    }));

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize);
    }, []);

    return ( 
        <PenggunaanStokMaterialComponent
            navigate={props.navigate}
            columns = {columns}
            data = {data}
            loading = {loading}
            pagination = {pagination}
            visible = {visible}
            detail = {detail}
            changePagination = {changePagination}
            handleCancel = {handleCancel}
            handleSubmit = {handleSubmit}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    idStock : state.stokMaterial.idStokMaterial
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(PenggunaanStokMaterialPage);
export default page