import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import EditRearingComponent from '../../../../modules/dashboard-peternak/rearing-component/rearing-aksi-component/edit-rearing-component'
import { detailPeriode } from './query-rearing'
import {getStrain} from '../../master-page/strain-page/strain-aksi-page/query-strain'
import { API } from '../../../../common/api'
import { useTranslation } from "react-i18next"

function EditRearingPage(props) {
    const {visible,handleCancel,handleSubmit,idData, setVisible} = props
    const [strain, setStrain] = useState([])
    const [detail, setDetail] = useState('')
    const { t } = useTranslation()

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailPeriode(idData))
                    .then(res => {
                        setDetail(res.data.data.rearing)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData, setVisible])

    const fetchDataStrain = () => {
        API.get(getStrain())
        .then(res => {
            setStrain(res.data.data.strains.strains)
        }).catch( ( error ) =>  {
            setStrain(null)
        })
    }
    
    useEffect(() => {
        if(detail !== '') {fetchDataStrain()}
    }, [detail])

    return ( 
        <EditRearingComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            data = {detail}
            strain = {strain}
            kandang = {props.kandang}
            locales = {t}
        />
    )
}

const mapStateToProps = state => ({
    kandang : state.layout.dataKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditRearingPage)
export default page