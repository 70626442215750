import * as actionType from './material-action-type';

const initialState = {
    dataMaterial: [],
    loading : false,
}

const handler = (currentState) => {
    const setDataMaterial = {
        startGetDataMaterials : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataMaterials : () => ({
            ...currentState,
            loading: false,
        }),

        getDataMaterials : data => ({
            ...currentState,
            dataMaterial: data
        })
    }

    return {
        ...setDataMaterial
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataMaterials();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataMaterials();
        case actionType.SET_DATA_MATERIAL:
            return handler(state).getDataMaterials(payload);

        default:
            return state;
    }
}