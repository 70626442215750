import React from 'react';
import { Modal,Form,Row,Col,Input,Select,InputNumber  } from 'antd';

function CreateKandangFirst(props) {
    const { TextArea } = Input;
    const { Option } = Select;
    const {visible,setVisible,handleSubmit,gudangPakan, gudangOvk,gudangMaterial} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title="Tambah Kandang"
            okText="Create"
            width={800}
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        gudangPakan : '',
                    }}
                >
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">Nama Kandang</span>
                            <Form.Item
                                name="namaKandang" rules={[{ required: true, message: 'Silahkan masukan nama kandang!' }]}
                            >
                                <Input
                                    name='namaKandang'
                                    placeholder="Kandang 1 Lantai 1..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">Kapasitas Kandang</span>
                            <Form.Item
                                name="kapasistasKandang" rules={[{ required: true, message: 'Silahkan masukan kapasitas kandang!' }]}
                            >
                                <InputNumber 
                                    name='kapasistasKandang'
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">Gudang Pakan</span>
                            <Form.Item
                                name="selectGudangPakan" rules={[{ required: true, message: 'Silahkan masukan gudang pakan!' }]}  
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih Gudang"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >  
                                {
                                        gudangPakan.map( data =>     
                                            <Option
                                            key={data.name.toString()}
                                            value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">Gudang Ovk</span>
                            <Form.Item
                                name="selectGudangOvk" rules={[{ required: true, message: 'Silahkan masukan gudang ovk!' }]}  
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih Gudang"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >  
                                   {
                                        gudangOvk.map( data =>     
                                            <Option
                                            key={data.name.toString()}
                                            value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">Gudang Material</span>
                            <Form.Item
                                name="selectGudangMaterial" rules={[{ required: true, message: 'Silahkan masukan gudang material    !' }]}  
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih Gudang Material"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    size="large"
                                    className="select-kategori black font-regular"
                                >  
                                    {
                                        gudangMaterial.map( data =>     
                                            <Option
                                            key={data.name.toString()}
                                            value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content" >Alamat Kandang</span>
                            <Form.Item
                                name="alamat" rules={[{ required: true, message: 'Silahkan masukan alamat kandang!' }]}  
                            >
                                <TextArea
                                    style={{borderColor:"#BE375F"}}
                                    placeholder="Masukkan alamat kandang"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content" >Keterangan</span>
                            <TextArea
                                style={{borderColor:"#BE375F"}}
                                placeholder="Masukkan keterangan"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </div>
    );
}
 
export default CreateKandangFirst;