export const getMaterial = (keyword,skip,limit) => {
    return (
        `query{
            materials(keyword:"${keyword}",limit:${limit},skip:${skip}){
            totalCount
            materials{
              _id
              code
              name
              type
              producer
              otherInformation
            }
          }
        }`
    );
}

export const createMaterial = (value) => {
    return {
        query: `mutation{
                createMaterial(materialInput: {
                    code : "${value.kodeMaterial}"
                    name : "${value.namaMaterial}"
                    producer : "${value.perusahaan}"
                    type : "${value.jenisMaterial.value}"
                    otherInformation : "${value.keterangan}"
                }){
                    _id
                    code
                    producer
                }
            }
        `
    }
} 

export const updateMaterial = (_id,value) => {
    return {
        query : `mutation{
                    updateMaterial(_id: "${_id}",updateMaterialInput:{
                        code:  "${value.kodeMaterial}"
                        name :  "${value.namaMaterial}"
                        producer:  "${value.perusahaan}"
                        type:  "${value.jenisMaterial.value}"
                    }){
                        _id
                    }
            }`
    }
}

export const detailMaterial = (_id) => {
    return (
        `query{
            material(_id : "${_id}"){
              _id
              code
              name
              producer
              value : type
              otherInformation
          }
        }`
    );
} 

export const deleteMaterial = (_id) => {
    return (
        `mutation{
            deleteMaterial(_id: "${_id}"){
               deleted
            }
        }`
    );
}