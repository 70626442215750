import * as actionType from './stok-material-action-type';
import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';

const startGetDataMaterials = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataMaterials = () => ({
    type : actionType.FINISH_GET_DATA
})

const setIdStokMaterial = payload => ({
    type : actionType.SET_ID_STOK_MATERIAL,
    payload,
});

const setDataStokMaterial= payload => ({
    type : actionType.SET_STOK_MATERIAL,
    payload,
});

const setDataNamaMaterial= payload => ({
    type : actionType.SET_NAMA_MATERIAL,
    payload,
});

export const getListMaterial = (params) => (dispatch) => {
    dispatch(startGetDataMaterials());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.materialStocks.materialStocks){
            dispatch(setDataStokMaterial(res.data.data.materialStocks.materialStocks))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataMaterials());
    })
}

export const getIdStokMaterial = (id_stok) => (dispatch) => {
    dispatch(setIdStokMaterial(id_stok));
}

export const getNamaMaterial = (nama_material) => (dispatch) => {
    dispatch(setDataNamaMaterial(nama_material));
}