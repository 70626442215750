export const getOverhead = (keyword,limit,skip) => {
    return (
        `query{
            overheads(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
            overheads {
                    _id
                    name
                    defaultPrice
                }
            }
        }
        `
    );
};

export const createOverhead = (value) => {
    return{
        query: `mutation{
            createOverhead(overheadInput: {
                name : "${value.name}"
                defaultPrice : ${parseFloat(value.price)}
              }){
                _id
              }
            }
        `
    }
}

export const deleteOverhead = (_id) => {
    return (
        `mutation{
            deleteOverhead(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const detailOverhead = (_id) => {
    return (
        `query{
            overhead(_id : "${_id}"){
                _id
                name
                defaultPrice
                }
            }
        `
    );
}

export const updateOverhead = (_id,value) => {
    return {
        query: `mutation{
            updateOverhead(_id : "${_id}", updateOverheadInput : {
              name : "${value.name}",
              defaultPrice : ${parseFloat(value.price)},
            }){
              _id
              name
              defaultPrice
            }
          }
        `
    }
}
