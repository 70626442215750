export const getCost = (keyword,limit,skip) => {
    return (
        `query{
            costs(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
                costs {
                    _id
                    name
                    price
                }
            }
        }
        `
    );
};

export const createCost = (value) => {
    return{
        query: `mutation{
            createCost(costInput: {
                name : "${value.nama}"
                price : ${parseFloat(value.biaya)}
              }){
                _id
              }
            }
        `
    }
}

export const deleteCost = (_id) => {
    return (
        `mutation{
            deleteCost(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const detailCost = (_id) => {
    return (
        `query{
            cost(_id : "${_id}"){
                _id
                name
                price
                }
            }
        `
    );
}

export const updateCost = (_id,value) => {
    return {
        query: `mutation{
            updateCost(_id : "${_id}", updateCostInput : {
              name : "${value.nama}",
              price : ${parseFloat(value.biaya)},
            }){
              _id
              name
            }
          }
        `
    }
}
