import React from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Input } from 'antd'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import { createStandardEnvDetail } from './query-iot'

function CreateStandarEws(props) {
    const { fetchData, setLoading, visible, setVisible, idStdEnv, form, t } = props

    const postData = (values) => {
        API.post(createStandardEnvDetail(values, idStdEnv))
            .then(res => {
                try {
                    if (res.data.errors) {
                        validationMessage('error', `${t('pages.monitor.resAddStdFailed')}`, res.data.errors[0].message)
                    }
                    if (res.data.data.createStandardEnvironmentDetail._id) {
                        validationMessage('success', `${t('pages.monitor.resAddStdSuccess')}`)
                        fetchData(idStdEnv)
                        form.resetFields()
                    }
                } catch (error) {
                    console.log(error)
                    validationMessage('error', `${t('pages.monitor.resAddStdFailed')}`, error.message)
                    setLoading(false)
                }
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <Form
            hidden={!visible}
            form={form} onFinish={handleSubmit} initialValues={{
                max_temperature: null, min_temperature: null, max_humidity: null, min_humidity: null, max_co2: null,
            }}
        >
            <Row gutter={[4, 4]}>
                <Col span={2}>
                    <Form.Item
                        name="day" rules={[{ required: true, message: `${t('pages.monitor.msgDay')}` }]}
                    >
                        <Input type='number' size="small"
                            placeholder={t('pages.monitor.day')}
                            className="input-suffix"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="max_temperature"
                    >
                        <Input type='number' step='any' size="small"
                            placeholder={t('pages.monitor.maxTemp')}
                            className="input-suffix"
                            suffix="°C"
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name="min_temperature"
                    >
                        <Input type='number' step='any' size="small"
                            placeholder={t('pages.monitor.minTemp')}
                            className="input-suffix"
                            suffix="°C"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="max_humidity"
                    >
                        <Input type='number' step='any' size="small"
                            placeholder={t('pages.monitor.maxHumidity')}
                            className="input-suffix"
                            suffix="%"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name="min_humidity"
                    >
                        <Input type='number' step='any' size="small"
                            placeholder={t('pages.monitor.minHumidity')}
                            className="input-suffix"
                            suffix="%"
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name="max_co2"
                    >
                        <Input type='number' step='any' size="small"
                            placeholder={t('pages.monitor.maxCo2')}
                            className="input-suffix"
                            suffix="ppm"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <ButtonDashboard
                        text={t('general.cancel')} size='small'
                        height={20}
                        borderRadius="5px" marginLeft={5} marginTop={10}
                        className="font-semi-medium font-content-title button-add"
                        onClick={() => setVisible(false)}
                    />
                    <ButtonDashboard
                        htmlType="submit"
                        text={t('general.add')} size='small'
                        height={20} marginTop={10}
                        backgroundColor="#008ad4"
                        borderRadius="5px"
                        className="font-semi-medium font-content-title button-add"
                        textColor="white"
                    />
                </Col>
            </Row>
        </Form>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStandarEws)
export default page