import React from 'react';
import { Modal,Form,Row,Col,DatePicker } from 'antd';
import { useTranslation } from "react-i18next";


function CloseRearing(props) {
    const {visible,handleSubmit,handleCancel,date} = props;
    const { t } = useTranslation();

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel}) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t("pages.periode.modal.titleTutupPeriode")}
            okText="OK"
            width={800}
            cancelText={t("general.cancel")}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        date_picker: date
                    }}
                >
                      <Row gutter={[16,8]}>
                        <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.periode.modal.tglPenutupan")}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t("pages.periode.modal.msgTglPenutupan")}` }]}
                            >
                                <DatePicker 
                                    placeholder={t("pages.periode.modal.phTglPenutupan")} 
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
             <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default CloseRearing;