import React, { useState, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { navigate } from '../../../common/store/action';
import Highlighter from 'react-highlight-words';
import { Space, Tooltip, Button, Modal,Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { faInfoCircle, faLockOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import RearingCloseComponent from '../../../modules/dashboard-peternak/rearing-component/rearing-close-component';
import {getIdPeriode} from '../../../modules/dashboard-peternak/layout-component/store/layout-action';

import { API } from '../../../common/api';
import CONSTANTS from '../../../common/utils/Constants';
import {deletePeriode,openPeriode} from './rearing-aksi-page/query-rearing'
import { validationMessage } from '../../../common/component/notification/notification';

import {getPeriodeClosing} from '../../../modules/dashboard-peternak/rearing-component/store/rearing-action';

/*Import Moment Js */
import moment from 'moment-timezone';
import 'moment-timezone';
import 'moment/locale/id';
/*End Import Moment Js */

import { useTranslation } from "react-i18next";

function RearingClosePage(props) {
    const {getIdPeriode,getPeriodeClosing} = props;
    const { confirm } = Modal;
    const {fetchDataClose,rearingClose,loading,setLoading} = props;
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('');
    const [pagination, setPagination] = useState(10);
    const { t } = useTranslation();

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
            <Input
                placeholder={`${t("general.search")} ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                >
                {t("general.search")}
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
                </Button>
            </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        ),
    });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSeacrhedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    /**End handle seacrh in row antd design */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deletePeriode(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.deleteRearing.deleted === true){
                validationMessage('success', `${t("pages.periode.periodeBerjalan.response.sukses.hapus")}`)
                fetchDataClose();
            }
        }).catch( ( error ) =>  {
            console.log(error);
            validationMessage('error', `${t("pages.periode.periodeBerjalan.response.gagal.hapus")}`)
            setLoading (false);
        });
    }
    
    const hapusConfirm = (_id)=> {
        confirm({
            title: `${t("pages.periode.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t("general.cancel")}`,
            onOk: () => {
                deleteData(_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const openRearing =  (_id) => {
        API.post(openPeriode(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.openRearing._id){
                validationMessage('success', `${t("pages.periode.response.sukses.open")}`)
                fetchDataClose();
            }
        }).catch( ( error ) =>  {
            console.log(error);
            validationMessage('error',`${t("pages.periode.response.gagal.open")}`);
            setLoading (false);
        });
    }
  
    const openConfirm = (_id)=> {
        confirm({
            title: `${t("pages.periode.textOpen")}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t("general.cancel")}`,
            onOk: () => {
                openRearing(_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const detailRearing = useCallback((idPeriode,kandang,periode) => {
        const periodeKandang = kandang + ' ' + periode;
        getIdPeriode(idPeriode);
        getPeriodeClosing(periodeKandang)
        props.navigate(CONSTANTS.DETAIL_REARING_CLOSE_MENU_KEY);
    }, [getIdPeriode,getPeriodeClosing,props]);

    const columns = [
        {
            title: `${t("pages.periode.periodeSelesai.table.tanggal")}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            width: 100
        },
        {
            title: `${t("pages.periode.periodeSelesai.table.periode")}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama', `${t("pages.periode.periodeSelesai.table.periode")}`),
            width: 100
        },
        {
            title: `${t("pages.periode.periodeSelesai.table.kandang")}`,
            dataIndex: 'kandang',
            key: 'kandang',
            ...getColumnSearchProps('kandang', `${t("pages.periode.periodeSelesai.table.kandang")}`),
            width: 150
        },
        {
            title: `${t("pages.periode.periodeSelesai.table.popHpp")}`,
            dataIndex: 'populasiHpp',
            key: 'populasiHpp',
            className : 'center',
            width: 100
        },
        {
            title: `${t("pages.periode.periodeSelesai.table.pop")}`,
            dataIndex: 'populasi',
            key: 'populasi',
            className : 'center',
            width: 100
        },
        {
            title: `${t("pages.periode.periodeSelesai.table.bobot")}`,
            dataIndex: 'bobot',
            className : 'center',
            key: 'bobot',
            width: 50
        },
        {
            title: 'Strain',
            dataIndex: 'strain',
            className : 'center',
            key: 'strain',
        },  
        {
            title: `${t("pages.periode.periodeSelesai.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            render: (value,row,index) => (
            <Space size="middle">
                <Tooltip placement="top" title={t("pages.periode.periodeSelesai.button.detail")}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#00923F',
                        borderRadius:'5px'
                    }} onClick={() => detailRearing(row.key,row.kandang,row.nama)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={t('general.open')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'deepskyblue',
                        borderRadius:'5px'
                    }} onClick={() => openConfirm(row.key)}><FontAwesomeIcon icon={faLockOpen} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={t("pages.periode.periodeSelesai.button.hapus")}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FF0303',
                        borderRadius:'5px'
                    }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                </Tooltip>
            </Space>
            ),
        },
    ];

    const data = rearingClose.map( ({_id,name,chickInDate,population,populationHpp,chickInWeight,house,strain}, index) => ({
        key: _id,
        id : _id,
        nama : name,
        tanggal : moment(new Date(parseInt(chickInDate))).format("DD-MM-YYYY"),
        kandang : house.warehouseName,
        strain : strain.name,
        populasi  :<NumberFormat value={population} displayType={'text'} thousandSeparator={true}/>,
        populasiHpp  :<NumberFormat value={populationHpp} displayType={'text'} thousandSeparator={true}/>,
        bobot : chickInWeight,
    }));

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize);
    }, []);

    return ( 
        <RearingCloseComponent
            navigate={props.navigate}
            pagination = {pagination}
            changePagination = {changePagination}
            columns = {columns}
            data = {data}
            loading = {loading}
            setLoading = {setLoading}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdPeriode,
    getPeriodeClosing
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(RearingClosePage);
export default page