import React from 'react'
import { Row, Col } from 'antd'
import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)

function FeedUsageChart(props) {
    const { ages, chartData, t } = props

    const data = {
        labels: ages,
        datasets: chartData
    }
    const options = {
        type: 'line',
        animation: false,
        responsive: true,
        data: data,
        options: {
            scales: {
                xAxes: {
                    title: { display: true, text: 'Umur (hari)' },

                },
                yAxes: [{
                    title: { display: true, text: 'Jumlah (kg)' },
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y-axis-2',
                    gridLines: {
                        drawOnArea: false,
                    },
                },
                ]
            },
            interaction: {
                mode: 'nearest',
                intersect: false,
                axis: 'x'
            },
        },
    }

    return (
        <>
            <Row>
                <Col span={1}><h5
                    style={{ transform: 'rotate(270deg)', marginTop: 150, textAlign: 'center' }}>
                    {t('pages.dashboard.feed.qty')}</h5></Col>
                <Col span={23} style={{ marginLeft: 0, marginBottom: 30 }}>
                    <Line
                     data={data}
                     options={options} className='mb-5' height={100} />
                    <h5 style={{ textAlign: 'center' }}> {t('pages.dashboard.feed.age')}</h5>
                </Col>
            </Row>
        </>
    )
}


export default FeedUsageChart