import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import ModalCreatePakan from '../../../../app/dashboard-peternak/master-page/pakan-page/pakan-aksi-page/create-pakan-page'
import ModalEditPakan from '../../../../app/dashboard-peternak/master-page/pakan-page/pakan-aksi-page/edit-pakan-page'

function PakanComponent(props) {
    const { Content } = Layout
    const { columns, data, handleOk, handleCancel, visible, idData, fetchData,
        loading, detail, handleSubmit, setLoading, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.pakan.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreatePakan fetchData={fetchData} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                className="table-dashboard"
                                scroll={{ y: '70vh' }}
                            />
                        </Loading>
                    </Col>
                    <ModalEditPakan visible={visible} handleOk={handleOk} idData={idData}
                        handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

export default PakanComponent