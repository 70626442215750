import React from 'react';
import { Modal,Form,Row,Col,Input,InputNumber } from 'antd';

function EditMasterBiaya(props) {
    const {visible, handleCancel,handleSubmit,data,t} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t('pages.master.biaya.modal.titleEdit')}
            okText="Edit"
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    modifier: 'public',
                    nama : data.name,
                    biaya : data.price,
                }}
            >
                <Row gutter={[16,8]}>
                    <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.biaya.modal.nama')}</span>
                        <Form.Item
                            name="nama" rules={[{ required: true, message:  `${t('pages.master.biaya.modal.msgNama')}` }]}
                        >
                            <Input
                                name='nama'
                                placeholder="Bop Kandang..."
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>  
                    <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.biaya.modal.biaya')}</span>
                        <Form.Item
                            name="biaya" rules={[{ required: true, message: `${t('pages.master.biaya.modal.msgBiaya')}` }]}
                        >
                            <InputNumber
                                name='biaya'
                                placeholder="5000..."
                                className="input-number mt-5"
                                formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/Rp\s?|(,*)/g, '')} 
                            />
                        </Form.Item>
                    </Col>  
                </Row>
            </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default EditMasterBiaya;