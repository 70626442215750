export const getStokOvk = (keyword,limit,skip) => {
    return (
        `query{
            ovkStocks(keyword: "${keyword}", limit:${limit}, skip:${skip}){
                totalCount
                ovkStocks{
                    _id
                    dateStock
                    stockName : name
                    sisaStok
                    ovk{
                        _id
                        code
                        name
                        producer
                        ovkUnitRecording{
                            name
                        }
                        ovkUnit{
                            name
                        }
                        }
                        ovkWarehouse{
                            name
                        }
                    }
                }
            }
    `
    )
}
export const getStokGudangOvk = (id) => {
    return (
        `query{
            ovkStocksWarehouse(warehouseId: "${id}"){
                _id
                dateStock
                stockName : name
                sisaStok
                ovk{
                    _id
                    code
                    name
                    producer
                    ovkUnitRecording{
                        name
                    }
                    ovkUnit{
                        name
                    }
                    }
                    ovkWarehouse{
                        name
                    }
                }
            }
    `
    )
}
export const getSatuanOvk = (_id) => {
    return (
        `query{
            ovk(_id : "${_id}"){
                _id
                name
                ovkUnit{
                    satuan : name
                }
              }
            }
        `
    )
}

export const getSatuanStockOvk = (_id) => {
    return (
        `query{
            ovkStock(_id: "${_id}"){
            _id
                ovk{
                    value:  _id
                    ovkUnit{
                        satuan : name
                    }
                }
            }
        }
        `
    )
}
export const createStokOvk = (value,year) => {
    return {
        query: `mutation{
                createOvkStock(ovkStockInput: {
                    number: ${parseFloat(value.jumlahPengadaan)}
                    name : "${value.namaPengadaan}"
                    ovk: "${value.ovk.value}"
                    ovkWarehouse: "${value.gudangOvk.value}"
                    pricePack : ${parseFloat(value.price)}
                    dateStock : "${year}"
                }){
                    _id
              }
            }
        `
    }
}
export const addStokOvk = (value,year,harga,stockId) => {
    return {
        query: `mutation{
                    createOvkTransaction(ovkTransactionInput:{
                        number: ${parseFloat(value.jumlahStok)}
                        storeTime: "${year}"
                        ovkStock : "${stockId}"
                        priceOvkTransaction : ${parseFloat(harga)}
                        typeTransaction : "In"
                    }){
                        _id
                    }
                }
            `
    }
}
export const deleteTransaksiStokOvk = (_id) => {
    return (
        `mutation{
            deleteOvkTransaction(_id : "${_id}"){
              deleted
            }
          }
      `
    )
}
export const detailStokOvk = (_id) => {
    return (`
        query{
            ovkStock(_id: "${_id}"){
            _id
            name
            ovk{
                value:  _id
                code
            }
            ovkWarehouse{
                value: _id
                name
            }
            }
        }
    `)
}

export const ovkTransaction = (_id) => {
    return (
        `query{
            ovkStock(_id: "${_id}"){
              _id
              ovkTransaction{
                  _id
              }
            }
          }`
    )
}

export const updateStokOvk = (_id,value) => {
    return {
        query : `mutation{
            updateOvkStock(_id:"${_id}",updateOvkStockInput: {
                name: "${value.namaPengadaan}"
                pricePack : ${parseFloat(value.hargaKemasan)}
                ovk: "${value.ovk.value}"
                ovkWarehouse: "${value.gudangOvk.value}"
              }){
              _id
              }
            }
        `
    }
}

export const deleteStokOvk = (_id) => {
    return (
        `mutation{
            deleteOvkStock(_id : "${_id}"){
              deleted
            }
          }
      `
    )
}

export const getDetailTransaksiStok = (keyword,limit,skip,ovkStok,type) => {
    return (
        `query{
            ovkTransactions(keyword: "${keyword}", limit:${limit}, skip:${skip}, ovkStock : "${ovkStok}", typeTransaction : "${type}"){
              totalCount
              ovkTransactions{
                  _id
                  typeTransaction
                  number
                  numberRecording
                  storeTime
                  priceOvkTransaction
              }
            }
          }`
    )
}

export const detailTransaksi = (_id) => {
    return (
        `query{
            ovkTransaction(_id: "${_id}"){
               _id
               number
               numberRecording
               storeTime
               value : typeTransaction
               priceOvkTransaction
               ovkStock {
                   _id
               }
             }
           }`
    )
}

export const updateTransaksiPenggunaan = (_id,date,ovkStock,stok) => {
    return {
        query:
        `mutation{
            updateOvkTransaction( _id:"${_id}" ,updateOvkTransactionInput: {
                number: ${parseFloat(stok)},
                storeTime: "${date}",
                ovkStock : "${ovkStock}",
                typeTransaction: "Out",
                }){
                _id
            }
        }`
    }
}

export const updateTransaksiPenambahan = (_id,value,year,ovkStock,stok) => {
    return {
        query:
        `mutation{
            updateOvkTransaction( _id:"${_id}" ,updateOvkTransactionInput: {
                number: ${parseFloat(stok)},
                storeTime: "${year}",
                ovkStock : "${ovkStock._id}",
                typeTransaction: "In",
                priceOvkTransaction : ${parseFloat(value.harga)}
                }){
                _id
            }
        }`
    }
}
