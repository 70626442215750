import React from 'react'
import { Layout, Breadcrumb, Row, Col, Tabs } from 'antd'
import '../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import ModalCreateRearing from '../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/create-rearing-page'
import RearingActive from '../../../app/dashboard-peternak/rearing-page/rearing-active-page'
import RearingClose from '../../../app/dashboard-peternak/rearing-page/rearing-close-page'

function RearingComponent(props) {
    const { Content } = Layout
    const { TabPane } = Tabs
    const { changeKey, loading, setLoading, activeKey, fetchDataActive, rearingActive, rearingClose, fetchDataClose, locales } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{locales("pages.periode.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateRearing fetchDataActive={fetchDataActive} setLoading={setLoading} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={locales("pages.periode.periodeBerjalan.title")} key="1">
                                    <RearingActive activeKey={activeKey} loading={loading} setLoading={setLoading} rearingActive={rearingActive} fetchDataActive={fetchDataActive}/>
                                </TabPane>
                                <TabPane tab={locales("pages.periode.periodeSelesai.title")} key="2">
                                    <RearingClose activeKey={activeKey} loading={loading} setLoading={setLoading} rearingClose={rearingClose} fetchDataClose={fetchDataClose} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default RearingComponent