export const getStrain = () => {
    return (`
    query{
        strains(keyword:"",limit:10,skip:0){
          totalCount,
          strains{
            _id
            name
          }
        }
      }
    `)
}