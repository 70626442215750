import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditAdjustmentComponent from '../../../../../modules/dashboard-peternak/adjustment-stock-component/ovk-component/ovk-aksi-component/edit-adjustment-ovk-component'

function EditAdjustmentOvkPage(props) {
    const {visible,handleCancel,detail,handleSubmit,satuan,object,t} = props
    const [data,setData] = useState(detail)
    const [hs, setHs] = useState(null)
    const [qty, setQty] = useState(null)
    const [dif, setDif] = useState(null)
    const hsSak = object.filter(d => d?.idOvk === data.ovk?.value)[0]?.hargaPengeluaran
    const qtySak = object.filter(d => d?.idOvk === data?.ovk?.value)[0]?.sisaStok
    const difference = data?.qty-qty


    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
                setHs(hsSak)
                setQty(qtySak)
                setDif(difference)
            }
        }   
        setDetailData()
    }, [detail,hsSak,qtySak,difference])

    return ( 
        <EditAdjustmentComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            object={object}
            data = {data}
            hs={hs}
            qty={qty} 
            dif={dif}
            ovk = {props.ovk}
            satuan = {satuan}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    ovk : state.ovk.dataOvk,
    idMonthlyStockOvk : state.adjustmentOvk.idMonthlyStockOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditAdjustmentOvkPage)
export default page