
export const getMonthlyStockMaterials = (keyword,limit,skip,month,year) => {
    return (
        `query{
            monthlyStockMaterials(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}"){
            totalCount
            locked
            calculateMonthlyStocks{
                _id
                idMaterial
                idMonthlyStockMaterial
                bulan
                tahun
                namaMaterial
                code
        
                stockAwalBulan
                hargaAwalBulan
                totalHargaAwal
                hargaTotalAwalBulan
        
                addStock
                hargaPengadaan
                nilaiPengadaan
        
                outStock
                hargaPengeluaran
                nilaiPengeluaran
        
                sisaStok
                nilaiSisaStok
                hargaTotalPenyesuaian
        
                qty
                price
                total
            }
          }
        }
      `
    )
}