export const getPenyakit = (keyword, limit, skip) => {
    return (
        `query{
            diseases(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              diseases {
                  _id
                  name
              }
            }
          }
      `
    );
}

export const createPenyakit = (value) => {
  return {
    query: `mutation{
            createDisease(diseaseInput: {
                name : "${value.namaPenyakit}"
              }){
                _id
              }
            }
        `
    }
}

export const detailPenyakit = (_id) => {
  return (
    `query{
        disease(_id : "${_id}"){
            _id
            name
          }
        }
    `
  );
}

export const updatePenyakit = (_id, value) => {
    return {
      query: `mutation{
            updateDisease(_id : "${_id}", updateDiseaseInput : {
                name : "${value.namaPenyakit}"
              }){
                _id
              }
            }
      `
    }   
}

export const deletePenyakit = (_id) => {
  return (
    `mutation{
        deleteDisease(_id : "${_id}"){
          deleted
        }
      }
    `
  );
}