import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import EditKandangComponent from '../../../../../modules/dashboard-peternak/master-component/kandang-component/kandang-aksi-component/edit-kandang-component';

function EditKandangPage(props) {
    const {visible,handleCancel,detail,handleSubmit,t} = props;
    const [data,setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData();
    }, [detail]);

    return ( 
        <EditKandangComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            gudangPakan = {props.gudangPakan}
            gudangOvk = {props.gudangOvk}
            gudangMaterial = {props.gudangMaterial}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    gudangPakan : state.gudangPakan.dataGudangPakan,
    gudangOvk : state.gudangOvk.dataGudangOvk,
    gudangMaterial : state.gudangMaterial.dataGudangMaterial,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditKandangPage);
export default page