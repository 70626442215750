import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Loading from '../../../../common/component/loading/loading-container'
import NumberFormat from 'react-number-format'

function TableEnvPage(props) {
    const { envDevice, loading, deviceType, page, onSelectTime, resetSelection, t } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [selectedRowKeys, setRowKeys] = useState([])

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const resetRow = () => {
        setRowKeys([])
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            onSelectTime(selectedRows[0])
            setRowKeys(selectedRowKeys)
        }
    }

    useEffect(() => {
        if (resetSelection) {
            resetRow()
        }
    }, [resetSelection])

    const columns = [
        {
            title: `${t("pages.dashboard.iot.date")}`,
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 170,
            ...getColumnSearchProps('timestamp'),
        },
        {
            title: `${t("pages.dashboard.iot.temperature")}`,
            dataIndex: 'temperature',
            key: 'temperature',
            align: 'center'
        },
        {
            title: `${t("pages.dashboard.iot.humidity")}`,
            dataIndex: 'humidity',
            key: 'humidity',
            align: 'center'
        },
        deviceType === 'Ambient 1.4' ?
            {
                title: `CO2`,
                dataIndex: 'co2',
                key: 'co2',
                align: 'center',
                render: (value, row, index) => (
                    <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                )
            } : { width: 1 },
        deviceType === 'Ambient 1.4' ?
            {
                title: `VOC`,
                dataIndex: 'voc',
                key: 'voc',
                align: 'center',
                render: (value, row, index) => (
                    <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                )
            }
            :
            {
                title: `Amonia`,
                dataIndex: 'amonia',
                key: 'amonia',
                align: 'center'
            }
    ]
    const columnsAnemo = [
        {
            title: `${t("pages.dashboard.iot.date")}`,
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 170,
            ...getColumnSearchProps('timestamp'),
        },
        {
            title: `km/h`,
            dataIndex: 'kph',
            key: 'kph',
            align: 'center'
        },
        {
            title: `m/s`,
            dataIndex: 'ms',
            key: 'ms',
            align: 'center'
        }
    ]

    return (
        <Loading loading={loading}>
            <Table
                rowSelection={page === 'tare' && {
                    type: 'radio',
                    ...rowSelection,
                }}
                columns={deviceType === 'Anemometer' ? columnsAnemo : columns} bordered
                dataSource={envDevice}
                pagination={false}
                className="table-dashboard"
                scroll={page === 'tare' ? { y: 100 } : { y: 296 }}
            />
        </Loading>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TableEnvPage)
export default page