import React from 'react'
import { Modal, Form, Row, Col, Input, Select } from 'antd'

function EditPetugas(props) {
    const { TextArea } = Input
    const { Option } = Select
    const { visible, handleCancel, handleSubmit, data, kandang,  t } = props

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.pengguna.modal.editPetugas')}
                okText="Edit"
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        _id: data._id,
                        namaPetugas: data.name,
                        username: data.username,
                        alamat: data.address,
                        email: data.email,
                        nomorTelefon: data.phone,
                        kandang: data.accessshouse?.map(d => d._id),
                        role: data,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.pengguna.modal.nama')}</span>
                            <Form.Item
                                name="namaPetugas" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='namaPetugas'
                                    placeholder={t('pages.master.pengguna.modal.phNama')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">Username</span>
                            <Form.Item
                                name="username" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgUsername')}` }]}
                            >
                                <Input
                                    name='username'
                                    placeholder={t('pages.master.pengguna.modal.phUsername')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">Email</span>
                            <Form.Item
                                name="email" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgEmail')}` }]}
                            >
                                <Input
                                    name='email'
                                    placeholder={t('pages.master.pengguna.modal.phEmail')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.pengguna.modal.telepon')}</span>
                            <Form.Item
                                name="nomorTelefon" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgTel')}` }]}
                            >
                                <Input
                                    name='nomorTelefon'
                                    placeholder="+62..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.pengguna.modal.kandang')}</span>
                            <Form.Item
                                name="kandang" 
                                rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgKandang')}` }]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    placeholder={t('pages.master.pengguna.modal.phKandang')}
                                    onChange={(e) => console.log('test', e)}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        kandang.map(d =>
                                            <Option
                                                key={d.name.toString()}
                                                value={d._id}
                                            >{d.name}</Option>

                                        )
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">Role</span>
                            <Form.Item
                                name="role" rules={[{ required: true, message: 'Silahkan pilih role!' }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih Jenis Role"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    <Option key="management" value="management">{t('pages.master.pengguna.titleManajemen')}</Option>
                                    <Option key="officerhouse" value="officerhouse">{t('pages.master.pengguna.titlePetugas')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.pengguna.modal.alamat')}</span>
                            <Form.Item
                                name="alamat" rules={[{ required: true, message: `${t('pages.master.pengguna.modal.msgAlamat')}` }]}
                            >
                                <TextArea
                                    name="alamat"
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t('pages.master.pengguna.modal.phAlamat')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        )
    }
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

export default EditPetugas