import React, { useState } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { navigate } from '../../../../common/store/action'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'

import Highlighter from 'react-highlight-words'
import { Space, Button, Input } from 'antd'
import { updateHpp } from '../../rearing-record-page/rearing-record-aksi-page/query-rearing-record'
import { SearchOutlined } from '@ant-design/icons'
import TableRearingComponent from '../../../../modules/dashboard-peternak/dashboard-component/dashboard-content-component/table-rearing-component'
import { useTranslation } from "react-i18next"
import moment from 'moment'

function RearingActivePage(props) {
    const { rearingActive, fetchDataActive, setLoading, onPeriode } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loadingHpp, setLoadingHpp] = useState(false)
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }
    const updateData = (idPeriode, cage, period) => {
        setLoadingHpp(true)
        API.post(updateHpp(idPeriode))
            .then(res => {
                if (res.data.data.updateHppRearing === null) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoadingHpp(false)
                }
                else if (res.data.data.updateHppRearing._id) {
                    validationMessage('success', `Berhasil memperbarui HPP ${cage} ${period}`)
                    fetchDataActive()
                    setLoadingHpp(false)
                }
            }).catch((error) => {
                console.log('err', error)
                validationMessage('error', `Gagal memperbarui HPP`)
                setLoadingHpp(false)
            })
    }

    const handleUpdate = (idPeriode, cage, period) => {
        updateData(idPeriode, cage, period)
    }

    const data = rearingActive.map(({ _id, name, house, fcr, age, totalBw, hppEkor, saldo, hpp, updatedAt }, index) => ({
        key: _id,
        id: _id,
        nama: name,
        umur: age,
        sisa: parseFloat(saldo.toFixed(0)),
        fcr: fcr.toFixed(2),
        bw: <NumberFormat value={totalBw} displayType={'text'} thousandSeparator={true} />,
        kandang: house.warehouseName,
        idKandang: house.houseId,
        hppEkor: <NumberFormat value={hppEkor.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
        hpp: <NumberFormat value={hpp?.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
        saldo: <NumberFormat value={saldo.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
        updatedAt: moment(updatedAt, 'DD/MM/YYYY hh:mm:ss').add(7, 'h').format('LLL')
    }))
    const columns = [
        {
            title: `${t("pages.periode.periodeBerjalan.table.kandang")}`,
            dataIndex: 'kandang',
            key: 'kandang',
            width: 150,
            sorter: (a, b) => a.kandang.localeCompare(b.kandang, 'en', { numeric: true }),
            defaultSortOrder: 'ascend',
            ...getColumnSearchProps('kandang', `${t("pages.periode.periodeBerjalan.table.kandang")}`),
            render: (value, row, index) => (
                <a href={() => false} className='link-periode'
                    onClick={(e) => {
                        e.preventDefault()
                        onPeriode(row.key, row.nama, row.kandang)
                    }
                    }>{row.kandang}</a>
            )
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.periode")}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama', `${t("pages.periode.periodeBerjalan.table.periode")}`),
            align: 'center',
        },
        {
            title: `Sisa ayam`,
            dataIndex: 'saldo',
            className: 'right',
            key: 'saldo',
            width: 80
        },
        {
            title: `${t("pages.periode.periodeBerjalan.table.umur")}`,
            dataIndex: 'umur',
            className: 'right',
            key: 'umur',
            width: 70
        },
        {
            title: 'BW (Gram)',
            dataIndex: 'bw',
            className: 'right',
            key: 'bw',
            width: 90
        },
        {
            title: 'FCR',
            dataIndex: 'fcr',
            className: 'right',
            key: 'fcr',
            width: 90
        },
        {
            title: 'HPP/Ekor',
            dataIndex: 'hppEkor',
            className: 'right',
            key: 'hppEkor',
        },
        {
            title: 'HPP/Kg',
            dataIndex: 'hpp',
            className: 'right',
            key: 'hpp',
        },
        {
            title: 'Terakhir diperbarui',
            dataIndex: 'updatedAt',
            className: 'center',
            key: 'updatedAt',
        },
        {
            title: 'Update HPP',
            dataIndex: 'action',
            align: 'center',
            render: (value, row, index) => (
                <Button
                    style={{ borderRadius: 10, backgroundColor: '#008ad4', color: 'white' }}
                    onClick={() => handleUpdate(row.key, row.kandang, row.nama)}
                >Update</Button>
            )
        },
    ]

    return (
        <TableRearingComponent
            navigate={props.navigate}
            columns={columns}
            data={data}
            loading={loadingHpp}
            fetchDataActive={fetchDataActive}
            setLoading={setLoading}
        />
    )
}

const mapStateToProps = state => ({
    idRearing: state.layout.idRearing
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RearingActivePage)
export default page
