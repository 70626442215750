import React,{useState,useCallback,useEffect} from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../common/api';
import { navigate } from '../../../../common/store/action';
import PeriodeComponent from '../../../../modules/dashboard-peternak/stock-flow-component/periode-component/periode-component';

import {getPeriodeStockFlowFeed,getPeriodeStockFlowLiveBird,getPeriodeStockFlowMaterial,getPeriodeStockFlowOvk,getListPeriodeFeed,getListPeriodeOvk, getListPeriodeMaterial,getListPeriodeLiveBird} from './periode-aksi-page/query-periode';

/*Import Moment Js */
import moment from 'moment-timezone';
import 'moment-timezone';
import 'moment/locale/id';
/*End Import Moment Js */

import {useTranslation} from 'react-i18next'

function PeriodePage(props) {
    const [activeKey, setActiveKey] = useState("1");
    const [loading, setLoading] = useState(false);
    const [liveBird, setLiveBird] = useState([]);
    const [material, setMaterial] = useState([]);
    const [ovk, setOvk] = useState([]);
    const [pakan, setPakan] = useState([]);
    const [yearPakan, setYearPakan] = useState(moment().format('YYYY'));
    const [yearRangePakan,setYearRangePakan] = useState ([]);
    const [yearOvk, setYearOvk] = useState(moment().format('YYYY'));
    const [yearRangeOvk,setYearRangeOvk] = useState ([]);
    const [yearMaterial, setYearMaterial] = useState(moment().format('YYYY'));
    const [yearRangeMaterial,setYearRangeMaterial] = useState ([]);
    const [yearLiveBird, setYearLiveBird] = useState(moment().format('YYYY'));
    const [yearRangeLiveBird,setYearRangeLiveBird] = useState ([]);

    const {t} = useTranslation()

    const changeKey = useCallback((value) => {
        setActiveKey(value);
    }, []); 

    /**Handle get data */
    const fetchDataLiveBird = (year) => {
        setLoading(true);
        API.get(getPeriodeStockFlowLiveBird('',0,0,year))
        .then(res => {
            setLiveBird(res.data.data.listMonthlyStockLiveBirds.monthlyStockLiveBirds);
            setLoading(false);
        })
    }

    const fetchDataMaterial = (year) => {
        setLoading(true);
        API.get(getPeriodeStockFlowMaterial('',0,0,year))
        .then(res => {
            setMaterial(res.data.data.listMonthlyStockMaterials.monthlyStockMaterials);
            setLoading(false);
        })
    }

    const fetchDataPakan = (year) => {
        setLoading(true);
        API.get(getPeriodeStockFlowFeed('',0,0,year))
        .then(res => {
            setPakan(res.data.data.listMonthlyStockFeeds.monthlyStockFeeds);
            setLoading(false);
        })
    }

    const fetchDataOvk = (year) => {
        setLoading(true);
        API.get(getPeriodeStockFlowOvk('',0,0,year))
        .then(res => {
            setOvk(res.data.data.listMonthlyStockOvks.monthlyStockOvks);
            setLoading(false);
        })
    }

    const fecthListYearPakan = () => {
        API.get(getListPeriodeFeed('',0,0))
        .then(res => {
            if(res.data.data.listYearStockFlowFeeds.totalCount === 0){
                let yearOptions = [];
                let minYears =  moment().format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangePakan(yearOptions)
            }else{
                let yearOptions = [];
                let minYears = moment( new Date(res.data.data.listYearStockFlowFeeds.listYearStockFlowFeeds[0].year)).format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangePakan(yearOptions)
            }
        })
    }

    const fecthListYearOvk = () => {
        API.get(getListPeriodeOvk('',0,0))
        .then(res => {
            if(res.data.data.listYearStockFlowOvks.totalCount === 0){
                let yearOptions = [];
                let minYears =  moment().format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangePakan(yearOptions)
            }else{
                let yearOptions = [];
                let minYears = moment( new Date(res.data.data.listYearStockFlowOvks.listYearStockFlowOvks[0].year)).format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangeOvk(yearOptions)
            }
        })
    }

    const fecthListYearMaterial = () => {
        API.get(getListPeriodeMaterial('',0,0))
        .then(res => {
            if(res.data.data.listYearStockFlowMaterials.totalCount === 0){
                let yearOptions = [];
                let minYears =  moment().format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangePakan(yearOptions)
            }else{
                let yearOptions = [];
                let minYears = moment( new Date(res.data.data.listYearStockFlowMaterials.listYearStockFlowMaterials[0].year)).format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangeMaterial(yearOptions)
            }
        })
    }

    const fecthListYearLiveBird = () => {
        API.get(getListPeriodeLiveBird('',0,0))
        .then(res => {
            if(res.data.data.listYearStockFlowLiveBirds.totalCount === 0){
                let yearOptions = [];
                let minYears =  moment().format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangePakan(yearOptions)
            }else{
                let yearOptions = [];
                let minYears = moment( new Date(res.data.data.listYearStockFlowLiveBirds.listYearStockFlowLiveBirds[0].year)).format('YYYY');
                
                let maxYears = moment().format('YYYY');
                for(;minYears<=maxYears;minYears++){
                    yearOptions.push(minYears)
                }
                setYearRangeLiveBird(yearOptions)
            }
        })
    }

    useEffect(() => {
        if(activeKey === "1"){
            fetchDataPakan(yearPakan);
            fecthListYearPakan();
        }else if(activeKey === "4"){
            fetchDataLiveBird(yearLiveBird);
            fecthListYearLiveBird();
        }else if(activeKey === "2"){
            fetchDataOvk(yearOvk);
            fecthListYearOvk(yearLiveBird);
        }else if(activeKey === "3"){
            fetchDataMaterial(yearMaterial);
            fecthListYearMaterial();
        }
    }, [activeKey,yearPakan,yearOvk,yearMaterial,yearLiveBird]);
  
    return ( 
        <PeriodeComponent
            navigate={props.navigate}
            loading = {loading}
            activeKey ={activeKey}
            setLoading = {setLoading}
            changeKey  = {changeKey}
            ovk = {ovk}
            pakan = {pakan}
            fetchDataPakan = {fetchDataPakan}
            fetchDataOvk = {fetchDataOvk}
            fetchDataMaterial = {fetchDataMaterial}
            fetchDataLiveBird = {fetchDataLiveBird}
            material = {material}
            liveBird = {liveBird}
            yearRangePakan = {yearRangePakan}
            yearPakan = {yearPakan}
            setYearPakan = {setYearPakan}
            setYearOvk = {setYearOvk}
            yearOvk = {yearOvk}
            yearRangeOvk = {yearRangeOvk}
            yearMaterial = {yearMaterial}
            yearRangeMaterial = {yearRangeMaterial}
            setYearMaterial = {setYearMaterial}
            yearLiveBird = {yearLiveBird}
            yearRangeLiveBird = {yearRangeLiveBird}
            setYearLiveBird = {setYearLiveBird}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(PeriodePage);
export default page