import React from 'react'
import '../assets/scss/App.scss'
//package list
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
//component list
import { store, persistor, browserHistory } from './../common/store'
import Router from '../common/router/router'


function App() {

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={browserHistory} />
        </PersistGate>
      </Provider>
    )
}

export default App
