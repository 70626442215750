import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import StandarComponent from '../../../../modules/dashboard-peternak/master-component/standar-component/standar-component'
import { getStandar } from './standar-aksi-page/query-standar-page'
import { API } from '../../../../common/api'
import { useTranslation } from 'react-i18next'

function StandarPage(props) {
    const { strain } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [object, setObject] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = (strain) => {
        setLoading(true);
        API.get(getStandar("", 0, 0, strain))
            .then(res => {
                if (res.data.data.standards.standards) {
                    setObject(res.data.data.standards.standards)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(strain)
    }, [strain])

    const data = object.map(({ _id, day, bw, dg, adg, fcr, dep, fi }, index) => ({
        key: _id,
        id: _id,
        hari: day,
        bw: bw,
        dg: dg === null ? '-' : dg,
        adg: adg === null ? '-' : adg,
        fcr: fcr === null ? '-' : fcr,
        deplesi: dep === null ? '-' : dep,
        fi : fi
    }))

    const columns = [
        {
            title: `${t('pages.master.strain.detail.table.hari')}`,
            dataIndex: 'hari',
            key: 'hari',
            ...getColumnSearchProps('hari', `${t('pages.master.strain.detail.table.hari')}`),
        },
        {
            title: 'BW (Gr)',
            dataIndex: 'bw',
            key: 'bw',
            ...getColumnSearchProps('bw', 'BW (gr)'),
        },
        {
            title: 'Dg (Gr)',
            dataIndex: 'dg',
            key: 'dg',
            ...getColumnSearchProps('dg', 'DG (gr)'),
        },
        {
            title: 'Adg (Gr)',
            dataIndex: 'adg',
            key: 'adg',
            ...getColumnSearchProps('adg', 'Adg (gr)'),
        },
        {
            title: 'Fcr (Gr)',
            dataIndex: 'fcr',
            key: 'fcr',
            ...getColumnSearchProps('fcr', 'Fcr (gr)'),
        },
        {
            title: `${t('pages.master.strain.detail.table.deplesi')}`,
            dataIndex: 'deplesi',
            key: 'deplesi',
            ...getColumnSearchProps('deplesi', `${t('pages.master.strain.detail.table.deplesi')}`),
        },
        {
            title: `FI`,
            dataIndex: 'fi',
            key: 'fi',
        }
    ]

    return (
        <StandarComponent
            navigate={props.navigate}
            columns={columns}
            data={data}
            loading={loading}
            setLoading={setLoading}
            visible={visible}
            setVisible={setVisible}
            fetchData={fetchData}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    strain: state.strain.idStrain,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StandarPage)
export default page