import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd'
import '../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import Loading from '../../../common/component/loading/loading-container'
import ModalCreateRecordingPemeliharaan from '../../../app/dashboard-peternak/rearing-record-page/rearing-record-aksi-page/create-rearing-record-aksi-page';

function RearingRecordComponent(props) {
    const { Content } = Layout
    const { columns, data, fetchData, loading, setLoading, locales, fetchDate, location, periode, house } = props
    return (
        <>
            {location.pathname === '/peternak/recording' ?
                <Layout>
                    <Content className="dashboard-container">

                        <Row className="dashboard-section">
                            <Col span={12}>
                                <Breadcrumb separator=">">
                                    <Breadcrumb.Item><span>{locales("pages.recording.title")}</span></Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                            <Col span={12}>
                                <ModalCreateRecordingPemeliharaan fetchData={fetchData} fetchDate={fetchDate} setLoading={setLoading} 
                                periode={periode} house={house}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24} md={24} sm={24}>
                                <Loading loading={loading}>
                                    <Table
                                        columns={columns}
                                        dataSource={data}
                                        bordered
                                        pagination={false}
                                        className="table-rearing-record"
                                        scroll={{ x: 2000, y: '70vh' }}
                                    />
                                </Loading>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
                :
                <Loading loading={loading}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        bordered
                        pagination={false}
                        className="table-rearing-record"
                        scroll={{ x: 2000, y: '70vh' }}
                    />
                </Loading>
            }
        </>
    )
}

export default RearingRecordComponent