import React from 'react'
import { Layout, Row, Col, Table } from 'antd'
import Loading from '../../../../../common/component/loading/loading-container'
import ModalEditPenambahanStokOvk from '../../../../../app/dashboard-peternak/stok-page/stok-ovk-page/transaksi-stok-ovk-page/edit-penambahan-stok-ovk-page'  

function PenambahanStokPakanComponent(props) {
    const { columns, data, loading, detail, visible,
        idData, handleSubmit, handleCancel, satuanOvk, t } = props
    return (
        <Layout style={{ backgroundColor: "white" }}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={false}
                            scroll={{ y: '70vh' }}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
                <ModalEditPenambahanStokOvk visible={visible} handleSubmit={handleSubmit} idData={idData}
                    handleCancel={handleCancel} detail={detail} satuanOvk={satuanOvk} t={t} />
            </Row>
        </Layout>
    )
}

export default PenambahanStokPakanComponent