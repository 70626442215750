import React, { useState,useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditPetugasComponent from '../../../../../modules/dashboard-peternak/master-component/petugas-component/petugas-aksi-component/edit-petugas-component'

function EditPetugasPage(props) {
    const {visible,handleCancel,detail,handleSubmit,t} = props
    const [data,setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData()
    }, [detail])
    
    return ( 
        <EditPetugasComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            kandang = {props.kandang} t={t}
        />
    )
}

const mapStateToProps = state => ({
    kandang : state.layout.dataKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPetugasPage)
export default page