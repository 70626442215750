import React from 'react'
import { Tooltip, Typography, Row, Col, Card, Form, Button, List, Divider, Upload, Breadcrumb, Layout } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../../../common/component/loading/loading-container'

function CreatePenyakitComponent(props) {
  const { detailFoto, t, hapusConfirm, handleSubmit, age,
    loading, dummyRequest, propsPicture, onPreview, fileList } = props
  const { Paragraph } = Typography
  const { Content } = Layout

  return (
    <Layout>
      <Content className="dashboard-container">
        <Row className="dashboard-section">
          <Breadcrumb separator=">">
            <Breadcrumb.Item><Link to="/peternak/recording"><span>{t("pages.recording.title")}</span></Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/peternak/recording/detail"><span>{t("pages.recording.detail.title")}{age}</span></Link></Breadcrumb.Item>
            <Breadcrumb.Item><span>{t("pages.recording.detail.penyakit.edit.title")}</span></Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Loading loading={loading}>
          <Card>
            <List
              dataSource={detailFoto}
              grid={{ gutter: 16, column: 4 }}
              renderItem={item => (
                <List.Item>
                  <div className='ant-card ant-card-bordered'>
                    <div className='ant-card-body' style={{ padding: 0 }}>
                      <div className='ant-image'>
                        <img className='ant-image-img'
                          alt={item.originalName}
                          src={item.linkPicture}
                          style={{ objectFit: "cover", height: 150 }}
                        />
                      </div>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    <Row gutter={16} style={{ padding: 5 }}>
                      <Col span={12}>
                        <Tooltip placement="top" title={item.originalName}>
                          <Paragraph ellipsis={{ rows: 1, expandable: false }}>{item.originalName}</Paragraph>
                        </Tooltip>
                      </Col>
                      <Col span={12}>
                          <Button style={{
                            color: 'white',
                            backgroundColor: '#30c758',
                            borderRadius: '5px'
                          }}
                            onClick={() => window.open(item.linkPicture,'_blank')}
                          ><FontAwesomeIcon icon={faEye} /></Button>
                        <Tooltip placement="top" title={t("general.del")}>
                          <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                          }}
                            onClick={() => hapusConfirm(item._id)}
                          ><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </div>
                </List.Item>
              )}
            />
            <Divider />
            <Form onFinish={handleSubmit}>
              <Form.Item name="fotoPenyakit" style={{ marginBottom: 0 }} rules={[{ required: true, message: `Masukkan minimal 1 gambar!` }]}>
                <Upload {...propsPicture}
                  customRequest={dummyRequest}
                  fileList={fileList}
                  listType="picture-card"
                  onPreview={onPreview}
                >
                  {fileList.length < 5 && '+ Upload'}
                </Upload>
              </Form.Item>
              <Button
                style={{ marginLeft: 8, marginTop: 0 }}
                type="primary"
                htmlType="submit"
              >
                {t('general.create')}
              </Button>
            </Form>
          </Card>
        </Loading>
      </Content>
    </Layout>
  )
}

export default CreatePenyakitComponent