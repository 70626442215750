import React from 'react';
import { Layout, Row, Col, Form, Carousel, Select } from 'antd';
import InputAuth from '../../common/component/input/input-auth';
import Loading from '../../common/component/loading/loading-container';
import ButtonLogin from '../../common/component/button/button-not-icon';
import { Link } from 'react-router-dom';

function ForgotPassword(props) {
    const { Content } = Layout;
    const { Option } = Select;
    const { email, handleSubmit, handleFailed, setEmail, loading, handleLocales, lang, t } = props;
    const logo = require(`../../assets/images/logo_horizontal_hq.svg`);
    const slide1 = require(`../../assets/svg/chicken-1.svg`);
    const slide2 = require(`../../assets/svg/chicken-2.svg`);
    const slide3 = require(`../../assets/svg/chicken-3.svg`);
    return (
        <Layout>
            <Content className="landing-container">
                <Loading loading={loading}>
                    <div className="section-header-page-avatar" style={{ marginTop: '.5rem' }}>
                        <Select defaultValue={lang} bordered={false} onChange={(e) => handleLocales(e)}>
                            <Option value="id">IND</Option>
                            <Option value="en">EN</Option>
                        </Select>
                    </div>
                    <Row>
                        <Col lg={12} md={24} sm={24}>
                            <div className="section-picture-password">
                                <div className="section-picture-center">
                                    <Carousel autoplay style={{ padding: '30px' }}>
                                        <div>
                                            <h3 className='caraousel'><img src={slide1} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide2} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide3} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={24} sm={24}>
                            <div className="section-content-password">
                                <Row>
                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                        <div className="section-logo-password">
                                            <Link to="/login"><img src={logo} alt="BroilerX logo" width="40%" /></Link>
                                        </div>
                                    </Col>
                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                        <div className="section-title-password">
                                            <span className="font-title-password black font-bold">{t('pages.auth.forgetTitle')}</span>
                                            <br />
                                            <span className="font-title-medium black font-light">{t('pages.auth.forgetText')}</span>
                                        </div>
                                    </Col>
                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                        <div className="section-form-password">
                                            <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="basic" initialValues={{ remember: true }}>
                                                <Row>
                                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                        <span className="font-semi-bold black font-title-medium">Email</span>
                                                        <Form.Item
                                                            name="email" rules={[{ required: true, message: `${t('pages.auth.msgEmail')}` }]}
                                                        >
                                                            <InputAuth
                                                                name='email'
                                                                placeholder={t('pages.auth.phEmail')}
                                                                onChange={setEmail}
                                                                value={email}
                                                                className="input-auth mt-5"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                        <div className="section-form-button-password">
                                                            <Form.Item>
                                                                <ButtonLogin
                                                                    text={t('pages.auth.send')}
                                                                    height={10}
                                                                    backgroundColor="#008ad4"
                                                                    borderRadius="10px"
                                                                    className="font-semi-bold font-title-medium button-login"
                                                                    textColor="white"
                                                                    htmlType="submit"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    );
}

export default ForgotPassword;