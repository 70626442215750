import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, InputNumber, Input, Typography, DatePicker, Button, Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FieldTimeOutlined } from '@ant-design/icons'
import moment from 'moment'

import { API } from '../../../../common/api'
import { errMessage } from '../../../../common/component/notification/notification'
import { createTare, latestEnv } from './query-iot'
import { getEnvDevice } from '../../dashboard-page/query-dashboard-page'
import TableEnv from '../../dashboard-page/dashboard-content-page/table-env'

function CreateTare(props) {
    const { fetchData, setLoading, visible, setVisible, idDevice, deviceId, deviceType, deviceName, t } = props
    const [form] = Form.useForm()
    const { Text } = Typography
    const [data, setData] = useState([])
    const [load, setLoad] = useState(false)
    const [range, setRange] = useState([moment().add(-2, 'hours'), moment()])
    const [type, setType] = useState('auto')

    const postData = (values) => {
        API.post(createTare(values, idDevice))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.monitor.resAddTareFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createTare._id) {
                    errMessage('success', `${t('pages.monitor.resAddTareSuccess')}`)
                    form.resetFields()
                    setType('auto')
                    fetchData()
                    setVisible(false)
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.monitor.resAddTareFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setLoading(true)
    }

    const fetchLatest = () => {
        setLoad(true)
        API.get(latestEnv(deviceId))
            .then(res => {
                if (res.data.errors) {
                    console.log(res.data.errors[0].message)
                    setLoad(false)
                } else if (res.data.data.latestEnvDevice.ts) {
                    
                    const value = res.data.data.latestEnvDevice
                    form.setFieldsValue({
                        time: moment(value.ts).format('YYYY/MM/DD HH:mm:ss'),
                        currentTemperature: value.temperature,
                        currentHumidity: value.humidity,
                        currentCo2: value.co2,
                        currentWindSpeed: value.kph,
                    })
                    setLoad(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setLoad(false)
            })
    }

    const fetchDataEnv = async (deviceId, range) => {
        setLoad(true)
        await API.get(getEnvDevice(deviceId, range[0], range[1]))
            .then(res => {
                if(res.data.errors){
                    console.log('err env ', res.data.errors[0].message)
                    setLoad(false)
                } else
                if (res.data.data.envDevice !== null) {
                    setData(res.data.data.envDevice.envDevice)
                    setLoad(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setData([])
                setLoad(false)
            })
    }

    const suhu = data.map(({ voc, amonia, humidity, temperature, co2, kph, ms, ts, timestamp }, index) => ({
        key: index,
        ts: new Date(ts),
        timestamp: timestamp,
        voc: voc,
        co2: co2,
        amonia: amonia,
        humidity: humidity,
        temperature: temperature,
        kph: kph,
        ms: ms,
    }))

    useEffect(() => {
        fetchDataEnv(deviceId, range)
        if (deviceId && type === 'auto') {
            fetchLatest()
        }
        // eslint-disable-next-line 
    }, [deviceId, range, type])

    const onSelectTime = (value) => {
        form.setFieldsValue({
            time: moment(new Date(value.ts)).utc(moment().utcOffset()).locale('id').format('YYYY/MM/DD HH:mm:ss'),
            currentTemperature: value.temperature,
            currentHumidity: value.humidity,
            currentCo2: value.co2,
            currentWindSpeed: value.ms,
        })
    }

    const onChangeType = value => {
        if (value === 'auto') {
            setType('auto')
        } else {
            setType('manual')
            form.resetFields()
        }
    }

    return (
        <Modal
            visible={visible}
            title={`${t('pages.monitor.addTare')} ${deviceName}`}
            width={800} centered
            closable={false}
            footer={null}
        >
            <Radio.Group defaultValue="auto" buttonStyle="solid"
                className='mb-20'
                value={type}
                onChange={e => onChangeType(e.target.value)}>
                <Radio.Button value="auto">{t('pages.monitor.latest')}</Radio.Button>
                <Radio.Button value="manual">{t('pages.monitor.manual')}</Radio.Button>
            </Radio.Group>
            <div hidden={type === 'auto' ? true : false}>
                <DatePicker.RangePicker showTime style={{ width: '100%' }}
                    onChange={e => setRange(e)} value={range} defaultValue={range} allowClear={false}
                />
                <TableEnv envDevice={suhu} t={t} loading={load} deviceType={deviceType} page='tare' onSelectTime={onSelectTime} resetSelection={type === 'auto'} />
            </div>
            <Form
                form={form} className='mt-20 pb-30'
                layout="vertical"
                initialValues={{
                    currentWeight: null,
                    temperatureFromComparedDevice: null,
                    humidityFromComparedDevice: null,
                    co2FromComparedDevice: null,
                    windSpeedFromComparedDevice: null,
                    weightFromComparedDevice: null,
                    otherInformation: ''
                }}
            >
                <Row justify="space-around" align="middle">
                    <Col span={5}>
                        <FieldTimeOutlined /><Text className='ml-10 font-semi-bold'>{t('pages.monitor.tareTime')}</Text>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="time" className='mb-0'
                        >
                            <Input bordered={false} readOnly />
                        </Form.Item>
                    </Col>
                    <Col span={13}></Col>
                </Row>
                <Row gutter={[8, 8]} justify="center">
                    <Col span={4} offset={4}>
                        <Text className='font-semi-bold'>{t('pages.monitor.current')}</Text>
                    </Col>
                    <Col span={6} offset={4}>
                        <Text className='font-semi-bold'>{t('pages.monitor.tare')}</Text>
                    </Col>
                    <Col span={4}></Col>
                </Row>

                <Row gutter={[8, 8]} justify="space-around" align="middle">
                    <Col span={4}>
                        <Text className='font-semi-bold'>{t('pages.monitor.temp')}</Text>
                    </Col>
                    <Col span={4}>
                        <Form.Item className='mb-0'
                            name="currentTemperature"
                        >
                            <InputNumber bordered={false} style={{ width: '100%' }}
                                formatter={value => `${value} °C`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/°C\s?|(,*)/g, '')} readOnly
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2} className=''>
                        <FontAwesomeIcon icon={faLongArrowAltRight} size='2x' />
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="temperatureFromComparedDevice" className='mb-0'
                        >
                            <Input suffix="°C"
                                className="input-form" />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <Row gutter={[8, 8]} justify="space-around" align="middle" className='mt-10'>
                    <Col span={4}>
                        <Text className="font-semi-bold">{t('pages.monitor.humidity')}</Text>
                    </Col>
                    <Col span={4}>
                        <Form.Item className='mb-0'
                            name="currentHumidity"
                        >
                            <InputNumber bordered={false} style={{ width: '100%' }}
                                formatter={value => `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/%\s?|(,*)/g, '')} readOnly
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2} className=''>
                        <FontAwesomeIcon icon={faLongArrowAltRight} size='2x' />
                    </Col>
                    <Col span={6}>
                        <Form.Item className='mb-0'
                            name="humidityFromComparedDevice"
                        >
                            <Input suffix="%"
                                className="input-form" />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <Row gutter={[8, 8]} justify="space-around" align="middle" className='mt-10'>
                    <Col span={4}>
                        <Text className="font-semi-bold">CO₂</Text>
                    </Col>
                    <Col span={4}>
                        <Form.Item className='mb-0'
                            name="currentCo2"
                        >
                            <InputNumber bordered={false} style={{ width: '100%' }}
                                formatter={value => `${value} ppm`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/ppm\s?|(,*)/g, '')} readOnly
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2} className=''>
                        <FontAwesomeIcon icon={faLongArrowAltRight} size='2x' />
                    </Col>
                    <Col span={6}>
                        <Form.Item className='mb-0'
                            name="co2FromComparedDevice"
                        >
                            <Input suffix="ppm"
                                className="input-form" />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <Row gutter={[8, 8]} justify="space-around" align="middle" className='mt-10'>
                    <Col span={4}>
                        <Text className="font-semi-bold">{t('pages.monitor.windSpeed')}</Text>
                    </Col>
                    <Col span={4}>
                        <Form.Item className='mb-0'
                            name="currentWindSpeed"
                        >
                            <InputNumber bordered={false} style={{ width: '100%' }}
                                formatter={value => `${value} m/s`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(`m/s`, '')} readOnly
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2} className=''>
                        <FontAwesomeIcon icon={faLongArrowAltRight} size='2x' />
                    </Col>
                    <Col span={6}>
                        <Form.Item className='mb-0'
                            name="windSpeedFromComparedDevice"
                        >
                            <Input suffix="m/s"
                                className="input-form" />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </Row>

                <Row gutter={[8, 8]} justify="space-around" align="middle" className='mt-10'>
                    <Col span={4}>
                        <Text className="font-semi-bold">{t('pages.monitor.weight')}</Text>
                    </Col>
                    <Col span={4}>
                        <Form.Item className='mb-0'
                            name="currentWeight"
                        >
                            <InputNumber bordered={false} style={{ width: '100%' }}
                                formatter={value => `${value} gram`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/gram\s?|(,*)/g, '')} readOnly
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2} className=''>
                        <FontAwesomeIcon icon={faLongArrowAltRight} size='2x' />
                    </Col>
                    <Col span={6}>
                        <Form.Item className='mb-0'
                            name="weightFromComparedDevice"
                        >
                            <Input suffix="gram"
                                className="input-form" />
                        </Form.Item>
                    </Col>
                    <Col span={6}></Col>
                </Row>
                <div className="font-semi-bold mt-20">{t('pages.monitor.desc')}</div>
                <Form.Item className=''
                    name="otherInformation"
                >
                    <Input className="input-form" placeholder={t('pages.monitor.phDesc')} />
                </Form.Item>
                <Form.Item shouldUpdate className='float-right'>
                    {() => (
                        <>
                            <Button onClick={() => { setVisible(false) }}>
                                {t('general.cancel')}
                            </Button>
                            <Button type="primary" className='ml-10'
                                disabled={form.getFieldValue(['time']) && (
                                    ~~form.getFieldValue(['temperatureFromComparedDevice']) !== 0 || ~~form.getFieldValue(['humidityFromComparedDevice']) !== 0 ||
                                    ~~form.getFieldValue(['co2FromComparedDevice']) !== 0 || ~~form.getFieldValue(['windSpeedFromComparedDevice']) !== 0 ||
                                    ~~form.getFieldValue(['weightFromComparedDevice']) !== 0)
                                    ? false : true}
                                onClick={() => {
                                    form
                                        .validateFields()
                                        .then((values) => {
                                            handleSubmit(values)
                                            form.resetFields()
                                        })
                                        .catch((info) => {
                                            console.log('Validate Failed:', info)
                                        })
                                }
                                }>
                                {t('general.add')}
                            </Button>
                        </>
                    )}
                </Form.Item>
            </Form>
        </Modal >
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateTare)
export default page