import * as actionType from './adjustment-ovk-action-type';

const initialState = {
    idMonthlyStockOvk : null,
    loading : false,
}

const handler = (currentState) => {
    const setDataMonthlyStock = {
        startGetMonthlyStockOvks : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetMonthlyStockOvks : () => ({
            ...currentState,
            loading: false,
        }),

        setIdMonthlyStockOvk : data => ({
            ...currentState,
            idMonthlyStockOvk: data
        }),
    }

    return {
        ...setDataMonthlyStock
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetMonthlyStockOvks();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetMonthlyStockOvks();
        case actionType.SET_ID_MONTHLY_STOCK_OVK:
            return handler(state).setIdMonthlyStockOvk(payload);

        default:
            return state;
    }
}