import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import Highlighter from 'react-highlight-words';
import NumberFormat from 'react-number-format';
import { Space, Tooltip, Button,Input,Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import PenambahanStokPakanComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/transaksi-stok-pakan-component/penambahan-stok-pakan-component';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';

/*Import Moment Js */
import moment from 'moment-timezone';
import 'moment-timezone';
import 'moment/locale/id';
/*End Import Moment Js */

import {detailTransaksi,updateTransaksiPenambahan,deleteTransaksiStokPakan} from '../stok-pakan-aksi-page/query-stok-pakan';

function PenambahanStokPakanPage(props) {
    const { confirm } = Modal;
    const {idStock,penambahan,fetchDataPenambahan,loading,setLoading, locales} = props;
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('');
    const [pagination, setPagination] = useState(10);
    const [idData, setIdData] = useState(null); 
    const [visible, setVisible] = useState(false);
    const [detail, setDetail] = useState('');

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
            <Input
                placeholder={`${locales('general.search')} ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                >
                {locales('general.search')}
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
                </Button>
            </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        ),
    });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSeacrhedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    /**End handle seacrh in row antd design */
    
    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(detailTransaksi(idData))
                .then(res => {        
                    setDetail(res.data.data.feeding)
                    setVisible(true);
                }).catch( ( error ) =>  {
                    console.log(error);
                    setDetail(null);
                    setVisible (false);
                });
            }
        }
        getDetailData();
    }, [idData]);

    const showModal = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value);
    },[idData]);

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const updatedData =  (values,year,feedStock) => {
        API.post(updateTransaksiPenambahan(idData,values,year,feedStock))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.updateFeeding._id){
                validationMessage('success', `${locales('pages.stok.pakan.response.sukses.editTrans')}`)
                fetchDataPenambahan(idStock);
            }
        }).catch( ( error ) =>  {
            console.log(error);
            setLoading(false);
            validationMessage('error', `${locales('pages.stok.pakan.response.gagal.editTrans')}`)
        });
    }

    const handleSubmit = (values) => {
        updatedData(values,values['date_picker'].format('MM/DD/YYYY'),detail.feedStock);
        setVisible(false);
        setIdData(null);
    };
    /**End handle updated data */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deleteTransaksiStokPakan(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }
            else if(res.data.data.deleteFeeding.deleted === true){
                validationMessage('success', `${locales('pages.stok.pakan.response.sukses.hapusTrans')}`)
                fetchDataPenambahan(idStock);
            }
        }).catch( ( error ) =>  {
            console.log(error);
            validationMessage('error',`${locales('pages.stok.pakan.response.sukses.hapusTrans')}`)
            setLoading(false);
        });
    }
    
    const hapusConfirm = (_id)=> {
        confirm({
            title: `${locales('pages.stok.pakan.detail.penambahan.textHapus')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${locales('general.cancel')}`,
            onOk: () => {
                deleteData(_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    /**End handle delete data */

    const columns = [
        {
            title: `${locales('pages.stok.pakan.detail.penambahan.table.tanggal')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            ...getColumnSearchProps('tanggal', `${locales('pages.stok.pakan.detail.penambahan.table.tanggal')}`),
        },
        {
            title: `${locales('pages.stok.pakan.detail.penambahan.table.harga')}`,
            dataIndex: 'harga',
            key: 'harga',
            ...getColumnSearchProps('harga', `${locales('pages.stok.pakan.detail.penambahan.table.harga')}`),
        },
        {
            title: `${locales('pages.stok.pakan.detail.penambahan.table.jumlah')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            ...getColumnSearchProps('jumlah', `${locales('pages.stok.pakan.detail.penambahan.table.jumlah')}`),
        },
        {
            title: `${locales('pages.stok.pakan.detail.penambahan.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 200,
            render: (value,row,index) => (
            <Space size="middle">
                <Tooltip placement="top" title={locales('pages.stok.pakan.detail.penambahan.button.edit')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FFA903',
                        borderRadius:'5px'
                    }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={locales('general.del')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FF0303',
                        borderRadius:'5px'
                    }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                </Tooltip>
            </Space>
            ),
        },
    ];

    const data = penambahan.map( ({_id,number,storeTime,priceFeeding}, index) => ({
        key: _id,
        total : number,
        jumlah : <NumberFormat value={number} displayType={'text'} thousandSeparator={true}/>,
        tanggal : moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
        harga :  <NumberFormat value={priceFeeding} displayType={'text'} thousandSeparator={true}/>
    }));

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize);
    }, []);

    return ( 
        <PenambahanStokPakanComponent
            navigate={props.navigate}
            columns = {columns}
            data = {data}
            loading = {loading}
            pagination = {pagination}
            visible = {visible}
            detail = {detail}
            changePagination = {changePagination}
            handleCancel = {handleCancel}
            handleSubmit = {handleSubmit}
            locales={locales}
        />
    );
}

const mapStateToProps = state => ({
    idStock : state.stokPakan.idStokPakan
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(PenambahanStokPakanPage);
export default page