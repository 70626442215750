import React from 'react'
import { Modal, Form, Row, Col, Input } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'

function CreateStandarLingkunganComponent(props) {
    const { visible, handleSubmit, setVisible, showModal, t } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.lingkungan.button.tambah')}
                okText={t('general.create')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.lingkungan.modal.hari')}</span>
                            <Form.Item
                                name="day" rules={[{ required: true, message: `${t('pages.master.lingkungan.modal.msgHari')}` }]}
                            >
                                <Input
                                    name='day'
                                    placeholder="5..."
                                    className="input-suffix mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.lingkungan.modal.suhu')}</span>
                            <Form.Item
                                name="temperature" rules={[{ required: true, message: `${t('pages.master.lingkungan.modal.msgSuhu')}` }]}
                            >
                                <Input
                                    name='temperature'
                                    placeholder="5..."
                                    className="input-suffix mt-5"
                                    suffix="(°C)"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.lingkungan.modal.kelembapan')}</span>
                            <Form.Item
                                name="humidity" rules={[{ required: true, message: `${t('pages.master.lingkungan.modal.msgKelembapan')}` }]}
                            >
                                <Input
                                    name='humidity'
                                    placeholder="5..."
                                    className="input-suffix mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">Amonia</span>
                            <Form.Item
                                name="ammonia" rules={[{ required: true, message: `${t('pages.master.lingkungan.modal.msgAmonia')}` }]}
                            >
                                <Input
                                    name='ammonia'
                                    placeholder="5..."
                                    className="input-suffix mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={t('pages.master.lingkungan.modal.titleTambah')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

export default CreateStandarLingkunganComponent