import React from 'react';
import { Modal,Form,Row,Col,Input,Select, DatePicker } from 'antd';
import moment from 'moment'

function EditPakan(props) {
    const { TextArea } = Input;
    const { Option } = Select;
    const {visible,handleSubmit,handleCancel, data,t} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t('pages.master.pakan.button.edit')}
            okText="Edit"
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        namaPakan : data.name,
                        kodePakan : data.code,
                        jenisPakan : data,
                        perusahaan : data.producer,
                        date_picker :moment(data.year, 'YYYY'),
                    }}
                >
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.pakan.modal.nama')}</span>
                            <Form.Item
                                name="namaPakan" rules={[{ required: true, message: `${t('pages.master.pakan.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='namaPakan'
                                    placeholder='Dedak...'
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.pakan.modal.kode')}</span>
                            <Form.Item
                                name="kodePakan" rules={[{ required: true, message: `${t('pages.master.pakan.modal.msgKode')}` }]}
                            >
                                <Input
                                    name='kodePakan'
                                    placeholder="1000..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.pakan.modal.jenis')}</span>
                            <Form.Item
                                name="jenisPakan" rules={[{ required: true, message: `${t('pages.master.pakan.modal.msgJenis')}`}]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.pakan.modal.phJenis')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"  
                                >  
                                    <Option key="Prestarter" value="prestarter">Prestarter</Option>
                                    <Option key="Starter" value="starter">Starter</Option>
                                    <Option key="Growing" value="growing">Growing</Option>
                                    <Option key="Finisher" value="finisher">Finisher</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.pakan.modal.tahun')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, message: `${t('pages.master.pakan.modal.msgTahun')}` }]} 
                            >
                                <DatePicker 
                                    placeholder={t('pages.master.pakan.modal.phTahun')}
                                    className="select-input-date font-semi-bold"
                                    picker="year"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.pakan.modal.perusahaan')}</span>
                            <Form.Item
                                name="perusahaan" rules={[{ required: true, message: `${t('pages.master.pakan.modal.msgPerusahaan')}` }]}
                            >
                                <Input
                                    name='perusahaan'
                                    placeholder="Indofood..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.pakan.modal.keterangan')}</span>
                            <TextArea
                                style={{borderColor:"#BE375F"}}
                                placeholder={t('pages.master.pakan.modal.phKet')}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default EditPakan;