import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { Tag, Table } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { API } from '../../../../common/api'
import { getReportRecord } from '../query-dashboard-page'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { useTranslation } from "react-i18next"

function TableDetailPage(props) {
    const { idPeriode } = props
    const [loading, setLoading] = useState(false)
    const [rearing, setRearing] = useState([])
    const { t } = useTranslation()

    const fetchDataRearingRecord = async (idPeriode) => {
        setLoading(true)
        await API.get(getReportRecord('', 0, 0, idPeriode))
            .then(res => {
                if (res.data.data.calculatedRearingRecords !== null) {
                    rearingObject(res.data.data.calculatedRearingRecords.calculatedArr)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                rearingObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchDataRearingRecord(idPeriode)
        // eslint-disable-next-line
    }, [idPeriode])

    const rearingObject = (object) => {
        let rearing = []
        let totBeratPanen = 0
        let totalDeplesiCum = 0
        for (let i = 0; i < object.length; i++) {
            totBeratPanen += object[i].totalBeratPanen
            totalDeplesiCum += object[i].persenDeplesiHarian

            rearing.push({
                umur: object[i].umur,
                _id: object[i]._id,
                tanggal: object[i].tanggal,

                persenDeplesiHarian: (parseFloat(totalDeplesiCum)).toFixed(2),
                standardDp: object[i].standardDp,
                saldo: object[i].saldo,

                pakanTotal: object[i].pakanTotal,
                bw: object[i].bw,
                standardBw: object[i].standardBw,
                abw: object[i].abw,
                adg: i === 0 ? object[i].adg : object[i].bw - object[i - 1].bw,
                standardDg: object[i].standardDg,
                totalBerat: i === 0 ? object[i].totalBerat : object[i].totalBerat + totBeratPanen,

                fcr: object[i].fcr,
                standardFcr: object[i].standardFcr,

                totalDoc: object[i].totalDoc,

                ovkTotal: object[i].ovkTotal,
                indeksPerformance: object[i].indeksPerformance,

                hppPerKg: object[i].hppPerKg,
                bopKandang: object[i].bopKandang,
                penyusutan: object[i].penyusutan,
            })
        }
        setRearing(rearing)
    }

    const columns = [
        {
            title: `${t("pages.dashboard.table.date")}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            align: 'left',
            width: 100,
        },
        {
            title: `${t("pages.dashboard.table.umur")}`,
            dataIndex: 'umur',
            key: 'umur',
            className: 'center',
            width: 50,
        },
        {
            title: `${t("pages.dashboard.table.populasi")}`,
            dataIndex: 'populasi',
            key: 'populasi',
            className: 'center',
            width: 65,
        },
        {
            title: `${t("pages.dashboard.table.deplesi.title")}`,
            className: 'deplesi',
            children: [
                {
                    title: 'CUM (%)',
                    dataIndex: 'deplesi',
                    key: 'deplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: 'Std (%)',
                    dataIndex: 'stddeplesi',
                    key: 'stddeplesi',
                    className: 'center-deplesi',
                    width: 60,
                },
                {
                    title: `${t("pages.dashboard.table.deplesi.ket")}`,
                    dataIndex: 'ketDeplesi',
                    key: 'ketDeplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
            ],
        },
        {
            title: 'Bw',
            className: 'bw',
            children: [
                {
                    title: `${t("pages.dashboard.table.bw.sampling")}`,
                    dataIndex: 'bw',
                    key: 'bw',
                    className: 'center-bw',
                    width: 90,
                },
                {
                    title: `STD (Gr/${t("pages.dashboard.table.ekor")})`,
                    dataIndex: 'stdbw',
                    key: 'stdbw',
                    className: 'center-bw',
                    width: 80,
                },
                {
                    title: `${t("pages.dashboard.table.bw.ket")}`,
                    dataIndex: 'ketBw',
                    key: 'ketBw',
                    className: 'center-bw',
                    width: 70,
                },
                {
                    title: 'Adg (Gr)',
                    dataIndex: 'adg',
                    key: 'adg',
                    className: 'center-bw',
                    width: 70,
                },
                {
                    title: 'Std Adg (Gr)',
                    dataIndex: 'stdadg',
                    key: 'stdadg',
                    className: 'center-bw',
                    width: 70,
                },
            ],
        },
        {
            title: 'Fcr',
            className: 'fcr',
            children: [
                {
                    title: 'Act Cum (Gr)',
                    dataIndex: 'fcr',
                    key: 'fcr',
                    className: 'center-fcr',
                    width: 70,
                },
                {
                    title: 'STD (Gr)',
                    dataIndex: 'stdfcr',
                    key: 'stdfcr',
                    className: 'center-fcr',
                    width: 60,
                },
                {
                    title: `${t("pages.dashboard.table.fcr.ket")}`,
                    dataIndex: 'ketFcr',
                    key: 'ketFcr',
                    className: 'center-fcr',
                    width: 70,
                },
            ],
        },
        {
            title: 'IP',
            dataIndex: 'indeksPerformance',
            key: 'indeksPerformance',
            className: 'center',
            width: 70,
        },
        {
            title: 'Hpp/Kg (Rp)',
            dataIndex: 'hpp',
            key: 'hpp',
            className: 'center',
            width: 100,
        },
    ]

    const data = rearing.map(({ _id, umur, tanggal, bw, fcr, saldo, persenDeplesiHarian, adg,
        indeksPerformance, hppPerKg, standardDp, standardBw, standardFcr, standardDg,
        pakanTotal, totalBerat }, index) => ({
            key: _id,
            id: _id,
            umur: umur,
            tanggal: moment(new Date(parseInt(tanggal))).format("DD MMMM YYYY"),
            populasi: <NumberFormat value={saldo} displayType={'text'} thousandSeparator={true} />,
            deplesi: persenDeplesiHarian,
            stddeplesi: standardDp,
            ketDeplesi: standardDp > persenDeplesiHarian ? <Tag color="#027d17">Aman</Tag> : <Tag color="#ff5100">Warning</Tag>,
            fcr: <NumberFormat value={(pakanTotal / totalBerat).toFixed(3)} displayType={'text'} thousandSeparator={true} />,
            ketFcr: fcr < standardFcr ? <Tag color="#027d17">Aman</Tag> : <Tag color="#ff5100">Warning</Tag>,
            stdfcr: standardFcr,
            bw: <NumberFormat value={bw} displayType={'text'} thousandSeparator={true} />,
            stdbw: <NumberFormat value={standardBw} displayType={'text'} thousandSeparator={true} />,
            ketBw: standardBw < bw ? <Tag color="#027d17">Aman</Tag> : <Tag color="#ff5100">Warning</Tag>,
            adg: <NumberFormat value={adg} displayType={'text'} thousandSeparator={true} />,
            stdadg: standardDg,
            indeksPerformance: <NumberFormat value={indeksPerformance} displayType={'text'} thousandSeparator={true} />,
            hpp: <NumberFormat value={hppPerKg} displayType={'text'} thousandSeparator={true} />,
        }))

    return (
        <Loading loading={loading}>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                className="table-rearing-record"
                scroll={{ x: 1000, y: 300 }}
            />
        </Loading>
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idRearing,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TableDetailPage)
export default page