import React from 'react';
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd';
import Loading from '../../../../common/component/loading/loading-container';
import ModalCreateStokMaterial from '../../../../app/dashboard-peternak/stok-page/stok-material-page/stok-material-aksi-page/create-stok-material-page';
import ModalTambahStokMaterial from '../../../../app/dashboard-peternak/stok-page/stok-material-page/stok-material-aksi-page/tambah-stok-material-page';
import ModalEditStokMaterial from '../../../../app/dashboard-peternak/stok-page/stok-material-page/stok-material-aksi-page/edit-stok-material';

function StokMaterialComponent(props) {
    const { Content } = Layout;
    const { columns, data, fetchData, handleCancel, visible, handleSubmit, loading,
        visibleEdit, handleSubmitEdit, handleCancelEdit, detail, setLoading, idGudangMaterial, t } = props;
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.stok.material.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateStokMaterial fetchData={fetchData} setLoading={setLoading} idGudangMaterial={idGudangMaterial} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh' }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalTambahStokMaterial visible={visible} handleSubmit={handleSubmit} handleCancel={handleCancel} t={t} />
                    <ModalEditStokMaterial visibleEdit={visibleEdit} handleSubmit={handleSubmitEdit} handleCancel={handleCancelEdit} detail={detail} t={t} />
                </Row>
            </Content>
        </Layout>
    );
}

export default StokMaterialComponent;