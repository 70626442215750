import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreateMaterialComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-material-component'
import { createRecordMaterial } from '../rearing-record-aksi-page/query-rearing-record'
import { getListMaterial } from '../../../../modules/dashboard-peternak/stok-component/stok-material-component/store/stok-material-action';
import { getMaterialStok } from '../../../../app/dashboard-peternak/stok-page/stok-material-page/stok-material-aksi-page/query-stok-material';
import { useTranslation } from "react-i18next"

function CreateMaterialPage(props) {
    const { dateRearing, idRearingRecord, setLoading, fetchDataMaterial, getListMaterial, monthlyMaterial } = props
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        getListMaterial(getMaterialStok("", 0, 0))
    }, [getListMaterial])

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createRecordMaterial(values, dateRearing, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    fetchDataMaterial(idRearingRecord)
                } else if (res.data.data.createMaterialTransaction._id) {
                    validationMessage('success', `${t("pages.recording.detail.material.response.sukses.tambah")}`)
                    fetchDataMaterial(idRearingRecord)
                } else {
                    validationMessage('error', `${t("pages.recording.detail.material.response.gagal.tambah")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.detail.material.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateMaterialComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            stokMaterial={props.stokMaterial}
            locales={t} monthlyMaterial={monthlyMaterial}
        />
    )
}

const mapStateToProps = state => ({
    stokMaterial: state.stokMaterial.dataStokMaterial,
    dateRearing: state.rearingRecord.dateRearingRecord,
    idRearingRecord: state.rearingRecord.idRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getListMaterial
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateMaterialPage)
export default page