import React from 'react';
import { Layout, Row, Col, Table } from 'antd';
import Loading from '../../../../../common/component/loading/loading-container';
import ModalEditPenggunaanStokMaterial from '../../../../../app/dashboard-peternak/stok-page/stok-material-page/transaksi-stok-material-page/edit-penggunaan-material';

function PenggunaanStokMaterialComponent(props) {
    const { columns, data, loading, changePagination, detail, visible, idData, handleSubmit, handleCancel, t } = props;
    return (
        <Layout style={{ backgroundColor: "white" }}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={{
                                onChange: (pageSize) =>
                                    changePagination(pageSize)
                            }}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
                <ModalEditPenggunaanStokMaterial visible={visible} handleSubmit={handleSubmit}
                    idData={idData} handleCancel={handleCancel} detail={detail} t={t} />
            </Row>
        </Layout>
    );
}

export default PenggunaanStokMaterialComponent;