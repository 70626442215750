export const getMonthlyStockFeeds = (keyword,limit,skip,month,year) => {
    return (
        `query{
            monthlyStockFeeds(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}"){
            totalCount
            locked
            calculateMonthlyStocks{
                _id
                idFeed
                idMonthlyStockFeed
                bulan
                tahun
                namaPakan
        
                stockAwalBulan
                hargaAwalBulan
                totalHargaAwal
                hargaTotalAwalBulan
        
                addStock
                hargaPengadaan
                nilaiPengadaan
        
                outStock
                hargaPengeluaran
                nilaiPengeluaran
        
                sisaStok
                nilaiSisaStok
                hargaTotalPenyesuaian
        
                qty
                price
                total
            }
          }
        }
      `
    )
}