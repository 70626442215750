import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { faTrash, faInfoCircle, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import StokOvkComponent from '../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/stok-ovk-component'
import { getIdStokOvk, getSatuanOvk, getSatuanRecordingOvk, getNamakOvk } from '../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'
import { getListGudangOvk } from '../../../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-action'
import { getListOvk } from '../../../../modules/dashboard-peternak/master-component/ovk-component/store/ovk-action'
import { getGudangOvk } from '../../../../app/dashboard-peternak/master-page/gudang-ovk-page/gudang-ovk-aksi-page/query-gudang-ovk'
import { getOvkList } from '../../../../app/dashboard-peternak/master-page/ovk-page/ovk-aksi-page/query-ovk'
import { getStokGudangOvk, addStokOvk, deleteStokOvk, detailStokOvk, updateStokOvk } from './stok-ovk-aksi-page/query-stok-ovk'
import { validationMessage } from '../../../../common/component/notification/notification'
import { API } from '../../../../common/api'
import CONSTANTS from '../../../../common/utils/Constants'
import { useTranslation } from 'react-i18next'

function StokOvkPage(props) {
    const { confirm } = Modal
    const { getIdStokOvk, getListGudangOvk, getListOvk, getSatuanOvk, getSatuanRecordingOvk, getNamakOvk, idGudangOvk } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [visibleEdit, setVisibleEdit] = useState(false)
    const [object, setObject] = useState([])
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState(null)
    const [stockId, setStockId] = useState(null)
    const [suffix, setSuffix] = useState('')
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = (idGudangOvk) => {
        setLoading(true)
        API.get(getStokGudangOvk(idGudangOvk))
            .then(res => {
                setObject(res.data.data.ovkStocksWarehouse)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(idGudangOvk)
    }, [idGudangOvk])

    useEffect(() => {
        getListOvk(getOvkList('', 0, 0))
        getListGudangOvk(getGudangOvk('', 0, 0))
    }, [getListOvk, getListGudangOvk])

    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deleteStokOvk(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteOvkStock.deleted === true) {
                    validationMessage('success', `${t('pages.stok.ovk.response.sukses.hapus')}`)
                    fetchData(idGudangOvk)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stok.ovk.response.gagal.hapus')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.stok.ovk.textHapus')} ${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const showModal = useCallback((stockId, satuan) => {
        setStockId(stockId)
        setSuffix(satuan)
        setVisible(true)
    }, [])

    const postData = (values, year, harga) => {
        API.post(addStokOvk(values, year, harga, stockId))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOvkTransaction._id) {
                    validationMessage('success', `${t('pages.stok.ovk.response.sukses.tambah')}`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stok.ovk.response.gagal.tambah')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('MM/DD/YYYY'), values.harga)
        setVisible(false)
        setLoading(true)
    }

    const handleCancel = useCallback((value) => {
        setVisible(false)
    }, [])

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailStokOvk(idData))
                    .then(res => {
                        setDetail(res.data.data.ovkStock)
                        setVisibleEdit(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisibleEdit(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEditStok = useCallback((value) => {
        if (value === idData)
            setVisibleEdit(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancelEdit = useCallback(() => {
        setVisibleEdit(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateStokOvk(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateOvkStock._id) {
                    validationMessage('success', `${t('pages.stok.ovk.response.sukses.edit')}`)
                    fetchData(idGudangOvk)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stok.ovk.response.gagal.edit')}`)
                setLoading(false)
            })
    }

    const handleSubmitEdit = (values) => {
        updatedData(values)
        setVisibleEdit(false)
        setIdData(null)
        setLoading(true)
    }
    /**End handle updated data */

    const createStokBaru = () => {
        props.navigate(CONSTANTS.CREATE_STOK_OVK_MENU_KEY)
    }

    /**Handle detail transaksi */
    const detailTranskasi = useCallback((value, satuan, satuanRecording, nama) => {
        getIdStokOvk(value)
        getSatuanOvk(satuan)
        getSatuanRecordingOvk(satuanRecording)
        getNamakOvk(nama)
        props.navigate(CONSTANTS.TRANSAKSI_STOK_OVK_MENU_KEY)
    }, [props, getIdStokOvk, getSatuanOvk, getSatuanRecordingOvk, getNamakOvk])
    /**End handle detail transaksi */

    const columns = [
        {
            title: `${t('pages.stok.ovk.table.kode')}`,
            dataIndex: 'kode',
            key: 'kode',
            width: 70,
            ...getColumnSearchProps('kode', `${t('pages.stok.ovk.table.kode')}`),
        },
        {
            title: 'OVK',
            dataIndex: 'ovk',
            key: 'ovk',
            width: 150,
            ...getColumnSearchProps('ovk', 'OVK'),
        },
        {
            title: `${t('pages.stok.ovk.table.nama')}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 100,
        },
        {
            title: `${t('pages.stok.ovk.table.supplier')}`,
            dataIndex: 'perusahaan',
            key: 'perusahaan',
            width: 100,
        },
        {
            title: `${t('pages.stok.ovk.table.saldo')}`,
            dataIndex: 'total',
            key: 'total',
            width: 80,
        },
        {
            title: `${t('pages.stok.ovk.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 150,
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('pages.stok.ovk.button.tambah')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#0f6cff',
                            borderRadius: '5px'
                        }} onClick={() => showModal(row.key, row.satuanOvk)}><FontAwesomeIcon icon={faPlus} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('pages.stok.ovk.button.edit')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showEditStok(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('pages.stok.ovk.button.detail')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }} onClick={() => detailTranskasi(row.key, row.satuanOvk, row.satuanRecordingOvk, row.ovk)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('pages.stok.ovk.button.hapus')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = object.map(({ _id, stockName, ovkWarehouse, ovk, sisaStok }, index) => ({
        key: _id,
        kode: ovk.code,
        ovk: ovk.name,
        nama: stockName,
        perusahaan: ovk.producer,
        gudang: ovkWarehouse.name,
        total: <NumberFormat value={sisaStok < 0 ? Math.abs(sisaStok) : sisaStok} displayType={'text'} thousandSeparator={true}
            suffix={sisaStok < 0 ? ') ' + ovk.ovkUnit.name : ' ' + ovk.ovkUnit.name} prefix={sisaStok < 0 ? '(' : ''} />,
        satuanOvk: ovk.ovkUnit.name,
        satuanRecordingOvk: ovk.ovkUnitRecording?.name
    }))

    return (
        <StokOvkComponent
            navigate={props.navigate}
            showModal={showModal}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            columns={columns}
            data={data}
            loading={loading}
            visible={visible}
            fetchData={fetchData}
            visibleEdit={visibleEdit}
            detail={detail}
            handleCancelEdit={handleCancelEdit}
            handleSubmitEdit={handleSubmitEdit}
            setLoading={setLoading}
            createStokBaru={createStokBaru}
            suffix={suffix}
            locales={t}
            idGudangOvk={idGudangOvk}
        />
    )
}

const mapStateToProps = state => ({
    idGudangOvk: state.gudangOvk.idGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdStokOvk,
    getListGudangOvk,
    getListOvk,
    getSatuanOvk,
    getSatuanRecordingOvk,
    getNamakOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StokOvkPage)
export default page