import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import NumberFormat from 'react-number-format'
import PemakaianUmumComponent from '../../../modules/dashboard-peternak/pemakaian-umum-component/pemakaian-umum-component'
import { API } from '../../../common/api'
import CONSTANTS from '../../../common/utils/Constants'
import { validationMessage } from '../../../common/component/notification/notification'
import { getPemakaianUmumOvk, deletePemakaianUmumOvk, getDetailPemakaianUmumOvk, updatePemakaianUmumOvk } from './pemakaian-umum-aksi/query-pemakaian-umum'
import { useTranslation } from 'react-i18next'

function PemakaianUmumPage(props) {
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [visible, setVisible] = useState(false)
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState("")
    const [suffix, setSuffix] = useState(null)
    const [rearingRecord, setRearingRecord] = useState(null)
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = () => {
        setLoading(true);
        API.get(getPemakaianUmumOvk('', 0, 0))
            .then(res => {
                setObject(res.data.data.ovkGeneralUses.ovkTransactions)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deletePemakaianUmumOvk(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteOvkTransaction.deleted === true) {
                    validationMessage('success', `${t('pages.pemakaianUmum.response.sukses.hapus')}`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.pemakaianUmum.response.gagal.hapus')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.pemakaianUmum.textHapus')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const createPemakaianUmumOvk = () => {
        props.navigate(CONSTANTS.CREATE_PEMAKAIAN_UMUM_MENU_KEY)
    }

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(getDetailPemakaianUmumOvk(idData))
                    .then(res => {
                        setRearingRecord(res.data.data.ovkGeneralUse.rearingRecord._id)
                        setDetail(res.data.data.ovkGeneralUse)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setRearingRecord(null)
                        setLoading(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value, satuan) => {
        if (value === idData) {
            setVisible(true)
            setSuffix(satuan)
        }
        else {
            setIdData(value)
            setSuffix(satuan)
        }
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (value) => {
        API.post(updatePemakaianUmumOvk(idData, value, rearingRecord))
            .then(res => {
                if (res.data.data.updateOvkGeneralUse._id) {
                    validationMessage('success', `${t('pages.pemakaianUmum.response.sukses.edit')}`)
                    fetchData()
                } else if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.pemakaianUmum.response.gagal.edit')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const columns = [
        {
            title: `${t('pages.pemakaianUmum.table.kandang')}`,
            dataIndex: 'kandang',
            key: 'kandang',
            ...getColumnSearchProps('kandang', `${t('pages.pemakaianUmum.table.kandang')}`),
        },
        {
            title: `${t('pages.pemakaianUmum.table.periode')}`,
            dataIndex: 'periode',
            key: 'periode',
            ...getColumnSearchProps('periode', `${t('pages.pemakaianUmum.table.periode')}`),
        },
        {
            title: `${t('pages.pemakaianUmum.table.ovk')}`,
            dataIndex: 'ovk',
            key: 'ovk',
            ...getColumnSearchProps('ovk', `${t('pages.pemakaianUmum.table.ovk')}`),
        },
        {
            title: `${t('pages.pemakaianUmum.table.jumlah')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
        },
        {
            title: `Harga`,
            dataIndex: 'price',
            key: 'price',
            align: 'right'
        },
        {
            title: `${t('pages.pemakaianUmum.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 200,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title='Edit'>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showEdit(row.key, row.satuan)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = object.map(({ _id, kandang, periode, number, ovkStock, priceOvkTransaction }, index) => ({
        key: _id,
        id: _id,
        kandang: kandang,
        periode: periode,
        jumlah: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} suffix={" " + ovkStock.ovk.ovkUnit.name} />,
        price: <NumberFormat value={priceOvkTransaction} displayType={'text'} thousandSeparator={true} prefix='Rp '/>,
        satuan: ovkStock.ovk.ovkUnit.name,
        ovk: ovkStock.ovk.name
    }))

    return (
        <PemakaianUmumComponent
            navigate={props.navigate}
            columns={columns}
            data={data}
            loading={loading}
            setLoading={setLoading}
            createPemakaianUmumOvk={createPemakaianUmumOvk}
            showEdit={showEdit}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            visible={visible}
            detail={detail}
            suffix={suffix}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PemakaianUmumPage)
export default page