import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreatePakanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-pakan-component'
import { getListPakan } from '../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-action'
import { feeding, getStokPakan } from '../../../dashboard-peternak/stok-page/stok-pakan-page/stok-pakan-aksi-page/query-stok-pakan'
import { createRecordPakan } from '../rearing-record-aksi-page/query-rearing-record'
import { useTranslation } from "react-i18next"

function CreatePakanPage(props) {
    const { dateRearing, idRearingRecord, setLoading, fetchDataPakan, getListPakan, feed } = props
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const fetchData = (stockId, values) => {
        API.get(feeding(stockId))
            .then(res => {
                postData(values, res.data.data.feedStock.feeding[0]._id)
                setVisible(false)
                setLoading(true)
            })
    }
    useEffect(() => {
        getListPakan(getStokPakan("", 0, 0))
    }, [getListPakan])

    const postData = (values, feedingId) => {
        API.post(createRecordPakan(values, feedingId, dateRearing, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createFeeding._id) {
                    validationMessage('success', `${t("pages.recording.detail.pakan.response.sukses.tambah")}`)
                    fetchDataPakan(idRearingRecord)
                } else {
                    validationMessage('error', `${t("pages.recording.detail.pakan.response.gagal.tambah")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.detail.pakan.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        fetchData(values.pakan.value, values)
    }

    return (
        <CreatePakanComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            stokPakan={props.stokPakan}
            locales={t} feed={feed}
        />
    )
}

const mapStateToProps = state => ({
    stokPakan: state.stokPakan.dataStokPakan,
    dateRearing: state.rearingRecord.dateRearingRecord,
    idRearingRecord: state.rearingRecord.idRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getListPakan
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePakanPage)
export default page