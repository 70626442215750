import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreatePemakaianUmumComponent from '../../../../modules/dashboard-peternak/pemakaian-umum-component/pemakaian-umum-aksi/create-pemakaian-umum-component'
import { createPemakaianUmumOvk, getRearingRecordDate } from './query-pemakaian-umum'
import { getPeriodeKandang } from '../../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/query-rearing'
import { getStokOvk } from '../../stok-page/stok-ovk-page/stok-ovk-aksi-page/query-stok-ovk'
import { getMonthlyStockOvks } from '../../stock-flow-page/ovk-page/ovk-aksi-page/query-stock-flow-ovk'
import CONSTANTS from '../../../../common/utils/Constants'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function CreatePemakaianUmumPage(props) {
    const [visible, setVisible] = useState(false)
    const [idkandang, setIdKandang] = useState(null)
    const [periode, setPeriode] = useState([])
    const [idPeriode, setIdPeriode] = useState(null)
    const [rearingRecord, setRearingRecord] = useState([])
    const [idRearingRecord, setIdRearingRecord] = useState(null)
    const [tanggal, setTanggal] = useState(null)
    const [idOvk, setIdOvk] = useState(null)
    const [ovkStok, setOvkStok] = useState([])
    const [monthlyOvk, setMonthlyOvk] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (value, idRearingRecord, idOvk) => {
        API.post(createPemakaianUmumOvk(value, idRearingRecord, idOvk))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createGeneralUseOvk._id) {
                    validationMessage('success', `${t('pages.pemakaianUmum.response.sukses.tambah')}`)
                    props.navigate(CONSTANTS.PEMAKAIAN_UMUM_MENU_KEY)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.pemakaianUmum.response.gagal.tambah')}`)
                setLoading(false)
            })
    }

    const getListPeriodeKandang = (idKandang) => {
        API.get(getPeriodeKandang('', 0, 0, idKandang))
            .then(res => {
                setPeriode(res.data.data.rearingHouses.rearings)
            }).catch((error) => {
                console.log(error)
                setPeriode(null)
            })
    }

    const getListRearingRecord = (idPeriode) => {
        API.get(getRearingRecordDate('', 0, 0, idPeriode))
            .then(res => {
                setRearingRecord(res.data.data.dateRearingRecords.calculatedArr)
            }).catch((error) => {
                console.log(error)
                setRearingRecord(null)
            })
    }

    const fetchData = () => {
        setLoading(true)
        API.get(getStokOvk('', 0, 0))
            .then(res => {
                setOvkStok(res.data.data.ovkStocks.ovkStocks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setOvkStok([])
                setLoading(false)
            })
    }
    const fetchMonthlyOvk = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockOvks('', 0, 0, month, year))
            .then(res => {
                setMonthlyOvk(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setMonthlyOvk([])
                setLoading(false)
            })
    }
    useEffect(() => {
        const month = moment(tanggal, 'DD-MM-YYYY').format('MMMM')
        const year = moment(tanggal, 'DD-MM-YYYY').format('YYYY')
        fetchData()
        fetchMonthlyOvk(month, year)
    }, [tanggal])

    const handleSubmit = (values) => {
        postData(values, idRearingRecord, idOvk)
        setLoading(true)
    }

    const handleChangeKandang = useCallback((value) => {
        getListPeriodeKandang(value.value)
        setIdKandang(value.value)
        form.setFieldsValue({ periode: '', tanggal: '' })
    }, [form])

    const handlePeriode = useCallback((value) => {
        getListRearingRecord(value.value)
        setIdPeriode(value.value)
        form.setFieldsValue({ tanggal: '' })
    }, [form])

    const handleRearingRecord = useCallback((value) => {
        setIdRearingRecord(value.value)
        setTanggal(value.label)
    }, [])

    const handleOvk = useCallback((value) => {
        setIdOvk(value.value)
        let idOvk = ovkStok.filter(d => d._id === value.value)[0].ovk._id
        let price = monthlyOvk.filter(d => d.idOvk === idOvk)[0]?.hargaPengeluaran
        form.setFieldsValue({
            price: price
        })
    }, [monthlyOvk, form, ovkStok])

    return (
        <CreatePemakaianUmumComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            kandang={props.kandang}
            periode={periode}
            form={form}
            rearingRecord={rearingRecord}
            handlePeriode={handlePeriode}
            handleChangeKandang={handleChangeKandang}
            handleRearingRecord={handleRearingRecord}
            idkandang={idkandang}
            idPeriode={idPeriode}
            stokOvk={ovkStok}
            idRearingRecord={idRearingRecord}
            tanggal={tanggal}
            loading={loading}
            handleOvk={handleOvk}
            t={t} monthlyOvk={monthlyOvk}
        />
    )
}

const mapStateToProps = state => ({
    kandang: state.layout.dataKandang,
    stokOvk: state.stokOvk.dataStokOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePemakaianUmumPage)
export default page