import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Input, Select, Button, Divider } from 'antd'
import NumberFormat from 'react-number-format'

function EditAdjustmentMaterial(props) {
    const { Option } = Select
    const { visible, handleSubmit, data, handleCancel, materials, object, qty, hs, dif, t } = props
    const [form] = Form.useForm()
    const [harga, setHs] = useState(hs)
    const [jum, setQty] = useState(qty)
    const [sel, setDif] = useState(dif)
    const [material, setMaterial] = useState(data.material)
    const [jumlah, setNum] = useState(data.qty)
    const [visibleDif, setVisible] = useState(false)

    useEffect(() => {
        function setDetailData() {
            if (data === null) {
                setNum(0)
                setMaterial(0)
            }
            else {
                setNum(data.qty)
                setMaterial(data.material)
            }
            if (qty === null) { setQty(0) } else { setQty(qty) }
            if (hs === null) { setHs(0) } else { setHs(hs) }
            if (dif === null) { setDif(0) } else { setDif(dif) }
        }
        setDetailData()
    }, [data, qty, hs, dif])

    const sak = (e, value) => {
        const hsSak = object.filter(d => d?.idMaterial === value)[0].hargaPengeluaran
        const qtySak = object.filter(d => d?.idMaterial === value)[0].sisaStok
        setHs(hsSak)
        setQty(qtySak)
        setMaterial(e)
        setVisible(true)

    }
    const diff = (e) => {
        const value = e.target.value
        let difference = value - jum
        setDif(difference)
        setNum(value)
        setVisible(false)
    }
    return (
        <Modal
            visible={visible}
            title={t('pages.stockflow.periode.penyesuaian.material.edit')}
            width={800}
            footer={null}
            onCancel={() => handleCancel()}
        >
            <Form
                form={form}
                onFinish={() => {
                    form
                        .validateFields()
                        .then(() => {
                            const values = { material, jumlah }
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
                layout="vertical"
                name="form_in_modal"
            >
                <Row gutter={[16, 8]}>
                    <Col span={12}>
                        <span className="black font-semi-bold font-title-content">Material</span>
                        <Select
                            name="material" value={material}
                            showSearch
                            labelInValue
                            placeholder={`${t('pages.stockflow.periode.penyesuaian.msgSelect')} material`}
                            optionFilterProp="children"
                            style={{ width: '100%' }}
                            onChange={(value) => sak(value, value.value)}
                            className="select-kategori black font-regular mt-5"
                        >
                            {
                                materials.map(data =>
                                    <Option
                                        key={data.name.toString()}
                                        value={data._id}
                                    >{data.name}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col span={12}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.periode.penyesuaian.jumlah')}</span>
                        <Input
                            name='jumlah' value={jumlah}
                            type='number' required
                            placeholder="10..."
                            onChange={(value) => diff(value)}
                            className="input-suffix mt-5"
                        />
                    </Col>
                    <Col span={6}>
                        <span>{t('pages.stockflow.periode.penyesuaian.jumlah')}: <b><NumberFormat value={jum} displayType={'text'} thousandSeparator={true} suffix={' kg'} /></b></span>
                    </Col>
                    <Col span={6}>
                        <span>HS: <b><NumberFormat value={harga} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /></b></span>
                    </Col>
                    <Col span={6}>
                        <span>{t('pages.stockflow.periode.penyesuaian.difference')}: <b><NumberFormat hidden={visibleDif} value={Math.abs(sel || sel === 0 ? sel : dif)} displayType={'text'} thousandSeparator={true} suffix={' kg'} /></b></span>
                    </Col>
                    <Col span={6}>
                        <span>HS: <b><NumberFormat value={harga} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /></b></span>
                    </Col>
                    <Divider />
                    <Col span={8} offset={16}>
                        <Button className='float-right'
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 8, marginTop: 0 }}
                        >
                            Edit
                        </Button>
                        <Button onClick={() => handleCancel()} className='float-right'>
                            {t('general.cancel')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default EditAdjustmentMaterial