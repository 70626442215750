import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import TambahStokMaterialComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-material-component/stok-material-aksi-component/tambah-stok-material-component';

function TambahStokMaterialPage(props) {
    const {visible,handleCancel,handleSubmit,t} = props;

    return ( 
        <TambahStokMaterialComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(TambahStokMaterialPage);
export default page