import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import EditPengunaanStokComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/transaksi-stok-pakan-component/edit-penggunaan-stok-component';

function EditPengunaanStokPage(props) {
    const {visible,handleCancel,detail,handleSubmit,locales} = props;
    const [data,setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData();
    }, [detail]);

    return ( 
        <EditPengunaanStokComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            locales={locales}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditPengunaanStokPage);
export default page