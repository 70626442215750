import React from 'react';
import { Layout, Row, Col,Table } from 'antd';
import Loading from '../../../../common/component/loading/loading-container';
import ModalEditManajemen from '../../../../app/dashboard-peternak/master-page/petugas-page/petugas-aksi-page/edit-manajemen-page';

function PetugasComponent(props) {
    const { columns, data, changePagination,handleOk,handleCancel,visible,loading,detail,handleSubmit,t} = props;
    return ( 
        <Layout style={{backgroundColor:"white"}}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table 
                            columns={columns} 
                            dataSource={data} 
                            bordered
                            pagination={{
                                onChange : (pageSize) =>
                                    changePagination(pageSize) 
                            }}
                            className="table-dashboard"
                            scroll={{ x: 700}}
                        />
                    </Loading>
                </Col>
                <ModalEditManajemen visible= {visible} handleOk ={handleOk} detail={detail} 
                handleCancel ={handleCancel} handleSubmit={handleSubmit} t={t}/>
            </Row>
        </Layout>
    );
}
 
export default PetugasComponent;