import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditStokComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/stok-pakan-aksi-component/edit-stok-pakan-component'
import { useTranslation } from "react-i18next"

function EditStokPage(props) {
    const {visibleEdit,handleCancel,detail,handleSubmit} = props
    const [data,setData] = useState("")

    const { t } = useTranslation()

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData()
    }, [detail])

    return ( 
        <EditStokComponent
            navigate={props.navigate}
            visibleEdit = {visibleEdit}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            data = {data}
            pakan = {props.pakan}
            gudangPakan = {props.gudangPakan}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    pakan : state.pakan.dataPakan,
    gudangPakan : state.gudangPakan.dataGudangPakan
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditStokPage)
export default page