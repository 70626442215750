import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import ModalTambahStokOvk from '../../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-aksi-page/tambah-stok-ovk-page'
import ModalEditStokOvk from '../../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-aksi-page/edit-stok-ovk-page'

function StokOvkComponent(props) {
    const { Content } = Layout
    const { columns, data, handleSubmit, handleCancel, visible, loading, visibleEdit,
        handleSubmitEdit, handleCancelEdit, detail, createStokBaru, suffix, locales, } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{locales('pages.stok.ovk.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ButtonDashboard
                            text={locales('pages.stok.ovk.button.tambahBaru')}
                            height={20}
                            backgroundColor="#008ad4"
                            borderRadius="5px"
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                            icon={faPlusSquare}
                            onClick={() => createStokBaru()}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{y:'70vh'}}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalTambahStokOvk visible={visible} handleSubmit={handleSubmit} handleCancel={handleCancel} suffix={suffix} />
                    <ModalEditStokOvk visibleEdit={visibleEdit} handleSubmit={handleSubmitEdit} handleCancel={handleCancelEdit} detail={detail} />
                </Row>
            </Content>
        </Layout>
    );
}

export default StokOvkComponent;