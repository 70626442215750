import React from 'react';
import { Layout, Row, Col, Form, Carousel, Select } from 'antd';
import InputAuth from '../../../common/component/input/input-auth';
import InputPassword from '../../../common/component/input/input-password';
import '../../../assets/scss/auth/login.scss';
import Loading from '../../../common/component/loading/loading-container';
import ButtonText from '../../../common/component/button/button-text';
import ButtonLogin from '../../../common/component/button/button-not-icon';
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { Link } from 'react-router-dom';

import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';

function LoginComponent(props) {
    const { Content } = Layout;
    const { Option } = Select
    const { username, password, handleSubmit, handleFailed, handleLocales, lang, t,
        setUsername, setPassword, responseFb, responseGoogle, loading } = props;
    const logo = require(`../../../assets/images/logo_hq.svg`);
    const slide1 = require(`../../../assets/svg/chicken-1.svg`);
    const slide2 = require(`../../../assets/svg/chicken-2.svg`);
    const slide3 = require(`../../../assets/svg/chicken-3.svg`);

    return (
        <Layout>
            <Content className="landing-container">
                <Loading loading={loading}>
                    <div className="section-header-page-avatar" style={{marginTop: '.5rem'}}>
                        <Select defaultValue={lang} bordered={false} onChange={(e) => handleLocales(e)}>
                            <Option value="id">IND</Option>
                            <Option value="en">EN</Option>
                        </Select>
                    </div>
                    <Row>
                        <Col lg={12} md={24} sm={24}>
                            <div className="section-picture-login">
                                <div className="section-picture-center">
                                    <Carousel autoplay style={{ padding: '30px' }}>
                                        <div>
                                            <h3 className='caraousel'><img src={slide1} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide2} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide3} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={24} sm={24}>
                            <div className="section-content-login">
                                <Row>
                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                        <div className="section-logo-login">
                                            <img src={logo} alt="BroilerX logo" width="50%" />
                                        </div>
                                    </Col>
                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                        <div className="section-title-login">
                                            <span className="font-title-login black font-bold">{t('pages.auth.welcome')}</span>
                                            <br />
                                            <span className="font-title-medium black font-light">{t('pages.auth.loginTitle')}</span>
                                        </div>
                                    </Col>
                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                        <div className="section-form-login">
                                            <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="basic" initialValues={{ remember: true }}>
                                                <Row>
                                                    <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                        <span className="font-semi-bold black font-title-medium">Username/Email</span>
                                                        <Form.Item
                                                            name="username" rules={[{ required: true, message: `${t('pages.auth.msgUsername')}` }]}
                                                        >
                                                            <InputAuth
                                                                name='username'
                                                                placeholder={t('pages.auth.phUsername')}
                                                                onChange={setUsername}
                                                                value={username}
                                                                className="input-auth mt-5"
                                                            />
                                                        </Form.Item>
                                                        <span className="font-semi-bold black font-title-medium">Password</span>
                                                        <Form.Item
                                                            name="password" rules={[{ required: true, message: `${t('pages.auth.msgPass')}`}]}
                                                        >
                                                            <InputPassword
                                                                name='password'
                                                                placeholder={t('pages.auth.phPass')}
                                                                onChange={setPassword}
                                                                value={password}
                                                                className="input-auth mt-5 "
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={10} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                        <div className="section-form-button-login">
                                                            <Form.Item>
                                                                <ButtonLogin
                                                                    text={t('pages.auth.login')}
                                                                    name='login'
                                                                    height={10}
                                                                    backgroundColor="#008ad4"
                                                                    borderRadius="10px"
                                                                    className="font-semi-bold font-title-medium button-login"
                                                                    textColor="white"
                                                                    htmlType="submit"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                    <Col span={14} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                        <div className="section-form-forgot-password">
                                                            <Link to="/forgot-password"><span className="font-semi-bold font-title-content black">{t('pages.auth.forgetTitle')}</span></Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                <div className="section-register-another-login">
                                                    <span className="font-medium font-title-content black">{t('pages.auth.oauth')}</span>
                                                </div>
                                            </Col>
                                            <Row gutter={[16, 24]}>
                                                <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                    <FacebookLogin
                                                        appId="2784727991754309"
                                                        autoLoad={false}
                                                        fields="name,email,picture"
                                                        callback={responseFb}
                                                        render={(renderProps) => (
                                                            <ButtonText
                                                                text="Facebook"
                                                                height={10}
                                                                icon={faFacebook}
                                                                backgroundColor="#3b5998"
                                                                borderRadius="5px"
                                                                className="font-semi-regular font-title-small button-facebook"
                                                                textColor="white"
                                                                onClick={renderProps.onClick}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                                                    <GoogleLogin
                                                        clientId="444433730780-jdmkscsbtqbk0vshlv91vrrj1logesme.apps.googleusercontent.com"
                                                        render={(renderProps) => (
                                                            <ButtonText
                                                                text="Google"
                                                                height={10}
                                                                icon={faGoogle}
                                                                backgroundColor="#D44638"
                                                                borderRadius="5px"
                                                                className="font-semi-regular font-title-small button-facebook"
                                                                textColor="white"
                                                                onClick={renderProps.onClick}
                                                            />
                                                        )}
                                                        buttonText="Login"
                                                        onSuccess={responseGoogle}
                                                        onFailure={responseGoogle}
                                                        cookiePolicy={"single_host_origin"}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    );
}

export default LoginComponent;