export const getGudangMaterial = (keyword,limit,skip) => {
    return (
        `query{
            materialWarehouses(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
                materialWarehouses {
                    _id
                    name
                }
            }
        }
        `
    );
};

export const createGudangMaterial = (value) => {
    return{
        query: `mutation{
            createMaterialWarehouse(materialWarehouseInput: {
                name : "${value}"
              }){
                _id
              }
            }
        `
    }
}

export const deleteGudangMaterial = (_id) => {
    return (
        `mutation{
            deleteMaterialWarehouse(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const detailGudangMaterial = (_id) => {
    return (
        `query{
            materialWarehouse(_id : "${_id}"){
                _id
                name
                }
            }
        `
    );
}

export const updateGudangMaterial = (_id,value) => {
    return {
        query: `mutation{
            updateMaterialWarehouse(_id : "${_id}", updateMaterialWarehouseInput : {
              name : "${value}",
            }){
              _id
              name
            }
          }
        `
    }
}


