import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { faTrash, faInfoCircle, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import StokPakanComponent from '../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/stok-pakan-component'
import { getListPakan } from '../../../../modules/dashboard-peternak/master-component/pakan-component/store/pakan-action'
import { getListGudangPakan } from '../../../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-action'
import { getPakan } from '../../../../app/dashboard-peternak/master-page/pakan-page/pakan-aksi-page/query-pakan'
import { queryGudangPakan } from '../../../../app/dashboard-peternak/master-page/gudang-pakan-page/gudang-pakan-aksi-page/query-gudang-pakan'
import { getIdStokPakan, getNamaPakan } from '../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-action'
import { getStokGudangPakan, deleteStokPakan, addStokPakan, detailStokPakan, updateStokPakan } from './stok-pakan-aksi-page/query-stok-pakan'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import CONSTANTS from '../../../../common/utils/Constants'

import { useTranslation } from "react-i18next"

function StokPakanPage(props) {
    const { confirm } = Modal
    const { getListPakan, getListGudangPakan, getIdStokPakan, getNamaPakan, idGudangPakan } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [pagination, setPagination] = useState(10)
    const [visible, setVisible] = useState(false)
    const [visibleEdit, setVisibleEdit] = useState(false)
    const [object, setObject] = useState([])
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [stockId, setStockId] = useState(null)
    const [detail, setDetail] = useState(null)
    const [feedId, setFeedId] = useState(null)

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = (idGudangPakan) => {
        setLoading(true)
        API.get(getStokGudangPakan(idGudangPakan))
            .then(res => {
                setObject(res.data.data.feedStocksWarehouse)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(idGudangPakan)
    }, [idGudangPakan])


    useEffect(() => {
        getListPakan(getPakan('', 0, 0))
        getListGudangPakan(queryGudangPakan('', 0, 0))
    }, [getListPakan, getListGudangPakan])
    
    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteStokPakan(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteFeedStock.deleted === true) {
                    validationMessage('success', `${t("pages.stok.pakan.response.sukses.hapus")}`)
                    fetchData(idGudangPakan)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.stok.pakan.response.gagal.hapus")}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t("pages.stok.pakan.textHapus")} ${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t("general.cancel")}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
     
    const showModal = useCallback((stockId, feedId) => {
        setStockId(stockId)
        setFeedId(feedId)
        setVisible(true)
    }, [])

    const postData = (values, year) => {
        API.post(addStokPakan(values, year, stockId, feedId))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false);
                } else if (res.data.data.createFeeding._id) {
                    validationMessage('success', `${t("pages.stok.pakan.response.sukses.tambah")}`)
                    fetchData(idGudangPakan)
                }
            }).catch((error) => {
                console.log(error);
                validationMessage('error', `${t("pages.stok.pakan.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setLoading(true)
    }

    const handleCancel = useCallback((value) => {
        setVisible(false)
    }, [])
 
    const detailTranskasi = useCallback((value, nama) => {
        getIdStokPakan(value)
        getNamaPakan(nama)
        props.navigate(CONSTANTS.TRANSAKSI_STOK_PAKAN_MENU_KEY)
    }, [props, getIdStokPakan, getNamaPakan])

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailStokPakan(idData))
                    .then(res => {
                        setDetail(res.data.data.feedStock)
                        setVisibleEdit(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setLoading(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEditStok = useCallback((value) => {
        if (value === idData)
            setVisibleEdit(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancelEdit = useCallback(() => {
        setVisibleEdit(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateStokPakan(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false);
                } else if (res.data.data.updateFeedStock._id) {
                    validationMessage('success', `${t("pages.stok.pakan.response.sukses.edit")}`)
                    fetchData(idGudangPakan)
                } else if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message);
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.stok.pakan.response.gagal.edit")}`);
                setLoading(false)
            })
    }

    const handleSubmitEdit = (values) => {
        updatedData(values)
        setVisibleEdit(false)
        setIdData(null)
        setLoading(true)
    }
    /**End handle updated data */

    const columns = [
        {
            title: `${t("pages.stok.pakan.table.kode")}`,
            dataIndex: 'kode',
            key: 'kode',
            width: 70,
            ...getColumnSearchProps('kode', `${t("pages.stok.pakan.table.kode")}`),
        },
        {
            title: `${t("pages.stok.pakan.table.nama")}`,
            dataIndex: 'pakan',
            key: 'pakan',
            width: 90,
        },
        {
            title: `${t("pages.stok.pakan.table.pengadaan")}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 70,
        },
        {
            title: `${t("pages.stok.pakan.table.supplier")}`,
            dataIndex: 'perusahaan',
            key: 'perusahaan',
            width: 70,
        },
        {
            title: `${t("pages.stok.pakan.table.saldo")}`,
            dataIndex: 'total',
            key: 'total',
            width: 70,
        },
        {
            title: `${t("pages.stok.pakan.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 150,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.stok.pakan.button.tambah")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#0f6cff',
                            borderRadius: '5px'
                        }} onClick={() => showModal(row.key, row.feedId)}><FontAwesomeIcon icon={faPlus} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.stok.pakan.button.edit")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showEditStok(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.stok.pakan.button.detail")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }} onClick={() => detailTranskasi(row.key, row.namaPakan)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.stok.pakan.button.hapus")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = object.map(({ _id, nameStock, feedWarehouse, feed, feeding, price, sisaStok }, index) => ({
        key: _id,
        kode: feed.code,
        pakan: feed.name + '-' + feed.type,
        namaPakan: feed.name,
        nama: nameStock,
        perusahaan: feed.producer,
        gudang: feedWarehouse.name,
        total: (sisaStok) < 0 ? <NumberFormat value={Math.abs(sisaStok)}
            suffix={')'} prefix={'('} displayType={'text'} thousandSeparator={true} /> :
            <NumberFormat value={sisaStok} displayType={'text'} thousandSeparator={true} />,
        feedingId: feeding[0]._id,
        feedId: feed._id,
    }))

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize)
    }, [])

    return (
        <StokPakanComponent
            navigate={props.navigate}
            pagination={pagination}
            changePagination={changePagination}
            showModal={showModal}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            columns={columns}
            data={data}
            loading={loading}
            visible={visible}
            detail={detail}
            fetchData={fetchData}
            visibleEdit={visibleEdit}
            handleCancelEdit={handleCancelEdit}
            handleSubmitEdit={handleSubmitEdit}
            setLoading={setLoading}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    idGudangPakan: state.gudangPakan.idGudangPakan,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListPakan,
    getListGudangPakan,
    getIdStokPakan,
    getNamaPakan
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StokPakanPage)
export default page