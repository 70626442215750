
import React from 'react';
import { Modal,Form,Row,Col,Input, InputNumber } from 'antd';

function CreatePanenComponent(props) {
    const { TextArea } = Input;
    const {visible,handleSubmit,setVisible,showModal,locales} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel}) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={locales("pages.recording.detail.panen.modal.titleTambah")}
            okText={locales("general.create")}
            width={800}
            cancelText={locales("general.cancel")}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        keterangan : '',
                    }}
                >
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content" >{locales("pages.recording.detail.panen.modal.jumlah")}</span>
                            <Form.Item
                                name="ayam" rules={[{ required: true, message: `${locales("pages.recording.detail.deplesi.modal.msgJumlah")}` }]}  
                            >
                                 <InputNumber
                                    name='ayam'
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.panen.modal.berat")}</span>
                            <Form.Item
                                name="berat" rules={[{ required: true, message: `${locales("pages.recording.detail.panen.modal.msgBerat")}`  }]}
                            >
                                <InputNumber
                                    name='berat'
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={24} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.panen.modal.keterangan")}</span>
                            <Form.Item
                                name="keterangan"
                            >
                                <TextArea
                                    style={{borderColor:"#BE375F"}}
                                    placeholder={locales("pages.recording.detail.deplesi.modal.phKet")}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                             </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <span onClick={() => showModal()}>{locales("pages.recording.detail.panen.button.tambah")}</span>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </div>
    );
}
 
export default CreatePanenComponent;