import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action/index';
import Highlighter from 'react-highlight-words';
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table, } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

import { API } from '../../../../common/api';
import { validationMessage } from '../../../../common/component/notification/notification';

import Loading from '../../../../common/component/loading/loading-container';
import { getOverhead, deleteOverhead, detailOverhead, updateOverhead } from './overhead-aksi-page/query-overhead';
import ModalCreateOverhead from './overhead-aksi-page/create-overhead-page';
import ModalEditOverhead from './overhead-aksi-page/edit-overhead-page';

import { useTranslation } from 'react-i18next'

function OverheadPage(props) {
    const { confirm } = Modal;
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('');
    const [visible, setVisible] = useState(false);
    const [object, setObject] = useState([]);
    const [detail, setDetail] = useState('');
    const [loading, setLoading] = useState(false);
    const [idData, setIdData] = useState(null);
    const { Content } = Layout;

    const { t } = useTranslation()

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSeacrhedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    /**End handle seacrh in row antd design */

    /**Handle get data */
    const fetchData = () => {
        setLoading(true);
        API.get(getOverhead('', 0, 0))
            .then(res => {
                setObject(res.data.data.overheads.overheads);
                setLoading(false);
            }).catch((error) => {
                setObject([]);
                setLoading(false);
            })
    }

    useEffect(() => {
        fetchData();
    }, []);
    /**End handle get data */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deleteOverhead(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false);
                } else if (res.data.data.deleteOverhead.deleted === true) {
                    validationMessage('success', `${t('pages.master.overhead.response.sukses.hapus')}`)
                    fetchData();
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.overhead.response.gagal.hapus')}`)
                setLoading(false);
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.overhead.textHapus')} ${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    /**End handle delete data */


    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailOverhead(idData))
                    .then(res => {
                        setDetail(res.data.data.overhead)
                        setVisible(true);
                    }).catch((error) => {
                        setDetail(null)
                    })
            }
        }
        getDetailData();
    }, [idData]);


    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value);
    }, [idData]);

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const updatedData = (values) => {
        API.post(updateOverhead(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false);
                } else if (res.data.data.updateOverhead._id) {
                    validationMessage('success', `${t('pages.master.overhead.response.sukses.edit')}`)
                    fetchData();
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.overhead.response.gagal.edit')}`)
                setLoading(false);
            })
    }

    const handleSubmit = (values) => {
        updatedData(values);
        setVisible(false);
        setLoading(true);
        setIdData(null);
    };
    /**End handle updated data */

    const columns = [
        {
            title: `${t('pages.master.overhead.table.nama')}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama', `${t('pages.master.overhead.table.nama')}`),
        },
        {
            title: `${t('pages.master.overhead.table.price')}`,
            dataIndex: 'harga',
            key: 'harga',
            ...getColumnSearchProps('harga', `${t('pages.master.overhead.table.price')}`),
        },
        {
            title: `${t('pages.master.overhead.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 100,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title='Edit'>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const data = object.map(({ _id, name, defaultPrice }, index) => ({
        key: _id,
        nama: name,
        harga: <NumberFormat value={defaultPrice} displayType={'text'} thousandSeparator={true} />,
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>Overhead</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateOverhead fetchData={fetchData} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalEditOverhead visible={visible} detail={detail}
                        handleCancel={handleCancel} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(OverheadPage);
export default page