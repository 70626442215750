import React from 'react';
import { Modal,Form,Row,Col,Input,DatePicker,InputNumber } from 'antd';

function TambahStokMaterial(props) {
    const {visible,handleSubmit,handleCancel,t} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel}) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t('pages.stok.material.tambah.title')}
            okText={t('general.create')}
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                      <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.material.tambah.tglPenambahan')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t('pages.stok.material.tambah.msgTglPenambahan')}` }]}
                            >
                                <DatePicker 
                                    placeholder={t('pages.stok.material.tambah.phTglPenambahan')}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.material.tambah.jumlah')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message:`${t('pages.stok.material.tambah.msgJumlah')}`}]}
                            >
                                <Input
                                    name='jumlahStok'
                                    placeholder="50..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row> 
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stok.material.tambah.harga')}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, type: 'number', message: `${t('pages.stok.material.tambah.msgHarga')}` }]}
                            >
                                <InputNumber
                                    name='harga'
                                    placeholder="1000"
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
             <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default TambahStokMaterial;