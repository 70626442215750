import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { Modal, Form, Row, Col, Select, InputNumber } from 'antd'

function EditOverheadPage(props) {
    const { visible, handleCancel, detail, handleSubmit, overhead, locales } = props
    const [data, setData] = useState(detail)
    const { Option } = Select

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={locales("pages.recording.detail.overhead.modal.titleEdit")}
                okText="Edit"
                width={800}
                cancelText={locales('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        _id: data._id,
                        overhead: data.overhead,
                        jumlah: data.quantity,
                        price: data.price,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{locales("pages.recording.detail.overhead.modal.overhead")}</span>
                            <Form.Item
                                name="overhead" rules={[{ required: true, message: 'Silahkan pilih overhead!' }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih overhead"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        overhead.map(d =>
                                            <Option
                                                key={d.name.toString()}
                                                value={d._id}
                                            >{d.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.overhead.modal.jumlah")}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${locales("pages.recording.detail.overhead.modal.msgJumlah")}` }]}
                            >
                                <InputNumber
                                    name='jumlah'
                                    placeholder="100..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.overhead.modal.price")}</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `${locales("pages.recording.detail.overhead.modal.msgPrice")}` }]}
                            >
                                <InputNumber
                                    name='price'
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditOverheadPage)
export default page