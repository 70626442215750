import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { TRANSLATIONS_ID } from "./id"
import { TRANSLATIONS_EN } from "./en"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'id',
        resources: {
            id: {
                translation: TRANSLATIONS_ID
            },
            en: {
                translation: TRANSLATIONS_EN
            },
        }
    })

export default i18n