import React, { useState,useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { navigate } from '../../../../../common/store/action';
import { validationMessage } from '../../../../../common/component/notification/notification';
import CreateGudangOvkComponent from '../../../../../modules/dashboard-peternak/master-component/gudang-ovk-component/gudang-ovk-aksi-component/create-gudang-ovk-component';

import {createGudangOvk} from './query-gudang-ovk';

function CreateGudangOvkPage(props) {
    const {fetchData,total,setLoading,t} = props;
    const [visible, setVisible] = useState(false);
    const [visibleFree, setVisibleFree] = useState(false);
    const [namaGudang, setNamaGudang] = useState('');

    const showModal = useCallback(() => {
        setVisible(true);
    },[]);

    const postData = (values) => {
        API.post(createGudangOvk(values))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.createOvkWarehouse._id){
                validationMessage('success', `${t('pages.master.gudangOvk.response.sukses.tambah')}`)
                fetchData();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.gudangOvk.response.gagal.tambah')}`);
            setLoading (false);
        })
    }

    const handleSubmit = (values) => {
        postData( values.gudangOvk);
        setVisible(false);
        setLoading(true);
    };

    return ( 
        <CreateGudangOvkComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            namaGudang = {namaGudang}
            setNamaGudang = {setNamaGudang}
            tipeUser = {props.tipeUser}
            visibleFree = {visibleFree}
            setVisibleFree = {setVisibleFree}
            total = {total}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    tipeUser : state.auth.dataUser.typeUser
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreateGudangOvkPage);
export default page