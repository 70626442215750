export const getMonthlyStockLiveBirds = (keyword,limit,skip,month,year) => {
    return (
        `query{
            monthlyStockLiveBirds(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}"){
            totalCount
            locked
            idMonthlyStock
            calculateMonthlyStocks{
                _id
                bulan
                kandang
                periode
          
                stockAwalBulan
                hargaAwalBulan
                totalHargaAwal
          
                pembelian
                hsPembelian
                nilaiPembelian
                hpp
                hppPanen
          
                totalCulling
                nilaiCulling
                totalMati
                nilaiMati
                totalPanen
                nilaiPanen

                sisaStok
                nilaiStokAkhir
            }
          }
        }
      `
    );
}