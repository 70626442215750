import * as actionType from './adjustment-pakan-action-type';

const initialState = {
    idMonthlyStockFeed : null,
    loading : false,
}

const handler = (currentState) => {
    const setDataMonthlyStock = {
        startGetMonthlyStockFeeds : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetMonthlyStockFeeds : () => ({
            ...currentState,
            loading: false,
        }),

        setIdMonthlyStockFeed : data => ({
            ...currentState,
            idMonthlyStockFeed: data
        }),
    }

    return {
        ...setDataMonthlyStock
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetMonthlyStockFeeds();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetMonthlyStockFeeds();
        case actionType.SET_ID_MONTHLY_STOCK_FEED:
            return handler(state).setIdMonthlyStockFeed(payload);

        default:
            return state;
    }
}