import React from 'react';
import { Modal,Form,Row,Col,Input,DatePicker,InputNumber } from 'antd';

function TambahStokOvk(props) {
    const {visible,handleSubmit,handleCancel,suffix,locales} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel}) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={locales('pages.stok.ovk.tambah.title')}
            okText={locales('general.create')}
            width={800}
            cancelText={locales('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                      <Row gutter={[16,8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.ovk.tambah.tglPenambahan')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${locales('pages.stok.ovk.tambah.msgTglPenambahan')}`}]}
                            >
                                <DatePicker 
                                    placeholder={locales('pages.stok.ovk.tambah.phTglPenambahan')} 
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.ovk.tambah.jumlahStok')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message: `${locales('pages.stok.ovk.tambah.msgJumlahStok')}` }]}
                            >
                                <Input
                                    name='jumlahStok'
                                    placeholder="50..."
                                    className="input-suffix mt-5"
                                    suffix={suffix}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.ovk.tambah.harga')}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, type: 'number', message: `${locales('pages.stok.ovk.tambah.msgHarga')}` }]}
                            >
                                <InputNumber
                                    name='harga'
                                    placeholder="1000"
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
             <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default TambahStokOvk;