export const getMaterialStok = (keyword,limit,skip) => {
    return (
        `query{
            materialStocks(keyword: "${keyword}", limit:${limit}, skip:${skip}){
                totalCount
                materialStocks{
                        _id
                        dateStock
                        name
                        sisaStok
                        material{
                            _id
                            code
                            producer
                            type
                            name
                        }
                        materialWarehouse {
                            _id
                            name
                        }
                         materialTransaction {
                            _id
                        }
                }
            }
        }`
    )
}
export const getGudangMaterialStok = (id) => {
    return (
        `query{
            materialStocksWarehouse(warehouseId: "${id}"){
                _id
                dateStock
                name
                sisaStok
                material{
                    _id
                    code
                    producer
                    type
                    name
                }
                materialWarehouse {
                    _id
                    name
                }
                    materialTransaction {
                    _id
                }
            }
        }`
    )
}

export const addMaterial = (value,year,materialStokId,materialTransactionId) => {
    return {
        query : `
        mutation{
            createMaterialTransaction(materialTransactionInput: {
                number: ${parseInt(value.jumlahStok)},
                storeTime: "${year}",
                materialStock : "${materialStokId}"
                materialTransaction: "${materialTransactionId}",
                typeTransaction: "In",
                priceMaterial : ${parseFloat(value.harga)}
            }){
                _id
            }
        }`
    }
}

export const createMaterialStok = (value,year) => {
    return {
        query: `mutation{
                createMaterialStock(materialStockInput: {
                    number: ${parseInt(value.jumlahStok)}
                    price : ${parseFloat(value.harga)}
                    material: "${value.material.value}"
                    materialWarehouse: "${value.gudang.value}"
                    dateStock : "${year}"
                    name : "${value.namaPengadaan}"
                }){
                    _id
                }
            }
        `
    }
}

export const detailStokMaterial = (_id) => {
    return (`
        query{
            materialStock(_id: "${_id}"){
            _id
            name
            price
            material{
                value:  _id
                code
            }
            materialWarehouse{
                value: _id
                name
            }
            }
        }
    `);
}

export const materialTransaction = (_id) => {
    return (
        `query{
            materialStock(_id: "${_id}"){
              _id
              materialTransaction{
                  _id
              }
            }
          }`
    );
}

export const updateStokMaterial = (_id,value) => {
    return {
        query : `mutation{
            updateMaterialStock(_id:"${_id}",updateMaterialStockInput: {
                name: "${value.namaPengadaan}"
                material: "${value.material.value}"
                materialWarehouse: "${value.gudang.value}"
              }){
              _id
              }
            }
        `
    }
}

export const deleteTransaksiStokMaterial = (_id) => {
    return (
        `mutation{
            deleteMaterialTransaction(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const getDetailTransaksiStok = (keyword,limit,skip,materialStok,type) => {
    return (
        `query{
            materialTransactions(keyword: "${keyword}", limit:${limit}, skip:${skip}, materialStock : "${materialStok}",typeTransaction : "${type}"){
              totalCount
              materialTransactions{
                  _id
                  typeTransaction
                  number
                  storeTime
                  priceMaterial
              }
            }
          }`
    );
}

export const detailTransaksi = (_id) => {
    return (
        `query{
            materialTransaction(_id: "${_id}"){
               _id
               number
               storeTime
               value : typeTransaction
               priceMaterial
               materialStock {
                   _id
               }
             }
           }`
    );
}

export const updateTransaksi = (_id,value,year,materialStock) => {
    return {
        query:
        `mutation{
            updateMaterialTransaction( _id:"${_id}" ,updateMaterialTransactionInput: {
                number: ${parseInt(value.jumlahStok)},
                storeTime: "${year}",
                materialStock : "${materialStock._id}",
                typeTransaction: "Out",
                }){
                _id
            }
        }`
    }
}

export const updateTransaksiPenambahan = (_id,value,year,materialStock) => {
    return {
        query:
        `mutation{
            updateMaterialTransaction( _id:"${_id}" ,updateMaterialTransactionInput: {
                number: ${parseInt(value.jumlahStok)},
                storeTime: "${year}",
                materialStock : "${materialStock._id}",
                priceMaterial : ${parseFloat(value.harga)}
                typeTransaction: "In",
                }){
                _id
            }
        }`
    }
}

export const deleteMaterialStok = (_id) => {
    return (
        `mutation{
            deleteMaterialStock(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}