import React from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import CloseRearingComponent from '../../../../modules/dashboard-peternak/rearing-component/rearing-aksi-component/close-rearing-component'

function CloseRearingPage(props) {
    const {visibleClose,handleCancelClose,handleSubmitClose,date} = props
    return ( 
        <CloseRearingComponent
            navigate={props.navigate}
            visible = {visibleClose}
            handleSubmit = {handleSubmitClose}
            handleCancel = {handleCancelClose}
            date={date}
        />
    )
}

const mapStateToProps = state => ({  
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CloseRearingPage)
export default page