import React, {useCallback,useState,useEffect} from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button,Input,Tooltip,Modal } from 'antd'
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons'
import DetailDeplesiComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-deplesi-component'
import {deleteRecordDeplesi,getDetailRecordDeplesi,updateRecordDeplesi} from '../rearing-record-aksi-page/query-rearing-record'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"

function DetailDeplesiPage(props) {
    const {fetchDataDeplesi,deplesi,loading,setLoading,idRearingRecord} = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [visible, setVisible] = useState(false)
    const [idData, setIdData] = useState(null)
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [detail,setDetail] = useState("")
    const [pagination, setPagination] = useState(10)
    const { t } = useTranslation()
     
    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`${t('general.search')} ${title}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {t('general.search')}
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteRecordDeplesi(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false)
            }
            else if(res.data.data.deleteMutation.deleted === true){
                validationMessage('success', `${t("pages.recording.detail.deplesi.response.sukses.hapus")}`)
                fetchDataDeplesi(idRearingRecord)
            }
        }).catch( ( error ) =>  {
            validationMessage('error', `${t("pages.recording.detail.deplesi.response.gagal.hapus")}`)
            setLoading(false)
        })
    }
    
    const hapusConfirm = (_id)=> {
        confirm({
            title: `${t("pages.recording.detail.deplesi.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(getDetailRecordDeplesi(idData))
                .then(res => {      
                    setDetail(res.data.data.mutation)
                    setVisible(true)
                }).catch( ( error ) =>  {
                    setDetail(null)
                    setVisible(false)
                })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value)
    },[idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData =  (values) => {
        API.post(updateRecordDeplesi(idData,values))
        .then(res => {
            if(res.data.data.updateMutation._id){
                validationMessage('success', `${t("pages.recording.detail.deplesi.response.sukses.edit")}`)
                fetchDataDeplesi(idRearingRecord)
            }else if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false)
            }
        }).catch( ( error ) =>  {
            console.log(error)
            validationMessage('error', `${t("pages.recording.detail.deplesi.response.gagal.edit")}`)
            setLoading(false)
        })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const columns = [
        {
            title: `${t("pages.recording.detail.deplesi.table.jenis")}`,
            dataIndex: 'jenis',
            key: 'jenis',
            ...getColumnSearchProps('jenis', `${t("pages.recording.detail.deplesi.table.jenis")}`),
        },
        {
            title: `${t("pages.recording.detail.deplesi.table.jumlah")}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
        },
        {
            title: `${t("pages.recording.detail.deplesi.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 100,
            render: (value,row,index) => (
              <Space size="middle">
                  <Tooltip placement="top" title={t("pages.recording.detail.deplesi.button.edit")}>
                      <Button style={{
                          color:'white',
                          backgroundColor:'#FFA903',
                          borderRadius:'5px'
                      }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                  </Tooltip>
                  <Tooltip placement="top" title={t("pages.recording.detail.deplesi.button.hapus")}>
                      <Button style={{
                          color:'white',
                          backgroundColor:'#FF0303',
                          borderRadius:'5px'
                      }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                  </Tooltip>
              </Space>
            )
        }
    ]

    const data = deplesi.map( ({_id,number,type}, index) => ({
        key: _id,
        jumlah : <NumberFormat value={number} displayType={'text'} thousandSeparator={true}/>,
        jenis : type,
    }))

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize)
    }, [])

    return ( 
        <DetailDeplesiComponent
            navigate={props.navigate}
            pagination = {pagination}
            changePagination = {changePagination}
            loading = {loading}
            columns = {columns}
            data = {data}
            setLoading = {setLoading}
            showEdit = {showEdit}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            visible = {visible}
            detail = {detail}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord : state.rearingRecord.idRearingRecord
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailDeplesiPage)
export default page