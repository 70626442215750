export const getStokPakan = (keyword, limit, skip) => {
    return (
        `query{
            feedStocks(keyword: "${keyword}", limit:${limit}, skip:${skip}){
                totalCount
                feedStocks{
                _id
                nameStock :name
                sisaStok
                feed{
                    _id
                    name
                    code
                    type
                    year
                    producer
                }
                feedWarehouse{
                    _id
                    name
                }
                feeding {
                    _id
                    number
                }
            }    
        }
    }
    `
    )
}
export const getStokGudangPakan = (idGudangPakan) => {
    return (
        `query{
            feedStocksWarehouse(warehouseId: "${idGudangPakan}"){
            _id
            nameStock :name
            sisaStok
            feed{
                _id
                name
                code
                type
                year
                producer
            }
            feedWarehouse{
                _id
                name
            }
            feeding {
                _id
                number
            }
        }    
    }
    `
    )
}

export const detailStokPakan = (_id) => {
    return (
        `query{
            feedStock(_id: "${_id}"){
              _id
              number
              name
              price
              feed{
                value:_id
                code
                type
                producer
                year
              }
              feedWarehouse{
                value : _id
                name
              }
            }
          }`
    );
}

export const feeding = (_id) => {
    return (
        `query{
            feedStock(_id: "${_id}"){
              _id
              feeding{
                  _id
              }
            }
          }`
    );
}

export const updateStokPakan = (_id, value) => {
    return {
        query:
            `mutation{
            updateFeedStock(_id: "${_id}",updateFeedStockInput:{
                name: "${value.namaPengadaan}"
                feed: "${value.pakan.value}"
                feedWarehouse: "${value.gudangPakan.value}"
            }){
                _id
            }
        }
        `
    }
}

export const deleteStokPakan = (_id) => {
    return (
        `mutation{
            deleteFeedStock(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const createStokPakan = (value, year) => {
    return {
        query: `mutation{
              createFeedStock(feedStockInput: {
                number: ${parseFloat(value.jumlahStok)}
                feed: "${value.pakan.value}"
                feedWarehouse: "${value.gudangPakan.value}"
                dateStock : "${year}"
                price : ${parseFloat(value.hargaSatuan)}
                name : "${value.namaPengadaan}"
              }){
                _id
              }
            }

            `
    }
}

export const addStokPakan = (value, year, stockId, feedId) => {
    return {
        query: `mutation{
                    createFeeding(feedingInput:{
                        number: ${parseInt(value.jumlahStok)}
                        storeTime: "${year}"
                        feedStock : "${stockId}"
                        priceFeeding : ${parseFloat(value.harga)}
                        typeFeeding : "In"
                    }){
                        _id
                    }
                }
            `
    }
}

export const deleteTransaksiStokPakan = (_id) => {
    return (
        `mutation{
            deleteFeeding(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const getDetailTransaksiStok = (keyword, limit, skip, feedStok, type) => {
    return (
        `query{
            feedings(keyword: "${keyword}", limit:${limit}, skip:${skip}, feedStock : "${feedStok}" , typeFeeding : "${type}"){
              totalCount
              feedings{
                  _id
                  typeFeeding
                  number
                  storeTime
                  priceFeeding
              }
            }
          }`
    );
}

export const detailTransaksi = (_id) => {
    return (
        `query{
            feeding(_id: "${_id}"){
               _id
               number
               storeTime
               value : typeFeeding
               priceFeeding
               feedStock {
                   _id
               }
             }
           }`
    );
}

export const updateTransaksiPenggunaan = (_id, value, year, feedStock) => {
    return {
        query:
            `mutation{
            updateFeeding( _id:"${_id}" ,updateFeedingInput: {
                number: ${parseInt(value.jumlahStok)},
                priceFeeding: ${value.price}
                storeTime: "${year}",
                feedStock : "${feedStock._id}",
                typeFeeding: "Out",
                }){
                _id
            }
        }`
    }
}

export const updateTransaksiPenambahan = (_id, value, year, feedStock) => {
    return {
        query:
            `mutation{
            updateFeeding( _id:"${_id}" ,updateFeedingInput: {
                number: ${parseInt(value.jumlahStok)},
                storeTime: "${year}",
                feedStock : "${feedStock._id}",
                typeFeeding: "In",
                priceFeeding : ${parseFloat(value.harga)}
                }){
                _id
            }
        }`
    }
}

