import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import Highlighter from 'react-highlight-words';
import { Space, Tooltip, Button, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined } from '@ant-design/icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import StrainComponent from '../../../../modules/dashboard-peternak/master-component/strain-component/strain-component'
import { getDataStrain, getIdStrain } from '../../../../modules/dashboard-peternak/master-component/strain-component/store/strain-action'
import { getStrain } from './strain-aksi-page/query-strain'
import { API } from '../../../../common/api'
import CONSTANTS from '../../../../common/utils/Constants'
import { useTranslation } from 'react-i18next'

function StrainPage(props) {
    const { getDataStrain, getIdStrain } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [pagination, setPagination] = useState(10)
    const [object, setObject] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = () => {
        setLoading(true)
        API.get(getStrain())
            .then(res => {
                setObject(res.data.data.strains.strains)
                getDataStrain(res.data.data.strains.strains)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                getDataStrain([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const showDetail = useCallback((value) => {
        getIdStrain(value)
        props.navigate(CONSTANTS.STANDAR_STRAIN_MENU_KEY)
    }, [getIdStrain, props])


    const data = object.map(({ _id, name }, index) => ({
        key: _id,
        id: _id,
        strain: name,
    }))

    const columns = [
        {
            title: `${t('pages.master.strain.table.nama')}`,
            dataIndex: 'strain',
            key: 'strain',
            ...getColumnSearchProps('strain', `${t('pages.master.strain.table.nama')}`),
        },
        {
            title: `${t('pages.master.strain.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 70,
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title='Detail'>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }} onClick={() => showDetail(row.id)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize)
    }, [])


    return (
        <StrainComponent
            navigate={props.navigate}
            pagination={pagination}
            changePagination={changePagination}
            columns={columns}
            data={data}
            loading={loading}
            setLoading={setLoading}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getDataStrain,
    getIdStrain
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StrainPage)
export default page