import React, { useState,useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreateStokMaterialComponent from '../../../../../modules/dashboard-peternak/adjustment-stock-component/material-component/material-aksi-component/create-adjustment-material-component'
import {createAdjustmentMaterial} from './query-adjustment-material-page'

function CreateAdjustmentMaterialPage(props) {
    const {setLoading,fetchData,idMonthlyStockMaterial,object, locked,t} = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    },[])

    const postData =  (values,idMonthlyStockMaterial) => {
        API.post(createAdjustmentMaterial(values,idMonthlyStockMaterial))
        .then(res => {
            if(res.data.data.createAdjustmentMaterial._id){
                validationMessage('success', `${t('pages.stockflow.periode.penyesuaian.material.response.sukses.tambah')}`)
                fetchData(idMonthlyStockMaterial)
            }
        }).catch((error) => {
            console.log(error)
            validationMessage('error', `${t('pages.stockflow.periode.penyesuaian.material.response.gagal.tambah')}`)
            setLoading (false)
        })
    }

    const handleSubmit = (values) => {
        postData(values,idMonthlyStockMaterial)
        setVisible(false)
        setLoading(true)
    }

    return ( 
        <CreateStokMaterialComponent
            showModal = {showModal}
            object={object}
            locked={locked}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            material = {props.material}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    material : state.material.dataMaterial,
    idMonthlyStockMaterial : state.adjustmentMaterial.idMonthlyStockMaterial,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateAdjustmentMaterialPage)
export default page