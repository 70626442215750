import React from 'react'
import { Layout, Row, Col, Table } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import EditMaterial from '../../../../app/dashboard-peternak/rearing-record-page/edit-detail-rearing-page/edit-material-page'

function DetailMaterialComponent(props) {
    const { Content } = Layout
    const { data, columns, loading, visible, handleSubmit, handleCancel, detail, monthlyMaterial } = props
    return (
        <Layout>
            <Content className="dashboard-section-content background-white">
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <div className="dashboard-section-table-dashboard">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    bordered
                                    pagination={false}
                                    className="table-dashboard"
                                />
                            </div>
                        </Loading>
                    </Col>
                </Row>
                <EditMaterial visible={visible} handleSubmit={handleSubmit} detail={detail}
                    handleCancel={handleCancel} monthlyMaterial={monthlyMaterial} />
            </Content>
        </Layout>
    )
}

export default DetailMaterialComponent