import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import CONSTANTS from '../../../../common/utils/Constants'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button, Input, Tooltip, Modal } from 'antd'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import DetailPenyakitComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-penyakit-component'
import { getDetailRecordPenyakit, updateRecordPenyakit, getDetailRecordPenyakitEdit } from '../rearing-record-aksi-page/query-rearing-record'
import { getIdDiseaseRecord, getDetailDiseaseRecord } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faImage } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"

function DetailPenyakitPage(props) {
    const { penyakit, fetchDataPenyakit, idRearingRecord, setLoading, loading, getDetailDiseaseRecord, getIdDiseaseRecord } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [visible, setVisible] = useState(false)
    const [visiblePicture, setVisiblePicture] = useState(false)
    const [idData, setIdData] = useState(null)
    const [idDataPicture, setIdDataPicture] = useState(null)
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [detail, setDetail] = useState("")
    const [detailFoto, setDetailFoto] = useState([])
    const [pagination, setPagination] = useState(10)
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteData = (_id) => {
        setLoading(true);
        API.deleteRest(`/hapus-penyakit/${_id}`)
            .then(res => {
                if (res.data.success === true) {
                    validationMessage('success', `${t("pages.recording.detail.penyakit.response.sukses.hapus")}`)
                    fetchDataPenyakit(idRearingRecord)
                } else {
                    validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.hapus")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.hapus")}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.recording.detail.penyakit.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            onOk: () => { deleteData(_id) },
            onCancel() { console.log('Cancel') }
        })
    }

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(getDetailRecordPenyakit(idData))
                    .then(res => {
                        setDetail(res.data.data.diseaseRecord)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value) => {
        getDetailDiseaseRecord(getDetailRecordPenyakitEdit(value))
    }, [getDetailDiseaseRecord])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateRecordPenyakit(idData, values))
            .then(res => {
                if (res.data.data.updateDiseaseRecord._id) {
                    validationMessage('success', `${t("pages.recording.detail.penyakit.response.sukses.edit")}`)
                    fetchDataPenyakit(idRearingRecord)
                } else if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.edit")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const showPicture = useCallback((value) => {
        props.navigate(CONSTANTS.FOTO_PENYAKIT_RECORDING_MENU_KEY)
        setIdDataPicture(value)
        getIdDiseaseRecord(value)
    }, [props, getIdDiseaseRecord])

    useEffect(() => {
        function getDetailData() {
            if (idDataPicture) {
                API.get(getDetailRecordPenyakit(idDataPicture))
                    .then(res => {
                        setDetailFoto(res.data.data.diseaseRecord.pictureDisease)
                        setVisiblePicture(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetailFoto([])
                        setVisiblePicture(false)
                    })
            }
        }
        getDetailData()
    }, [idDataPicture])


    const columns = [
        {
            title: `${t("pages.recording.detail.penyakit.table.jumlah")}`,
            dataIndex: 'ayam',
            key: 'ayam',
        },
        {
            title: `${t("pages.recording.detail.penyakit.table.penyakit")}`,
            dataIndex: 'penyakit',
            key: 'penyakit',
            ...getColumnSearchProps('penyakit', `${t("pages.recording.detail.penyakit.table.penyakit")}`),
        },
        {
            title: `${t("pages.recording.detail.penyakit.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 100,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.recording.detail.penyakit.button.foto")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#30c758',
                            borderRadius: '5px'
                        }} onClick={() => showPicture(row.key)}><FontAwesomeIcon icon={faImage} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.recording.detail.penyakit.button.edit")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.recording.detail.penyakit.button.hapus")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = penyakit.map(({ _id, number, disease }, index) => ({
        key: _id,
        ayam: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} />,
        penyakit: disease.name
    }))

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize)
    }, [])

    return (
        <DetailPenyakitComponent
            navigate={props.navigate}
            pagination={pagination}
            changePagination={changePagination}
            loading={loading}
            columns={columns}
            data={data}
            setLoading={setLoading}
            showEdit={showEdit}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            visiblePicture={visiblePicture}
            setVisiblePicture={setVisiblePicture}
            visible={visible}
            detail={detail}
            idDataPicture={idDataPicture}
            setDetailFoto={setDetailFoto}
            detailFoto={detailFoto}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdDiseaseRecord,
    getDetailDiseaseRecord
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailPenyakitPage)
export default page