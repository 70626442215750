import React from 'react'
import { Layout, Row, Col, Table, Select, Modal, Dropdown, Form, Button, DatePicker, Menu } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PeriodeLiveBirdComponent(props) {
    const { columns, data, loading, yearRangeLiveBird, yearLiveBird, setYearLiveBird,
        createConfirm, visible, setVisible, createNewData, t } = props
    const { Option } = Select
    const [form] = Form.useForm()

    const menu = () => {
        return (
            <Menu>
                <Menu.Item key="1">
                    <div onClick={() => setVisible(true)}>Manual</div>
                    <Modal
                        visible={visible}
                        title={`Manual Input ${t('pages.stockflow.periode.livebird.date')}`}
                        okText={t("general.create")}
                        width={800}
                        cancelText={t('general.cancel')}
                        onCancel={() => setVisible(false)}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields()
                                    createNewData(values.date)
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                })
                        }}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                        >
                            <Row gutter={[16, 8]}>
                                <Col span={24}>
                                    <span className="font-semi-bold black font-title-medium">{t("pages.stockflow.periode.livebird.date")}</span>
                                    <Form.Item
                                        name="date" rules={[{ required: true, message: `${t("pages.stockflow.periode.livebird.msgDate")}` }]}
                                    >
                                        <DatePicker
                                            placeholder={t("pages.stockflow.periode.livebird.phDate")}
                                            format={'MMMM YYYY'}
                                            picker='month'
                                            className="select-input-date mt-5"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Menu.Item>
                <Menu.Item key="2" onClick={() => createConfirm()}>
                    Auto
                </Menu.Item>
            </Menu>
        )
    }
    return (
        <Layout style={{ backgroundColor: "white" }}>
            <Row>
                <Col lg={4} md={4} sm={4}>
                    <Select
                        showSearch
                        labelInValue
                        placeholder="Pilih Tahun"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        value={{ value: yearLiveBird }}
                        className="select-kategori black font-regular mt-5 mb-10"
                        onChange={(value) => setYearLiveBird(value.value)}
                    >
                        <Option key={'Semua'} value={'Semua'}>{t('pages.stockflow.periode.table.all')}</Option>
                        {yearRangeLiveBird &&
                            yearRangeLiveBird.map(data =>
                                <Option key={data} value={data}>{data}</Option>
                            )
                        }
                    </Select>
                </Col>
                <Col offset={16} lg={4} md={4} sm={4}>
                    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#008ad4',
                            borderRadius: '5px',
                            float: "right",
                        }}
                        >
                            <FontAwesomeIcon icon={faPlusSquare} style={{ marginRight: "10px" }} />
                            {t('pages.stockflow.periode.livebird.create')}
                        </Button>
                    </Dropdown>
                </Col>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={false}
                            scroll={{ y: '60vh' }}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
            </Row>
        </Layout>
    )
}

export default PeriodeLiveBirdComponent