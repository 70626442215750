import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../common/store/action'
import { API } from '../../common/api'
import { validationMessage } from '../../common/component/notification/notification'
import ForgotPasswordComponent from '../../modules/forgot-password/forgot-password-component'
import CONSTANTS from '../../common/utils/Constants'
import { useTranslation } from "react-i18next"
import i18n from '../../translations/i18n'

function ForgotPasswordPage(props) {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const [lang] = useState(i18n.language)

    const handleLocales = useCallback((e) => {
        if (i18n.language !== e) {
            i18n.changeLanguage(e)
        }
    }, [])

    const handleSubmit = (value) => {
        const query = {
            query:
                `mutation{
                sendLinkForgetPassword(email: "${value.email}"){
                  email
                }
              }
            `,
        };
        if (email !== null) {
            const postData = async () => {
                setLoading(true);
                await API.post(query)
                    .then(res => {
                        if (res.data.data === null) {
                            validationMessage('error', res.data.errors[0].message)
                            setLoading(false);
                        } else {
                            try {
                                if (res.data.data.sendLinkForgetPassword.email) {
                                    validationMessage('success', 'Silahkan Cek Email Anda');
                                    props.navigate(CONSTANTS.HOME_MENU_KEY);
                                    setLoading(false);
                                }
                                else {
                                    validationMessage('error', 'Gagal Mengirimkan Email')
                                    setLoading(false);
                                }
                            } catch (error) {
                                console.log(error);
                                validationMessage('error', 'Gagal Mengirimkan Email');
                                setLoading(false);
                            }
                        }
                    })
            }

            postData();
        } else {
            validationMessage('error', 'Email Belum Dimasukkan!');
        }
    };

    return (
        <ForgotPasswordComponent
            navigate={props.navigate}
            handleSubmit={handleSubmit}
            email={email}
            setEmail={setEmail}
            loading={loading}
            handleLocales={handleLocales}
            t={t}
            lang={lang}
        />
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
export default page