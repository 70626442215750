import React, { useState } from 'react'
import { Modal, Form, Row, Col, Select, Input, InputNumber } from 'antd'

function CreateOvkComponent(props) {
    const { Option } = Select
    const [form] = Form.useForm()
    const { visible, handleSubmit, setVisible, stokOvk, showModal, monthlyOvk, locales } = props
    const [unit, setUnit] = useState('')

    const onChangeOvk = (value) => {
        const unitOvk = stokOvk.filter(d => d.ovk.name === value.label)[0].ovk.ovkUnitRecording.name
        setUnit(unitOvk)
        let idOvk = stokOvk.filter(d => d._id === value.value)[0].ovk._id
        let price = monthlyOvk.filter(d => d.idOvk === idOvk)[0]?.hargaPengeluaran
        form.setFieldsValue({
            price: price
        })
    }

    return (
        <div>
            <span onClick={() => showModal()}>{locales("pages.recording.detail.ovk.button.tambah")}</span>
            <Modal
                visible={visible}
                title={locales("pages.recording.detail.ovk.modal.titleTambah")}
                okText={locales('general.create')}
                width={500}
                cancelText={locales('general.cancel')}
                onCancel={() => { setVisible(false) }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{locales("pages.recording.detail.ovk.modal.ovk")}</span>
                            <Form.Item
                                name="ovk" rules={[{ required: true, message: `${locales("pages.recording.detail.ovk.modal.msgOvk")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={locales("pages.recording.detail.ovk.modal.phOvk")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                    onChange={(value) => onChangeOvk(value)}
                                >
                                    {
                                        stokOvk.map(data =>
                                            <Option
                                                key={data.ovk.name.toString()}
                                                unit={data.ovk.ovkUnitRecording.name}
                                                value={data._id}
                                            >{data.ovk.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.ovk.modal.jumlah")}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${locales("pages.recording.detail.ovk.modal.msgJumlah")}` }]}
                            >
                                <Input type='number'
                                    name='jumlah'
                                    placeholder="1000..."
                                    className="input-form mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    suffix={unit}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Harga (Rp)</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                            >
                                <InputNumber
                                    placeholder="2500..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default CreateOvkComponent