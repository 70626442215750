import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Row, Col, Breadcrumb, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faSlidersH, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"

import { getIot, updateIot, deleteIot, detailIot, getTypeIot } from './iot-aksi-page/query-iot'
import { getListTipeIot } from './store/iot-action'

import ModalEditIot from './iot-aksi-page/edit-iot-page'
import CreateTare from './iot-aksi-page/create-tare'
import Loading from '../../../common/component/loading/loading-container'
import { API } from '../../../common/api'
import { navigate } from '../../../common/store/action'
import { errMessage } from '../../../common/component/notification/notification'

function IotPage(props) {
    const { confirm } = Modal
    const { getListTipeIot, role } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [idData, setIdData] = useState(null)
    const [deviceId, setDeviceId] = useState(null)
    const [dataDevice, setDataDevice] = useState(null)
    const [visible, setVisible] = useState(false)
    const [visibleTare, setVisibleTare] = useState(false)
    const [object, setObject] = useState([])
    const [detail, setDetail] = useState('')
    const { Content } = Layout
    const { t } = useTranslation()

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t("general.reset")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async () => {
        setLoading(true)
        await API.get(getIot('', 0, 0))
            .then(res => {
                setObject(res.data.data.devices.devices)
                setLoading(false)
            }).catch((error) => {
                console.log(error.message)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        getListTipeIot(getTypeIot())
    }, [getListTipeIot])

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteIot(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t("pages.monitor.resDelFailed")}`, res.data.errors[0].message)
                } else if (res.data.data.deleteDevice.deleted === true) {
                    errMessage('success', `${t("pages.monitor.resDelSuccess")}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t("pages.monitor.resDelFailed")}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, serial) => {
        confirm({
            title: `${t("pages.monitor.delConfirm")} ${serial}`,
            onOk: () => { deleteData(_id) },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailIot(idData))
                    .then(res => {
                        setDetail(res.data.data.device)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const showTare = useCallback((value, row) => {
        if (value === deviceId)
            setVisibleTare(true)
        else {
            setVisibleTare(true)
            setDeviceId(value)
            setDataDevice(row)
        }
    }, [deviceId])

    const updatedData = async (values) => {
        await API.post(updateIot(idData, values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t("pages.monitor.resEditFailed")}`, res.data.errors[0].message)
                } else if (res.data.data.updateDevice._id) {
                    errMessage('success', `${t("pages.monitor.resEditSuccess")}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t("pages.monitor.resEditFailed")}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setLoading(true)
        setIdData(null)
    }

    const columns = [
        {
            title: `${t("pages.monitor.serial")}`,
            dataIndex: 'serial_number',
            key: 'serial_number',
            ...getColumnSearchProps('serial_number'),
        },
        {
            title: `${t("pages.monitor.house")}`,
            dataIndex: 'kandang',
            key: 'kandang',
            ...getColumnSearchProps('kandang'),
        },
        {
            title: `${t("pages.monitor.position")}`,
            dataIndex: 'posisi',
            key: 'posisi',
        },
        {
            title: `${t("pages.monitor.tool")}`,
            dataIndex: 'alat',
            key: 'alat',
        },
        role === 'staff' ? { width: 1 } :
            {
                title: `${t("general.action")}`,
                dataIndex: 'pilihan',
                key: 'pilihan',
                width: 200,
                align: 'center',
                render: (value, row, index) => (
                    <Space size="middle">
                        <Tooltip placement="top" title={t("pages.monitor.calibrate")}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#00923F',
                                borderRadius: '5px'
                            }}
                                onClick={() => showTare(row.deviceId, row)}
                            ><FontAwesomeIcon icon={faSlidersH} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t("general.edit")}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t("general.del")}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key, row.serial_number)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                        {/* <Tooltip placement="top" title={t("general.detail")}>
                            <Button hidden style={{
                                color: 'white',
                                backgroundColor: '#00923F',
                                borderRadius: '5px'
                            }}
                                onClick={() => props.navigate(CONSTANTS.DETAIL_DEVICE_KEY)}
                            ><FontAwesomeIcon icon={faLaptopHouse} /></Button>
                        </Tooltip> */}
                    </Space>
                )
            }
    ]

    const data = object.map(({ _id, deviceType, house, serialNumber, position, deviceId }, index) => ({
        key: _id,
        id: _id,
        serial_number: serialNumber,
        posisi: position,
        alat: deviceType,
        kandang: house?.name,
        deviceId: deviceId,
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t("pages.monitor.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh' }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                </Row>
                <ModalEditIot visible={visible} idData={idData} t={t} dataTipeIot={props.dataTipeIot}
                    handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} />

                <CreateTare visible={visibleTare} setVisible={setVisibleTare} t={t} deviceName={dataDevice?.serial_number}
                    setLoading={setLoading} fetchData={fetchData} deviceId={deviceId} idDevice={dataDevice?.id} deviceType={dataDevice?.alat}
                />
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    dataTipeIot: state.iot.dataTipeIot,
    role: state.auth.dataUser.role,

})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListTipeIot
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(IotPage)
export default page