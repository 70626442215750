import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditPeriodeOvkComponent from '../../../../../modules/dashboard-peternak/stock-flow-component/periode-component/periode-aksi-component/edit-periode-ovk-component'

function EditPeriodeOvkPage(props) {
    const {visible,handleCancel,detail,handleSubmit,t} = props
    const [data,setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData()
    }, [detail])

    return ( 
        <EditPeriodeOvkComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            t={t}
        />
    )
}

const mapStateToProps = state => ({  
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPeriodeOvkPage)
export default page