import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import NumberFormat from 'react-number-format'

function CreateAdjustmentOvk(props) {
    const { visible, handleSubmit, setVisible, showModal, locked, object, t } = props
    const { Option } = Select
    const [form] = Form.useForm()
    const [hs, setHs] = useState(null)
    const [qty, setQty] = useState(null)
    const [dif, setDif] = useState(null)
    const [unit, setUnit] = useState(null)

    const sak = (value) => {
        const hs = object.filter(d => d?.idOvk === value)[0].hargaPengeluaran
        const qty = object.filter(d => d?.idOvk === value)[0].sisaStok
        const unitOvk = object.filter(d => d?.idOvk === value)[0].satuan
        setHs(hs)
        setQty(qty)
        setUnit(unitOvk)
    }
    const diff = (e) => {
        const value = e.target.value
        setDif(value - qty)
    }

    return (
        <div>
            <ButtonDashboard
                text={t('pages.stockflow.periode.penyesuaian.ovk.tambah')}
                height={20}
                disabled={locked ? true : false}
                backgroundColor={locked ? "#d9d9d9" : "#008ad4"}
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <Modal
                visible={visible}
                title={t('pages.stockflow.periode.penyesuaian.ovk.tambah')}
                okText={t('general.create')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={() => { setVisible(false) }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">OVK</span>
                            <Form.Item style={{ marginBottom: 0 }}
                                name="ovk" rules={[{ required: true, message: `${t('pages.stockflow.periode.penyesuaian.msgSelect')} OVK` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={`${t('pages.stockflow.periode.penyesuaian.msgSelect')} OVK`}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                    onChange={(value) => sak(value.value)}
                                >
                                    {
                                        object.filter(d => d !== null).map(data =>
                                            <Option
                                                key={data.namaOvk.toString()}
                                                value={data.idOvk}
                                            >{data.namaOvk}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.periode.penyesuaian.jumlah')}</span>
                            <Form.Item style={{ marginBottom: 0 }}
                                name="jumlah" rules={[{ required: true, message: `${t('pages.stockflow.periode.penyesuaian.msgJumlah')}` }]}
                            >
                                <Input
                                    name='jumlah' type='number'
                                    placeholder="10..."
                                    className="input-form mt-5"
                                    onChange={(value) => diff(value)}
                                    suffix={unit}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span>{t('pages.stockflow.periode.penyesuaian.jumlah')}: <b><NumberFormat value={qty} displayType={'text'} thousandSeparator={true} /></b></span>
                        </Col>
                        <Col span={6}>
                            <span>HS: <b><NumberFormat value={hs} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /></b></span>
                        </Col>
                        <Col span={6}>
                            <span>{t('pages.stockflow.periode.penyesuaian.difference')}: <b><NumberFormat value={Math.abs(dif)} displayType={'text'} thousandSeparator={true} /></b></span>
                        </Col>
                        <Col span={6}>
                            <span>HS: <b><NumberFormat value={hs} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /></b></span>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default CreateAdjustmentOvk