import React from 'react';
import { Input } from 'antd';
import './scss/input-auth.scss';

function InputAuth({
    value, onChange, placeholder, className, style, name, type,
}) {
    return (        
        <Input
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={className}
            style={style}
            type={type}
        />
    );
}

export default InputAuth;