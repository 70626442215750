import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import EditGudangOvkComponent from '../../../../../modules/dashboard-peternak/master-component/gudang-ovk-component/gudang-ovk-aksi-component/edit-gudang-ovk-component';

function EditGudangOvkPage(props) {
    const {visible,handleCancel,detail,handleSubmit,t} = props;
    const [data,setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail.name)
            }
        }   
        setDetailData();
    }, [detail]);

    return ( 
        <EditGudangOvkComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            data = {data}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditGudangOvkPage);
export default page