import React from 'react';
import { Button } from 'antd';
import './button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ButtonDashboard({
    type, text, disabled, borderRadius,
    textColor , border = 'none',
    margin = '0px', marginLeft, marginRight, marginTop, marginBottom,backgroundColor,
    className,onClick,paddingLeft,paddingRight,icon,htmlType
}) {
    return (
        <Button
            type={type}
            className={className}
            disabled={disabled}
            htmlType = {htmlType}
            style={{
                backgroundColor: backgroundColor,
                color: textColor,
                borderRadius: borderRadius,
                border: border,
                margin: margin,
                marginLeft: marginLeft,
                marginRight: marginRight,
                marginTop: marginTop,
                marginBottom: marginBottom,
                paddingRight :paddingRight,
                paddingLeft : paddingLeft,
            }}
            onClick={onClick}
        >
            <FontAwesomeIcon
                icon={icon}
                style={{marginRight: 10}}
            /> 
            {text}
        </Button>
    );
}

export default ButtonDashboard;