import React from 'react';
import { Layout, Row, Col,Table } from 'antd';
import Loading from '../../../../../common/component/loading/loading-container';
import ModalEditPenggunaanStokOvk from '../../../../../app/dashboard-peternak/stok-page/stok-ovk-page/transaksi-stok-ovk-page/edit-penggunaan-stok-ovk-page';

function PenggunaanStokPakanComponent(props) {
    const { columns,data,loading,detail,visible,idData,handleSubmit,handleCancel,satuanRecordingOvk,t} = props;
    return ( 
        <Layout style={{backgroundColor:"white"}}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table 
                            columns={columns} 
                            dataSource={data}
                            bordered 
                            pagination={false}
                            scroll={{y: '70vh'}}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
                <ModalEditPenggunaanStokOvk visible= {visible} handleSubmit={handleSubmit} 
                idData={idData} handleCancel ={handleCancel} detail={detail} satuanRecordingOvk={satuanRecordingOvk} t={t}/>
            </Row>
        </Layout>
    );
}
 
export default PenggunaanStokPakanComponent