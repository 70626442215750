import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input } from 'antd'
import NumberFormat from 'react-number-format'
import { API } from '../../../../common/api'
import { SearchOutlined } from '@ant-design/icons'
import { getMonthlyStockMaterials } from './material-aksi-page/query-stock-flow-material'
import MaterialComponent from '../../../../modules/dashboard-peternak/stock-flow-component/material-component/material-component'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { useTranslation } from 'react-i18next'

function MaterialPage(props) {
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [idStockflow, setId] = useState(null)
    const [month, setMonth] = useState(moment().format('MMMM'))
    const [year, setYear] = useState(moment().format('YYYY'))
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    /**Handle Get Data */
    const fetchData = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockMaterials('', 0, 0, month, year))
            .then(res => {
                setObject(res.data.data.monthlyStockMaterials.calculateMonthlyStocks)
                setId(res.data.data.monthlyStockMaterials.calculateMonthlyStocks[0].idMonthlyStockMaterial)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(month, year)
    }, [month, year])

    const columns = [
        {
            title: `Code`,
            dataIndex: 'code',
            key: 'code',
            fixed: 'left',
            width: 70,
            ...getColumnSearchProps('code', `Code`),
        },
        {
            title: `${t('pages.stockflow.material.table.material')}`,
            dataIndex: 'material',
            key: 'material',
            fixed: 'left',
            width: 100,
            ...getColumnSearchProps('material', `${t('pages.stockflow.material.table.material')}`),
        },
        {
            title: `${t('pages.stockflow.material.table.saldoAwal.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.material.table.saldoAwal.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyAwal',
                    key: 'qtyAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.saldoAwal.hs')}`,
                    className: 'right',
                    dataIndex: 'hargaSatuanAwal',
                    key: 'hargaSatuanAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.saldoAwal.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalAwal',
                    key: 'hargaTotalAwal',
                    width: 120,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.material.table.masuk.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.material.table.masuk.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPengadaan',
                    key: 'qtyPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.masuk.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPengadaan',
                    key: 'hsPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.masuk.nilai')}`,
                    className: 'right',
                    dataIndex: 'totalPengadaan',
                    key: 'totalPengadaan',
                    width: 120,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.material.table.keluar.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.material.table.keluar.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenggunaan',
                    key: 'qtyPenggunaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.keluar.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPenggunaan',
                    key: 'hsPenggunaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.keluar.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalPenggunaan',
                    key: 'hargaTotalPenggunaan',
                    width: 120,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.material.table.penyesuaian.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.material.table.penyesuaian.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenyesuaian',
                    key: 'qtyPenyesuaian',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.penyesuaian.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPenyesuaian',
                    key: 'hsPenyesuaian',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.penyesuaian.nilai')}`,
                    className: 'right',
                    dataIndex: 'hsPenyesuaian',
                    key: 'hsPenyesuaian',
                    width: 120,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.material.table.saldoAkhir.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.material.table.saldoAkhir.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyStokAkhir',
                    key: 'qtyStokAkhir',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.saldoAkhir.hs')}`,
                    className: 'right',
                    dataIndex: 'hsStokAkhir',
                    key: 'hsStokAkhir',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.material.table.saldoAkhir.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalStokAkhir',
                    key: 'hargaTotalStokAkhir',
                    width: 120,
                },
            ],
        },
    ]
   
    const filter1 = object.filter(a => a !== null)
    const filter2 = filter1.filter(a=> a.stockAwalBulan !== 0 || a.hargaAwalBulan !== 0 || a.addStock !== 0 || a.hargaPengadaan !== 0 || a.outStock !==0 || a.qty)

    const data = filter2.map(({ _id, idMonthlyStockMaterial, namaMaterial, code, addStock, stockAwalBulan, hargaAwalBulan, hargaTotalAwalBulan,
        outStock, sisaStok, qty, price, hargaTotalPenyesuaian, hargaPengeluaran, nilaiSisaStok, nilaiPengadaan, hargaPengadaan, nilaiPengeluaran }, index) => ({
            key: _id,
            id: idMonthlyStockMaterial,
            material: namaMaterial,
            code: code,

            stokAwal: stockAwalBulan,
            hargaAwalBulan: hargaAwalBulan,
            hargaTotalAwalBulan: hargaTotalAwalBulan,
            qtyAwal: <NumberFormat value={stockAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaSatuanAwal: <NumberFormat value={hargaAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaTotalAwal: <NumberFormat value={hargaTotalAwalBulan} displayType={'text'} thousandSeparator={true} />,

            pengadaan: addStock,
            hargaPengadaan: hargaPengadaan,
            totalHargaPengadaan: nilaiPengadaan,
            qtyPengadaan: <NumberFormat value={addStock} displayType={'text'} thousandSeparator={true} />,
            hsPengadaan: <NumberFormat value={hargaPengadaan} displayType={'text'} thousandSeparator={true} />,
            totalPengadaan: <NumberFormat value={nilaiPengadaan} displayType={'text'} thousandSeparator={true} />,

            penggunaan: outStock,
            hargaPenggunaan: hargaPengeluaran,
            totalHargaPenggunaan: nilaiPengeluaran,
            qtyPenggunaan: <NumberFormat value={outStock} displayType={'text'} thousandSeparator={true} />,
            hsPenggunaan: <NumberFormat value={hargaPengeluaran} displayType={'text'} thousandSeparator={true} />,
            hargaTotalPenggunaan: <NumberFormat value={nilaiPengeluaran} displayType={'text'} thousandSeparator={true} />,

            penyesuaian: qty,
            hargaPenyesuaian: price,
            totalHargaPenyesuaian: hargaTotalPenyesuaian,
            qtyPenyesuaian: <NumberFormat value={qty} displayType={'text'} thousandSeparator={true} />,
            hsPenyesuaian: <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />,
            hargaTotalPenyesuaian: <NumberFormat value={hargaTotalPenyesuaian} displayType={'text'} thousandSeparator={true} />,

            stokAkhir: (sisaStok),
            hargaStokAkhir: hargaPengeluaran,
            totalHargaStokAkhir: nilaiSisaStok,
            qtyStokAkhir: <NumberFormat value={sisaStok} displayType={'text'} thousandSeparator={true} />,
            hsStokAkhir: <NumberFormat value={hargaPengeluaran} displayType={'text'} thousandSeparator={true} />,
            hargaTotalStokAkhir: <NumberFormat value={nilaiSisaStok} displayType={'text'} thousandSeparator={true} />,
        }))


    const handleTanggalRange = (dateStrings) => {
        setMonth(moment(dateStrings).format('MMMM'))
        setYear(moment(dateStrings).format('YYYY'))
    }

    const disabledDate = (current) => {
        return current > moment().endOf('day')
    }

    return (
        <MaterialComponent
            navigate={props.navigate}
            loading={loading}
            setLoading={setLoading}
            data={data}
            columns={columns}
            month={month}
            year={year}
            idStockflow={idStockflow}
            handleTanggalRange={handleTanggalRange}
            disabledDate={disabledDate}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(MaterialPage)
export default page