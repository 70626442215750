import React from 'react'
import { Layout, Breadcrumb, Row, Col,Tabs  } from 'antd'
import '../../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import PeriodeLiveBird from '../../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-live-bird-page'
import PeriodeMaterial from '../../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-material-page'
import PeriodeOvk from '../../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-ovk-page'
import PeriodePakan from '../../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-pakan-page'

function RearingComponent(props) {
    const { Content } = Layout
    const { TabPane } = Tabs
    const { changeKey,loading,setLoading,activeKey,material,liveBird,ovk,pakan,fetchDataPakan,
            fetchDataOvk,yearRangePakan,yearPakan,setYearPakan,yearRangeOvk,yearOvk,setYearOvk,
            yearRangeMaterial,yearMaterial,setYearMaterial,fetchDataMaterial,
            yearRangeLiveBird,yearLiveBird,setYearLiveBird,fetchDataLiveBird,t
          } = props
    return ( 
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><span>{t('pages.stockflow.periode.title')}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={t('pages.stockflow.periode.pakan.title')} key="1">
                                    <PeriodePakan activeKey= {activeKey} loading = {loading} setLoading = {setLoading} 
                                    fetchDataPakan={fetchDataPakan} pakan = {pakan} yearRangePakan={yearRangePakan} 
                                    yearPakan={yearPakan} setYearPakan = {setYearPakan} t={t}/>
                                </TabPane>
                                <TabPane tab={t('pages.stockflow.periode.ovk.title')} key="2">
                                    <PeriodeOvk activeKey= {activeKey} loading = {loading} setLoading = {setLoading}  ovk={ovk} 
                                    fetchDataOvk = {fetchDataOvk} yearRangeOvk={yearRangeOvk} yearOvk={yearOvk} setYearOvk = {setYearOvk} t={t}/>
                                </TabPane>
                                <TabPane tab={t('pages.stockflow.periode.material.title')} key="3">
                                    <PeriodeMaterial activeKey= {activeKey} loading = {loading} setLoading = {setLoading}
                                     material={material} fetchDataMaterial = {fetchDataMaterial} yearRangeMaterial={yearRangeMaterial} 
                                     yearMaterial={yearMaterial} setYearMaterial = {setYearMaterial} t={t}/>
                                </TabPane>
                                <TabPane tab={t('pages.stockflow.periode.livebird.title')} key="4">
                                    <PeriodeLiveBird activeKey= {activeKey} loading = {loading} setLoading = {setLoading} 
                                    liveBird={liveBird} fetchDataLiveBird = {fetchDataLiveBird} yearRangeLiveBird={yearRangeLiveBird} 
                                    yearLiveBird={yearLiveBird} setYearLiveBird = {setYearLiveBird} t={t}/>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}
 
export default RearingComponent