import React from 'react'
import { Layout, Row, Col, Table, Typography } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import NumberFormat from 'react-number-format'
import EditOvk from '../../../../app/dashboard-peternak/rearing-record-page/edit-detail-rearing-page/edit-ovk-page'

function DetailOvkComponent(props) {
    const { Content } = Layout
    const { data, columns, loading, visible, handleSubmit, handleCancel, detail, suffix, monthlyOvk } = props
  const { Text } = Typography
  return (
        <Layout>
            <Content className="dashboard-section-content background-white">
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <div className="dashboard-section-table-dashboard">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                    scroll={{ y: '70vh' }}
                                    bordered
                                    className="table-dashboard"
                                    summary={pageData => {
                                        let totalNilai = 0
                                        pageData.forEach(({
                                            nilai,
                                        }) => {
                                            totalNilai += parseFloat(nilai)
                                        });
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0} colSpan={5}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                        <Text><NumberFormat value={totalNilai.toFixed(2)} displayType={'text'} thousandSeparator={true} /></Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                        </Loading>
                    </Col>
                </Row>
                <EditOvk visible={visible} handleSubmit={handleSubmit} detail={detail} handleCancel={handleCancel} suffix={suffix} monthlyOvk={monthlyOvk} />
            </Content>
        </Layout>
    )
}

export default DetailOvkComponent