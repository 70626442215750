import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../../common/store/action';
import TransaksiStokPakanComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/transaksi-stok-pakan-component/transaksi-stok-pakan-component';
import { API } from '../../../../../common/api';

import {getDetailTransaksiStok} from '../stok-pakan-aksi-page/query-stok-pakan';

import {useTranslation} from 'react-i18next'

function TransaksiStokPakanPage(props) {
    const {idStock} = props;
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState("1");
    const [penambahan, setPenambahan] = useState([]);
    const [penggunaan, setPenggunaan] = useState([]);

    const {t} = useTranslation()

    /**Handle get data */
    const fetchDataPenambahan = (idStock) => {
        setLoading(true);
        API.get(getDetailTransaksiStok('',0,0,idStock,"In"))
        .then(res => {
            setPenambahan(res.data.data.feedings.feedings);
            setLoading(false);
        }).catch( ( error ) =>  {
            console.log(error);
            setPenambahan([]);
            setLoading (false);
        });
    }

    const fetchDataPenggunaan = (idStock) => {
        setLoading(true);
        API.get(getDetailTransaksiStok('',0,0,idStock,"Out"))
        .then(res => {
            setPenggunaan(res.data.data.feedings.feedings);
            setLoading(false);
        }).catch( ( error ) =>  {
            console.log(error);
            setPenggunaan([]);
            setLoading (false);
        });
    }

    useEffect(() => {
        if(activeKey === "1"){
            fetchDataPenambahan(idStock);
        }else{
            fetchDataPenggunaan(idStock);
        }
    }, [activeKey,idStock]);
    /**End handle get data */

    const changeKey = useCallback((value) => {
        setActiveKey(value);
    }, []); 
    

    return ( 
        <TransaksiStokPakanComponent
            navigate={props.navigate}
            penambahan = {penambahan}
            fetchDataPenambahan = {fetchDataPenambahan}
            penggunaan = {penggunaan}
            fetchDataPenggunaan = {fetchDataPenggunaan}
            setLoading = {setLoading}
            changeKey  = {changeKey}
            loading = {loading}
            namaPakan = {props.namaPakan}
            locales={t}
        />
    );
}

const mapStateToProps = state => ({
    idStock : state.stokPakan.idStokPakan,
    namaPakan : state.stokPakan.namaPakan
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(TransaksiStokPakanPage);
export default page