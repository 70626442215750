import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../common/store/action';
import CreateKandangFirstComponent from '../../../modules/dashboard-peternak/layout-component/create-kandang-first-component';

import {getListKandang} from '../../../modules/dashboard-peternak/layout-component/store/layout-action';


function CreateKandangFirstPage(props) {
    const {visible,handleCancel,handleSubmit,setVisible} = props;
    return ( 
        <CreateKandangFirstComponent
            navigate={props.navigate}
            setVisible = {setVisible}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            gudangPakan = {props.gudangPakan}
            gudangOvk = {props.gudangOvk}
            gudangMaterial = {props.gudangMaterial}
        />
    );
}

const mapStateToProps = state => ({
    gudangPakan : state.gudangPakan.dataGudangPakan,
    gudangOvk : state.gudangOvk.dataGudangOvk,
    gudangMaterial : state.gudangMaterial.dataGudangMaterial,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListKandang,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreateKandangFirstPage);
export default page