import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import ModalCreateGudangOvk from '../../../../app/dashboard-peternak/master-page/gudang-ovk-page/gudang-ovk-aksi-page/create-gudang-ovk-page'
import ModalEditGudangOvk from '../../../../app/dashboard-peternak/master-page/gudang-ovk-page/gudang-ovk-aksi-page/edit-gudang-ovk-page'

function GudangOvkComponent(props) {
    const { Content } = Layout
    const { columns, data, handleOk, handleCancel, fetchData, visible, loading, detail, handleSubmit, total, setLoading, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.gudangOvk.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateGudangOvk fetchData={fetchData} total={total} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{y: '70vh'}}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalEditGudangOvk visible={visible} handleOk={handleOk} detail={detail}
                        handleCancel={handleCancel} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

export default GudangOvkComponent