import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { navigate } from '../../../../../common/store/action';
import CONSTANTS from '../../../../../common/utils/Constants';
import * as actionType from './ovk-action-type';

const startGetDataOvks = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataOvks = () => ({
    type : actionType.FINISH_GET_DATA
})

const setDataOvkType = payload => ({
    type : actionType.SET_DATA_OVK_TYPE,
    payload,
});

const setDataOvkMedia = payload => ({
    type : actionType.SET_DATA_OVK_MEDIA,
    payload,
});

const setDataOvkUnit = payload => ({
    type : actionType.SET_DATA_OVK_UNIT,
    payload,
});

const setDataOvkShape = payload => ({
    type : actionType.SET_DATA_OVK_SHAPE,
    payload,
});

const setDataOvk = payload => ({
    type : actionType.SET_DATA_OVK,
    payload
});

const setDetailOvk = payload => ({
    type : actionType.SET_DETAIL_OVK,
    payload
});

const setIdOvk = payload => ({
    type : actionType.SET_ID_OVK,
    payload
})

const setDataOvkUnitRecording = payload => ({
    type : actionType.SET_DATA_OVK_UNIT_RECORDING,
    payload
});

export const getListOvk = (params) => (dispatch) => {
    dispatch(startGetDataOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovks.ovks){
            dispatch(setDataOvk(res.data.data.ovks.ovks))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataOvks());
    })
}

export const getListOvkType = (params) => (dispatch) => {
    dispatch(startGetDataOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovkTypes.ovkTypes){
            dispatch(setDataOvkType(res.data.data.ovkTypes.ovkTypes))
        } else {
            validationMessage('error', 'Data Gagal')
        }
        dispatch(finishGetDataOvks());
    })
}

export const getListOvkUnitRecording = (params) => (dispatch) => {
    dispatch(startGetDataOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovkUnitRecordings.ovkUnitRecordings){
            dispatch(setDataOvkUnitRecording(res.data.data.ovkUnitRecordings.ovkUnitRecordings))
        } 
        else {
            validationMessage('error', 'Data Gagal')
        }
        dispatch(finishGetDataOvks());
    })
} 

export const getListOvkMedia = (params) => (dispatch) => {
    dispatch(startGetDataOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovkMedias.ovkMedias){
            dispatch(setDataOvkMedia(res.data.data.ovkMedias.ovkMedias))
        } else {
            validationMessage('error', 'Data Gagal')
        }
        dispatch(finishGetDataOvks());
    })
}

export const getListOvkUnit = (params) => (dispatch) => {
    dispatch(startGetDataOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovkUnits.ovkUnits){
            dispatch(setDataOvkUnit(res.data.data.ovkUnits.ovkUnits))
        } else {
            validationMessage('error', 'Data Gagal')
        }
        dispatch(finishGetDataOvks());
    })
}

export const getListOvkShape = (params) => (dispatch) => {
    dispatch(startGetDataOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovkShapes.ovkShapes){
            dispatch(setDataOvkShape(res.data.data.ovkShapes.ovkShapes))
        } else {
            validationMessage('error', 'Data Gagal')
        }
        dispatch(finishGetDataOvks());
    })
}

export const getDetailOvk = (params,id) => (dispatch) => {
    dispatch(startGetDataOvks());
    dispatch(setIdOvk(id))
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovk){
            dispatch(setDetailOvk(res.data.data.ovk))
            dispatch(navigate(CONSTANTS.EDIT_MASTER_OVK_MENU_KEY))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataOvks());
    })
}



