import React from 'react';
import { Layout, Row, Col, Breadcrumb, Avatar, Form, Input, Card } from 'antd';
import Loading from '../../../common/component/loading/loading-container';
import { UserOutlined } from '@ant-design/icons';
import { faCheckCircle, faDoorClosed, faLockOpen, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import ButtonDashboard from '../../../common/component/button/button-dashboard';

function ProfilComponent(props) {
    const { Content } = Layout;
    const { TextArea } = Input;
    const { handleSubmit, handleFailed, loading, disabled, setDisabled, profil,
        handleSubmitPassword, passChange, setPassChange, form, t } = props;
    const title = passChange === false ? `${t('pages.profil.title')}` : `Password`;
    return (
        <Layout>
            <Loading loading={loading}>
                <Content className="dashboard-container" style={{ minHeight: '100vh' }}>
                    <Row className="dashboard-section">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item> <span>{title}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>
                    <Card title={title} bordered={false}>
                        {passChange === false ? (
                            <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="basic"
                                initialValues={{
                                    remember: true,
                                    namaLengkap: profil.name,
                                    email: profil.email,
                                    username: profil.username,
                                    phone: profil.phone,
                                    perusahaan: profil.company.name,
                                    alamat: profil.address
                                }}
                            >
                                <Row className="dashboard-section" gutter={[16, 16]}>
                                    <Col lg={5} md={24} sm={24}>
                                        <div className="dashboard-section-profil-guskom-display-foto">
                                            <Avatar shape="square" icon={<UserOutlined />} size={170} />
                                        </div>
                                    </Col>
                                    <Col lg={19} md={24} sm={24} style={{ marginTop: "10px" }}>
                                        <div className="dashboard-section-profil-guskom-display-konten">
                                            <Row gutter={8}>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">{t('pages.profil.nama')}</span>
                                                    <Form.Item
                                                        name="namaLengkap" rules={[{ required: true, message: `${t('pages.profil.msgNama')}` }]}
                                                    >
                                                        <Input
                                                            name='namaLengkap'
                                                            placeholder={t('pages.profil.phNama')}
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">Email</span>
                                                    <Form.Item
                                                        name="email" rules={[{ required: true, message: `${t('pages.profil.msgEmail')}` }]}
                                                    >
                                                        <Input
                                                            name='email'
                                                            placeholder={t('pages.profil.phEmail')}
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">Username</span>
                                                    <Form.Item
                                                        name="username" rules={[{ required: true, message: `${t('pages.profil.msgUsername')}` }]}
                                                    >
                                                        <Input
                                                            name='username'
                                                            placeholder={t('pages.profil.phUsername')}
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">{t('pages.profil.tel')}</span>
                                                    <Form.Item
                                                        name="phone" rules={[{ required: true, message: `${t('pages.profil.msgTel')}` }]}
                                                    >
                                                        <Input
                                                            name='phone'
                                                            placeholder={t('pages.profil.phTel')}
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="dashboard-section" gutter={16}>
                                    <Col lg={24} md={24} sm={24}>
                                        <Row>
                                            <Col lg={24} md={24} sm={24}>
                                                <span className="font-title-content black font-semi-bold">{t('pages.profil.perusahaan')}</span>
                                                <Form.Item
                                                    name="perusahaan" rules={[{ required: true, message: `${t('pages.profil.msgPerusahaan')}` }]}
                                                >
                                                    <Input
                                                        name='perusahaan'
                                                        placeholder={t('pages.profil.phPerusahaan')}
                                                        className="input-rearing mt-5"
                                                        disabled={disabled}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} md={24} sm={24}>
                                                <span className="font-title-content black font-semi-bold">{t('pages.profil.alamat')}</span>
                                                <Form.Item
                                                    name="alamat" rules={[{ required: true, message: `${t('pages.profil.msgAlamat')}` }]}
                                                >
                                                    <TextArea
                                                        style={{ borderColor: "#BE375F" }}
                                                        placeholder={t('pages.profil.phAlamat')}
                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                        disabled={disabled}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {disabled === true && passChange === false ? (
                                    <Row className="dashboard-section" gutter={16}>
                                        <Col lg={6} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('pages.profil.changeProfil')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faUserEdit}
                                                onClick={() => setDisabled(false)}
                                            />
                                        </Col>
                                        <Col lg={6} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('pages.profil.changePass')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faLockOpen}
                                                onClick={() => setPassChange(true)}
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className="dashboard-section">
                                        <Col lg={3} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.cancel')}
                                                height={20}
                                                backgroundColor="#bdc2c9"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faDoorClosed}
                                                onClick={passChange === false ? () => setDisabled(true) : () => setPassChange(false)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.save')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faCheckCircle}
                                                htmlType="submit"
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        ) : (
                            <Form onFinish={handleSubmitPassword} onFinishFailed={handleFailed} name="basic" form={form}>
                                <Row className="dashboard-section" gutter={[16, 16]}>
                                    <Col lg={12} md={24} sm={24}>
                                        <span className="font-title-content black font-semi-bold">{t('pages.profil.oldPass')}</span>
                                        <Form.Item
                                            name="oldPass" rules={[{ required: true, message: `${t('pages.profil.msgOldPass')}` }]}
                                        >
                                            <Input.Password
                                                name='oldPass'
                                                placeholder={t('pages.profil.phOldPass')}
                                                className="input-password mt-5"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={24} sm={24}>
                                        <span className="font-title-content black font-semi-bold">{t('pages.profil.newPass')}</span>
                                        <Form.Item
                                            name="newPass" rules={[{ required: true, message: `${t('pages.profil.msgNewPass')}` }]}
                                        >
                                            <Input.Password
                                                name='newPass'
                                                placeholder={t('pages.profil.phNewPass')}
                                                className="input-password mt-5"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {disabled === true && passChange === false ? (
                                    <Row className="dashboard-section">
                                        <ButtonDashboard
                                            text={t('pages.profil.changeProfil')}
                                            height={20}
                                            backgroundColor="#008ad4"
                                            borderRadius="5px"
                                            className="font-semi-medium font-content-title button-edit"
                                            textColor="white"
                                            icon={faUserEdit}
                                            onClick={() => setDisabled(false)}
                                        />
                                        <ButtonDashboard
                                            text={t('pages.profil.changePass')}
                                            height={20}
                                            backgroundColor="#008ad4"
                                            borderRadius="5px"
                                            className="font-semi-medium font-content-title button-edit"
                                            textColor="white"
                                            icon={faLockOpen}
                                            onClick={() => setPassChange(true)}
                                        />
                                    </Row>
                                ) : (
                                    <Row className="dashboard-section">
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.cancel')}
                                                height={20}
                                                backgroundColor="#bdc2c9"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faDoorClosed}
                                                onClick={passChange === false ? () => setDisabled(true) : () => setPassChange(false)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.save')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faCheckCircle}
                                                htmlType="submit"
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        )}
                    </Card>
                </Content>
            </Loading>
        </Layout>
    );
}

export default ProfilComponent;