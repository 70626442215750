import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Drawer, Form, Row, Col, Input } from 'antd'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'

function EditStandarEws(props) {
    const { visible, detail, handleSubmit, setVisible, t } = props;
    const [data, setData] = useState(detail)
    const [form] = Form.useForm()

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
                form.setFieldsValue({
                    day: detail.day,
                    max_temperature: detail.max_temperature,
                    min_temperature: detail.min_temperature,
                    max_humidity: detail.max_humidity,
                    min_humidity: detail.min_humidity,
                    max_co2: detail.max_co2,
                })
            }
        }
        setDetailData()
    }, [detail, form])

    return (
        <div>
            <Drawer
                title={false}
                height={150}
                placement="bottom"
                closable={false}
                onClose={() => setVisible(false)}
                visible={visible} hidden={!visible}
                getContainer={false}
                style={{ position: 'absolute', opacity: visible ? 100 : 0 }}
            >
                <Form
                    form={form} onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{
                        day: data.day,
                        max_temperature: data?.max_temperature,
                        min_temperature: data?.min_temperature,
                        max_humidity: data?.max_humidity,
                        min_humidity: data?.min_humidity,
                        max_co2: data?.max_co2
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={4}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.day')}</span>
                            <Form.Item
                                name="day" rules={[{ required: true, message: `${t('pages.monitor.msgDay')}` }]}
                            >
                                <Input type='number'
                                    placeholder="11"
                                    className="input-suffix"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.maxTemp')}</span>
                            <Form.Item name="max_temperature">
                                <Input type='number' step='any'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="°C"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.minTemp')}</span>
                            <Form.Item name="min_temperature">
                                <Input type='number' step='any'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="°C"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.maxHumidity')}</span>
                            <Form.Item name="max_humidity">
                                <Input type='number' step='any'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="%"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.minHumidity')}</span>
                            <Form.Item name="min_humidity">
                                <Input type='number' step='any'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="%"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.maxCo2')}</span>
                            <Form.Item name="max_co2">
                                <Input type='number' step='any'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="ppm"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col>
                        <ButtonDashboard
                            htmlType="submit"
                            text={t('general.edit')}
                            height={20} size='small'
                            backgroundColor="#008ad4"
                            borderRadius="5px" marginLeft={10}
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                        />
                    </Col>
                    <Col>
                        <ButtonDashboard
                            text={t('general.cancel')}
                            height={20} size='small'
                            borderRadius="5px" marginLeft={5}
                            className="font-semi-medium font-content-title button-add"
                            onClick={() => setVisible(false)}
                        />
                    </Col>
                </Form>
            </Drawer>
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditStandarEws)
export default page