import * as actionType from './penyakit-action-type';

const initialState = {
    dataPenyakit : [],
    loading : false,
}

const handler = (currentState) => {
    const setDataPenyakit = {
        startGetDataPenyakits : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataPenyakits : () => ({
            ...currentState,
            loading: false,
        }),

        getDataPenyakit : data => ({
            ...currentState,
            dataPenyakit : data,
        }),
    }

    return {
        ...setDataPenyakit
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataPenyakits();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataPenyakits();
        case actionType.SET_DATA_PENYAKIT:
            return handler(state).getDataPenyakit(payload);
        default:
            return state;
    }
}