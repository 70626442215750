export const getResumeMonthlyStocks = (keyword,limit,skip,month,year) => {
    return (
        `query{
            resumeMonthlyStocks(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}"){
            resume{
                unit
                bgnQty 
                bgnPrice
                bgnTotal 
                inTotal 
                priceIn 
                totalPrice 
                outTotal 
                priceOut 
                totalOut 
                qtyAdj
                hsAdj
                totalAdj
                sisaStok 
                nilaiSisaStok 
                hargaSisaStok
            }
          }
        }
      `
    );
}