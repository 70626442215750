export const START_GET_DATA = '@layout/start-get-data'
export const FINISH_GET_DATA = '@layout/start-finish-data'
export const SET_DATA_KANDANG = '@layout/set_data_kandang'
export const SET_DATA_PERIODE = '@layout/set_data_periode'
export const SET_ID_PERIODE = '@layout/set_id_periode'
export const SET_ID_REARING = '@layout/set_id_rearing'
export const SET_ID_KANDANG = '@layout/set_id_kandang'
export const SET_KANDANG = '@layout/set_kandang'
export const SET_PERIODE = '@layout/set_periode'
export const SET_DATE_PERIODE = '@layout/set_date_periode'
export const SET_TOTAL_KANDANG = '@layout/total_kandang'
export const SET_CHICKINDATE = '@layout/set_chickindate'
export const SET_IS_TOUR = '@layout/set_is_tour'
export const SET_LOCALES = '@layout/set_locales'
export const SET_ID_DEVICE = '@layout/set_id_device'
export const SET_DEVICE_TYPE = '@layout/set_device_type'
