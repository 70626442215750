/**Record Ovk */
export const createPemakaianUmumOvk = (value, idRearingRecord, idOvk) => {
    return {
        query: `
    mutation{
        createGeneralUseOvk(ovkTransactionInput: {
            number: ${parseFloat(value.jumlah)},
            priceOvkTransaction: ${parseFloat(value.price)},
            ovkStock : "${idOvk}"
            rearingRecord: "${idRearingRecord}"
        }){
            _id
        }
    }
    `
    }
}

export const getPemakaianUmumOvk = (keyword, limit, skip) => {
    return (`
    query{
        ovkGeneralUses(keyword: "${keyword}", limit:${limit}, skip:${skip}){
        totalCount
        ovkTransactions{
            _id
            typeTransaction
            number
            priceOvkTransaction
            periode
            kandang
            ovkStock {
            _id
            name
            ovk { 
                _id
                name
                ovkUnit{
                    name
                }
            }
            }
        }
    }
    }`
    );
}

export const getDetailPemakaianUmumOvk = (_id) => {
    return (
        `query{
        ovkGeneralUse(_id: "${_id}"){
        _id
        number
        priceOvkTransaction
        storeTime
        rearingRecord{
            _id
        }
        ovkStock {
            value : _id
        }
    }
    }`
    );
}

export const updatePemakaianUmumOvk = (_id, value, idRearingRecord) => {
    return {
        query: `
    mutation{
        updateOvkGeneralUse( _id:"${_id}",updateOvkTransactionInput: {
            number: ${parseFloat(value.jumlah)},
            priceOvkTransaction: ${parseFloat(value.price)},
            rearingRecord: "${idRearingRecord}",
            ovkStock : "${value.ovk.value}"
        }){
            _id
        }
    }
    `
    }
}

export const deletePemakaianUmumOvk = (_id) => {
    return (
        `mutation{
        deleteOvkTransaction(_id : "${_id}"){
        deleted
        }
    }
    `
    )
}

export const getRearingRecordDate = (keyword, limit, skip, rearing) => {
    return (
        `query{
            dateRearingRecords(rearing:"${rearing}", keyword:"${keyword}",limit:${limit},skip:${skip}){
              totalCount
              calculatedArr{
                  _id
                  tanggal
              }
            }
          }
        `
    );
}