import React from 'react';
import { Modal,Form,Row,Col,Input,Select } from 'antd';

function EditPakan(props) {
    const { Option } = Select;
    const {visible,handleSubmit,handleCancel, data,t} = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
          <Modal
            visible={visible}
            title={t('pages.master.material.modal.titleEdit')}
            okText="Edit"
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  handleSubmit(values);
                })
                .catch((info) => {
                  console.log('Validate Failed:', info);
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        namaMaterial : data.name,
                        kodeMaterial : data.code,
                        jenisMaterial : data,
                        perusahaan : data.producer,
                    }}
                >
                   <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.material.modal.nama')}</span>
                            <Form.Item
                                name="namaMaterial" rules={[{ required: true, message: `${t('pages.master.material.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='namaMaterial'
                                    placeholder={t('pages.master.material.modal.phNama')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.material.modal.kode')}</span>
                            <Form.Item
                                name="kodeMaterial" rules={[{ required: true, message: `${t('pages.master.material.modal.msgKode')}` }]}
                            >
                                <Input
                                    name='kodeMaterial'
                                    placeholder={t('pages.master.material.modal.phKode')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.material.modal.jenis')}</span>
                            <Form.Item
                                name="jenisMaterial" rules={[{ required: true, message: `${t('pages.master.material.modal.msgJenis')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.material.modal.phJenis')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >  
                                    <Option key="Sekam" value="Sekam">Sekam</Option>
                                    <Option key="Lpg" value="Lpg">LPG</Option>
                                    <Option key="Garam" value="Garam">Garam</Option>
                                    <Option key="Kapur" value="Kapur">Kapur</Option>
                                    <Option key="PaperChick" value="PaperChick">PaperChick</Option>
                                    <Option key="SodaApi" value="SodaApi">Soda Api</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.material.modal.perusahaan')}</span>
                            <Form.Item
                                name="perusahaan" rules={[{ required: true, message: `${t('pages.master.material.modal.msgPerusahaan')}` }]}
                            >
                                <Input
                                    name='perusahaan'
                                    placeholder="1000..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
              <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default EditPakan;