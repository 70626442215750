
import React, { useState,useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../common/api';
import { validationMessage } from '../../../../common/component/notification/notification';
import { navigate } from '../../../../common/store/action';
import CreatePanenComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-panen-component';

import {createRecordPanen} from '../rearing-record-aksi-page/query-rearing-record'

import { useTranslation } from "react-i18next";

function CreatePanenPage(props) {
    const {idRearingRecord,setLoading,fetchDataPanen} = props;
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();

    const showModal = useCallback(() => {
        setVisible(true);
    },[]);

    const postData = (values) => {
        API.post(createRecordPanen(values,idRearingRecord))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.createHarvest._id){
                validationMessage('success', `${t("pages.recording.detail.panen.response.sukses.tambah")}`)
                fetchDataPanen(idRearingRecord);
            }else{
                validationMessage('error', `${t("pages.recording.detail.panen.response.gagal.tambah")}`);
                setLoading(false);
            }
        }).catch( ( error ) =>  {
            validationMessage('error', `${t("pages.recording.detail.panen.response.gagal.tambah")}`);
            setLoading(false);
        });
    }

    const handleSubmit = (values) => {
        // console.log(values);
        postData(values);
        setVisible(false);
        setLoading(true);
    };

    return ( 
        <CreatePanenComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            locales  = {t}
        />
    );
}

const mapStateToProps = state => ({
    idRearingRecord : state.rearingRecord.idRearingRecord
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePanenPage);
export default page