import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { Modal, Form, Row, Col, Select, InputNumber } from 'antd'
import { useTranslation } from "react-i18next"

function EditPakanPage(props) {
    const { visible, handleCancel, detail, handleSubmit, feed, stokPakan } = props
    const [data, setData] = useState(detail)
    const { t } = useTranslation()
    const [form] = Form.useForm()

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
                form.setFieldsValue({
                    pakan: detail.feedStock,
                    jumlah: detail.number,
                    price: detail.priceFeeding
                })
            }
        }
        setDetailData()
    }, [detail, form])

    const sak = (value) => {
        let idFeed = stokPakan.filter(d => d._id === value.value)[0].feed._id
        let price = feed.filter(d => d.idFeed === idFeed)[0]?.hargaPengeluaran
        form.setFieldsValue({
            price: price
        })
    }

    return (
        <Modal
            visible={visible}
            title={t("pages.recording.detail.pakan.modal.titleEdit")}
            okText="Edit"
            width={500}
            cancelText={t('general.cancel')}
            onCancel={() => handleCancel()}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields()
                        handleSubmit(values)
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info)
                    })
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    _id: data._id,
                    pakan: data.feedStock,
                    jumlah: data.number,
                    price: data.price
                }}
            >
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <span className="black font-semi-bold font-title-content" >{t("pages.recording.detail.pakan.modal.pakan")}</span>
                        <Form.Item
                            name="pakan" rules={[{ required: true, message: 'Silahkan pilih pakan!' }]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                onChange={val => sak(val)}
                                placeholder="Pilih Pakan"
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular mt-5"
                            >
                                {
                                    stokPakan.map(data =>
                                        <Select.Option
                                            key={data.feed.name.toString()}
                                            value={data._id}
                                        >{data.feed.name}</Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span className="font-semi-bold black font-title-medium">{t("pages.recording.detail.pakan.modal.jumlah")}</span>
                        <Form.Item
                            name="jumlah" rules={[{ required: true, message: `${t("pages.recording.detail.pakan.modal.msgJumlah")}` }]}
                        >
                            <InputNumber
                                name='jumlah'
                                placeholder="100..."
                                className="input-number mt-5"
                                formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span className="font-semi-bold black font-title-medium">Harga (Rp)</span>
                        <Form.Item
                            name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                        >
                            <InputNumber
                                placeholder="2500..."
                                className="input-number mt-5"
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    stokPakan: state.stokPakan.dataStokPakan,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPakanPage)
export default page