import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import CreateRearingRecordComponent from '../../../../modules/dashboard-peternak/rearing-record-component/rearing-aksi-component/create-recording-pemeliharaan-component'
import { API } from '../../../../common/api'
import { createRearingRecord } from './query-rearing-record'
import { validationMessage } from '../../../../common/component/notification/notification'
import { useTranslation } from "react-i18next"
import { Button } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import Cookies from 'universal-cookie'
import axios from 'axios'

function CreateRearingRecordPage(props) {
    const { fetchData, idPeriode, setLoading, house, periode } = props
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()
    const [loading, setLoad] = useState(false)
    const cookies = new Cookies()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (idPeriode, date) => {
        API.post(createRearingRecord(idPeriode, date))
            .then(res => {
                if (res.data.data.createRearingRecord === null) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createRearingRecord._id) {
                    validationMessage('success', `${t("pages.recording.response.sukses.tambah")}`)
                    fetchData(idPeriode)
                }
            }).catch((error) => {
                console.log('err', error)
                validationMessage('error', `${t("pages.recording.response.gagal.tambah")}`)
                setLoading(false)
            })
    }
    const exportData = async (idPeriode) => {
        setLoad(true)
        let token = cookies.get('token')
        await axios
            .get(`${process.env.REACT_APP_API_URL}/excel-rearing/${idPeriode}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
             if (typeof(res.data === 'blob')) {
                setLoad(false)
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(res.data)
                link.download = `Recording ${periode} ${house}.xlsx`
                link.click()
                validationMessage('success', `Berhasil export recording ke file Excel`)
            }
        }).catch((error) => {
            console.log('err', error)
            validationMessage('error', `Gagal export recording ke file Excel`)
            setLoad(false)
        })
    }

    const handleSubmit = (values) => {
        const date = values['date_picker']
        postData(idPeriode, date)
        setVisible(false)
        setLoading(true)
    }
    const handleExport = () => {
        exportData(idPeriode)
    }

    return (
        <>
            <CreateRearingRecordComponent
                showModal={showModal}
                navigate={props.navigate}
                visible={visible}
                handleSubmit={handleSubmit}
                setVisible={setVisible}
                locales={t}
            />
            <Button style={{
                float: 'right', marginRight: 11, backgroundColor: '#00a266', color: 'white',
                borderRadius: 5, boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
            }}
                loading={loading} onClick={() => handleExport()}>
                <FileExcelOutlined />Export Excel</Button>
        </>
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateRearingRecordPage)
export default page
