import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { Modal, Form, Row, Col, Input, InputNumber } from 'antd';
import { navigate } from '../../../../../common/store/action';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import ButtonDashboard from '../../../../../common/component/button/button-dashboard';

import { createOverhead } from './query-overhead';

function CreateOverheadPage(props) {
    const { fetchData, setLoading, t } = props;
    const [visible, setVisible] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    }, []);

    const postData = (values) => {
        API.post(createOverhead(values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false);
                } else if (res.data.data.createOverhead._id) {
                    validationMessage('success', `${t('pages.master.overhead.response.sukses.tambah')}`)
                    fetchData();
                }
            }).catch((error) => {
                console.log(error);
                validationMessage('error', `${t('pages.master.overhead.response.gagal.tambah')}`);
                setLoading(false);
            })
    }

    const handleSubmit = (values) => {
        postData(values);
        setVisible(false);
        setLoading(true);
    };
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={t('pages.master.overhead.modal.titleTambah')}
                okText={t('general.create')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.overhead.modal.nama')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.overhead.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='name'
                                    placeholder="paper chick, BBM genset ..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.overhead.modal.price')}</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `${t('pages.master.overhead.modal.msgPrice')}` }]}
                            >
                                <InputNumber
                                    name='price'
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col offset={20} lg={4} md={24} sm={24}>
                    <ButtonDashboard
                        text={t('pages.master.overhead.button.tambah')}
                        height={20}
                        backgroundColor="#008ad4"
                        borderRadius="5px"
                        className="font-semi-medium font-content-title button-add"
                        textColor="white"
                        icon={faPlusSquare}
                        onClick={() => showModal()}
                    />
                </Col>
            </Row>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </div>
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreateOverheadPage);
export default page