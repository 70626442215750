import * as actionType from './strain-action-type';

const setIdStrain = payload => ({
    type : actionType.SET_ID_STRAIN,
    payload,
});

const setDataStrain = payload => ({
    type : actionType.SET_DATA_STRAIN,
    payload,
});

export const getIdStrain = (id_strain) => (dispatch) => {
    dispatch(setIdStrain(id_strain));
}

export const getDataStrain = (data) => (dispatch) => {
    dispatch(setDataStrain(data));
}




