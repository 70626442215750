import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreateOvkComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-ovk-component'
import { createRecordOvk } from '../rearing-record-aksi-page/query-rearing-record'
import { getListOvk } from '../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'
import { getStokOvk } from '../../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-aksi-page/query-stok-ovk'
import { useTranslation } from "react-i18next"

function CreateOvkPage(props) {
    const { dateRearing, idRearingRecord, setLoading, fetchDataOvk, getListOvk, monthlyOvk } = props
    const [visible, setVisible] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        getListOvk(getStokOvk("", 0, 0))
    }, [getListOvk])

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createRecordOvk(values, dateRearing, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createOvkTransaction._id) {
                    validationMessage('success', `${t("pages.recording.detail.ovk.response.sukses.tambah")}`)
                    fetchDataOvk(idRearingRecord)
                } else {
                    validationMessage('error', `${t("pages.recording.detail.deplesi.response.gagal.tambah")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.detail.deplesi.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateOvkComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            stokOvk={props.stokOvk}
            locales={t} monthlyOvk={monthlyOvk}
        />
    )
}


const mapStateToProps = state => ({
    stokOvk: state.stokOvk.dataStokOvk,
    dateRearing: state.rearingRecord.dateRearingRecord,
    idRearingRecord: state.rearingRecord.idRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getListOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateOvkPage)
export default page