import React, {useState,useCallback, useEffect} from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import Highlighter from 'react-highlight-words';
import { Space, Tooltip, Button, Modal,Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { faUserEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ManajemenComponent from '../../../../modules/dashboard-peternak/master-component/petugas-component/manajemen-component';

import {deletePetugas, detailManajemen, updateManajemen} from './petugas-aksi-page/query-petugas';
import { API } from '../../../../common/api';
import { validationMessage } from '../../../../common/component/notification/notification';

function ManajemenPage(props) {
    const { confirm } = Modal;
    const {manajemen,fetchDataManajemen,loading,setLoading,t} = props;
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('');
    const [pagination, setPagination] = useState(10);
    const [visible, setVisible] = useState(false);
    const [detail, setDetail] = useState('');
    const [idData, setIdData] = useState(null); 

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`${t('general.search')} ${title}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {t('general.search')}
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        ),
    });
    

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSeacrhedColumn(dataIndex);
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    /**End handle seacrh in row antd design */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deletePetugas(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading (false);
            }else if(res.data.data.deleteUser.deleted === true){
                validationMessage('success', `${t('pages.master.pengguna.manajemen.response.sukses.hapus')}`)
                fetchDataManajemen();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.pengguna.manajemen.response.gagal.hapus')}`)
            setLoading(false);
        })
    }

    const hapusConfirm = (_id,nama)=> {
        confirm({
            title: `${t('pages.master.pengguna.manajemen.textHapus')}${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    /**End handle delete data */    

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize);
    }, []);

    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(detailManajemen(idData))
                .then(res => {    
                    setDetail(res.data.data.user)
                    setVisible(true);
                }).catch((error) => {
                    console.log(error);
                    setDetail(null);
                    setVisible(false);
                })
            }
        }
        getDetailData();
    }, [idData]);

    const showModal = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value);
    },[idData]);

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const updatedData =  (values) => {
        setLoading(true);
        API.post(updateManajemen(idData,values))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading (false);
            }else if(res.data.data.updateManagement._id){
                validationMessage('success', `${t('pages.master.pengguna.manajemen.response.sukses.edit')}`)
                fetchDataManajemen();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.pengguna.manajemen.response.gagal.edit')}`);
            setLoading(false);
        })
    };

    const handleSubmit = (values) => {
        updatedData( values);
        setVisible(false);
        setIdData(null);
        setLoading(true);
    };
    /**End handle updated data */

    const columns = [
        {
            title: `${t('pages.master.pengguna.table.namaManajemen')}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama',`${t('pages.master.pengguna.table.namaManajemen')}`),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email','email'),
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: `${t('pages.master.pengguna.table.alamat')}`,
            dataIndex: 'alamat',
            key: 'alamat',
        },
        {
            title: `${t('pages.master.pengguna.table.telepon')}`,
            dataIndex: 'nomor',
            key: 'nomor',
        },
        {
            title: `${t('pages.master.pengguna.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            render: (value,row,index) => (
            <Space size="middle">
                <Tooltip placement="top" title='Edit'>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FFA903',
                        borderRadius:'5px'
                    }} onClick={() => showModal(row.id)}><FontAwesomeIcon icon={faUserEdit} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={t('general.del')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FF0303',
                        borderRadius:'5px'
                    }} onClick={() => hapusConfirm(row.id,row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                </Tooltip>
            </Space>
            ),
        },
    ];

    const data = manajemen.map( ({_id,name,address,phone,email,username}, index) => ({
        key: _id,
        id : _id,
        email : email,
        alamat : address,
        nomor : phone,
        username : username,
        nama : name
    }));

    return ( 
        <ManajemenComponent
            navigate={props.navigate}
            pagination = {pagination}
            changePagination = {changePagination}
            showModal = {showModal}
            handleCancel = {handleCancel}
            columns = {columns}
            idData = {idData}
            visible = {visible}
            data = {data}
            detail = {detail}
            loading = {loading}
            handleSubmit = {handleSubmit}
            fetchDataManajemen = {fetchDataManajemen}
            setLoading = {setLoading} t={t}
        />
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(ManajemenPage);
export default page