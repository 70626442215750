import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import TransaksiStokOvkComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/transaksi-stok-ovk-component/transaksi-stok-ovk-component'
import { API } from '../../../../../common/api'
import {getDetailTransaksiStok} from '../stok-ovk-aksi-page/query-stok-ovk'

import {useTranslation} from 'react-i18next'

function TransaksiStokOvkPage(props) {
    const {idStock} = props;
    const [loading, setLoading] = useState(false)
    const [activeKey, setActiveKey] = useState("1")
    const [penambahan, setPenambahan] = useState([])
    const [penggunaan, setPenggunaan] = useState([])

    const {t} = useTranslation()
    
    console.log(idStock)

    /**Handle get data */
    const fetchDataPenambahan = (idStock) => {
        setLoading(true);
        API.get(getDetailTransaksiStok('',0,0,idStock,"In"))
        .then(res => {
            setPenambahan(res.data.data.ovkTransactions.ovkTransactions)
            setLoading(false)
        }).catch( ( error ) =>  {
            console.log(error)
            setPenambahan([])
            setLoading(false)
        })
    }

    const fetchDataPenggunaan = (idStock) => {
        setLoading(true)
        API.get(getDetailTransaksiStok('',0,0,idStock,"Out"))
        .then(res => {
            setPenggunaan(res.data.data.ovkTransactions.ovkTransactions)
            setLoading(false)
        }).catch( ( error ) =>  {
            console.log(error)
            setPenggunaan([])
            setLoading(false)
        })
    }

    useEffect(() => {
        if(activeKey === "1"){
            fetchDataPenambahan(idStock)
        }else{
            fetchDataPenggunaan(idStock)
        }
    }, [activeKey,idStock])
    /**End handle get data */
    
    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    return ( 
        <TransaksiStokOvkComponent
            navigate={props.navigate}
            penambahan = {penambahan}
            fetchDataPenambahan = {fetchDataPenambahan}
            penggunaan = {penggunaan}
            fetchDataPenggunaan = {fetchDataPenggunaan}
            setLoading = {setLoading}
            changeKey  = {changeKey}
            loading = {loading}
            namaOvk = {props.namaOvk}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    idStock : state.stokOvk.idStokOvk,
    namaOvk : state.stokOvk.namaOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TransaksiStokOvkPage)
export default page