export const queryGudangPakan = (keyword,limit,skip) => {
    return (
        `query{
            feedWarehouses(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
                feedWarehouses {
                    _id
                    name
                }
            }
        }
        `
    );
};

export const deleteGudangPakan = (_id) =>{
   return (
        `mutation{
            deleteFeedWarehouse(_id : "${_id}"){
                deleted
            }
        }
        `
   );     
}

export const createGudangPakan = (value) => {
    return{
        query: `mutation{
            createFeedWarehouse(feedWarehouseInput: {
                name : "${value}"
              }){
                _id
              }
            }
        `
    }
}

export const detailGudangPakan = (_id) => {
    return (
        `query{
            feedWarehouse(_id : "${_id}"){
                _id
                name
            }
        }
        `
    );
}

export const updateGudangPakan = (_id,value) => {
    return {
        query: `mutation{
            updateFeedWarehouse(_id : "${_id}", updateFeedWarehouseInput : {
                    name : "${value}",
                }){
                    _id
                }
            }
        `
    }
}