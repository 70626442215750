import * as actionType from './gudang-ovk-action-type';

const initialState = {
    dataGudangOvk: [],
    idGudangOvk : null,
    loading : false,
}

const handler = (currentState) => {
    const setDataGudangOvk = {
        startGetDataGudangOvks : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataGudangOvks : () => ({
            ...currentState,
            loading: false,
        }),

        getDataGudangOvks : data => ({
            ...currentState,
            dataGudangOvk: data
        }),

        setIdGudangOvk : data => ({
            ...currentState,
            idGudangOvk : data
        }),
    }

    return {
        ...setDataGudangOvk
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataGudangOvks();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataGudangOvks();
        case actionType.SET_DATA_GUDANG_OVK:
            return handler(state).getDataGudangOvks(payload);
        case actionType.SET_ID_GUDANG_OVK:
            return handler(state).setIdGudangOvk(payload);

        default:
            return state;
    }
}