import * as actionType from './adjustment-ovk-action-type';

const setIdMonthlyStockOvk = payload => ({
    type : actionType.SET_ID_MONTHLY_STOCK_OVK,
    payload,
});

export const getIdMonthlyStock = (idMonthlyStock) => (dispatch) => {
    dispatch(setIdMonthlyStockOvk(idMonthlyStock));
}
