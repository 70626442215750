export const getSummaryReport = (rearing) => {
  return (
      `query{
        dashboardCalculate(rearing:"${rearing}", keyword:"",limit:0,skip:0){
          liveBird
          fcr
          stdFcr
          culling
          mati
          persenCulling
          persenDeplesi
          stdDeplesi
        }
      }`
  );
}

export const getReportRecord = (keyword,limit,skip,periode) => {
    return (
        `query{
            calculatedRearingRecords(rearing:"${periode}", keyword:"${keyword}",limit:${limit},skip:${skip}){
              calculatedArr{
                _id
                tanggal
                umur
                mati
                culling
                deplesiPerHari
                total
                persenKematian
                persenDeplesiHarian
                standardDp

                panen
                saldo
                totalBeratPanen
                cumDeplesi
                cumPanen
                
                ovkTotal
        
                pakanPerHari
                pakanTotal
                
                fcr
                standardFcr
                bw
                standardBw
                hpp
        
                totalBerat
                abw
                adg
                standardDg
        
                feedIntake
                standardFi
                saldoPerHari  

                hppPakan
                hppMaterial
                totalHpp
                totalDoc
                hppPerKg

                indeksPerformance

                bopKandang
              }
            }
          }
        `
    );
}

export const getReportChart = (periode) => {
  return(
    `query{
      calculateDashboardRecords(rearing:"${periode}", keyword:"",limit:0,skip:0){
        calculatedArr{
            day
            tanggal
            standardFi
            feedIntake
            standardFcr
            fcr
            standardBw 
            bw  
            standardDp
            persenDeplesiHarian
            saldo
        }
      }
    }`
  );
}

export const getDiseaseReport = (periode) => {
  return (
    `query{
      diseaseDashboardRecords(
        rearing:"${periode}", keyword:"",limit:0,skip:0){
        calculatedDisease{
            number
            disease
        }
      }
    }`
  )
}

export const getThiDevice = (deviceId, start, end) => (
  `
  query{
    thiDevice(deviceId: "${deviceId}", start: "${start}", end: "${end}"){
        thiDevice{
          ts
          value
          timestamp
          status
        }
    }
  }
  `
)

export const getEnvDevice = (deviceId, start, end) => (
  `
  query{
    envDevice(deviceId: "${deviceId}", start: "${start}", end: "${end}"){
      type
      envDevice{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
      envReverse{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
    }
  }
  `
)


export const getTaredDevice = (deviceId, start, end) => (
  `
  query{
    taredDevice(deviceId: "${deviceId}", start: "${start}", end: "${end}"){
      type
      envDevice{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
      envReverse{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
    }
  }
  `
)

export const getFeedUsage = (periode) => {
  return (
    `query{
        feedUsage(rearing:"${periode}"){
          feedCount
          ages
          feedUsage{
                _id
                feed
                firstAge
                lastAge
                total
                feeding{
                    age
                    number
                    fi
                }
              }
              chart{
                label
                lineTension
                borderColor
                backgroundColor
                borderDash
                yAxisID
                data{
                  x
                  y
                }
              }
          }
        }
      `
  )
}

export const getNotifications = (keyword, limit, skip) => (
  `
  query{
    notifications(keyword:"${keyword}",limit:${limit},skip:${skip}){
      totalAll
      totalCount
      totalUnread
      notifications{
        _id
        title
        message
        read
        createdAt
      }
    }
  }
  `
)

export const clearUnread = (limit) => (
  `
  query{
    clearUnread(limit:${limit}){
      totalCount
      notifications{
        _id
        title
        message
        read
        createdAt
      }
    }
  }
  `
)

