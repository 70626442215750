/**Query Pakan */
export const getPeriodeStockFlowFeed = (keyword, limit, skip, year) => {
  return (
    `query{
          listMonthlyStockFeeds(keyword:"${keyword}",limit:${limit},skip:${skip}, year:"${year}"){
            totalCount
            monthlyStockFeeds{
              _id
              month
              year
              locked
              createdAt
            }
          }
        }
      `
  );
}

export const getDetailPeriodeStockFeed = (_id) => {
  return (
    `query{
        listMonthlyStockFeed(_id: "${_id}"){
            _id
            month
            year
            locked
          }
        }
    `
  );
}
export const createStockflowFeed = () => {
  return (
    `mutation{
        createMonthlyStockFeed{
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const newStockflowFeed = (date) => {
  return (
    `mutation{
        newMonthlyStockFeed(feedInput: {
          date: "${date}"
      }){
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const lockPeriodeStokFeed = (_id) => {
  return {
    query: `                      
          mutation{
            lockMonthlyStockFeed(_id: "${_id}"){
                ok
              _id
            }
          }
      `
  }
}

export const reLockPeriodeStokFeed = (_id) => {
  return {
    query: `                      
          mutation{
            reOpenMonthlyStockFeed(_id: "${_id}"){
                ok
              _id
            }
          }
      `
  }
}

export const updateDetailPeriodeStockFeed = (_id, value) => {

}
export const getListPeriodeFeed = (keyword, limit, skip) => {
  return (
    `query{
      listYearStockFlowFeeds(keyword:"${keyword}",limit:${limit},skip:${skip}){
        totalCount
        listYearStockFlowFeeds{
          year
        }
      }
    }
  `
  );
}
export const updateInitialBalanceFeed = (_id, bgnQty, bgnPrice) => {
  return (
    `mutation{
        updateInitialBalanceFeed(_id: "${_id}", updatedInitialBalanceFeed: {
          bgnQty:${bgnQty}, bgnPrice:${bgnPrice}
        }){
          _id
          bgnQty
          bgnPrice
          bgnTotal
          storeTime
            }
          }`
  )
}
export const delStockflowFeed = (_id) => {
  return (
    `mutation{
            deleteMonthlyStockFeed(_id: "${_id}"){
                deleted
            }
          }`
  )
}
/**Query Live Bird */
export const getPeriodeStockFlowLiveBird = (keyword, limit, skip, year) => {
  return (
    `query{
        listMonthlyStockLiveBirds(keyword:"${keyword}",limit:${limit},skip:${skip}, year : "${year}"){
          totalCount
          monthlyStockLiveBirds{
            _id
            month
            year
            locked
            createdAt
          }
        }
      }
    `
  );
}
export const createStockflowLiveBird = () => {
  return (
    `mutation{
        createMonthlyStockLiveBird{
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const newStockflowLiveBird = (date) => {
  return (
    `mutation{
        newMonthlyStockLiveBird(lbInput: {
          date: "${date}"
      }){
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const lockPeriodeStokLiveBird = (_id) => {
  return {
    query: `                      
          mutation{
            lockMonthlyStockLiveBird(_id: "${_id}"){
              _id
            }
          }
      `
  }
}
export const reLockPeriodeStokLiveBird = (_id) => {
  return {
    query: `                      
        mutation{
          reOpenMonthlyStockLiveBird(_id: "${_id}"){
            _id
          }
        }
    `
  }
}
export const createInitialBalanceLiveBird = (value, monthlyStockLiveBird) => {
  return (
    `mutation{
      createInitialBalanceLiveBird( createdInitialBalanceLiveBird: {
        bgnQty:${value.bgnQty}, bgnPrice:${value.bgnPrice},
        rearing:  "${value.rearing.value}",
        monthlyStockLiveBird:  "${monthlyStockLiveBird}"
        })
        {
            _id
            bgnQty
            bgnPrice
            bgnTotal
            storeTime
            company{
                _id
            }
            monthlyStockLiveBird{
                _id
                month
            }
        }
    }`
  )
}
export const updateInitialBalanceLiveBird = (_id, bgnQty, bgnPrice) => {
  return (
    `mutation{
        updateInitialBalanceLiveBird(_id: "${_id}", updatedInitialBalanceLiveBird: {
          bgnQty:${bgnQty}, bgnPrice:${bgnPrice}
        }){
          _id
          bgnQty
          bgnPrice
          bgnTotal
          storeTime
            }
          }`
  )
}
export const getListPeriodeLiveBird = (keyword, limit, skip) => {
  return (
    `query{
      listYearStockFlowLiveBirds(keyword:"${keyword}",limit:${limit},skip:${skip}){
        totalCount
        listYearStockFlowLiveBirds{
          year
        }
      }
    }
  `
  );
}
export const getDetailPeriodeStockLiveBird = (_id) => {
  return (
    `query{
      listMonthlyStockLiveBird(_id: "${_id}"){
            _id
            month
            year
            locked
          }
        }
    `
  );
}
export const delStockflowLiveBird = (_id) => {
  return (
    `mutation{
            deleteMonthlyStockLiveBird(_id: "${_id}"){
                deleted
            }
          }`
  )
}
/**Query Ovk */
export const getPeriodeStockFlowOvk = (keyword, limit, skip, year) => {
  return (
    `query{
        listMonthlyStockOvks(keyword:"${keyword}",limit:${limit},skip:${skip},year:"${year}"){
          totalCount
          monthlyStockOvks{
            _id
            month
            year
            locked
            createdAt
          }
        }
      }
    `
  );
}
export const getDetailPeriodeStockOvk = (_id) => {
  return (
    `query{
      listMonthlyStockOvk(_id: "${_id}"){
            _id
            month
            year
            locked
          }
        }
    `
  );
}
export const createStockflowOvk = () => {
  return (
    `mutation{
        createMonthlyStockOvk{
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const newStockflowOvk = (date) => {
  return (
    `mutation{
        newMonthlyStockOvk(ovkInput: {
          date: "${date}"
      }){
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const lockPeriodeStokOvk = (_id) => {
  return {
    query: `                      
        mutation{
          lockMonthlyStockOvk(_id: "${_id}"){ 
            _id
          }
        }
    `
  }
}
export const reLockPeriodeStokOvk = (_id) => {
  return {
    query: `                      
        mutation{
          reOpenMonthlyStockOvk(_id: "${_id}"){
              ok
            _id
          }
        }
    `
  }
}
export const createInitialBalanceOvk = (value, monthlyStockOvk) => {
  return (
    `mutation{
      createInitialBalanceOvk( createdInitialBalanceOvk: {
        bgnQty:${value.bgnQty}, bgnPrice:${value.bgnPrice},
        ovk:  "${value.ovk.value}",
        monthlyStockOvk:  "${monthlyStockOvk}"
        })
        {
            _id
            bgnQty
            bgnPrice
            bgnTotal
            storeTime
            ovk{
                _id
                name
            }
            company{
                _id
            }
            monthlyStockOvk{
                _id
                month
            }
        }
    }`
  )
}
export const updateInitialBalanceOvk = (_id, bgnQty, bgnPrice) => {
  return (
    `mutation{
        updateInitialBalanceOvk(_id: "${_id}", updatedInitialBalanceOvk: {
          bgnQty:${bgnQty}, bgnPrice:${bgnPrice}
        }){
          _id
          bgnQty
          bgnPrice
          bgnTotal
          storeTime
            }
          }`
  )
}
export const getListPeriodeOvk = (keyword, limit, skip) => {
  return (
    `query{
      listYearStockFlowOvks(keyword:"${keyword}",limit:${limit},skip:${skip}){
        totalCount
        listYearStockFlowOvks{
          year
        }
      }
    }
  `
  );
}
export const delStockflowOvk = (_id) => {
  return (
    `mutation{
            deleteMonthlyStockOvk(_id: "${_id}"){
                deleted
            }
          }`
  )
}
/**Query Material */
export const getPeriodeStockFlowMaterial = (keyword, limit, skip, year) => {
  return (
    `query{
        listMonthlyStockMaterials(keyword:"${keyword}",limit:${limit},skip:${skip},year:"${year}"){
          totalCount
          monthlyStockMaterials{
            _id
            month
            year
            locked
          }
        }
      }
    `
  );
}
export const getDetailPeriodeStockMaterial = (_id) => {
  return (
    `query{
      listMonthlyStockMaterial(_id: "${_id}"){
            _id
            month
            year
            locked
          }
        }
    `
  );
}
export const createStockflowMaterial = () => {
  return (
    `mutation{
        createMonthlyStockMaterial{
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const newStockflowMaterial = (date) => {
  return (
    `mutation{
        newMonthlyStockMaterial(materialInput: {
          date: "${date}"
      }){
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const lockPeriodeStokMaterial = (_id) => {
  return {
    query: `                      
        mutation{
          lockMonthlyStockMaterial(_id: "${_id}"){ 
            _id
          }
        }
    `
  }
}
export const reLockPeriodeStokMaterial = (_id) => {
  return {
    query: `                      
        mutation{
          reOpenMonthlyStockMaterial(_id: "${_id}"){
              ok
            _id
          }
        }
    `
  }
}
export const updateDetailPeriodeStockMaterial = (_id, value) => {

}
export const updateInitialBalanceMaterial = (_id, bgnQty, bgnPrice) => {
  return (
    `mutation{
        updateInitialBalanceMaterial(_id: "${_id}", updatedInitialBalanceMaterial: {
          bgnQty:${bgnQty}, bgnPrice:${bgnPrice}
        }){
          _id
          bgnQty
          bgnPrice
          bgnTotal
          storeTime
            }
          }`
  )
}
export const getListPeriodeMaterial = (keyword, limit, skip) => {
  return (
    `query{
      listYearStockFlowMaterials(keyword:"${keyword}",limit:${limit},skip:${skip}){
        totalCount
        listYearStockFlowMaterials{
          year
        }
      }
    }
  `
  );
}
export const delStockflowMaterial = (_id) => {
  return (
    `mutation{
            deleteMonthlyStockMaterial(_id: "${_id}"){
                deleted
            }
          }`
  )
}


