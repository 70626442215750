import React, { useState,useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { navigate } from '../../../../../common/store/action';
import CreateKandangComponent from '../../../../../modules/dashboard-peternak/master-component/kandang-component/kandang-aksi-component/create-kandang-component';

import {createKandang,queryListKandang} from './query-kandang';
import {getListKandang} from '../../../../../modules/dashboard-peternak/layout-component/store/layout-action';


function CreateKandangPage(props) {
    const {fetchData,getListKandang,setLoading,keyword,limit,skip ,t} = props;
    const [visible, setVisible] = useState(false);
    const [visibleFree, setVisibleFree] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    },[]);

    const postData = (values) => {
        API.post(createKandang(values))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }else if(res.data.data.createHouse._id){
                setConfirmLoading(false);
                validationMessage('success', `${t('pages.master.kandang.response.sukses.tambah')}`)
                getListKandang(queryListKandang('',0,0));
                fetchData(keyword,limit,skip);
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.kandang.response.gagal.tambah')}`);
            setLoading (false);
        })
    }

    const handleSubmit = (values) => {
        setConfirmLoading(true);
        postData( values);
        setVisible(false);
        setLoading (true);
    };

    return ( 
        <CreateKandangComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            confirmLoading = {confirmLoading}
            gudangPakan = {props.gudangPakan}
            gudangOvk = {props.gudangOvk}
            gudangMaterial = {props.gudangMaterial}
            tipeUser = {props.tipeUser}
            visibleFree = {visibleFree}
            setVisibleFree = {setVisibleFree}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    gudangPakan : state.gudangPakan.dataGudangPakan,
    gudangOvk : state.gudangOvk.dataGudangOvk,
    gudangMaterial : state.gudangMaterial.dataGudangMaterial,
    tipeUser : state.auth.dataUser.typeUser
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListKandang,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreateKandangPage);
export default page