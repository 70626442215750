import React, {useCallback,useState,useEffect} from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import Highlighter from 'react-highlight-words';
import NumberFormat from 'react-number-format';
import { Space, Button,Input,Tooltip,Modal } from 'antd';
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import DetailPertumbuhanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-pertumbuhan-component';

import {getListGrowingParams} from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action';
import {getGrowingParams,getDetailRecordPertumbuhan,updateRecordPertumbuhan,deleteRecordPertumbuhan} from '../rearing-record-aksi-page/query-rearing-record';
import { validationMessage } from '../../../../common/component/notification/notification';
import { API } from '../../../../common/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from "react-i18next";

function DetailPertumbuhanPage(props) {
    const { confirm } = Modal;
    const {getListGrowingParams,pertumbuhan,idRearingRecord,fetchDataPertumbuhan,setLoading,loading} = props;
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('');
    const [pagination, setPagination] = useState(10);
    const [visible, setVisible] = useState(false);
    const [idData, setIdData] = useState(null);
    const [detail,setDetail] = useState("");
    const { t } = useTranslation();

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`${t('general.search')} ${title}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                  {t('general.search')}
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSeacrhedColumn(dataIndex);
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    /**End handle seacrh in row antd design */

    /**Handle get data */
    useEffect(() => {
        getListGrowingParams(getGrowingParams("",0,0));
    },[getListGrowingParams]);
    /**End handle get data */

     /**Handle updated data */
     useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(getDetailRecordPertumbuhan(idData))
                .then(res => {        
                    setDetail(res.data.data.growing)
                    setVisible(true);
                }).catch( ( error ) =>  {
                    console.log(error);
                    setDetail(null)
                    setVisible(false);
                });  
            }
        }
        getDetailData();
    }, [idData]);

    const showEdit = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value);
    },[idData]);

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const updatedData =  (values) => {
        API.post(updateRecordPertumbuhan(idData,values))
        .then(res => {
            if(res.data.data.updateGrowing._id){
                validationMessage('success', `${t("pages.recording.detail.pertumbuhan.response.sukses.edit")}`)
                fetchDataPertumbuhan(idRearingRecord);
            }else if(res.data.errors){
                validationMessage('error', res.data.errors[0].message);
                setLoading(false);
            }
        }).catch( ( error ) =>  {
            console.log(error);
            validationMessage('error', `${t("pages.recording.detail.pertumbuhan.response.gagal.edit")}`);
            setLoading(false);
        });  
    }

    const handleSubmit = (values) => {
        console.log(values)
        updatedData(values);
        setVisible(false);
        setIdData(null);
        setLoading(true)
    };
    /**End handle updated data */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deleteRecordPertumbuhan(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading(false);
            }
            else if(res.data.data.deleteGrowing.deleted === true){
                validationMessage('success', `${t("pages.recording.detail.pertumbuhan.response.sukses.hapus")}`)
                fetchDataPertumbuhan(idRearingRecord);
            }
        }).catch( ( error ) =>  {
            console.log(error);
            validationMessage('error', `${t("pages.recording.detail.pertumbuhan.response.gagal.hapus")}`)
            setLoading(false);
        }); 
    }

  const hapusConfirm = (_id)=> {
      confirm({
        title: `${t("pages.recording.detail.pertumbuhan.textHapus")}`,
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
              deleteData(_id);
          },
          onCancel() {
              console.log('Cancel');
          },
      });
  }
  /**End handle delete data */

    const columns = [
        {
            title: `${t("pages.recording.detail.pertumbuhan.table.parameter")}`,
            dataIndex: 'parameter',
            key: 'parameter',
            ...getColumnSearchProps('parameter', `${t("pages.recording.detail.pertumbuhan.table.parameter")}`),
        },
        {
            title: `${t("pages.recording.detail.pertumbuhan.table.nilai")}`,
            dataIndex: 'nilai',
            key: 'nilai',
        },
        {
            title: `${t("pages.recording.detail.pertumbuhan.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 100,
            render: (value,row,index) => (
              <Space size="middle">
                  <Tooltip placement="top" title={t("pages.recording.detail.pertumbuhan.button.edit")}>
                      <Button style={{
                          color:'white',
                          backgroundColor:'#FFA903',
                          borderRadius:'5px'
                      }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                  </Tooltip>
                  <Tooltip placement="top" title={t("pages.recording.detail.pertumbuhan.button.hapus")}>
                      <Button style={{
                          color:'white',
                          backgroundColor:'#FF0303',
                          borderRadius:'5px'
                      }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                  </Tooltip>
              </Space>
            ),
        },
    ];

    const data = pertumbuhan.map( ({_id,growingParam,value}, index) => ({
      key: _id,
      parameter : growingParam.name,
      nilai : <NumberFormat value={value} displayType={'text'} thousandSeparator={true}/>,
  }));

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize);
    }, []);

    return ( 
        <DetailPertumbuhanComponent
            navigate={props.navigate}
            pagination = {pagination}
            changePagination = {changePagination}
            loading = {loading}
            columns = {columns}
            data = {data}
            setLoading = {setLoading}
            showEdit = {showEdit}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            visible = {visible}
            detail = {detail}
        />
    );
}

const mapStateToProps = state => ({
    idRearingRecord : state.rearingRecord.idRearingRecord,
    dateRearing : state.rearingRecord.dateRearingRecord,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListGrowingParams,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(DetailPertumbuhanPage);
export default page