import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { navigate } from '../../../../../common/store/action';
import CreateStokMaterialComponent from '../../../../../modules/dashboard-peternak/stok-component/stok-material-component/stok-material-aksi-component/create-stok-material-component';

import { createMaterialStok } from './query-stok-material';

function CreateStokMaterialPage(props) {
    const { fetchData, setLoading, t, idGudangMaterial } = props;
    const [visible, setVisible] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    }, []);

    const postData = (values, year) => {
        API.post(createMaterialStok(values, year))
            .then(res => {
                if (res.data.data.createMaterialStock._id) {
                    validationMessage('success', `${t("pages.stok.material.response.sukses.tambah")}`)
                    fetchData(idGudangMaterial);
                }
                else {
                    validationMessage('error', `${t("pages.stok.material.response.gagal.tambah")}`)
                    setLoading(false);
                }
            }).catch((error) => {
                console.log(error);
                validationMessage('error', `${t("pages.stok.material.response.gagal.tambah")}`);
                setLoading(false);
            });
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('YYYY-MM-DD'))
        setVisible(false);
        setLoading(true);
    };

    return (
        <CreateStokMaterialComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            gudang={props.gudang}
            material={props.material}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    gudang: state.gudangMaterial.dataGudangMaterial,
    material: state.material.dataMaterial
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStokMaterialPage);
export default page