import * as actionType from './rearing-action-type';

const setPeriodeClosing = payload => ({
    type : actionType.SET_DETAIL_CLOSING,
    payload,
});
const setIdRearing = payload => ({
    type : actionType.SET_ID_REARING,
    payload,
});

export const getPeriodeClosing = (id) => (dispatch) => {
    dispatch(setPeriodeClosing(id));
}

export const getIdRearing = (id) => (dispatch) => {
    dispatch(setIdRearing(id));
}