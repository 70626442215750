import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import _ from 'lodash'
import './styles.scss'
import { withTranslation } from 'react-i18next'

class AnemoChart extends Component {
    static propTypes = {
        chartId: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
            }).isRequired,
        ).isRequired,
        rtl: PropTypes.bool,
    }

    static defaultProps = {
        rtl: false
    }

    componentDidMount() {
        this.initChart()
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            if (this.chart._super) {
                this.chart.dispose()
            }
            this.initChart()
        }
    }

    componentWillUnmount() {
        if (this.chart._super) {
            this.chart.dispose()
        }
    }

    initChart() {
        const { chartId, t } = this.props
        this.chart = am4core.create(chartId, am4charts.XYChart)
        this.chart.exporting.menu = new am4core.ExportMenu()
        this.chart.scrollbarX = new am4core.Scrollbar()
        this.chart.exporting.menu.align = "left"
        this.chart.exporting.menu.verticalAlign = "top"

        this.chart.events.on("ready", function (ev) {
            valueAxis.min = valueAxis.minZoomed
            valueAxis.max = valueAxis.maxZoomed
        })

        let dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
        dateAxis.baseInterval = {
            "timeUnit": "minute",
            "count": 1
        }
        dateAxis.renderer.minGridDistance = 60
        dateAxis.title.text = `${t('pages.dashboard.iot.date')}`

        let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
        // valueAxis.baseValue = 0
        valueAxis.title.text = `${t('pages.dashboard.iot.value')}`

        // Create series
        var series = this.chart.series.push(new am4charts.LineSeries())
        series.smoothing = "monotoneX"
        series.dataFields.dateX = "ts"
        series.dataFields.valueY = "kph"
        series.name = `km/h`
        series.tooltipText = "{name}: [bold]{valueY}[/]"
        series.strokeWidth = 3;
        series.stroke = am4core.color("#01497c")
        series.tooltip.getFillFromObject = false
        series.tooltip.background.fill = am4core.color("#01497c")
        series.yAxis = valueAxis
        valueAxis.renderer.line.strokeOpacity = 1
        valueAxis.renderer.line.strokeWidth = 2
        valueAxis.renderer.opposite = false
        valueAxis.renderer.line.stroke = series.stroke
        valueAxis.renderer.labels.template.fill = series.stroke

        var series2 = this.chart.series.push(new am4charts.LineSeries())
        var valueAxis2 = this.chart.yAxes.push(new am4charts.ValueAxis())
        series2.smoothing = "monotoneX";
        series2.dataFields.valueY = "ms"
        series2.dataFields.dateX = "ts"
        series2.name = "m/s"
        series2.tooltipText = "{name}: [bold]{valueY}[/]"
        series2.strokeWidth = 3
        series2.yAxis = valueAxis2
        series2.stroke = am4core.color("#61a5c2")
        series2.tooltip.getFillFromObject = false
        series2.tooltip.background.fill = am4core.color("#61a5c2")
        valueAxis2.renderer.line.strokeOpacity = 1
        valueAxis2.renderer.line.strokeWidth = 2
        valueAxis2.renderer.opposite = true
        valueAxis2.renderer.line.stroke = series2.stroke
        valueAxis2.renderer.labels.template.fill = series2.stroke


        // add data
        this.chart.data = this.props.data
        // Add cursor
        this.chart.cursor = new am4charts.XYCursor()

        // Add legend
        this.chart.legend = new am4charts.Legend()
    }

    render() {
        return <div id={this.props.chartId} className={this.props.className} />
    }
}

export default withTranslation()(AnemoChart)