import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import OvkComponent from '../../../../modules/dashboard-peternak/master-component/ovk-component/ovk-component'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { getListOvkUnit, getListOvkMedia, getListOvkShape, getListOvkType, getListOvkUnitRecording, getDetailOvk } from '../../../../modules/dashboard-peternak/master-component/ovk-component/store/ovk-action'
import { getOvk, getOvkMedia, getOvkShape, getOvkUnit, getOvkType, deleteOvk, getOvkUnitRecording, detailOvk } from './ovk-aksi-page/query-ovk'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { useTranslation } from 'react-i18next'

function OvkPage(props) {
    const { getListOvkUnit, getListOvkMedia, getListOvkShape, getListOvkType, getListOvkUnitRecording, getDetailOvk } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const { t } = useTranslation()

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = () => {
        setLoading(true)
        API.get(getOvk('', 0, 0))
            .then(res => {
                setObject(res.data.data.ovks.ovks)
                setLoading(false);
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        getListOvkUnit(getOvkUnit('', 0, 0))
        getListOvkMedia(getOvkMedia('', 0, 0))
        getListOvkShape(getOvkShape('', 0, 0))
        getListOvkType(getOvkType('', 0, 0))
        getListOvkUnitRecording(getOvkUnitRecording('', 0, 0))
    }, [getListOvkUnit, getListOvkMedia, getListOvkShape, getListOvkType, getListOvkUnitRecording])

    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deleteOvk(_id))
            .then(res => {
                if (res.data.data.deleteOvk.deleted === true) {
                    validationMessage('success', `${t('pages.master.ovk.response.sukses.hapus')}`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.master.ovk.response.gagal.hapus')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.gudangOvk.textHapus')}${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const showEdit = useCallback((value) => {
        getDetailOvk(detailOvk(value), value)
    }, [getDetailOvk])

    const columns = [
        {
            title: `${t('pages.master.ovk.table.kode')}`,
            dataIndex: 'kode',
            key: 'kode',
            width: 110,
            ...getColumnSearchProps('kode', `${t('pages.master.ovk.table.kode')}`),
        },
        {
            title: `${t('pages.master.ovk.table.nama')}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 140,
            ...getColumnSearchProps('nama', `${t('pages.master.ovk.table.nama')}`),
        },
        {
            title: `${t('pages.master.ovk.table.golongan')}`,
            dataIndex: 'golongan',
            key: 'golongan',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.table.satuanPengadaan')}`,
            dataIndex: 'satuan',
            key: 'satuan',
            width: 110,
        },
        {
            title: `${t('pages.master.ovk.table.bentuk')}`,
            dataIndex: 'bentuk',
            key: 'bentuk',
            width: 100,
        },
        {
            title: `Tipe`,
            dataIndex: 'type',
            key: 'type',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.table.kapasitas')}`,
            dataIndex: 'kapasitas',
            key: 'kapasitas',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.table.dosis')} (%)`,
            dataIndex: 'dosis',
            key: 'dosis',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.table.penggunaan')}`,
            dataIndex: 'penggunaan',
            key: 'penggunaan',
            width: 120,
        },
        {
            title: `${t('pages.master.ovk.table.kandungan')}`,
            dataIndex: 'kandungan',
            key: 'kandungan',
            width: 140,
        },
        {
            title: `${t('pages.master.ovk.table.supplier')}`,
            dataIndex: 'supplier',
            key: 'supplier',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.table.kedaluwarsa')}`,
            dataIndex: 'tanggalKedaluarsa',
            key: 'tanggalKedaluarsa',
            width: 150,
        },
        {
            title: `${t('pages.master.ovk.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 130,
            fixed: 'right',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title='Edit'>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]
    const data = object && object.map(({ _id, code, name, producer, dosis, expired, ingredient, ovkMedia, 
        ovkShape, ovkUnit, recommendedUse, qtyUnit, ovkUnitRecording, ovkType }, index) => ({
        key: _id,
        kode: code,
        nama: name,
        supplier: producer,
        dosis: dosis,
        tanggalKedaluarsa: moment(new Date(parseInt(expired))).format("DD-MM-YYYY"),
        kandungan: ingredient,
        penggunaan: recommendedUse,
        golongan: ovkMedia.name,
        bentuk: ovkShape.name,
        type: ovkType.name,
        satuan: ovkUnit.name,
        kapasitas: <NumberFormat value={qtyUnit} displayType={'text'} thousandSeparator={true} suffix={' '+ovkUnitRecording?.name} />
    }))
 
    return (
        <OvkComponent
            navigate={props.navigate}
            columns={columns}
            data={data}
            loading={loading}
            fetchData={fetchData}
            setLoading={setLoading}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListOvkUnit,
    getListOvkMedia,
    getListOvkShape,
    getListOvkType,
    getListOvkUnitRecording,
    getDetailOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(OvkPage)
export default page