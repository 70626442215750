import React from 'react'
import { Form, Row, Col, InputNumber, Select, Button, Upload, Card, Breadcrumb, Layout } from 'antd'
import { Link } from 'react-router-dom';
import CONSTANTS from '../../../../common/utils/Constants'
import Loading from '../../../../common/component/loading/loading-container'

function CreatePenyakit(props) {
    const { handleSubmit, penyakit, handleFailed, navigate, umur, loading, dummyRequest, propsPicture, onPreview, fileList, locales } = props
    const { Option } = Select
    const { Content } = Layout
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/recording"><span>{locales("pages.recording.title")}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/peternak/recording/detail"><span>{locales("pages.recording.detail.title")}{umur}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{locales("pages.recording.detail.penyakit.tambah.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title='Tambah Data Penyakit' bordered={false}>
                                <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="basic" initialValues={{ remember: true}}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >{locales("pages.recording.detail.penyakit.tambah.penyakit")}</span>
                                            <Form.Item
                                                name="penyakit" rules={[{ required: true, message: `${locales("pages.recording.detail.penyakit.tambah.msgPenyakit")}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={locales("pages.recording.detail.penyakit.tambah.phPenyakit")}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular mt-5"
                                                >
                                                    {
                                                        penyakit.map(data =>
                                                            <Option
                                                                key={data.name.toString()}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >{locales("pages.recording.detail.penyakit.tambah.jumlah")}</span>
                                            <Form.Item
                                                name="jumlahAyam" rules={[{ required: true, message: `${locales("pages.recording.detail.deplesi.modal.msgJumlah")}` }]}
                                            >
                                                <InputNumber
                                                    name='jumlahAyam'
                                                    placeholder="1000..."
                                                    className="input-number mt-5"
                                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={24}>
                                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.penyakit.tambah.foto")}</span>
                                            <Form.Item name="fotoPenyakit">
                                                <Upload {...propsPicture}
                                                    customRequest={dummyRequest}
                                                    listType="picture-card"
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 5 && '+ Upload'}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button
                                        onClick={() => navigate(CONSTANTS.RECORDING_MENU_KEY)}
                                    >
                                        {locales('general.cancel')}
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 8, marginTop: 0 }}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {locales('general.create')}
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    )
}

export default CreatePenyakit