import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { API } from '../../../../common/api'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import MaterialComponent from '../../../../modules/dashboard-peternak/adjustment-stock-component/material-component/material-component'
import { getListMaterial } from '../../../../modules/dashboard-peternak/master-component/material-component/store/material-action'
import { getMaterial } from '../../../../app/dashboard-peternak/master-page/material-page/material-aksi-page/query-material'
import { getAdjustmentMaterial, detailAdjustmentMaterial, deleteAdjustmentMaterial, updateAdjustmentMaterial } from './material-aksi-page/query-adjustment-material-page'
import { getMonthlyStockMaterials } from '../../stock-flow-page/material-page/material-aksi-page/query-stock-flow-material'
import { useTranslation } from 'react-i18next'

function MaterialPage(props) {
    const { confirm } = Modal
    const { getListMaterial, idMonthlyStockMaterial, monthPeriode, yearPeriode } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [pagination, setPagination] = useState(10)
    const [loading, setLoading] = useState(false)
    const [idData, setIdData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    const [object, setObject] = useState([])
    const [material, setMaterial] = useState([])
    const [locked, setLocked] = useState(false)

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    /**Handle get data */
    const fetchData = (idMonthlyStockMaterial) => {
        setLoading(true)
        API.get(getAdjustmentMaterial('', 0, 0, idMonthlyStockMaterial))
            .then(res => {
                setObject(res.data.data.adjustmentMaterials.adjustmentMaterials)
                setLoading(false)
            }).catch((error) => {
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        getListMaterial(getMaterial('', 0, 0))
    }, [getListMaterial])

    useEffect(() => {
        fetchData(idMonthlyStockMaterial)
    }, [idMonthlyStockMaterial])

    const fetchDataMaterial = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockMaterials('', 0, 0, month,year))
            .then(res => {
                setMaterial(res.data.data.monthlyStockMaterials.calculateMonthlyStocks)
                setLocked(res.data.data.monthlyStockMaterials.locked)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setMaterial([])
                setLoading(false)
            })
    }
    useEffect(() => {
        fetchDataMaterial(monthPeriode, yearPeriode)
        // eslint-disable-next-line
    }, [monthPeriode, yearPeriode])


    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if (idData)
                API.get(detailAdjustmentMaterial(idData))
                    .then(res => {
                        setDetail(res.data.data.adjustmentMaterial)
                        setVisible(true)
                    }).catch((error) => {
                        setDetail(null)
                        setVisible(false)
                    })
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateAdjustmentMaterial(idData, values))
            .then(res => {
                if (res.data.data.updateAdjustmentMaterial._id) {
                    validationMessage('success', `${t('pages.stockflow.periode.penyesuaian.material.response.sukses.edit')}`)
                    fetchData(idMonthlyStockMaterial)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.penyesuaian.material.response.gagal.edit')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }
    /**End handle updated data */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteAdjustmentMaterial(_id))
            .then(res => {
                if (res.data.data.deleteAdjustmentMaterial.deleted === true) {
                    validationMessage('success', `${t('pages.stockflow.periode.penyesuaian.material.response.sukses.hapus')}`)
                    fetchData(idMonthlyStockMaterial)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.penyesuaian.material.response.gagal.hapus')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.stockflow.periode.penyesuaian.ovk.textHapus')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
    /**End handle delete data */

    const columns = [
        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            ...getColumnSearchProps('material', 'material'),
        },
        {
            title: `${t('pages.stockflow.periode.penyesuaian.jumlah')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            ...getColumnSearchProps('jumlah', `${t('pages.stockflow.periode.penyesuaian.jumlah')}`),
        },
        {
            title: `${t('pages.stockflow.periode.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 150,
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title='Edit'>
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: locked ? 'white smoke' : '#FFA903',
                                borderRadius: '5px'
                            }}
                            onClick={() => showModal(row.key)}
                            disabled={locked ? true : false}
                        ><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button 
                        style={{
                            color: 'white',
                            backgroundColor: locked ? 'white smoke' : '#FF0303',
                            borderRadius: '5px'
                        }}
                            onClick={() => hapusConfirm(row.key)}
                            disabled={locked ? true : false}
                        ><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = object.map(({ _id, material, qty }, index) => ({
        key: _id,
        id: _id,
        material: material.name,
        jumlah: qty,
    }))

    return (
        <MaterialComponent
            navigate={props.navigate}
            setLoading={setLoading}
            pagination={pagination}
            setPagination={setPagination}
            data={data}
            columns={columns}
            month={monthPeriode}
            year={yearPeriode}
            loading={loading}
            visible={visible}
            detail={detail}
            object={material}
            locked={locked}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            idData={idData}
            fetchData={fetchData}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    idMonthlyStockMaterial: state.adjustmentMaterial.idMonthlyStockMaterial,
    monthPeriode: state.rearingRecord.monthPeriode,
    yearPeriode: state.rearingRecord.yearPeriode,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListMaterial
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(MaterialPage)
export default page