import {notification } from 'antd';
import i18next from 'i18next'
import React from 'react'

export const validationMessage = (type,message,description) => {
  notification[type]({
    message: (message),
    description:
      (description),
  });
};
export const errMessage = (type, message, description) => {
  switch (true) {
    case message === 'Unauthenticated':
      notification[type]({ message: `${i18next.t('error.authenticate')}` })
      break
    case message === 'Unauthorized':
      notification[type]({ message: `${i18next.t('error.authorize')}` })
      break
    case message === 'User not found':
      notification[type]({ message: `${i18next.t('error.user')}` })
      break
    case message === 'Company not found':
      notification[type]({ message: `${i18next.t('error.company')}` })
      break
    case message === 'invalid credential':
      notification[type]({ message: `${i18next.t('error.email')}` })
      break
    case message === 'not verified':
      notification[type]({
        message: `${i18next.t('error.verified')}`,
        description:
          <span>
            {i18next.t('error.verifiedDesc')}
            <br /><a href='/resend-verify'>{i18next.t('error.verifiedLink')}</a>
          </span>,
        duration: 0
      })
      break
    case message === 'google not found':
      notification[type]({ message: `${i18next.t('error.google')}` })
      break
    case message === 'facebook not found':
      notification[type]({ message: `${i18next.t('error.facebook')}` })
      break
    case message === 'invalid password':
      notification[type]({ message: `${i18next.t('error.password')}` })
      break
    case message === 'email':
      notification[type]({ message: `${i18next.t('error.oauthEmail')}` })
      break
    case message === 'facebook':
      notification[type]({ message: `${i18next.t('error.oauthFb')}` })
      break
    case message === 'google':
      notification[type]({ message: `${i18next.t('error.oauthGoogle')}` })
      break
    case message === 'Captcha invalid':
      notification[type]({ message: `${i18next.t('error.captcha')}` })
      break
    case message.includes('email already registered'):
      notification[type]({
        message:
          `${i18next.t('error.registeredEmail',
            { email: message.replace(' - email already registered', '') })}`
      })
      break
    case message.includes('username already registered'):
      notification[type]({
        message:
          `${i18next.t('error.registeredUsername',
            { username: message.replace(' - username already registered', '') })}`
      })
      break
    case message === `Cannot read property '_id' of undefined`:
      notification[type]({ message: `${i18next.t('error.errId')}` })
      break
    case message === 'this date has been entered':
      notification[type]({ message: `${i18next.t('error.dateRecording')}` })
      break
      case message === 'This city has been added':
      notification[type]({ message: `${i18next.t('error.addCity')}` })
      break
    default:
      notification[type]({
        message: (message),
        description: (description),
      })
  }
}