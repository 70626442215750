export const getOvkList = (keyword,limit,skip) => {
    return (
        `query{
            ovks(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              ovks {
                    _id
                    name
                    ovkUnitRecording {
                        satuan  : name
                    }
              }
            }
          }
      `
    );
}

export const getOvk = (keyword,limit,skip) => {
    return (
        `query{
            ovks(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              ovks {
                  _id
                  name
                  dosis
                  code
                  ingredient
                  expired
                  producer
                  recommendedUse
                  ovkUnitRecording{
                    _id
                    name
                  }
                  qtyUnit
                  ovkType{
                      _id
                       name
                  }
                  ovkShape{
                      _id
                       name
                  }
                  ovkUnit {
                       _id
                       name
                  }
                  ovkMedia{
                     _id
                      name
                  }
              }
            }
          }
      `
    );
}

export const createOvk = (value,expired,unit) => {
    return {
        query: 
        `mutation{
                    createOvk(ovkInput: {
                    code : "${value.kodeOvk}"
                    name : "${value.namaOvk}"
                    dosis : "${value.dosisOvk}"
                    producer : "${value.distributor}"
                    recommendedUse :"${value.penggunaan}"
                    ingredient : "${value.kandunganOvk}"
                    expired : "${expired}"
                    ovkUnitRecording : "${unit ? value.unitRecording.value : value.unitRecording}"
                    qtyUnit : ${parseInt(value.qtyUnit)}
                    ovkType : "${value.ovkType.value}"
                    ovkShape: "${value.ovkShape.value}"
                    ovkUnit : "${value.ovkUnit.value}"
                    ovkMedia : "${value.ovkMedia.value}"
                }){
                _id
                }
            }
        `
    }
}

export const detailOvk = (_id) => {
    return (
        `query{
            ovk(_id : "${_id}"){
                _id
                name
                dosis
                code
                ingredient
                expired
                producer
                qtyUnit
                recommendedUse
                ovkType{
                    value : _id
                    name
                }
                ovkShape{
                    value : _id
                    name
                }
                ovkUnit{
                    value : _id
                    name
                }
                ovkMedia{
                    value : _id
                    name
                }
                ovkUnitRecording{
                    value: _id
                    name
                  }
              }
            }
    `
    );
}

export const udpateOvk = (_id,value,year) => {
    return {
        query: `mutation{
                    updateOvk(_id : "${_id}", updateOvkInput : {
                        code : "${value.kodeOvk}"
                        name : "${value.namaOvk}"
                        dosis : "${value.dosisOvk}"
                        producer : "${value.distributor}"
                        recommendedUse :"${value.penggunaan}"
                        ingredient : "${value.kandunganOvk}"
                        expired : "${year}"
                        ovkType : "${value.ovkType.value}"
                        ovkShape: "${value.ovkShape.value}"
                        ovkUnit : "${value.ovkUnit.value}"
                        ovkMedia : "${value.ovkMedia.value}"
                        ovkUnitRecording : "${value.ovkUnitRecording.value}"
                        qtyUnit : ${parseInt(value.qtyUnit)}
                    }){
                        _id
                    }
                }
            `
    }
}

export const deleteOvk = (_id) => {
    return (
        `mutation{
            deleteOvk(_id : "${_id}"){
                deleted
              }
            }
        `
    );
}

export const getOvkType = (keyword) => {
    return (
        `query{
            ovkTypes(keyword : "${keyword}", limit : 0, skip : 0){
                ovkTypes{
                    _id
                    name
                }
            } 
        }`
    );
}

export const getOvkShape = (keyword,limit,skip) => {
    return (
        `query{
            ovkShapes(keyword:"${keyword}",limit:0,skip:0){
                ovkShapes{
                    _id
                    name
                }
            }
        }
    `
    );
}

export const getOvkUnit = (keyword,limit,skip) => {
    return (
        `query{
            ovkUnits(keyword:"${keyword}",limit:0,skip:0){
                ovkUnits{
                    _id
                    name
                }
            }
        }
    `)
}

export const getOvkUnitRecording = () => {
    return (
        `query{
            ovkUnitRecordings(keyword:"",limit:0,skip:0){
                ovkUnitRecordings{
                    _id
                    name
                }
            }
        }
    `)
}

export const getOvkMedia = (keyword,limit,skip) => {
    return (
        `query{
            ovkMedias(keyword:"${keyword}",limit:0,skip:0){
                ovkMedias{
                    _id
                    name
                }
            }
        }
    `
    );
}