import React from 'react'
import { Layout, Row, Col, Table, Select, Modal, Dropdown, Form, DatePicker, Menu } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../../../common/component/loading/loading-container'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'

function PeriodeMaterialComponent(props) {
    const { columns, data, loading, yearRangeMaterial, yearMaterial, setYearMaterial, createConfirm, t,
        visible, setVisible, createNewData } = props
    const { Option } = Select
    const [form] = Form.useForm()
    
    const menu = (
        <Menu>
            <Menu.Item key="1">
                <div onClick={() => setVisible(true)}>Manual</div>
                <Modal
                    visible={visible}
                    title={`Manual Input ${t('pages.stockflow.periode.material.date')}`}
                    okText={t("general.create")}
                    width={800}
                    cancelText={t('general.cancel')}
                    onCancel={() => setVisible(false)}
                    onOk={() => {
                        form
                            .validateFields()
                            .then((values) => {
                                form.resetFields()
                                createNewData(values.date)
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info);
                            })
                    }}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        name="form_in_modal"
                    >
                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <span className="font-semi-bold black font-title-medium">{t("pages.stockflow.periode.material.date")}</span>
                                <Form.Item
                                    name="date" rules={[{ required: true, message: `${t("pages.stockflow.periode.livebird.msgDate")}` }]}
                                >
                                    <DatePicker
                                        placeholder={t("pages.stockflow.periode.livebird.phDate")}
                                        format={'MMMM YYYY'}
                                        picker='month'
                                        className="select-input-date mt-5"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => createConfirm()}>
                Auto
            </Menu.Item>
        </Menu>
    )

    return (
        <Layout style={{ backgroundColor: "white" }}>
            <Row>
                <Col lg={4} md={4} sm={4}>
                    <Select
                        showSearch
                        labelInValue
                        placeholder="Pilih Tahun"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        value={{ value: yearMaterial }}
                        className="select-kategori black font-regular mt-5 mb-10"
                        onChange={(value) => setYearMaterial(value.value)}
                    >
                        <Option key={'Semua'} value={'Semua'}>{t('pages.stockflow.periode.table.all')}</Option>
                        {yearRangeMaterial &&
                            yearRangeMaterial.map(data =>
                                <Option key={data} value={data}>{data}</Option>
                            )
                        }
                    </Select>
                </Col>
                <Col offset={16} lg={4} md={4} sm={4}>
                    <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                        <ButtonDashboard
                            text={t('pages.stockflow.periode.material.create')}
                            height={20}
                            backgroundColor="#008ad4"
                            borderRadius="5px"
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                            icon={faPlusSquare}
                        />
                    </Dropdown>
                </Col>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={false}
                            scroll={{y:'60vh'}}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
            </Row>
        </Layout>
    )
}

export default PeriodeMaterialComponent