import React from 'react'
import { Modal, Form, Row, Col, Select, InputNumber } from 'antd'

function CreateOvkComponent(props) {
    const { Option } = Select
    const { visible, handleSubmit, setVisible, stokPakan, showModal, feed, locales } = props
    const [form] = Form.useForm()

    const sak = (value) => {
        let idFeed = stokPakan.filter(d => d._id === value.value)[0].feed._id
        let price = feed.filter(d => d.idFeed === idFeed)[0]?.hargaPengeluaran
        form.setFieldsValue({
            price: price
        })
    }
    return (
        <div>
            <span onClick={() => showModal()}>{locales("pages.recording.detail.pakan.button.tambah")}</span>
              <Modal
                visible={visible}
                title={locales("pages.recording.detail.pakan.modal.titleTambah")}
                okText={locales('general.create')}
                width={500}
                cancelText={locales('general.cancel')}
                onCancel={() => {
                    setVisible(false)
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{locales("pages.recording.detail.pakan.modal.pakan")}</span>
                            <Form.Item
                                name="pakan" rules={[{ required: true, message: `${locales("pages.recording.detail.pakan.modal.msgPakan")}` }]}
                            >
                                <Select
                                    showSearch
                                    onChange={(val) => sak(val)}
                                    labelInValue
                                    placeholder={locales("pages.recording.detail.pakan.modal.phPakan")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        stokPakan.map(data =>
                                            <Option
                                                key={data.feed.name.toString()}
                                                value={data._id}
                                            >{data.feed.name + '-' + data.feed.type}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.pakan.modal.jumlah")}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${locales("pages.recording.detail.pakan.modal.msgJumlah")}` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Harga (Rp)</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                            >
                                <InputNumber
                                    placeholder="2500..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default CreateOvkComponent