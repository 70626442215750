import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Select, Input, Divider, Button } from 'antd'

function EditOvk(props) {
    const { Option } = Select
    const { visible, handleSubmit, data, handleCancel, stokOvk, suffix, monthlyOvk, locales } = props
    const [form] = Form.useForm()
    const [ovk, setOvk] = useState(data.ovkStock)
    const [jumlah, setJum] = useState(data.numberRecording)
    const [harga, setHarga] = useState(data.priceOvkTransaction)
    const [unit, setUnit] = useState(suffix)

    useEffect(() => {
        function setDetailData() {
            if (data === null) {
                setOvk('')
                setJum(0)
                setHarga(0)
            } else {
                setOvk(data.ovkStock)
                setJum(data.numberRecording)
                setHarga(data.priceOvkTransaction)
            }
            if (suffix === null) { setUnit('') } else { setUnit(suffix) }
        }
        setDetailData()
    }, [suffix, data])

    const onChangeOvk = (value) => {
        setOvk(value)
        const unitOvk = stokOvk.filter(d => d.ovk.name === value.label)[0].ovk.ovkUnitRecording.name
        setUnit(unitOvk)
        let idOvk = stokOvk.filter(d => d._id === value.value)[0].ovk._id
        let price = monthlyOvk.filter(d => d.idOvk === idOvk)[0]?.hargaPengeluaran
        setHarga(price)
    }
    const onChangeNum = (e) => {
        let value = e.target.value
        setJum(value)
    }
    const onChangeHarga = (e) => {
        let value = e.target.value
        setJum(value)
    }

    return (
        <div>
            <Modal
                visible={visible}
                title={locales("pages.recording.detail.ovk.modal.titleEdit")}
                width={500}
                footer={null}
                onCancel={() => handleCancel()}
            >
                <Form
                    form={form}
                    onFinish={() => {
                        form
                            .validateFields()
                            .then(() => {
                                const values = { ovk, jumlah, harga }
                                form.resetFields()
                                handleSubmit(values)
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info)
                            })
                    }}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{locales("pages.recording.detail.ovk.modal.ovk")}</span>
                            <Select
                                name='ovk' value={ovk}
                                showSearch
                                labelInValue
                                placeholder="Pilih Ovk"
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular mt-5"
                                onChange={(value) => onChangeOvk(value)}

                            >
                                {
                                    stokOvk.map(data =>
                                        <Option
                                            key={data.ovk.name.toString()}
                                            value={data._id}
                                        >{data.ovk.name}</Option>
                                    )
                                }
                            </Select>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.recording.detail.ovk.modal.jumlah")}</span>
                            <Input
                                name='jumlah' value={jumlah} type='number'
                                onChange={(value) => onChangeNum(value)}
                                placeholder="100..." required
                                suffix={unit}
                                className="input-form mt-5"
                            />
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Harga</span>
                            <Input
                                name='harga' value={harga} type='number'
                                onChange={(value) => onChangeHarga(value)}
                                placeholder="100..." required
                                prefix='Rp'
                                className="input-form mt-5"
                            />
                        </Col>
                        <Divider />
                        <Col span={8} offset={16}>
                            <Button className='float-right'
                                type="primary"
                                htmlType="submit"
                                style={{ marginLeft: 8, marginTop: 0 }}
                            >
                                Edit
                            </Button>
                            <Button onClick={() => handleCancel()} className='float-right'>
                                {locales('general.cancel')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default EditOvk;