export const TRANSLATIONS_EN = {
    "general": {
        "create": "Create",
        "detail": "Details",
        "del": "Delete",
        "cancel": "Cancel",
        "search": "Search",
        "open": "Open",
        "close": "Close",
        "back": "Back",
        "save": "Save",
        "add": "Add",
        "edit": "Edit",
        "reset": "Reset",
        "send": "Send",
        "lock": "Lock",
        "unlock": "Unlock",
        "next": "Next",
        "fin": "Finish",
        "action": "Action",
        "print": "Print",
        "yes": "Yes",
        "no": "No",
        "errCon": "Connection Error",
        "errData" : "Data Error"
    },
    "sidebar": {
        "dashboard": "Dashboard",
        "periodePemeliharaan": "Rearing Period",
        "recording": "Recording",
        "stok": "Stock",
        "subMenustok": {
            "pakan": "Feed",
            "ovk": "OVK",
            "material": "Materials"
        },
        "monitor": "Monitor",
        "subMenuMonitor": {
            "device": "Device",
            "ews": "EWS"
        },
        "pemakaianUmum": "General Use",
        "stokFlow": "Stock Flow",
        "subMenuStokFlow": {
            "periode": "Period",
            "liveBird": "Live Bird",
            "pakan": "Feed",
            "ovk": "OVK",
            "material": "Material",
            "resume": "Recapitulation "
        },
        "master": "Master",
        "subMenuMaster": {
            "kandang": "Cage",
            "strain": "Strain",
            "lingkungan": "Environment",
            "gudangPakan": "Feed Warehouse",
            "pakan": "Feed",
            "gudangOvk": "OVK Warehouse",
            "ovk": "OVK",
            "gudangMaterial": "Material Warehouse",
            "material": "Material",
            "biaya": "Cost",
            "overhead": "Overhead",
            "penyakit": "Disease",
            "kostumer": "Costumer",
            "supplier": "Supplier",
            "pengguna": "Users"
        }
    },
    "pages": {
        "auth":{
            "login": "Login",
            "welcome":"Welcome",
            "loginTitle": "Login with your account",
            "forgetTitle": "Forgot your password?",
            "forgetText": "Input your email to get notification for changing your password.",
            "phUsername": "Input your username/email",
            "phEmail": "Input your email",
            "phPass": "Input your password",
            "msgUsername":"Please input your username/email!",
            "msgEmail":"Please input your email!",
            "msgPass":"Please input your password!",
            "forget": "Forgot Password",
            "oauth": "Or login with",
            "send": "Send",
            "response":{
                "sukses":{
                    "login": "Login successful"
                },
                "gagal":{
                    "login": "Login failed"
                }
            }
        },
        "dashboard": {
            "title": "Record Dashboard Farmer",
            "phPeriode":"Select period",
            "cardStokAyam": "Stock (chickens)",
            "cardCulling": "Culling (chickens)",
            "cardDeplesi": "Depletion (chickens)",
            "cardFCR": "FCR",
            "recordLingkunganKandang": "Enclosure Condition Graph",
            "recordPerformaKandang": "Cage Performance Graph",
            "tabelPerformaKandang": "Cage Performance Table",
            "nilai": "Value",
            "mingguke": "Week",
            "recordNekorpsi": "Necropsy Results",
            "suhu": "Temperature",
            "standarSuhu": "Temperature Standard ",
            "amonia": "Amonia(NH3)",
            "standarAmonia": "Amonia Standard ",
            "kelembapan": "Humidity",
            "standarKelembapan": "Humidity Standard",
            "penyakit":"Disease",
            "jumlah":"Amount",
            "jam": "Hour",
            "hari": "Day",
            "tanggal": "Date",
            "mingguan": "Weekly",
            "deplesi": "Depletion",
            "standarDeplesi": "Depletion Standard",
            "standarBw": "Body Weight Standard",
            "standarFcr": "FCR Standard",
            "standarFi": "Feed Intake Standard",
            "jumlahNekropsi": "amount",
            "table": {
                "ekor": "chicken(s)",
                "umur": "Age",
                "date": "Date",
                "populasi": "Population (chickens)",
                "deplesi": {
                    "title": "Depletion",
                    "ket": "Details"
                },
                "bw": {
                    "sampling": "Sampling",
                    "ket": "Details"
                },
                "fcr": {
                    "ket": "Details"
                }
            },
            "performance":{
                "title": "Performance",
                "chartTitle": "House Performance Chart",
                "age": "Age",
                "day": "days",
                "chicken": "chickens",
                "population": "Population",
                "bw": "BW",
                "depletion": "Depletion",
                "adg": "ADG",
                "fcr": "FCR",
                "value": "Value",
                "standarBw":"' Body Weight Standard",
                "standarDepletion": "Depletion Standard",
                "standarFcr": "FCR Standard",
                "standarFi":"Feed Intake Standard"
            },
            "iot": {
                "title": "Climate",
                "chartTitle": "House Environment Condition Chart",
                "thiChartTitle": "THI Chart",
                "phParameter": "Select Parameter",
                "hour": "Hour",
                "day": "Day",
                "phTool": "Select Tool",
                "date": "Date",
                "humidity": "Humidity",
                "temperature": "Temperature (°C)",
                "airPressure": "Air Pressure",
                "value": "Value",
                "updatedAt": "Updated at:",
                "nodevice": "You have no registered BroilerX IoT device",
                "notelemetry": "Please select your device first to see detail information",
                "selfHumidity": "Self-scaling humidity"
            },
            "feed": {
                "title": "Feed Usage",
                "chart": "Feed Usage Chart",
                "age": "Age (day)",
                "qty": "Quantity (kg)",
                "feed": "Feed",
                "total": "Total",
                "day": "Days",
                "days": "days"
            }
        },
        "periode": {
            "title": "Maintenance Period",
            "periodeBerjalan": {
                "title": "Current period",
                "table": {
                    "tanggal": "Date",
                    "periode": "Period",
                    "kandang": "Cage",
                    "strain": "Strain",
                    "ppl": "Ppl",
                    "popHpp": "HPP Population (chicken)",
                    "pop": "Performance Population (chicken)",
                    "bobot": "Weight (Gr)",
                    "harga": "Price (Rp)",
                    "umur": "Age",
                    "performa": {
                        "deplesi": "Depletion"
                    },
                    "biaya": {
                        "title": "Price (Rp)",
                        "listrik": "Electricity",
                        "tenagaKerja": "Direct Labor",
                        "penyusutan": "Shrinkage Cage",
                        "bop": "Bop Cage"
                    },
                    "pilihan": "Action"
                },
                "button": {
                    "tambah": "Add Period",
                    "edit": "Edit",
                    "tutup": "Close",
                    "hapus": "Delete"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully Adding Period",
                        "hapus": "Successfully Deleting Period",
                        "edit": "Successfully Changed Period",
                        "tutup": "Successfully Closing Period"
                    },
                    "gagal": {
                        "tambah": "Failed to Add Period",
                        "hapus": "Failed to Delete Period",
                        "edit": "Failed to Change Period",
                        "tutup": "Failed to Close Period"
                    }
                }
            },
            "periodeSelesai": {
                "title": "End period",
                "table": {
                    "tanggal": "Date",
                    "periode": "Periode",
                    "kandang": "Cage",
                    "strain": "Strain",
                    "popHpp": "HPP Population (chicken)",
                    "pop": "Performance Population (chicken)",
                    "bobot": "Weight (Gr)",
                    "pilihan": "Action"
                },
                "button": {
                    "detail": "Details",
                    "hapus": "Delete"
                }
            },
            "modal": {
                "titleTambah": "Add Current Period",
                "titleEdit": "Edit Current Period",
                "create": "Create",
                "cancel": "Cancel",
                "namaPeriode": "Period Name",
                "tanggalPeriode": "Period Date",
                "bobot": "Weight",
                "populasiHpp": "Initial Population (HPP)",
                "populasi": "Initial Population (Performance)",
                "kandang": "Cage",
                "strain": "Strain",
                "ppl": "Ppl",
                "listrik": "Electricity",
                "penyusutan": "Shrinkage Cage",
                "tenagaKerja": "Direct Labor",
                "bop": "Bop Cage",
                "harga": "DOC Price",
                "keterangan": "Description",
                "phNamaPeriode": "June Period...",
                "phTanggalPeriode": "Select a period date",
                "phKandang": "Select cage",
                "phStrain": "Select strain",
                "phKet": "Input description",
                "suffixPopulasi": "chicken(s)",
                "msgNamaPeriode": "Please input period name!",
                "msgTglPeriode": "Please select a period date!",
                "msgBobot": "Please input weight of DOC chicken!",
                "msgPopulationHpp": "Please input initial population for HPP!",
                "msgPopulation": "Please input initial population for Performance!",
                "msgKandang": "Please select cage!",
                "msgStrain": "Please select strain!",
                "msgPpl": "Please input cage ppl!",
                "msgListrik": "Please input electricity cost!",
                "msgPenyusutan": "Please input shrinkage cage!",
                "msgTenagaKerja": "Please input direct labor costs!",
                "msgBop": "Please input Bop cage cost!",
                "msgHarga": "Please input DOC chicken price!",
                "titleTutupPeriode": "Close Maintenance Period",
                "tglPenutupan": "Closing Date",
                "phTglPenutupan": "Select closing date",
                "msgTglPenutupan": "Please select closing date!"
            },
            "detail": {
                "title": "Detail Current Period ",
                "grafikTitle": "Cage Performance Graph",
                "tableTitle": "Cage Performance Table",
                "minggu": "Week"
            },
            "textHapus": "Are you sure you want to delete the period?",
            "textOpen": "Are you sure you want to open the period?",
            "response": {
                "sukses": {
                    "hapus": "Successfully Deleting Period",
                    "open":"Successfully opened period"
                },
                "gagal": {
                    "hapus": "Failed to Delete Period",
                    "open":"Failed to open period"
                }
            }
        },
        "recording": {
            "title": "Maintenance Recording",
            "table": {
                "pilihan": "Action",
                "umur": "Age",
                "tanggal": "Date",
                "pakan": {
                    "title": "Feed",
                    "pakan/hari": "Feed / Day (Kg)",
                    "cum": "CUM",
                    "fiAct": "FI Act (Gr/Chicken)",
                    "fiStd": "Fi Std (Gr/Chicken)",
                    "keterangan": "Inf",
                    "actCum": "Act Cum (Gr/Chicken)",
                    "stdFiCum": "STD FI Cum (Gr/Chicken)",
                    "deviasi": "Deviation CUM (%)"
                },
                "deplesi": {
                    "title": "Depletion",
                    "culling": "Culling (chickens)",
                    "mati": "Dead (chickens)",
                    "jumlah": "Amount (chickens)",
                    "cum": "CUM (chickens)",
                    "persenHari": "Day (%)",
                    "persenCum": "CUM (%)",
                    "std": "STD (%)",
                    "keterangan": "Inf"
                },
                "panen": {
                    "title": "Harvest",
                    "total": "Amount (chickens)",
                    "berat": "Weight (Kg)",
                    "sisaAyam": "Rest of Chicken (chickens)"
                },
                "bw": {
                    "sampling": "Sampling (Gr/chicken)",
                    "std": "STD (Gr/chicken)",
                    "deviasi": "Deviation (%)",
                    "adg": "Adg (Gr)",
                    "totalBerat": "Total Weight (Kg)",
                    "abw": "ABW (Gr)"
                },
                "fcr": {
                    "actCum": "Act Cum(Gr)",
                    "std": "STD (Gr)"
                },
                "biaya": {
                    "pakan": "Feed (Rp)",
                    "ovk": "OVK (Rp)",
                    "material": "Material (Rp)",
                    "penyusutan": "Depreciation (Rp)",
                    "hpp": "Total Hpp (Rp)"
                }
            },
            "button": {
                "tambah": "Add Recording",
                "hapus": "Delete",
                "detail": "Detail"
            },
            "detail": {
                "title": "Details of Recording Day-",
                "deplesi": {
                    "title": "Depletion",
                    "table": {
                        "jenis": "Type",
                        "jumlah": "Amount (chickens)",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add Depletion Recording",
                        "hapus": "Delete",
                        "edit": "Edit"
                    },
                    "textHapus": "Are you sure you want to delete depletion data ?",
                    "modal": {
                        "titleTambah": "Add Depletion Recording",
                        "titleEdit": "Edit Depletion Recording",
                        "jenis": "Type",
                        "jumlah": "Amount (chickens)",
                        "keterangan": "Description",
                        "msgJenis": "Please input depletion type!",
                        "phJenis": "Input depletion type",
                        "msgJumlah": "Please input amount!",
                        "phKet": "Input additional information"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding Depletion Data",
                            "edit": "Successfully changed the depletion data",
                            "hapus": "Successfully deleted depletion data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add Depletion Data",
                            "edit": "Failed to change depletion data",
                            "hapus": "Failed to clear depletion data"
                        }
                    }
                },
                "panen": {
                    "title": "Harvest",
                    "table": {
                        "berat": "Chicken Weight (kg)",
                        "jumlah": "Amount (chickens)",
                        "rataRata": "Average Chicken Weight (kg/Chicken)",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add Harvest Recording",
                        "hapus": "Delete",
                        "edit": "Edit"
                    },
                    "textHapus": "Are you sure you want to delete harvest data?",
                    "modal": {
                        "titleTambah": "Add Harvest Recording",
                        "titleEdit": "Edit Harvest Recording",
                        "berat": "Chicken Weight (kg)",
                        "jumlah": "Amount (chickens)",
                        "keterangan": "Information",
                        "msgBerat": "Please input weight!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding Harvest Data",
                            "edit": "Successfully Changing Harvest Data",
                            "hapus": "Successfully Deleting Harvest Data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add Harvest Data",
                            "edit": "Failed to Change Harvest Data",
                            "hapus": "Failed to Delete Harvest Data"
                        }
                    }
                },
                "pertumbuhan": {
                    "title": "Growing",
                    "table": {
                        "parameter": "Parameter",
                        "nilai": "Value (Gram)",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add Growth Recording",
                        "hapus": "Delete",
                        "edit": "Edit"
                    },
                    "textHapus": "Are you sure you want to delete the growth records?",
                    "modal": {
                        "titleTambah": "Add Growth Recording",
                        "titleEdit": "Edit Growth Recording",
                        "berat": "Weight of Sampling Chickens (Gram)",
                        "keterangan": "Information"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding Growth Data",
                            "edit": "Successfully Changing Growth Data",
                            "hapus": "Successfully Deleting Growth Data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add Growth Data",
                            "edit": "Failed to Change Growth Data",
                            "hapus": "Failed to Delete Growth Data"
                        }
                    }
                },
                "pakan": {
                    "title": "Feed",
                    "table": {
                        "stok": "Feed Stock",
                        "pakan": "Feed",
                        "jumlah": "Amount (Kg)",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add Feed Recording",
                        "hapus": "Delete",
                        "edit": "Edit"
                    },
                    "textHapus": "Are you sure you want to delete the recording feed data?",
                    "modal": {
                        "titleTambah": "Add Feed Recording",
                        "titleEdit": "Edit Feed Recording",
                        "stok": "Feed Stock",
                        "pakan": "Feed",
                        "jumlah": "Amount (Kg)",
                        "msgPakan":"Plese select feed!",
                        "phPakan":"Select feed",
                        "msgJumlah":"Please input amount of feed usage!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding Feed Recording Data",
                            "edit": "Successfully Changing Feed Recording Data",
                            "hapus": "Successfully Deleting Feed Recording Data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add Feed Recording Data",
                            "edit": "Failed to Change Feed Recording Data",
                            "hapus": "Failed to Delete Feed Recording Data"
                        }
                    }
                },
                "ovk": {
                    "title": "OVK",
                    "table": {
                        "stok": "OVK Stock",
                        "ovk": "OVK",
                        "jumlah": "Amount",
                        "jumlahPengadaan": "Amount of Procurement",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add OVK Recording",
                        "hapus": "Delete",
                        "edit": "Edit"
                    },
                    "textHapus": "Are you sure you want to delete the ovk recording data?",
                    "modal": {
                        "titleTambah": "Add Recording Material",
                        "titleEdit": "Edit Recording Material",
                        "stok": "OVK Stock",
                        "ovk": "OVK",
                        "jumlah": "Total",
                        "msgOvk":"Please select OVK!",
                        "phOvk":"Select OVK",
                        "msgJumlah":"Please input amount of OVK usage!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding OVK Recording Data",
                            "edit": "Successfully Changing OVK Recording Data",
                            "hapus": "Successfully Deleting OVK Recording Data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add OVK Recording Data",
                            "edit": "Failed to Change OVK Recording Data",
                            "hapus": "Failed to Delete OVK Recording Data"
                        }
                    }
                },
                "material": {
                    "title": "Material",
                    "table": {
                        "stok": "Material Stock",
                        "material": "Material",
                        "jumlah": "Total",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add Material Recording",
                        "hapus": "Delete",
                        "edit": "Edit"
                    },
                    "textHapus": "Are you sure you want to delete the recording material data?",
                    "modal": {
                        "titleTambah": "Add Recording Material",
                        "titleEdit": "Edit Recording Material",
                        "stok": "Material Stock",
                        "material": "Material",
                        "jumlah": "Total",
                        "msgMaterial":"Please select material!",
                        "phMaterial":"Select material",
                        "msgJumlah":"Please input amount of material usage!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding Material Recording Data",
                            "edit": "Successfully Changing Material Recording Data",
                            "hapus": "Successfully Deleting Material Recording Data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add Material Recording Data",
                            "edit": "Failed to Change Material Recording Data",
                            "hapus": "Failed to Delete Material Recording Data"
                        }
                    }
                },
                "penyakit": {
                    "title": "Disease",
                    "table": {
                        "penyakit": "Disease",
                        "jumlah": "Total (chickens)",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add Disease Recording",
                        "hapus": "Delete",
                        "edit": "Edit",
                        "foto": "Picture"
                    },
                    "textHapus": "Are you sure you want to delete the disease recording data?",
                    "edit": {
                        "title": "Edit Disease Data",
                        "penyakit": "Disease",
                        "jumlah": "Total (chickens)",
                        "keterangan": "Information"
                    },
                    "tambah": {
                        "title": "Add Disease Data",
                        "penyakit": "Disease",
                        "jumlah": "Total (chickens)",
                        "foto": "Disease Photo",
                        "keterangan": "Information",
                        "msgPenyakit": "Please input disease",
                        "phPenyakit": "Input disease"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding Disease Data",
                            "edit": "Successfully Changing Disease Data",
                            "hapus": "Successfully Deleting Disease Data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add Disease Data",
                            "edit": "Failed to Change Disease Data",
                            "hapus": "Failed to Delete Disease Data"
                        }
                    }
                },
                "overhead": {
                    "title": "Overhead",
                    "table": {
                        "overhead": "Overhead",
                        "jumlah": "Quantity",
                        "price": "Price (Rp)",
                        "pilihan": "Action"
                    },
                    "button": {
                        "tambah": "Add Overhead Recording",
                        "hapus": "Delete",
                        "edit": "Edit"
                    },
                    "textHapus": "Are you sure you want to delete the recording overhead data?",
                    "modal": {
                        "titleTambah": "Add Overhead Recording",
                        "titleEdit": "Edit Overhead Recording",
                        "overhead": "Overhead",
                        "jumlah": "Quantity",
                        "price": "Price",
                        "phOverhead":"Select overhead",
                        "msgOverhead":"Plese select overhead!",
                        "msgPrice":"Plese input price!",
                        "msgJumlah":"Please input quantity of overhead usage!"
                    },
                    "response": {
                        "sukses": {
                            "tambah": "Successfully Adding overhead Recording Data",
                            "edit": "Successfully Changing overhead Recording Data",
                            "hapus": "Successfully Deleting overhead Recording Data"
                        },
                        "gagal": {
                            "tambah": "Failed to Add Overhead Recording Data",
                            "edit": "Failed to Change Overhead Recording Data",
                            "hapus": "Failed to Delete Overhead Recording Data"
                        }
                    }
                }
            },
            "modal": {
                "title": "Add Maintenance Recording",
                "tanggal": "Recording date",
                "phTgl": "Select recording date",
                "msgTgl": "Please select recording date!"
            },
            "response": {
                "sukses": {
                    "tambah": "Successfully added Maintenance Recording",
                    "hapus": "Successfully deleted Maintenance Recording"
                },
                "gagal": {
                    "tambah": "Failed to add Maintenance Recording",
                    "hapus": "Failed to delete Maintenance Recording"
                }
            },
            "textHapus": "Are you sure you want to delete recording?"
        },
        "stok": {
            "pakan": {
                "title": "Feed Stock",
                "table": {
                    "kode": "Feed Code",
                    "nama": "Feed Name",
                    "pengadaan": "Procurement Name",
                    "supplier": "Supplier",
                    "saldo": "Rest Feed (Kg)",
                    "pilihan": "Action"
                },
                "modal": {
                    "titleTambah": "Add New Feed Stock",
                    "titleEdit": "Edit Feed Stock",
                    "gudang": "Feed Warehouse",
                    "pakan": "Feed",
                    "namaPengadaan": "Procurement Name",
                    "tglPengadaan": "Procurement Date",
                    "harga": "Unit Price",
                    "stok": "Amount of Stock",
                    "phGudang": "Select feed warehouse",
                    "phPakan": "Select feed",
                    "phNamaPengadaan": "Pengadaan Stater",
                    "phTglPengadaan": "Select procurement date",
                    "phTglPenambahan": "Select date of addition",
                    "phTglPeriode": "Select period date",
                    "msgGudang": "Please select feed warehouse!",
                    "msgPakan": "Plese select feed!",
                    "msgNamaPengadaan": "Please input procurement name!",
                    "msgTglPengadaan": "Please select procurement date!",
                    "msgTglPenambahan": "Please select date of addition!",
                    "msgTglPeriode": "Please select period date!",
                    "msgHarga": "Please input feed price!",
                    "msgStok": "Please input amount of stock!"
                },
                "button": {
                    "tambahBaru": "Add New Feed Stock",
                    "edit": "Edit Stock",
                    "detail": "Details",
                    "hapus": "Delete",
                    "tambah": "Add Stock"
                },
                "tambah": {
                    "title": "Add Feed Stock",
                    "tanggal": "Addition Date",
                    "jumlah": "Amount of Stock",
                    "harga": "Feed Price"
                },
                "edit": {
                    "title": "Edit Feed Stock",
                    "gudang": "Feed Warehouse",
                    "pakan": "Feed",
                    "pengadaan": "Procurement Name"
                },
                "textHapus": "Are you sure you want to remove the feed stock",
                "detail": {
                    "title": "Feed Stock Transaction Details ",
                    "penambahan": {
                        "title": "Addition",
                        "table": {
                            "tanggal": "Transaction date",
                            "harga": "Feed Prices (Rp)",
                            "jumlah": "Amount of Stock (Kg)",
                            "pilihan": "Action"
                        },
                        "button": {
                            "edit": "Edit Stock",
                            "hapus": "Delete"
                        },
                        "textHapus": "Are you sure you want to delete feed transactions?",
                        "edit": {
                            "title": "Edit Feed Stock Addition Transaction",
                            "jumlah": "Amount of Stock",
                            "tanggal": "Stock Date",
                            "harga": "Price"
                        }
                    },
                    "penggunaan": {
                        "title": "Usage",
                        "table": {
                            "tanggal": "Transaction date",
                            "jumlah": "Amount of Stock (Kg)",
                            "pilihan": "Action"
                        },
                        "button": {
                            "edit": "Edit Stock",
                            "hapus": "Delete"
                        },
                        "textHapus": "Are you sure you want to delete feed transactions?",
                        "edit": {
                            "title": "Edit Feed Stock Usage Transactions",
                            "jumlah": "Amount of Stock",
                            "tanggal": "Stock Date"
                        }
                    }
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added feed stock",
                        "edit": "Successfully changed feed stock",
                        "editTrans": "Successfully changed feed transaction",
                        "hapus": "Successfully deleted feed stock",
                        "hapusTrans": "Successfully deleted feed transaction"
                    },
                    "gagal": {
                        "tambah": "Failed to add feed stock",
                        "edit": "Failed to change feed stock",
                        "editTrans": "Failed to change feed transaction",
                        "hapus": "Failed to delete feed stock",
                        "hapusTrans": "Failed to delete feed transaction"
                    }
                }
            },
            "ovk": {
                "title": "OVK Stock",
                "table": {
                    "kode": "OVK Code",
                    "nama": "Procurement Name",
                    "pengadaan": "Procurement Name",
                    "supplier": "Supplier",
                    "saldo": "Rest OVK",
                    "pilihan": "Action"
                },
                "button": {
                    "tambahBaru": "Add New OVK Stock",
                    "edit": "Edit Stock",
                    "detail": "Detail",
                    "hapus": "Delete",
                    "tambah": "Add Stock"
                },
                "tambah": {
                    "title": "Add OVK Stock",
                    "gudang": "OVK Warehouse",
                    "nama": "Procurement Name",
                    "tglPengadaan": "Procurement Date",
                    "tglPenambahan": "Addition Date",
                    "jumlahPengadaan": "Amount of Addition",
                    "jumlahStok": "Amount of Stock",
                    "harga": "OVK Price",
                    "phGudang": "Select OVK Warehouse",
                    "phOVK": "Select OVK",
                    "phTglPengadaan": "Select procurement date",
                    "phTglPenambahan": "Select addition date",
                    "phTglPeriode": "Select period date",
                    "msgGudang": "Please select warehouse!",
                    "msgOvk": "Please select OVK!",
                    "msgNama": "Please input procurement name!",
                    "msgTglPengadaan": "Please select procurement date!",
                    "msgTglPenambahan": "Please select addition date!",
                    "msgTglPeriode": "Please select period date!",
                    "msgHarga": "Please input OVK price!",
                    "msgJumlahPengadaan": "Please input amount of procurement!",
                    "msgJumlahStok": "Please input amount of stock!"
                },
                "edit": {
                    "title": "Edit OVK Stock",
                    "gudang": "OVK warehouse",
                    "ovk": "OVK",
                    "pengadaan": "Procurement Name"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added OVK stock",
                        "edit": "Successfully changed OVK stock",
                        "editTrans": "Successfully changed OVK transaction",
                        "hapus": "Successfully deleted OVK stock",
                        "hapusTrans": "Successfully deleted OVK transaction"
                    },
                    "gagal": {
                        "tambah": "Failed to add OVK stock",
                        "edit": "Failed to change OVK stock",
                        "editTrans": "Failed to change OVK transaction",
                        "hapus": "Failed to delete OVK stock",
                        "hapusTrans": "Failed to delete OVK transaction"
                    }
                },
                "textHapus": "Are you sure you want to remove the OVK stock",
                "detail": {
                    "title": "OVK Stock Transaction Details ",
                    "penambahan": {
                        "title": "Addition",
                        "table": {
                            "tanggal": "Transaction date",
                            "harga": "OVK Price (Rp)",
                            "jumlah": "Amount",
                            "pilihan": "Action"
                        },
                        "button": {
                            "edit": "Edit Stock",
                            "hapus": "Delete"
                        },
                        "textHapus": "Are you sure you want to delete OVK transactions?",
                        "edit": {
                            "title": "Edit OVK Stock Addition Transaction",
                            "jumlah": "Amount of Stock",
                            "tanggal": "Stock Date",
                            "harga": "Price"
                        }
                    },
                    "penggunaan": {
                        "title": "Usage",
                        "table": {
                            "tanggal": "Transaction date",
                            "jumlah": "Amount",
                            "pilihan": "Action"
                        },
                        "button": {
                            "edit": "Edit Stock",
                            "hapus": "Delete"
                        },
                        "textHapus": "Are you sure you want to delete ovk transactions?",
                        "edit": {
                            "title": "Edit OVK Stock Usage Transactions",
                            "jumlah": "Amount of Stock",
                            "tanggal": "Stock Date"
                        }
                    }
                }
            },
            "material": {
                "title": "Material Stock",
                "table": {
                    "kode": "Material Code",
                    "pengadaan": "Procurement Name",
                    "supplier": "Supplier",
                    "saldo": "Rest Material",
                    "pilihan": "Action"
                },
                "button": {
                    "tambahBaru": "Add New Material Stock",
                    "edit": "Edit Stock",
                    "detail": "Detail",
                    "hapus": "Delete",
                    "tambah": "Add Stock"
                },
                "tambah": {
                    "title": "Add Material Stock",
                    "gudang": "Material Warehouse",
                    "pengadaan": "Procurement Name",
                    "tglPengadaan": "Procurement Date",
                    "tglPenambahan": "Addition Date",
                    "jumlah": "Amount of Stock",
                    "harga": "Material Prices",
                    "phGudang": "Select material warehouse",
                    "phMaterial": "Select material",
                    "phNamaPengadaan": "Pengadaan Stater",
                    "phTglPengadaan": "Select procurement date",
                    "phTglPenambahan": "Select addition date",
                    "phTglPenggunaan": "Select usage date",
                    "msgGudang": "Please select material warehouse!",
                    "msgMaterial": "Please select material!",
                    "msgNamaPengadaan": "Please input procurement name!",
                    "msgTglPengadaan": "Please select procurement date!",
                    "msgTglPenambahan": "Please select addition date!",
                    "msgTglPengunaan": "Please select usage date!",
                    "msgHarga": "Please input material price!",
                    "msgStok": "Please input amount of stock!"
                },
                "edit": {
                    "title": "Edit Material Stock"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added material stock",
                        "edit": "Successfully changed material stock",
                        "editTrans": "Successfully changed material transaction",
                        "hapus": "Successfully deleted material stock",
                        "hapusTrans": "Successfully deleted material transaction"
                    },
                    "gagal": {
                        "tambah": "Failed to add material stock",
                        "edit": "Failed to change material stock",
                        "editTrans": "Failed to change material transaction",
                        "hapus": "Failed to delete material stock",
                        "hapusTrans": "Failed to delete material transaction"
                    }
                },
                "textHapus": "Are you sure you want to clear the stock material",
                "detail": {
                    "title": "Material Stock Transaction Details ",
                    "penambahan": {
                        "title": "Addition",
                        "table": {
                            "tanggal": "Transaction date",
                            "harga": "Material Prices (Rp)",
                            "jumlah": "Amount",
                            "pilihan": "Action"
                        },
                        "button": {
                            "edit": "Edit Stock",
                            "hapus": "Delete"
                        },
                        "textHapus": "Are you sure you want to delete material transactions?",
                        "edit": {
                            "title": "Edit Material Stock Addition Transaction",
                            "jumlah": "Amount of Stock",
                            "tanggal": "Stock Date",
                            "harga": "Price"
                        }
                    },
                    "penggunaan": {
                        "title": "Usage",
                        "table": {
                            "tanggal": "Transaction date",
                            "jumlah": "Amount",
                            "pilihan": "Action"
                        },
                        "button": {
                            "edit": "Edit Stock",
                            "hapus": "Delete"
                        },
                        "textHapus": "Are you sure you want to delete material transactions?",
                        "edit": {
                            "title": "Edit Material Stock Use Transactions",
                            "jumlah": "Amount of Stock",
                            "tanggal": "Stock Date"
                        }
                    }
                }
            }
        },
          "monitor": {
            "title": "Devices",
            "serial": "Serial Number",
            "house": "House",
            "tool": "Device",
            "position": "Position",
            "add": "Add Device",
            "battery": "Battery",
            "type": "Device Type",
            "phHouse": "Select house",
            "phType": "Select device type",
            "phPosition": "Center..",
            "ews": "Early Warning System",
            "envName": "Environtment Standard Name",
            "std": "Environtment Standard",
            "calibrate": "Calibrate",
            "day": "Day",
            "maxTemp": "Max Temperature",
            "minTemp": "Min Temperature",
            "maxHumidity": "Max Humidity",
            "minHumidity": "Min Humidity",
            "maxCo2": "Max CO₂",
            "msgDay": "Please input day!",
            "msgEnvName": "Please input environtment standard name!",
            "msgSerial": "Please input serial number!",
            "msgHouse": "Please select house!",
            "msgType": "Please select device type!",
            "msgPosition": "Please input position!",
            "delConfirm": "Are you sure want to delete device?",
            "delStdConfirm": "Are you sure want to delete environmtment standard",
            "delStdDayConfirm": "Are you sure want to delete environmtment standard day",
            "edit": "Edit Device",
            "addStandardEnv": "Add Environtment Standard",
            "editStandardEnv": "Edit Environtment Standard",
            "addTare": "Add Tare Device",
            "latest": "Latest",
            "manual": "Manual",
            "tareTime": "Calibration time",
            "current": "Current",
            "tare": "Tare",
            "temp": "Temperature",
            "humidity": "Humidity",
            "windSpeed": "Wind Speed",
            "weight": "Weight",
            "desc": "Description",
            "phDesc": "Input description..",
            "resAddSuccess": "Successfully added device",
            "resEditSuccess": "Successfully changed device",
            "resDelSuccess": "Successfully deleted device",
            "resAddStdSuccess": "Successfully added environtment standard",
            "resEditStdSuccess": "Successfully changed environtment standard",
            "resDelStdSuccess": "Successfully deleted environtment standard",
            "resAddTareSuccess": "Successfully added tare and calibrate device",
            "resAddFailed": "Failed to add device",
            "resEditFailed": "Failed to change device",
            "resDelFailed": "Failed to delete device",
            "resAddStdFailed": "Failed to add environtment standard",
            "resEditStdFailed": "Failed to change environtment standard",
            "resDelStdFailed": "Failed to delete environtment standard",
            "resAddTareFailed": "Failed to add tare and device calibration"
        },
        "pemakaianUmum": {
            "title": "Common Usage of OVK",
            "table": {
                "kandang": "Cage",
                "periode": "Period",
                "ovk": "OVK",
                "jumlah": "Amount",
                "pilihan": "Action"
            },
            "button": {
                "tambah": "Add OVK General Usage",
                "edit": "Edit",
                "hapus": "Delete"
            },
            "tambah": {
                "title": "Add OVK General Usage",
                "kandang": "Cage",
                "periode": "Period",
                "tanggal": "Amount",
                "ovk": "OVK",
                "jumlah": "Amount",
                "keterangan": "Information",
                "phKandang": "Select cage",
                "phPeriode": "Select period",
                "phTgl": "Select date",
                "phOvk": "Select OVK",
                "phKet": "Input additional information",
                "msgKandang": "Please select cage!",
                "msgPeriode": "Please select period!",
                "msgTgl": "Please select date!",
                "msgOvk": "Please select OVK!",
                "msgJumlah": "Please input amount of usage!"
            },
            "edit": {
                "title": "Edit General Usage OVK",
                "ovk": "OVK",
                "jumlah": "Amount",
                "keterangan": "Information"
            },
            "textHapus": "Are you sure you want to remove this common usage of OVK?",
            "response": {
                "sukses": {
                    "tambah": "Successfully added common usage of OVK",
                    "edit": "Successfully changed common usage of OVK",
                    "hapus": "Successfully deleted common usage of OVK"
                },
                "gagal": {
                    "tambah": "Failed to add common usage of OVK",
                    "edit": "Failed to change common usage of OVK",
                    "hapus": "Failed to delete common usage of OVK"
                }
            }
        },
        "stockflow": {
            "periode": {
                "title": "Stock Flow Period",
                "table": {
                    "periode": "Period",
                    "tahun": "Year",
                    "status": "Status",
                    "pilihan": "Action",
                    "penyesuaian": "Adjustment",
                    "lock": "Lock",
                    "unlock": "Unlock",
                    "locked": "Locked",
                    "unlocked": "Unlocked",
                    "all": "All"
                },
                "penyesuaian": {
                    "jumlah": "Amount",
                    "difference": "Difference",
                    "phSelect": "Select ",
                    "msgSelect": "Please select ",
                    "msgJumlah": "Please input amount of adjustment!",
                    "pakan": {
                        "title": "Adjustment of Feed Stock Flow",
                        "tambah": "Add Feed Adjustment",
                        "edit": "Edit Feed Adjustment",
                        "textHapus": "Are you sure want to delete this feed adjustment?",
                        "response": {
                            "sukses": {
                                "tambah": "Successfully added feed adjustment",
                                "edit": "Successfully changed feed adjustment",
                                "hapus": "Successfully deleted feed adjustment"
                            },
                            "gagal": {
                                "tambah": "Failed to add feed adjustment",
                                "edit": "Failed to change feed adjustment",
                                "hapus": "Failed to delete feed adjustment"
                            }
                        }
                    },
                    "ovk": {
                        "title": "Adjustment of OVK Stock Flow",
                        "tambah": "Add OVK Adjustment",
                        "edit": "Edit OVK Adjustment",
                        "textHapus": "Are you sure want to delete this OVK adjustment?",
                        "response": {
                            "sukses": {
                                "tambah": "Successfully added OVK adjustment",
                                "edit": "Successfully changed OVK adjustment",
                                "hapus": "Successfully deleted OVK adjustment"
                            },
                            "gagal": {
                                "tambah": "Failed to add OVK adjustment",
                                "edit": "Failed to change OVK adjustment",
                                "hapus": "Failed to delete OVK adjustment"
                            }
                        }
                    },
                    "material": {
                        "title": "Adjustment of Material Stock Flow",
                        "tambah": "Add Material Adjustment",
                        "edit": "Edit Material Adjustment",
                        "textHapus": "Are you sure want to delete this material adjustment?",
                        "response": {
                            "sukses": {
                                "tambah": "Successfully added material adjustment",
                                "edit": "Successfully changed material adjustment",
                                "hapus": "Successfully deleted material adjustment"
                            },
                            "gagal": {
                                "tambah": "Failed to add material adjustment",
                                "edit": "Failed to change material adjustment",
                                "hapus": "Failed to delete material adjustment"
                            }
                        }
                    },
                    "livebird": {
                        "title": "Adjustment of Live Bird Stock Flow",
                        "tambah": "Add Live Bird Adjustment",
                        "edit": "Edit Live Bird Adjustment",
                        "textHapus": "Are you sure want to delete this live bird adjustment ini?",
                        "response": {
                            "sukses": {
                                "tambah": "Successfully added live bird adjustment",
                                "edit": "Successfully changed live bird adjustment",
                                "hapus": "Successfully deleted live bird adjustment"
                            },
                            "gagal": {
                                "tambah": "Failed to add live bird adjustment",
                                "edit": "Failed to change live bird adjustment",
                                "hapus": "Failed to delete live bird adjustment"
                            }
                        }
                    }
                },
                "pakan": {
                    "title": "Feed",
                    "titleInitialBalance": "Initial Balance Feed ",
                    "titleEdit": "Edit Initial Balance Feed",
                    "create": "Add Feed Period",
                    "qty": "Quantity",
                    "price": "Price (Rp)",
                    "date": "Period Month of Feed",
                    "msgQty": "Please input quantity!",
                    "msgPrice": "Please input price!",
                    "textCreate": "Are you sure want to create next feed period?",
                    "textClose": "Are you sure want to close feed period in ",
                    "textOpen": "Are you sure want to open feed period in ",
                    "textDel": "Are you sure want to delete feed period in ",
                    "response": {
                        "sukses": {
                            "create": "Successfully added feed period",
                            "close": "Successfully closed feed period",
                            "reclose": "Successfully closed back feed period",
                            "open": "Successfully opened feed period",
                            "edit": "Successfully updated feed initial balance",
                            "delete": "Successfully deleted feed period"
                        },
                        "gagal": {
                            "create": "Failed to add feed period",
                            "close": "Failed to close feed period",
                            "open": "Failed to open feed period",
                            "edit": "Failed to update feed initial balance",
                            "delete": "Failed to delete feed period"
                        }
                    }
                },
                "ovk": {
                    "title": "OVK",
                    "titleInitialBalance": "Initial Balance OVK ",
                    "titleEdit": "Edit Initial Balance OVK",
                    "create": "Add OVK Period",
                    "qty": "Quantity",
                    "price": "Price (Rp)",
                    "date": "Period Month of OVK",
                    "msgQty": "Please input quantity!",
                    "msgPrice": "Please input price!",
                    "textCreate": "Are you sure want to create next OVK period?",
                    "textClose": "Are you sure want to close OVK period in ",
                    "textOpen": "Are you sure want to open OVK period in ",
                    "textDel": "Are you sure want to delete OVK period in ",
                    "response": {
                        "sukses": {
                            "create": "Successfully added OVK period",
                            "close": "Successfully closed OVK period",
                            "reclose": "Successfully closed back OVK period",
                            "open": "Successfully opened OVK period",
                            "edit": "Successfully updated OVK initial balance",
                            "delete": "Successfully deleted OVK period"
                        },
                        "gagal": {
                            "create": "Failed to add OVK period",
                            "close": "Failed to close OVK period",
                            "open": "Failed to open OVK period",
                            "edit": "Failed to update OVK initial balance",
                            "delete": "Failed to delete OVK period"
                        }
                    }
                },
                "material": {
                    "title": "Material",
                    "titleInitialBalance": "Initial Balance Material ",
                    "titleEdit": "Edit Initial Balance Material",
                    "create": "Add Material Period",
                    "qty": "Quantity",
                    "price": "Price (Rp)",
                    "date": "Period Month of Material",
                    "msgQty": "Please input quantity!",
                    "msgPrice": "Please input price!",
                    "textCreate": "Are you sure want to create next material period?",
                    "textClose": "Are you sure want to close material period in ",
                    "textOpen": "Are you sure want to open material period in ",
                    "textDel": "Are you sure want to delete OVK period in ",
                    "response": {
                        "sukses": {
                            "create": "Successfully added material period",
                            "close": "Successfully closed material period",
                            "reclose": "Successfully closed back material period",
                            "open": "Successfully opened material period",
                            "edit": "Successfully updated material initial balance",
                            "delete": "Successfully deleted material period"
                        },
                        "gagal": {
                            "create": "Failed to add material period",
                            "close": "Failed to close material period",
                            "open": "Failed to open material period",
                            "edit": "Failed to update material initial balance",
                            "delete": "Failed to delete material period"
                        }
                    }
                },
                "livebird": {
                    "title": "Live Bird",
                    "titleInitialBalance": "Initial Balance Live Bird ",
                    "titleEdit": "Edit Initial Balance Live Bird",
                    "create": "Add Live Bird Period",
                    "qty": "Quantity",
                    "price": "Price (Rp)",
                    "date": "Period Month of Live Bird",
                    "phDate": "Select period month",
                    "msgDate": "Please select period month!",
                    "msgQty": "Please input quantity!",
                    "msgPrice": "Please input price!",
                    "textCreate": "Are you sure want to create next live bird period?",
                    "textClose": "Are you sure want to close live bird period in ",
                    "textOpen": "Are you sure want to open live bird period in ",
                    "textDel": "Are you sure want to delete live bird period in ",
                    "response": {
                        "sukses": {
                            "create": "Successfully added live bird period",
                            "close": "Successfully closed live bird period",
                            "reclose": "Successfully closed back live bird period",
                            "open": "Successfully opened live bird period",
                            "edit": "Successfully updated live bird initial balance",
                            "delete": "Successfully deleted live bird period"
                        },
                        "gagal": {
                            "create": "Failed to add live bird period",
                            "close": "Failed to close live bird period",
                            "open": "Failed to open live bird period",
                            "edit": "Failed to update live bird initial balance",
                            "delete": "Failed to delete live bird period"
                        }
                    }
                }
            },
            "livebird": {
                "title": "Stock Flow Live Bird ",
                "table": {
                    "kandang": "Cage",
                    "saldoAwal": {
                        "title": "Initial Balance",
                        "qty": "Qty (chickens)",
                        "hs": "HS (Rp/chicken)",
                        "nilai": "Value (Rp)"
                    },
                    "masuk": {
                        "title": "In",
                        "qty": "Qty (chickens)",
                        "hs": "HS (Rp/chicken)",
                        "nilai": "Value (Rp)"
                    },
                    "deplesi": {
                        "title": "Depletion",
                        "qty": "Qty (chicken)",
                        "hs": "HS (Rp/chicken)",
                        "nilai": "Value (Rp)"
                    },
                    "culling": {
                        "title": "Culling",
                        "qty": "Qty (chickens)",
                        "hs": "HS (Rp/chicken)",
                        "nilai": "Value (Rp)"
                    },
                    "panen": {
                        "title": "Harvest",
                        "qty": "Qty (chickens)",
                        "hs": "HS (Rp/chicken)",
                        "nilai": "Value (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Ending balance",
                        "qty": "Qty (chickens)",
                        "hs": "HS (Rp/chicken)",
                        "nilai": "Value (Rp)"
                    }
                }
            },
            "pakan": {
                "title": "Stock Flow Feed",
                "table": {
                    "pakan": "Feed",
                    "saldoAwal": {
                        "title": "Initial Balance",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "masuk": {
                        "title": "In",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "keluar": {
                        "title": "Out",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "penyesuaian": {
                        "title": "Adjustments",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Ending balance",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    }
                }
            },
            "ovk": {
                "title": "OVK Stock Flow ",
                "titleResume": "Stock Flow Recapitulation ",
                "table": {
                    "ovk": "OVK",
                    "resume": "Recapitulation",
                    "saldoAwal": {
                        "title": "Initial Balance",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "masuk": {
                        "title": "In",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "keluar": {
                        "title": "Out",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "penyesuaian": {
                        "title": "Adjustments",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Ending balance",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    }
                }
            },
            "material": {
                "title": "Stok Flow Material ",
                "table": {
                    "material": "Material",
                    "saldoAwal": {
                        "title": "Initial Balance",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "masuk": {
                        "title": "In",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "keluar": {
                        "title": "Out",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "penyesuaian": {
                        "title": "Adjustments",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    },
                    "saldoAkhir": {
                        "title": "Ending balance",
                        "qty": "Qty",
                        "hs": "HS (Rp)",
                        "nilai": "Value (Rp)"
                    }
                }
            }
        },
        "master": {
            "kandang": {
                "title": "Master Cage",
                "table": {
                    "kandang": "Cage",
                    "kapasitas": "Capacity (chickens)",
                    "alamat": "Address",
                    "pilihan": "Action"
                },
                "button": {
                    "tambah": "Add Master Cage",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "modal": {
                    "tambah": "Add Master Cage",
                    "edit": "Edit Master Cage",
                    "nama": "Cage Name",
                    "kapasitas": "Cage Capacity",
                    "gudangPakan": "Feed Warehouse",
                    "gudangOvk": "OVK Warehouse",
                    "gudangMaterial": "Material Warehouse",
                    "alamat": "Cage Address",
                    "keterangan": "Information",
                    "phGudangPakan": "Select feed warehouse",
                    "phGudangOvk": "Select OVK warehouse",
                    "phGudangMaterial": "Select material warehouse",
                    "phAlamat": "Input cage address",
                    "phKet": "Input additional information",
                    "msgNama": "Please input cage name!",
                    "msgKapasitas": "Please input cage capacity!",
                    "msgGudangPakan": "Please select feed warehouse!",
                    "msgGudangOvk": "Please select OVK warehouse!",
                    "msgGudangMaterial": "Please select material warehouse!",
                    "msgAlamat": "Please input cage address!"
                },
                "hapusText": "Are you sure you want to delete ",
                "response": {
                    "sukses": {
                        "tambah": "Successfully added cage",
                        "edit": "Successfully changed cage",
                        "hapus": "Successfully deleted cage"
                    },
                    "gagal": {
                        "tambah": "Failed to add cage",
                        "edit": "Failed to change cage",
                        "hapus": "Failed to delete cage"
                    }
                }
            },
            "strain": {
                "title": "Master Strain",
                "table": {
                    "nama": "Strain Name",
                    "pilihan": "Action"
                },
                "button": {
                    "detail": "Detail"
                },
                "detail": {
                    "title": "Standard Strain",
                    "table": {
                        "hari": "Day",
                        "deplesi": "Depletion(%)"
                    }
                }
            },
            "lingkungan": {
                "title": "Environmental Standards",
                "table": {
                    "hari": "Day",
                    "suhu": "Temperature",
                    "kelembapan": "Humidity",
                    "ammonia": "Ammonia",
                    "pilihan": "Action"
                },
                "button": {
                    "tambah": "Add Environmental Standards Master",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "modal": {
                    "titleTambah": "Add Environmental Standards Master",
                    "titleEdit": "Edit Environmental Standards Master",
                    "hari": "Day-",
                    "suhu": "Temperature",
                    "kelembapan": "Humidity",
                    "ammonia": "Amonia",
                    "msgHari": "Plese input day-",
                    "msgSuhu": "Please input temperature!",
                    "msgKelembapan": "Please input humidity!",
                    "msgAmonia": "Please input amonia!"
                },
                "textHapus": "Are you sure you want to clear the environmental standards on day-",
                "response": {
                    "sukses": {
                        "tambah": "Successfully added environmental standards",
                        "edit": "Successfully changed environmental standards",
                        "hapus": "Successfully deleted environmental standards"
                    },
                    "gagal": {
                        "tambah": "Failed to add environmental standards",
                        "edit": "Failed to change environmental standards",
                        "hapus": "Failed to delete environmental standards"
                    }
                }
            },
            "gudangPakan": {
                "title": "Feed Warehouse Master",
                "table": {
                    "nama": "Warehouse Name",
                    "pilihan": "Action"
                },
                "button": {
                    "tambah": "Add Feed Warehouse Master",
                    "edit": "Edit Feed Warehouse Master",
                    "hapus": "Delete"
                },
                "modal": {
                    "gudang": "Feed Warehouse",
                    "msgGudang": "Please input feed warehouse!"
                },
                "textHapus": "Are you sure you want to delete ",
                "response": {
                    "sukses": {
                        "tambah": "Successfully added feed warehouse",
                        "edit": "Successfully changed feed warehouse",
                        "hapus": "Successfully deleted feed warehouse"
                    },
                    "gagal": {
                        "tambah": "Failed to add feed warehouse",
                        "edit": "Failed to change feed warehouse",
                        "hapus": "Failed to delete feed warehouse"
                    }
                }
            },
            "pakan": {
                "title": "Feed Master",
                "table": {
                    "kode": "Code",
                    "nama": "Feed Name",
                    "perusahaan": "Company",
                    "tahun": "Production year",
                    "jenis": "Type Feed",
                    "pilihan": "Options"
                },
                "modal": {
                    "titleTambah": "Add Feed Master",
                    "titleEdit": "Edit Feed Master",
                    "nama": "Feed Name",
                    "kode": "Feed Code",
                    "jenis": "Feed Type",
                    "tahun": "Production year",
                    "perusahaan": "Company",
                    "keterangan": "Information",
                    "phJenis": "Select feed type",
                    "phTahun": "Select production year",
                    "phKet": "Input additional information",
                    "msgNama": "Please input feed name!",
                    "msgKode": "Please input feed code!",
                    "msgJenis": "Please select feed type!",
                    "msgTahun": "Please select tahun produksi!",
                    "msgPerusahaan": "Please input feed company!"
                },
                "button": {
                    "tambah": "Add Feed Master",
                    "edit": "Edit Feed Master",
                    "hapus": "Delete"
                },
                "textHapus": "Are you sure you want to delete the feed ",
                "response": {
                    "sukses": {
                        "tambah": "Successfully added feed",
                        "edit": "Successfully changed feed",
                        "hapus": "Successfully deleted feed"
                    },
                    "gagal": {
                        "tambah": "Failed to add feed",
                        "edit": "Failed to change feed",
                        "hapus": "Failed to delete feed"
                    }
                }
            },
            "gudangOvk": {
                "title": "OVK Warehouse Master",
                "table": {
                    "nama": "Warehouse Name",
                    "pilihan": "Action"
                },
                "button": {
                    "tambah": "Add OVK Warehouse Master",
                    "edit": "Edit OVK Warehouse Master",
                    "hapus": "Delete"
                },
                "modal": {
                    "gudang": "Warehouse OVK",
                    "msgGudang": "Please input OVK warehouse!"
                },
                "textHapus": "Are you sure you want to delete ",
                "response": {
                    "sukses": {
                        "tambah": "Successfully added OVK warehouse",
                        "edit": "Successfully changed OVK warehouse",
                        "hapus": "Successfully deleted OVK warehouse"
                    },
                    "gagal": {
                        "tambah": "Failed to add OVK warehouse",
                        "edit": "Failed to change OVK warehouse",
                        "hapus": "Failed to delete OVK warehouse"
                    }
                }
            },
            "ovk": {
                "title": "OVK Master",
                "table": {
                    "kode": "OVK Code",
                    "nama": "OVK Name",
                    "golongan": "Category",
                    "satuanPengadaan": "Procurement Unit",
                    "bentuk": "Shape",
                    "kapasitas": "Capacity",
                    "dosis": "Dose ",
                    "penggunaan": "Usage",
                    "kandungan": "Content",
                    "supplier": "Supplier",
                    "kedaluarsa": "Expired",
                    "pilihan": "Action"
                },
                "button": {
                    "tambah": "Add OVK Master",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "textHapus": "Are you sure you want to delete ovk ",
                "modal": {
                    "title": "Add Master OVK",
                    "bentuk": "Shape OVK",
                    "tipe": "OVK Type",
                    "satuanPengadaan": "Procurement Unit",
                    "media": "OVK Media",
                    "kode": "OVK Code",
                    "nama": "OVK Name",
                    "dosis": "OVK dosage",
                    "penggunaan": "Recommended Use",
                    "kandungan": "OVK content",
                    "supplier": "Supplier",
                    "satuanPenggunaan": "Usage Unit",
                    "kuantitas": "Quantity Units",
                    "kedaluwarsa": "Expired Date",
                    "phBentuk": "Select shape",
                    "phTipe": "Select type",
                    "phMedia": "Select media",
                    "phKandungan": "Input content",
                    "phSatuanPenggunaan": "Select usage unit",
                    "phSatuanPengadaan": "Select procurement unit",
                    "phKedaluwarsa": "Select expired date",
                    "msgBentuk": "Please select OVK shape!",
                    "msgTipe": "Please select OVK type!",
                    "msgSatuanPenggunaan": "Please select OVK usage unit!",
                    "msgSatuanPengadaan": "Please select OVK procurement unit!",
                    "msgMedia": "Please select OVK media!",
                    "msgKode": "Please input OVK code!",
                    "msgNama": "Please input OVK name!",
                    "msgDosis": "Please input OVK dosage!",
                    "msgPenggunaan": "Please input OVK usage!",
                    "msgKandungan": "Please input OVK content!",
                    "msgSupplier": "Please input OVK supplier!",
                    "msgKuantitas": "Please input OVK quantity!",
                    "msgKedaluwarsa": "Please select expired date!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added OVK",
                        "edit": "Successfully changed OVK",
                        "hapus": "Successfully deleted OVK"
                    },
                    "gagal": {
                        "tambah": "Failed to add OVK",
                        "edit": "Failed to change OVK",
                        "hapus": "Failed to delete OVK"
                    }
                }
            },
            "gudangMaterial": {
                "title": "Material Warehouse Master",
                "table": {
                    "nama": "Warehouse Name",
                    "pilihan": "Actions"
                },
                "button": {
                    "tambah": "Add Material Warehouse Master",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "modal": {
                    "gudang": "Material Warehouse",
                    "msgGudang": "Please input material warehouse!"
                },
                "textHapus": "Are you sure you want to delete ",
                "response": {
                    "sukses": {
                        "tambah": "Successfully added material warehouse",
                        "edit": "Successfully changed material warehouse",
                        "hapus": "Successfully deleted material warehouse"
                    },
                    "gagal": {
                        "tambah": "Failed to add material warehouse",
                        "edit": "Failed to change material warehouse",
                        "hapus": "Failed to delete material warehouse"
                    }
                }
            },
            "material": {
                "title": "Material Master",
                "table": {
                    "kode": "Code",
                    "nama": "Material name",
                    "perusahaan": "Material Company",
                    "jenis": "Material Type",
                    "pilihan": "Actions"
                },
                "button": {
                    "tambah": "Add Material Master",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "textHapus": "Are you sure you want to remove material ",
                "modal": {
                    "titleTambah": "Add Material Master",
                    "titleEdit": "Edit Material Master",
                    "nama": "Material Name",
                    "kode": "Material Code",
                    "jenis": "Material Type",
                    "perusahaan": "Company",
                    "phNama": "Input material name",
                    "phKode": "Input material code",
                    "phJenis": "Select material type",
                    "msgNama": "Please input material name",
                    "msgKode": "Please input material code",
                    "msgJenis": "Please input material type",
                    "msgPerusahaan": "Please input production company"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added material",
                        "edit": "Successfully changed material",
                        "hapus": "Successfully deleted material"
                    },
                    "gagal": {
                        "tambah": "Failed to add material",
                        "edit": "Failed to change material",
                        "hapus": "Failed to delete material"
                    }
                }
            },
            "biaya": {
                "title": "Master of Cost",
                "table": {
                    "nama": "Cost name",
                    "biaya": "Cost (Rp)",
                    "pilihan": "Actions"
                },
                "button": {
                    "tambah": "Add Cost Master",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "textHapus": "Are you sure you want to remove fees ",
                "modal": {
                    "titleTambah": "Add Cost Master",
                    "titleEdit": "Edit Cost Master",
                    "nama": "Cost name",
                    "biaya": "Cost",
                    "msgNama": "Please input cost name!",
                    "msgBiaya": "Please input cost!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added cost",
                        "edit": "Successfully changed cost",
                        "hapus": "Successfully deleted cost"
                    },
                    "gagal": {
                        "tambah": "Failed to add cost",
                        "edit": "Failed to change cost",
                        "hapus": "Failed to delete cost"
                    }
                }
            },
            "overhead": {
                "title": "Overhead Master",
                "table": {
                    "nama": "Overhead name",
                    "price": "Price (Rp)",
                    "pilihan": "Actions"
                },
                "button": {
                    "tambah": "Add Overhead Master",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "textHapus": "Are you sure you want to remove overhead ",
                "modal": {
                    "titleTambah": "Add Overhead Master",
                    "titleEdit": "Edit Overhead Master",
                    "nama": "Overhead name",
                    "price": "Price",
                    "msgNama": "Please input overhead name!",
                    "msgPrice": "Please input overhead price!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added overhead",
                        "edit": "Successfully changed overhead",
                        "hapus": "Successfully deleted overhead"
                    },
                    "gagal": {
                        "tambah": "Failed to add overhead",
                        "edit": "Failed to change overhead",
                        "hapus": "Failed to delete overhead"
                    }
                }
            },
            "penyakit": {
                "title": "Master of Disease",
                "table": {
                    "nama": "Name of Disease",
                    "pilihan": "Actions"
                },
                "button": {
                    "tambah": "Add Master of Disease",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "textHapuss": "Are you sure you want to remove fees ",
                "modal": {
                    "titleTambah": "Add Master of Disease",
                    "titleEdit": "Edit Master of Disease",
                    "nama": "Disease Name",
                    "penyakit": "Disease",
                    "msgNama": "Please input disease name!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added disease",
                        "edit": "Successfully changed disease",
                        "hapus": "Successfully deleted disease"
                    },
                    "gagal": {
                        "tambah": "Failed to add disease",
                        "edit": "Failed to change disease",
                        "hapus": "Failed to delete disease"
                    }
                }
            },
            "konsumen": {
                "title": "Master of Consumers",
                "table": {
                    "nama": "Name",
                    "alamat": "Address",
                    "kontak": "Contact",
                    "informasi": "Another Information",
                    "pilihan": "Actions"
                },
                "button": {
                    "tambah": "Add Master Customer",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "textHapus": "Are you sure you want to delete the customer ",
                "modal": {
                    "titleTambah": "Add Master Customer",
                    "titleEdit": "Edit Master Kustomer",
                    "nama": "Name",
                    "alamat": "Address",
                    "kontak": "Contact",
                    "informasi": "Another Information",
                    "phNama": "Input customer name",
                    "phAlamat": "Input customer address",
                    "phInformasi": "Input another information",
                    "msgNama": "Please input customer name!",
                    "msgKontak": "Please input customer contact!",
                    "msgAlamat": "Please input customer address!",
                    "msgInformasi": "Please input another information!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added customer",
                        "edit": "Successfully changed customer",
                        "hapus": "Successfully deleted customer"
                    },
                    "gagal": {
                        "tambah": "Failed to add customer",
                        "edit": "Failed to change customer",
                        "hapus": "Failed to delete customer"
                    }
                }
            },
            "supplier": {
                "title": "Master Supplier",
                "table": {
                    "kode": "Supplier Code",
                    "nama": "Name",
                    "alamat": "Address",
                    "kontak": "Contact",
                    "tipe": "Supplier Type",
                    "informasi": "Another Information",
                    "pilihan": "Actions"
                },
                "button": {
                    "tambah": "Add Master Supplier",
                    "edit": "Edit",
                    "hapus": "Delete"
                },
                "textHapus": "Are you sure you want to delete the supplier ",
                "modal": {
                    "titleTambah": "Add Master Supplier",
                    "titleEdit": "Edit Master Supplier",
                    "nama": "Name",
                    "alamat": "Address",
                    "kode": "Supplier Code",
                    "tipe": "Supplier Type",
                    "kontak": "Contact",
                    "informasi": "Another Information",
                    "phNama": "Input supplier name",
                    "phAlamat": "Input supplier address",
                    "phKode": "Input supplier code",
                    "phTipe": "Select supplier type",
                    "phInformasi": "Input another information",
                    "msgNama": "Please input supplier name!",
                    "msgAlamat": "Please input supplier address!",
                    "msgTipe": "Please select supplier type!",
                    "msgKode": "Please input supplier code!",
                    "msgKontak": "Please input supplier contact!"
                },
                "response": {
                    "sukses": {
                        "tambah": "Successfully added supplier",
                        "edit": "Successfully changed supplier",
                        "hapus": "Successfully deleted supplier"
                    },
                    "gagal": {
                        "tambah": "Failed to add supplier",
                        "edit": "Failed to change supplier",
                        "hapus": "Failed to delete supplier"
                    }
                }
            },
            "pengguna": {
                "title": "Master User",
                "titlePetugas": "Officer",
                "titleManajemen": "Management",
                "modal": {
                    "tambahPengguna": "Add User Data",
                    "tambahPetugas": "Add Cage Officer",
                    "tambahManajemen": "Add Management",
                    "editPetugas": "Edit Cage Officer",
                    "editManajemen": "Edit Management",
                    "nama": "Name",
                    "kandang": "Cage",
                    "telepon": "Phone Number",
                    "alamat": "Address",
                    "phNama": "Input name..",
                    "phKandang": "Select cage",
                    "phAlamat": "Input address ...",
                    "phEmail": "Input email",
                    "phUsername": "Input username",
                    "phPass": "Input password",
                    "msgNama": "Please input name!",
                    "msgAlamat": "Please input address!",
                    "msgKandang": "Please select cage!",
                    "msgTel": "Please input phone number!",
                    "msgEmail": "Please input email",
                    "msgUsername": "Please input username",
                    "msgPass": "Please input password"
                },
                "table": {
                    "namaPetugas": "Officer Name",
                    "namaManajemen": "Management Name",
                    "alamat": "Address",
                    "telepon": "Phone Number",
                    "pilihan": "Actions"
                },
                "petugas": {
                    "textHapus": "Are you sure you want to delete officer ",
                    "response": {
                        "sukses": {
                            "tambah": "Successfully added officer",
                            "edit": "Successfully changed officer",
                            "hapus": "Successfully deleted officer"
                        },
                        "gagal": {
                            "tambah": "Failed to add officer",
                            "edit": "Failed to change officer",
                            "hapus": "Failed to delete officer"
                        }
                    }
                },
                "manajemen": {
                    "textHapus": "Are you sure you want to delete management ",
                    "response": {
                        "sukses": {
                            "tambah": "Successfully added management",
                            "edit": "Successfully changed management",
                            "hapus": "Successfully deleted management"
                        },
                        "gagal": {
                            "tambah": "Failed to add management",
                            "edit": "Failed to change management",
                            "hapus": "Failed to delete management"
                        }
                    }
                }
            }
        },
        "profil": {
            "title": "Profile",
            "changeProfil": "Change Profile",
            "changePass": "Change Password",
            "nama": "Fullname",
            "tel": "Phone Number",
            "perusahaan": "Company Name",
            "alamat": "Address",
            "newPass": "Old Password",
            "oldPass": "New Password",
            "phNama": "Input your fullname",
            "phEmail": "Input your email",
            "phUsername": "Input your username",
            "phTel": "Input your phone number",
            "phPerusahaan": "Input your company",
            "phAlamat": "Input your address",
            "phOldPass": "Input your old password",
            "phNewPass": "Input your new password",
            "msgNama": "Please input your fullname!",
            "msgEmail": "Please input your email!",
            "msgUsername": "Please input your username!",
            "msgTel": "Please input your phone number!",
            "msgPerusahaan": "Please input your company!",
            "msgAlamat": "Please input your address!",
            "msgOldPass": "Please input your old password!",
            "msgNewPass": "Please input your new password!",
            "response": {
                "sukses": {
                    "profil": "Successfully update your profile",
                    "password": "Successfully update your password"
                },
                "gagal": {
                    "profil": "Failed to update your profile",
                    "password": "Failed to update your password"
                }
            }
        }
    }
} 