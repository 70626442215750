import * as actionType from './adjustment-pakan-action-type';

const setIdMonthlyStockFeed = payload => ({
    type : actionType.SET_ID_MONTHLY_STOCK_FEED,
    payload,
});

export const getIdMonthlyStock = (idMonthlyStock) => (dispatch) => {
    dispatch(setIdMonthlyStockFeed(idMonthlyStock));
}
