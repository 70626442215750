import React from 'react'
import NumberFormat from 'react-number-format'
import {
    Layout, Breadcrumb, Row, Col, Card, Skeleton, Divider,
    Tabs, DatePicker, Button, Empty, Image, Select,
} from 'antd'
import '../../../assets/scss/dashboard-peternak/dashboard-peternak.scss'
import PerformanceChart from '../../../common/component/chart/chart-performance'
import DiseaseChart from '../../../common/component/chart/chart-disease'
import TablePerformance from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-performance-page'
import TableRearing from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-rearing-page'

import deviceImage from '../../../assets/images/device.png'
import anemoImage from '../../../assets/images/anemo.png'
import humidityIcon from '../../../assets/svg/carbon_humidity-alt.svg'
import nh3Icon from '../../../assets/svg/NH3.svg'
import co2Icon from '../../../assets/svg/CO2.svg'
import vocIcon from '../../../assets/svg/voc.svg'
import kphIcon from '../../../assets/svg/kph.svg'
import msIcon from '../../../assets/svg/ms.svg'
import SelectDeviceImage from '../../../assets/svg/select-device.svg'

/**import chart */
import SuhuChart from '../../../common/component/chart/chart-suhu'
import VocChart from '../../../common/component/chart/chart-voc'
import AnemoChart from '../../../common/component/chart/chart-anemo'
import ThiChart from '../../../common/component/chart/chart-thi'
import GaugeSuhu from '../../../common/component/chart/gauge-suhu'
import FeedUsageChart from '../../../common/component/chart/chart-feed-usage'

import TableEnv from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-env'
import TableFeedUsage from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-feed-usage'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileExcelOutlined, HistoryOutlined } from '@ant-design/icons'
import { faChartArea, faChartLine, faCheckCircle, faExclamationTriangle, faInfoCircle, faTemperatureLow, faThermometerEmpty, faWarehouse } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'

function DashboardComponent(props) {
    const { performance, report, active, listDisease, locales, idPeriode, kandang,
        rearingActive, fetchDataActive, loading, setId, setPeriod, setCage,
        //device
        thi, range, suhuReverse, env, load, handleExport, idDevice, devices, onDevice,
        suhu, setRange, deviceType,
        // feed usage
        feedUsage, chartData, ages, onPeriode, period, cage, dataKandang, idKandang, handleChangeKandang
    } = props
    const { Content } = Layout

    return (
        <Layout style={{ overflow: "hidden" }}>
            <Content className="dashboard-container">
                <Tabs defaultActiveKey="1" tabBarStyle={{ boxShadow: '0px 0.5px 3px #BE375F' }}>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 5 }} />
                                <span>{locales('pages.dashboard.performance.title')}</span>
                            </span>
                        }
                        key="1"
                    >
                        {/* table rearing */}
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card title={locales("pages.periode.periodeBerjalan.title")} bordered={false}>
                                    <TableRearing rearingActive={rearingActive} fetchDataActive={fetchDataActive}
                                        loading={loading} t={locales} onPeriode={onPeriode} setId={setId} setPeriod={setPeriod} setCage={setCage} />
                                </Card>
                            </Col>
                        </Row>
                        <Row className="dashboard-section">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item><span> {locales("pages.dashboard.title")}</span>
                                    {kandang === ' ' ? '' : <span> - {cage} {period}</span>}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        {/* dashboardCalculate */}
                        <Row className="card-report-section" gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card title={locales("pages.dashboard.cardStokAyam")} bordered={false} className="card-bird-live">
                                    <Row gutter={16}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {
                                                active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                                    <div>
                                                        <NumberFormat value={report.liveBird} displayType={'text'} thousandSeparator={true} />
                                                    </div>
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card title={locales("pages.dashboard.cardCulling")} bordered={false} className="card-bird-culling">
                                    <Row gutter={16}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            {
                                                active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                                    <div>
                                                        <NumberFormat value={report.culling} displayType={'text'} thousandSeparator={true} /> (<NumberFormat value={report.persenCulling} displayType={'text'} thousandSeparator={true} suffix={' %'} />)
                                                    </div>
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card title={locales("pages.dashboard.cardDeplesi")} bordered={false} className="card-bird-dead">
                                    <Row gutter={16}>
                                        <Col span={12} style={{ paddingRight: 0 }}>
                                            {
                                                active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                                    <span>
                                                        <NumberFormat value={report.mati} displayType={'text'} thousandSeparator={true} />
                                                        (<NumberFormat value={report.persenDeplesi} displayType={'text'} thousandSeparator={true} suffix={' %'} />)
                                                    </span>
                                            }
                                        </Col>
                                        <Col span={12} style={{ paddingRight: 0 }}>
                                            {
                                                active === true ?
                                                    <Skeleton active={active} paragraph={{ rows: 1 }} /> :
                                                    <NumberFormat value={report.stdDeplesi} displayType={'text'} thousandSeparator={true} prefix={'Std: '} suffix={' %'} />
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Card title={locales("pages.dashboard.cardFCR")} bordered={false} className="card-bird-feed">
                                    <Row gutter={16}>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            {active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> : <NumberFormat value={report.fcr} displayType={'text'} thousandSeparator={true} />}
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                            {active === true ? <Skeleton active={active} paragraph={{ rows: 1 }} /> : <NumberFormat value={report.stdFcr} displayType={'text'} thousandSeparator={true} prefix={'Std: '} />}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        {/* performance */}
                        <Row className="chart-die-section" gutter={[16, 16]}>
                            <Col span={24} >
                                <Card title={locales("pages.dashboard.recordPerformaKandang")} bordered={false}>
                                    <PerformanceChart
                                        data={performance}
                                        chartId="berat-chart"
                                    />
                                    <Divider />
                                    <Row>
                                        <Col span={24} style={{ textAlign: "center" }}>
                                            <span className="font-title-medium black font-medium">{locales("pages.dashboard.tabelPerformaKandang")}</span>
                                        </Col>
                                    </Row>
                                    <TablePerformance idPeriode={idPeriode} />
                                </Card>
                            </Col>
                        </Row>

                        {/* disease */}
                        <Row className="chart-disease-section" gutter={[16, 16]}>
                            <Col span={24} >
                                <Card title={locales("pages.dashboard.recordNekorpsi")} bordered={false}>
                                    <DiseaseChart
                                        data={listDisease}
                                        chartId="heatmap-chart"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>

                    {/* FEED USAGE */}
                    <Tabs.TabPane
                        tab={
                            <span>
                                <FontAwesomeIcon icon={faChartArea} style={{ marginRight: 5 }} />
                                <span>{locales('pages.dashboard.feed.title')}</span>
                            </span>
                        }
                        key="2"
                    >
                        <Row className="chart-die-section" gutter={[16, 16]}>
                            {rearingActive && rearingActive
                                .sort((a, b) => a.house.warehouseName.localeCompare(b.house.warehouseName, 'en', { numeric: true }))
                                .map(d =>
                                    <Col span={4}>
                                        <Card
                                            hoverable={idPeriode === d._id ? false : true}
                                            className={idPeriode === d._id ? 'selected' : ''}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                onPeriode(d._id, d.name, d.house.warehouseName)
                                            }}
                                        >
                                            <span style={{ color: idPeriode === d._id ? '#BE375F' : '', }}>
                                                <FontAwesomeIcon icon={faWarehouse}
                                                    style={{ fontSize: 20 }}
                                                />
                                                <p className='font-medium mt-10 mb-0'>{d.house.warehouseName}</p>
                                                <p className='mt-0'>{d.name}</p>
                                            </span>
                                        </Card>
                                    </Col>
                                )}
                            <Col span={24}>
                                <Card title={locales('pages.dashboard.feed.chart')} bordered={false}>
                                    <FeedUsageChart
                                        chartData={chartData} t={locales} ages={ages}
                                    />
                                    <TableFeedUsage t={locales} feedUsage={feedUsage} loading={loading} />
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>

                    {/* CLIMATE */}
                    <Tabs.TabPane
                        tab={
                            <span>
                                <FontAwesomeIcon icon={faTemperatureLow} style={{ marginRight: 5 }} />
                                <span>{locales('pages.dashboard.iot.title')}</span>
                            </span>
                        }
                        key="3"
                    >
                        <Row gutter={[8, 8]}>
                            <Col span={8}>
                                <div className="section-header-page">
                                    <Select
                                        showSearch
                                        labelInValue
                                        placeholder="Pilih Kandang"
                                        optionFilterProp="children"
                                        style={{ width: '100%' }}
                                        className="select-kategori black font-regular"
                                    value={{ key: idKandang }}
                                    onChange={handleChangeKandang}
                                    >
                                        { dataKandang.map(data =>
                                        <Select.Option
                                        key={data.name.toString()}
                                        value={data._id}
                                        >
                                            {data.name}
                                        </Select.Option>
                                        )} 
                                    </Select>
                                </div>
                            </Col>
                            <Col span={16}></Col>
                            {devices.length < 1 &&
                                <Col span={12} offset={6}>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={
                                            <span>{locales('pages.dashboard.iot.nodevice')}</span>
                                        }
                                    >
                                    </Empty>
                                </Col>
                            }
                            {devices && devices.map((d) =>

                                <Col span={8}>
                                    <Card hoverable={idDevice === d.deviceId ? false : true} className={idDevice === d.deviceId ? 'selected' : ''}
                                        onClick={() => onDevice(d.deviceId, d.deviceType)}>
                                        <Row gutter={[10, 8]} justify="space-around" align="middle">
                                            <Col span={8}>
                                                <Image src={d.deviceType === 'Anemometer' ? anemoImage : deviceImage} preview={false} />
                                                <div className='font-light text-center'>{d.deviceType}</div>
                                            </Col>
                                            <Col span={16}>
                                                <Row justify="space-around" align="middle">
                                                    <Col span={18}>
                                                        <h3 className='font-semi-bold mb-0'>{d.house.name} - {d.position}</h3>
                                                        <h5 className='font-regular'>SN: {d.serialNumber}</h5>
                                                        <HistoryOutlined /> <span className='font-light font-content mr-5'>
                                                            {d.latestTelemetry.ts ? moment(new Date(d.latestTelemetry.ts)).calendar() : ''}</span>
                                                    </Col>
                                                    <Col span={6}>
                                                        <FontAwesomeIcon
                                                            icon={d.latestTelemetry.status === 'danger' || d.latestTelemetry.status === 'emergency' ? faExclamationTriangle : d.latestTelemetry.status === 'alert' ?
                                                                faInfoCircle : faCheckCircle}
                                                            color={d.latestTelemetry.status === 'emergency' ? "#fcad35" : d.latestTelemetry.status === 'alert' ? '#f5e942' : d.latestTelemetry.status === 'danger' ? '#fcad35' : '#02db93'}
                                                            className='float-right' size="3x" />
                                                    </Col>
                                                </Row>
                                                <Divider style={{
                                                    marginTop: 15, marginBottom: 15, borderWidth: 2,
                                                    borderColor: d.latestTelemetry.status === 'emergency' ? "#FF0000" : d.latestTelemetry.status === 'alert' ? '#f5e942' : d.latestTelemetry.status === 'danger' ? '#fcad35' : '#02db93',
                                                }} />
                                                {d.deviceType === 'Anemometer' ?
                                                    <Row gutter={[2, 6]} className='font-regular'>
                                                        <Col span={3}><Image src={kphIcon} preview={false} /></Col><Col span={21}>{d.latestTelemetry.kph} km/h</Col>
                                                        <Col span={3}><Image src={msIcon} preview={false} /></Col><Col span={21}>{d.latestTelemetry.ms} m/s</Col>
                                                        <Col span={24}>Status: {d.latestTelemetry.status}</Col>
                                                    </Row>
                                                    :

                                                    <Row gutter={[2, 6]} className='font-regular'>
                                                        <Col span={3}><FontAwesomeIcon icon={faThermometerEmpty} size='lg' /> </Col><Col span={9}>{d.latestTelemetry.temperature} °C</Col>
                                                        <Col span={3}><Image src={humidityIcon} preview={false} /></Col><Col span={9}>{d.latestTelemetry.humidity} %</Col>
                                                        {d.deviceType === 'Ambient 1.3' ?
                                                            <>
                                                                <Col span={3} ><Image src={nh3Icon} preview={false} /></Col><Col span={9} >{d.latestTelemetry.amonia} ppm</Col>
                                                            </>
                                                            :
                                                            <>
                                                                <Col span={3}><Image src={vocIcon} preview={false} /> </Col>
                                                                <Col span={9}><NumberFormat value={d.latestTelemetry.voc} displayType={'text'} thousandSeparator={true} /></Col>
                                                            </>
                                                        }

                                                        <Col span={3}><Image src={co2Icon} preview={false} /></Col><Col span={9}>{d.latestTelemetry.co2} ppm</Col>
                                                        <Col span={24}>Status: {d.latestTelemetry.status}</Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}

                        </Row>
                        <Divider />
                        <div className='center-object text-center' hidden={idDevice || devices.length < 1 ? true : false}>
                            <Image src={SelectDeviceImage} preview={false} />
                            <div className='font-semi-medium'>{locales('pages.dashboard.iot.notelemetry')}</div>
                        </div>
                        <Row gutter={[8, 8]} hidden={idDevice && devices.length > 0 ? false : true}>
                            <Col span={12}>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <Card className='gauge'>
                                            <div style={{ margin: 10 }}>
                                                <HistoryOutlined /> <span className='font-light' style={{ marginLeft: 4 }}> {locales('pages.dashboard.iot.updatedAt')}  {env.timestamp}</span>
                                            </div>
                                        </Card>
                                    </Col>

                                    {/* ambient */}
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#ff7112' }}>{locales('pages.dashboard.iot.temperature')}</h3>
                                            <GaugeSuhu t={locales} chartId="suhu-gauge" data={env?.temperature} fixed={2}
                                                chartMin={0} chartMax={100} suffix="°C" fontSize="6em" fill1='#ff7112' fill2='#f5a876' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#10b4e6' }}>{locales('pages.dashboard.iot.humidity')} </h3>
                                            <GaugeSuhu t={locales} chartId="humidity-gauge" data={env?.humidity} fixed={2}
                                                chartMin={0} chartMax={100} suffix="%" fontSize="6em" fill2='#7fd9f5' fill1='#10b4e6' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#9e7402' }}>{deviceType === 'Ambient 1.4' ? 'VOC' : 'NH3'}</h3>
                                            <GaugeSuhu t={locales} chartId="amonia-gauge" data={deviceType === 'Ambient 1.4' ? env.voc : env.amonia}
                                                chartMin={0} chartMax={deviceType === 'Ambient 1.4' ? 40000 : 10} suffix={deviceType === 'Ambient 1.4' ? '' : "PPM"}
                                                fixed={0} fontSize="5em" fill2='#cfb570' fill1='#9e7402' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#08cf9f' }}>CO₂</h3>

                                            <GaugeSuhu t={locales} chartId="co2-gauge" data={env?.co2} fixed={0}
                                                chartMin={0} chartMax={2000} suffix="PPM" fontSize="5em" fill2='#aafae7' fill1='#08cf9f' />
                                        </Card>
                                    </Col>

                                    {/* anemo */}
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? false : true}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#014f86' }}>Kilometer per hour</h3>
                                            <GaugeSuhu t={locales} chartId="kph-gauge" data={env?.kph} fixed={2}
                                                chartMin={0} chartMax={30} suffix="km/h" fontSize="6em" fill1='#014f86' fill2='#2a6f97' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? false : true}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#2c7da0' }}>Meter per second</h3>
                                            <GaugeSuhu t={locales} chartId="ms-gauge" data={env?.ms} fixed={2}
                                                chartMin={0} chartMax={5} suffix="m/s" fontSize="6em" fill2='#61a5c2' fill1='#2c7da0' />
                                        </Card>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={12}>
                                <Card bordered={false} className='card-table-sm'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={4}>
                                                <Col span={16}>
                                                    <DatePicker.RangePicker showTime style={{ width: '100%' }}
                                                        onChange={e => setRange(e)} value={range} defaultValue={range} allowClear={false}

                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <Button style={{
                                                        float: 'right', backgroundColor: '#00a266', color: 'white',
                                                        borderRadius: 5, boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                                                    }}
                                                        loading={load} onClick={() => handleExport()}>
                                                        <FileExcelOutlined />Export Excel</Button>
                                                </Col>
                                            </Row>
                                        </div>

                                    }
                                >
                                    <TableEnv envDevice={suhu} t={locales} loading={loading} deviceType={deviceType} />
                                </Card>
                            </Col>
                            <Col span={24} className="chart-temparature-section">
                                <Card title={locales('pages.dashboard.iot.chartTitle')} bordered={false} className='card-thi'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={16}>
                                                <Col span={14} offset={10}>
                                                    <DatePicker.RangePicker showTime style={{ width: '100%' }}
                                                        onChange={e => setRange(e)} value={range} defaultValue={range} allowClear={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                >
                                    {deviceType === 'Ambient 1.4' ?
                                        <VocChart data={suhuReverse} t={locales} chartId="voc-chart" />
                                        : deviceType === 'Ambient 1.3' ?
                                            <SuhuChart data={suhuReverse} t={locales} chartId="suhu-chart" />
                                            : <AnemoChart data={suhuReverse} t={locales} chartId="anemo-chart" />
                                    }
                                </Card>
                            </Col>
                            <Col span={24} className="chart-temparature-section" hidden={deviceType === 'Anemometer' ? true : false}>
                                <Card title={locales('pages.dashboard.iot.thiChartTitle')} bordered={false} className='card-thi'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={16}>
                                                <Col span={14} offset={10}>
                                                    <DatePicker.RangePicker showTime style={{ width: '100%' }}
                                                        onChange={e => setRange(e)} value={range} defaultValue={range} allowClear={false}

                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                >
                                    <ThiChart t={locales} data={thi} chartId="thi-chart" />
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </Content>
        </Layout>
    )
}

export default DashboardComponent