import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../../../common/component/loading/loading-container'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import CONSTANTS from '../../../../common/utils/Constants'

function OvkComponent(props) {
    const { Content } = Layout
    const { columns, data, loading, navigate, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.ovk.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ButtonDashboard
                            text={t('pages.master.ovk.button.tambah')}
                            height={20}
                            backgroundColor="#008ad4"
                            borderRadius="5px"
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                            icon={faPlusSquare}
                            onClick={() => navigate(CONSTANTS.CREATE_MASTER_OVK_MENU_KEY)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                className="table-dashboard"
                                scroll={{ x: 1300, y: 500 }}
                            />
                        </Loading>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default OvkComponent