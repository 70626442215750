import React from 'react'
import { Layout, Breadcrumb, Row, Col, Tabs, Button, Dropdown } from 'antd'
import '../../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import { Link } from 'react-router-dom'

import DetailDeplesi from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-deplesi-page'
import DetailPanen from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-panen-page'
import DetailOvk from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-ovk-page'
import DetailPakan from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-pakan-page'
import DetailPertumbuhan from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-pertumbuhan-page'
import DetailMaterial from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-material-page'
import DetailPenyakit from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-penyakit-page'
import DetailOverhead from '../../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-overhead-page'

import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DetailRearingRecordComponent(props) {
    const { Content } = Layout
    const { TabPane } = Tabs
    const { changeKey, activeKey, menu, handleButtonClick, loading, setLoading, deplesi, fetchDataDeplesi, feed,
        ovk, fetchDataOvk, monthlyOvk, pakan, fetchDataPakan, pertumbuhan, fetchDataPertumbuhan, panen, fetchDataPanen,
        material, fetchDataMaterial, monthlyMaterial, umur, penyakit, fetchDataPenyakit, fetchDataOverhead, overhead, overheading, locales } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><Link to="/peternak/recording"><span>{locales("pages.recording.title")}</span></Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{locales("pages.recording.detail.title")}{umur}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#008ad4',
                                borderRadius: '5px',
                                float: "right",
                            }} onClick={() => handleButtonClick()}>{locales("pages.recording.button.tambah")}
                                <FontAwesomeIcon icon={faPlusSquare} style={{ marginLeft: "10px" }} /></Button>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={locales("pages.recording.detail.deplesi.title")} key="1">
                                    <DetailDeplesi activeKey={activeKey} loading={loading} setLoading={setLoading} deplesi={deplesi} fetchDataDeplesi={fetchDataDeplesi} />
                                </TabPane>
                                <TabPane tab={locales("pages.recording.detail.panen.title")} key="2">
                                    <DetailPanen activeKey={activeKey} loading={loading} setLoading={setLoading} panen={panen} fetchDataPanen={fetchDataPanen} />
                                </TabPane>
                                <TabPane tab={locales("pages.recording.detail.pertumbuhan.title")} key="3">
                                    <DetailPertumbuhan activeKey={activeKey} loading={loading} setLoading={setLoading} pertumbuhan={pertumbuhan} fetchDataPertumbuhan={fetchDataPertumbuhan} />
                                </TabPane>
                                <TabPane tab={locales("pages.recording.detail.pakan.title")} key="4">
                                    <DetailPakan activeKey={activeKey} loading={loading} setLoading={setLoading} pakan={pakan} fetchDataPakan={fetchDataPakan} feed={feed}/>
                                </TabPane>
                                <TabPane tab={locales("pages.recording.detail.ovk.title")} key="5">
                                    <DetailOvk activeKey={activeKey} loading={loading} setLoading={setLoading} ovk={ovk} fetchDataOvk={fetchDataOvk} monthlyOvk={monthlyOvk} />
                                </TabPane>
                                <TabPane tab={locales("pages.recording.detail.material.title")} key="6">
                                    <DetailMaterial activeKey={activeKey} loading={loading} setLoading={setLoading} material={material} fetchDataMaterial={fetchDataMaterial} monthlyMaterial={monthlyMaterial} />
                                </TabPane>
                                <TabPane tab={locales("pages.recording.detail.penyakit.title")} key="7">
                                    <DetailPenyakit activeKey={activeKey} loading={loading} setLoading={setLoading} penyakit={penyakit} fetchDataPenyakit={fetchDataPenyakit} />
                                </TabPane>
                                <TabPane tab={locales("pages.recording.detail.overhead.title")} key="8">
                                    <DetailOverhead activeKey={activeKey} loading={loading} setLoading={setLoading} overhead={overhead} overheading={overheading} fetchDataOverhead={fetchDataOverhead} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default DetailRearingRecordComponent