export const getRearingRecord = (keyword, limit, skip, periode) => {
  return (
    `query{
          calculatedRearingRecords(rearing:"${periode}", keyword:"${keyword}",limit:${limit},skip:${skip}){
            totalCount
            periode
            house
            calculatedArr{
                _id
                tanggal
                umur
                mati
                culling
                deplesiPerHari
                cumDeplesi
                total
                persenKematian
                persenDeplesiHarian
                standardDp
                ketDeplesi

                panen
                saldo
                totalBeratPanen
                cumBeratPanen
                cumPanen
                cumFcr
                
                ovkTotal
        
                pakanPerHari
                pakanTotal
                
                fcr
                standardFcr
                bw
                standardBw
                hpp
        
                totalBerat
                abw
                adg
                standardDg
        
                feedIntake
                standardFi
                saldoPerHari
                standardFiCum
                fiCum
                devFi

                hppPakan
                hppMaterial
                cumFeed
                totalHpp
                totalDoc
                hppPerKg

                indeksPerformance

                bopKandang
            }
          }
        }
      `
  )
}

export const updateHpp = (idPeriode) => {
  return {
    query:
      `mutation{
        updateHppRearing(_id: "${idPeriode}"){
          _id
          hpp
          updatedAt
        }
      }
  `
  }
}
export const updateDate = (idPeriode) => {
  return (
    `query{
        rearing(_id: "${idPeriode}"){
           updatedAt
          }
        }
    `
  )
}
export const createRearingRecord = (idPeriode, date) => {
  return {
    query:
      `mutation{
      createRearingRecord(rearingRecordInput:{
          date: "${date}"
          rearing: "${idPeriode}"
        }){
          _id
        }
      }
  `
  }
}
export const exportExcel = (idPeriode) => {
  return {
    query: `query{
        rearingRhpp(_id:"${idPeriode}"){
            _id
            updatedAt
            house{
              name
            }
            name
        }
      }
  `
    }
}

export const deleteRearingRecord = (_id) => {
  return (
    `mutation{
      deleteRearingRecord(_id : "${_id}"){
        deleted
      }
    }
  `
  )
}

/**Record Deplesi */
export const createRecordDeplesi = (value, rearing) => {
  return {
    query: `mutation{
              createMutation(mutationInput: {
                type: "${value.jenis.value}",
                number: ${parseInt(value.jumlah)}
                rearingRecord: "${rearing}"
                otherInformation: "${value.keterangan}"
              }){
                _id
              }
            }
        `
  }
}

export const getRecordDeplesi = (keyword, limit, skip, record) => {
  return (
    `query{
      mutations(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
        totalCount
        mutations {
            _id
            type
            number
            rearingRecord {
                _id
            }
            otherInformation
        }
      }
    }`
  )
}

export const getDetailRecordDeplesi = (_id) => {
  return (
    `query{
    mutation(_id: "${_id}"){
      _id
      value :type
      number
      rearingRecord {
          _id
      }
      otherInformation
    }
  }`
  )
}

export const updateRecordDeplesi = (_id, value) => {
  return {
    query: `mutation{
      updateMutation(_id:"${_id}",updateMutationInput: {
        type : "${value.jenis.value}"
        number : ${value.jumlah}
        otherInformation : "${value.keterangan}"
      })
      {
        _id
      }
    }`
  }
}

export const deleteRecordDeplesi = (_id) => {
  return (
    `mutation{
    deleteMutation(_id:"${_id}"){
      deleted
    }
  }`
  )
}

/**Record Panen*/
export const createRecordPanen = (value, rearing) => {
  return {
    query: `mutation{
            createHarvest(mutationInput: {
              type: "Harvest"
              number: ${parseInt(value.ayam)}
              rearingRecord: "${rearing}"
              averageWeight : ${parseFloat(value.berat) / parseFloat(value.ayam)}
              totalWeight : ${parseFloat(value.berat)}
              otherInformation : "${value.keterangan}"
            }){
              _id
            }
          }
      `
  }
}

export const getRecordPanen = (keyword, limit, skip, record) => {
  return (
    `query{
    harvests(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
      totalCount
      mutations {
          _id
          number
          averageWeight
          totalWeight
          rearingRecord {
              _id
          }
          otherInformation
      }
    }
  }`
  )
}

export const getDetailRecordPanen = (_id) => {
  return (
    `query{
    mutation(_id: "${_id}"){
      _id
      number
      averageWeight
      totalWeight
      rearingRecord {
          _id
      }
      otherInformation
    }
  }`
  )
}

export const updateRecordPanen = (_id, value) => {
  return {
    query: `mutation{
      updateHarvest(_id:"${_id}",updateMutationInput: {
        number: ${parseInt(value.ayam)}
        averageWeight : ${parseFloat(value.berat) / parseFloat(value.ayam)}
        totalWeight : ${parseFloat(value.berat)}
        otherInformation : "${value.keterangan}"
      })
      {
        _id
      }
    }`
  }
}

export const deleteRecordPanen = (_id) => {
  return (
    `mutation{
    deleteMutation(_id:"${_id}"){
      deleted
    }
  }`
  )
}


/**Record Pertumbuhan */
export const getGrowingParams = (keyword, limit, skip) => {
  return (
    `query{
      growingParams(keyword:"${keyword}", limit:${limit}, skip:${skip}){
        totalCount
        growingParams{
            _id
            name
            abbr
            unit
        }
      }
    }`
  )
}

export const createRecordPertumbuhan = (value, rearingRecord) => {
  return {
    query: `
      mutation{
        createGrowing(growingInput:{
            value : ${parseFloat(value.nilai)}
            rearingRecord : "${rearingRecord}"
        })
        {
            _id
        }
    }`
  }
}

export const getRecordPertumbuhan = (keyword, limit, skip, rearingRecord) => {
  return (`
  query{
    growings(keyword:"${keyword}",limit:${limit},skip:${skip},rearingRecord:"${rearingRecord}"){
      totalCount
      growings{
        _id
        growingParam{
            _id
            name
            unit
            abbr
        }
        value
      }
    }
  }
`)
}

export const getDetailRecordPertumbuhan = (_id) => {
  return (`
  query{
    growing(_id : "${_id}"){
        _id
        growingParam{
            value : _id
            name
            unit
            abbr
        }
        value
    }
  }
`)
}

export const updateRecordPertumbuhan = (_id, value) => {
  return {
    query:
      `
    mutation{
      updateGrowing(_id:"${_id}",updateGrowingInput: {
        value : ${parseFloat(value.nilai)}
      })
      {
        _id
      }
    }
    `
  }
}

export const deleteRecordPertumbuhan = (_id) => {
  return (`
  mutation{
    deleteGrowing(_id:"${_id}"){
      deleted
    }
  }
  `)
}


/**Record Pakan */
export const createRecordPakan = (value, feedingId, dateRearing, idRearingRecord) => {
  return {
    query: `mutation{
            createFeeding(feedingInput: {
                number: ${value.jumlah},
                priceFeeding: ${value.price}
                storeTime: "${dateRearing}",
                feedStock : "${value.pakan.value}"
                feeding: "${feedingId}",
                typeFeeding: "Out",
                rearingRecord: "${idRearingRecord}"
            }){
                _id
            }
        }
      `
  }
}

export const getRecordPakan = (keyword, limit, skip, record) => {
  return (
    `query{
    feedingRecords(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
      totalCount
      feedings{
          _id
          typeFeeding
          number
          storeTime
          price
          priceFeeding
          feedStock {
            _id
            name
            feed{
                _id
                name
            }
          }
      }
    }
  }`
  )
}

export const getDetailRecordPakan = (_id) => {
  return (
    `query{
    feeding(_id: "${_id}"){
       _id
       number
       price
       priceFeeding
       storeTime
       typeFeeding
       feedStock {
            value : _id
       }
     }
   }`
  )
}

export const updateRecordPakan = (_id, value, dateRearing) => {
  return {
    query: `mutation{
      updateFeeding( _id:"${_id}" ,updateFeedingInput: {
          number: ${value.jumlah},
          priceFeeding: ${value.price},
          storeTime: "${dateRearing}",
          feedStock : "${value.pakan.value}"
          typeFeeding: "Out",
          }){
          _id
      }
  }`
  }
}

export const deleteRecordPakan = (_id) => {
  return (
    `mutation{
      deleteFeeding(_id : "${_id}"){
        deleted
      }
    }
  `
  )
}

/**Record Ovk */
export const createRecordOvk = (value, dateRearing, idRearingRecord) => {
  return {
    query: `
    mutation{
        createOvkTransaction(ovkTransactionInput: {
            number: ${parseFloat(value.jumlah)},
            priceOvkTransaction: ${parseFloat(value.price)},
            storeTime: "${dateRearing}",
            ovkStock : "${value.ovk.value}"
            typeTransaction: "Out",
            rearingRecord: "${idRearingRecord}"
        }){
            _id
        }
    }
  `
  }
}

export const getRecordOvk = (keyword, limit, skip, record) => {
  return (`
  query{
    ovkTransactionsRecord(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
      totalCount
      ovkTransactions{
          _id
          typeTransaction
          number
          numberRecording
          price
          priceOvkTransaction
          ovkStock {
            _id
            name
            ovk { 
                _id
                name
                ovkUnitRecording{
                  name
                }
                ovkUnit{
                   name
                }
            }
          }
      }
    }
  }`
  )
}

export const getDetailRecordOvk = (_id) => {
  return (
    `query{
    ovkTransaction(_id: "${_id}"){
      _id
      number
      priceOvkTransaction
      storeTime
      numberRecording
      typeTransaction
      ovkStock {
            value : _id
      }
    }
  }`
  )
}

export const updateRecordOvk = (_id, value, dateRearing) => {
  return {
    query: `
  mutation{
      updateOvkTransaction( _id:"${_id}",updateOvkTransactionInput: {
          number: ${parseFloat(value.jumlah)},
          priceOvkTransaction: ${parseFloat(value.harga)},
          storeTime: "${dateRearing}",
          ovkStock : "${value.ovk.value}"
          typeTransaction : "Out"
      }){
          _id
      }
  }
  `
  }
}

export const deleteRecordOvk = (_id) => {
  return (
    `mutation{
      deleteOvkTransaction(_id : "${_id}"){
        deleted
      }
    }
  `
  )
}

/**Record Material */
export const createRecordMaterial = (value, dateRearing, idRearingRecord) => {
  return {
    query: `
    mutation{
        createMaterialTransaction(materialTransactionInput: {
            number: ${value.jumlah},
            priceMaterial: ${value.price},
            storeTime: "${dateRearing}",
            materialStock : "${value.material.value}"
            typeTransaction: "Out",
            rearingRecord: "${idRearingRecord}"
        }){
            _id
        }
    }
  `
  }
}

export const getRecordMaterial = (keyword, limit, skip, record) => {
  return (`
    query{
      materialTransactionsRecord(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
        totalCount
        materialTransactions{
            _id
            typeTransaction
            number
            priceMaterial
            materialStock {
              _id
              name
              material { 
                  _id
                  name
              }
            }
        }
      }
    }`
  )
}

export const getDetailRecordMaterial = (_id) => {
  return (
    `query{
   materialTransaction(_id: "${_id}"){
      _id
      number
      priceMaterial
      storeTime
      typeTransaction
      materialStock {
            value : _id
      }
    }
  }`
  )
}

export const updateRecordMaterial = (_id, value, dateRearing) => {
  return {
    query: `
    mutation{
        updateMaterialTransaction( _id:"${_id}",updateMaterialTransactionInput: {
            number: ${value.jumlah},
            priceMaterial: ${value.price},
            storeTime: "${dateRearing}",
            materialStock : "${value.material.value}"
            typeTransaction : "Out"
        }){
            _id
        }
    }
  `
  }
}

export const deleteRecordMaterial = (_id) => {
  return (
    `mutation{
      deleteMaterialTransaction(_id : "${_id}"){
        deleted
      }
    }
  `
  )
}
/**Record Overhead */
export const createRecordOverhead = (value, idRearingRecord) => {
  return {
    query: `mutation{
      createOverheading(overheadingInput: {
                  overhead : "${value.overhead.value}"
                  quantity: ${value.jumlah},
                  price: ${value.price},
                  rearingRecord: "${idRearingRecord}"
              }){
                  _id
              }
          }
        `
  }
}

export const getRecordOverhead = (keyword, limit, skip, record) => {
  return (
    `query{
      overheadings(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
        totalCount
        overheadings{
            _id
            quantity
            price
            overhead {
              _id
              name
            }
        }
      }
    }`
  )
}

export const getDetailRecordOverhead = (_id) => {
  return (
    `query{
      overheading(_id: "${_id}"){
         _id
         quantity
         price
         overhead {
              value: _id
              name
         }
       }
     }`
  )
}

export const updateRecordOverhead = (_id, value) => {
  return {
    query: `mutation{
      updateOverheading( _id:"${_id}" ,updateOverheadingInput: {
        overhead : "${value.overhead.value}"
        quantity: ${value.jumlah},
        price: ${value.price},
            }){
            _id
        }
    }`
  }
}

export const deleteRecordOverhead = (_id) => {
  return (
    `mutation{
      deleteOverheading(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}
/**Record Penyakit */
export const createRecordPenyakit = (value, idRearingRecord) => {
  return {
    query: `
      mutation{
          createDiseaseRecord(diseaseRecordInput: {
              number: ${value.jumlahAyam},
              disease: "${value.penyakit.value}",
              rearingRecord: "${idRearingRecord}"
          }){
              _id
          }
      }
    `
  }
}

export const getRecordPenyakit = (keyword, limit, skip, record) => {
  return (`
      query{
        diseaseRecords(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
          totalCount
          diseaseRecords{
              _id
              disease{
                _id
                name
              }
              number
          }
        }
      }`
  )
}

export const getDetailRecordPenyakit = (_id) => {
  return (
    `query{
      diseaseRecord(_id: "${_id}"){
        _id
        number
        disease {
              value : _id
        }
        pictureDisease{
          _id
          linkPicture
          originalName
        }
      }
    }`
  )
}

export const getDetailRecordPenyakitEdit = (_id) => {
  return (
    `query{
        diseaseRecord(_id: "${_id}"){
          _id
          number
          disease {
                value : _id
          }
          pictureDisease{
            url :   linkPicture
            name : originalName
          }
        }
      }`
  )
}

export const updateRecordPenyakit = (_id, value) => {
  return {
    query: `
      mutation{
          updateDiseaseRecord( _id:"${_id}",updateDiseaseRecordInput: {
              number: ${value.jumlahAyam},
          }){
              _id
          }
      }
    `
  }
}

export const deleteRecordPenyakit = (_id) => {
  return (
    `mutation{
      deleteDiseaseRecord(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}