import React from 'react';
import { Input} from 'antd';
import './scss/input-form.scss';

function InputPassword({
    value, onChange, placeholder, className, style, name, type,
    disabled, icon,withIcon = true
}) {
    return (        
        <Input.Password
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={className}
            style={style}
            type={type}
            disabled = {disabled}
        />
    );
}

export default InputPassword;