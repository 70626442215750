import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Modal,Form,Row,Col,Select, InputNumber } from 'antd'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import { createRecordOverhead } from '../rearing-record-aksi-page/query-rearing-record'
import { useTranslation } from "react-i18next"

function CreatePakanPage(props) {
    const { idRearingRecord, setLoading, fetchDataOverhead, overhead } = props
    const [visible, setVisible] = useState(false)
    const {Option} = Select
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const setPrice = (value) => {
        const price = overhead.filter(d => d._id === value)[0].defaultPrice
        form.setFieldsValue({price:price})
    }
    const postData = (values) => {
        API.post(createRecordOverhead(values, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOverheading._id) {
                    validationMessage('success', `${t("pages.recording.detail.overhead.response.sukses.tambah")}`)
                    fetchDataOverhead(idRearingRecord)
                    setVisible(false)
                } else {
                    validationMessage('error', `${t("pages.recording.detail.overhead.response.gagal.tambah")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.detail.overhead.response.gagal.tambah")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
    }
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        return (
            <Modal
                visible={visible}
                title={t("pages.recording.detail.overhead.modal.titleTambah")}
                okText={t('general.create')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.recording.detail.overhead.modal.overhead")}</span>
                            <Form.Item
                                name="overhead" rules={[{ required: true, message: `${t("pages.recording.detail.overhead.modal.msgOverhead")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.recording.detail.overhead.modal.phOverhead")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    onChange={(value)=> setPrice(value.value)}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        overhead.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.recording.detail.overhead.modal.jumlah")}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${t("pages.recording.detail.overhead.modal.msgJumlah")}` }]}
                            >
                                <InputNumber
                                    name='jumlah'
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.recording.detail.overhead.modal.price")}</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `${t("pages.recording.detail.overhead.modal.msgPrice")}` }]}
                            >
                                <InputNumber
                                    name='price'
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <div>
            <span onClick={() => showModal()}>{t("pages.recording.detail.overhead.button.tambah")}</span>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePakanPage)
export default page