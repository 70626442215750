import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input } from 'antd'
import NumberFormat from 'react-number-format'
import { SearchOutlined } from '@ant-design/icons'
import { API } from '../../../../common/api'
import { getMonthlyStockFeeds } from './pakan-aksi-page/query-stock-flow-pakan'
import PakanComponent from '../../../../modules/dashboard-peternak/stock-flow-component/pakan-component/pakan-component'
import moment from 'moment'

import { useTranslation } from 'react-i18next'

function PakanPage(props) {
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [idStockflow, setId] = useState(null)
    const [month, setMonth] = useState(moment().format('MMMM'))
    const [year, setYear] = useState(moment().format('YYYY'))

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    /**Handle Get Data */
    const fetchData = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockFeeds('', 0, 0, month, year))
            .then(res => {
                setObject(res.data.data.monthlyStockFeeds.calculateMonthlyStocks)
                setId(res.data.data.monthlyStockFeeds.calculateMonthlyStocks[0].idMonthlyStockFeed)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(month, year)
    }, [month, year])

    const columns = [
        {
            title: `${t('pages.stockflow.pakan.table.pakan')}`,
            dataIndex: 'pakan',
            key: 'pakan',
            fixed: true,
            width: 100,
            ...getColumnSearchProps('pakan', `${t('pages.stockflow.pakan.table.pakan')}`),
        },
        {
            title: `${t('pages.stockflow.pakan.table.saldoAwal.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.pakan.table.saldoAwal.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyAwal',
                    key: 'qtyAwal',
                    width: 130,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.saldoAwal.hs')}`,
                    className: 'right',
                    dataIndex: 'hargaSatuanAwal',
                    key: 'hargaSatuanAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.saldoAwal.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalAwal',
                    key: 'hargaTotalAwal',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.pakan.table.masuk.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.pakan.table.masuk.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPengadaan',
                    key: 'qtyPengadaan',
                    width: 130,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.masuk.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPengadaan',
                    key: 'hsPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.masuk.nilai')}`,
                    className: 'right',
                    dataIndex: 'totalPengadaan',
                    key: 'totalPengadaan',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.pakan.table.keluar.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.pakan.table.keluar.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenggunaan',
                    key: 'qtyPenggunaan',
                    width: 130,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.keluar.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPenggunaan',
                    key: 'hsPenggunaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.keluar.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalPenggunaan',
                    key: 'hargaTotalPenggunaan',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.pakan.table.penyesuaian.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.pakan.table.penyesuaian.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyPenyesuaian',
                    key: 'qtyPenyesuaian',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.penyesuaian.hs')}`,
                    className: 'right',
                    dataIndex: 'hsPenyesuaian',
                    key: 'hsPenyesuaian',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.penyesuaian.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalPenyesuaian',
                    key: 'hargaTotalPenyesuaian',
                    width: 130,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.pakan.table.saldoAkhir.title')}`,
            children: [
                {
                    title: `${t('pages.stockflow.pakan.table.saldoAkhir.qty')}`,
                    className: 'right',
                    dataIndex: 'qtyStokAkhir',
                    key: 'qtyStokAkhir',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.saldoAkhir.hs')}`,
                    className: 'right',
                    dataIndex: 'hsStokAkhir',
                    key: 'hsStokAkhir',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.pakan.table.saldoAkhir.nilai')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalStokAkhir',
                    key: 'hargaTotalStokAkhir',
                    width: 130,
                }
            ]
        }
    ]

    const filter1 = object.filter(a => a !== null)
    const filter2 = filter1.filter(a => a.stockAwalBulan !== 0 || a.hargaAwalBulan !== 0 || a.addStock !== 0 ||
        a.hargaPengadaan !== 0 || a.outStock || a.hargaPengeluaran || a.qty)

    const data = filter2.map(({ _id, idMonthlyStockFeed, namaPakan, addStock, stockAwalBulan, hargaAwalBulan,
        hargaTotalAwalBulan, outStock, sisaStok, qty, price, hargaPengeluaran, nilaiSisaStok, nilaiPengadaan,
        hargaTotalPenyesuaian, hargaPengadaan, nilaiPengeluaran }, index) => ({
            key: _id,
            id: idMonthlyStockFeed,
            pakan: namaPakan,

            stokAwal: stockAwalBulan,
            hargaAwalBulan: hargaAwalBulan,
            hargaTotalAwalBulan: hargaTotalAwalBulan,
            qtyAwal: stockAwalBulan < 0 ? <NumberFormat value={Math.abs(stockAwalBulan)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={stockAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaSatuanAwal: <NumberFormat value={hargaAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaTotalAwal: stockAwalBulan < 0 ?
                <NumberFormat value={Math.abs(hargaTotalAwalBulan)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={hargaTotalAwalBulan} displayType={'text'} thousandSeparator={true} />,

            pengadaan: addStock,
            hargaPengadaan: hargaPengadaan,
            totalHargaPengadaan: nilaiPengadaan,
            qtyPengadaan: <NumberFormat value={addStock} displayType={'text'} thousandSeparator={true} />,
            hsPengadaan: <NumberFormat value={hargaPengadaan} displayType={'text'} thousandSeparator={true} />,
            totalPengadaan: <NumberFormat value={nilaiPengadaan} displayType={'text'} thousandSeparator={true} />,

            penggunaan: outStock,
            hargaPenggunaan: hargaPengeluaran,
            totalHargaPenggunaan: nilaiPengeluaran,
            qtyPenggunaan: <NumberFormat value={outStock} displayType={'text'} thousandSeparator={true} />,
            hsPenggunaan: <NumberFormat value={hargaPengeluaran} displayType={'text'} thousandSeparator={true} />,
            hargaTotalPenggunaan: <NumberFormat value={nilaiPengeluaran} displayType={'text'} thousandSeparator={true} />,

            penyesuaian: qty,
            hargaPenyesuaian: price,
            totalHargaPenyesuaian: hargaTotalPenyesuaian,
            qtyPenyesuaian: <NumberFormat value={qty < 0 ? Math.abs(qty) : qty} prefix={qty < 0 ? '(' : ''} suffix={qty < 0 ? ')' : ''}
                displayType={'text'} thousandSeparator={true} />,
            hsPenyesuaian: <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />,
            hargaTotalPenyesuaian: <NumberFormat value={hargaTotalPenyesuaian < 0 ? Math.abs(hargaTotalPenyesuaian) : hargaTotalPenyesuaian}
                prefix={hargaTotalPenyesuaian < 0 ? '(' : ''} suffix={hargaTotalPenyesuaian < 0 ? ')' : ''}
                displayType={'text'} thousandSeparator={true} />,

            stokAkhir: sisaStok,
            hargaStokAkhir: hargaPengeluaran,
            totalHargaStokAkhir: nilaiSisaStok,
            qtyStokAkhir: sisaStok < 0 ? <NumberFormat value={Math.abs(sisaStok)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={sisaStok} displayType={'text'} thousandSeparator={true} />,
            hsStokAkhir: <NumberFormat value={hargaPengeluaran} displayType={'text'} thousandSeparator={true} />,
            hargaTotalStokAkhir: sisaStok < 0 ? <NumberFormat value={Math.abs(nilaiSisaStok)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                <NumberFormat value={nilaiSisaStok} displayType={'text'} thousandSeparator={true} />,
        }))

    const handleTanggalRange = (dateStrings) => {
        setMonth(moment(dateStrings).format('MMMM'))
        setYear(moment(dateStrings).format('YYYY'))
    }

    const disabledDate = (current) => {
        return current > moment().endOf('day')
    }

    return (
        <PakanComponent
            navigate={props.navigate}
            loading={loading}
            setLoading={setLoading}
            data={data}
            month={month} year={year}
            idStockflow={idStockflow}
            columns={columns}
            disabledDate={disabledDate}
            handleTanggalRange={handleTanggalRange}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PakanPage)
export default page