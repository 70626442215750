import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { navigate } from '../../../../common/store/action'
import EditPemakaianUmumOvkComponent from '../../../../modules/dashboard-peternak/pemakaian-umum-component/pemakaian-umum-aksi/update-pemakaian-umum-component'
import { getMonthlyStockOvks } from '../../stock-flow-page/ovk-page/ovk-aksi-page/query-stock-flow-ovk'
import moment from 'moment'

function EditPemakaianUmumOvkPage(props) {
    const { visible, handleCancel, detail, handleSubmit, suffix, setLoading, t } = props
    const [data, setData] = useState(detail)
    const [monthlyOvk, setMonthlyOvk] = useState([])

    const fetchMonthlyOvk = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockOvks('', 0, 0, month, year))
            .then(res => {
                setMonthlyOvk(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setMonthlyOvk([])
                setLoading(false)
            })
    }

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
                let date = moment(new Date(parseInt(detail.storeTime)))
                let month = moment(date).locale('id').format('MMMM')
                let year = moment(date).locale('id').format('YYYY')
                console.log(month, year)
                fetchMonthlyOvk(month, year)
            }
        }
        setDetailData()
        // eslint-disable-next-line
    }, [detail])
    return (
        <EditPemakaianUmumOvkComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            detail={detail}
            data={data}
            suffix={suffix}
            stokOvk={props.stokOvk}
            t={t} monthlyOvk={monthlyOvk}
        />
    )
}

const mapStateToProps = state => ({
    stokOvk: state.stokOvk.dataStokOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPemakaianUmumOvkPage)
export default page