import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import CONSTANTS from '../../../../common/utils/Constants'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '../../../../common/store/action/index'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { faInfoCircle, faLock, faLockOpen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import PeriodeLiveBirdComponent from '../../../../modules/dashboard-peternak/stock-flow-component/periode-component/periode-live-bird-component'
import { createStockflowLiveBird, newStockflowLiveBird, lockPeriodeStokLiveBird, reLockPeriodeStokLiveBird, delStockflowLiveBird } from './periode-aksi-page/query-periode'
import { getMonthPeriode, getYearPeriode } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'

function PeriodeLiveBirdPage(props) {
    const { confirm } = Modal
    const { setLoading, loading, liveBird, yearRangeLiveBird, yearLiveBird, setYearLiveBird, fetchDataLiveBird,
        getMonthPeriode, getYearPeriode, t } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }
    const createData = () => {
        setLoading(true)
        API.get(createStockflowLiveBird())
            .then(res => {
                if (res.data.data.createMonthlyStockLiveBird._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.livebird.response.sukses.create')}`)
                    fetchDataLiveBird(yearLiveBird)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.livebird.response.gagal.create')}`)
                setLoading(false)
            })
    }
    const createNewData = (date) => {
        setLoading(true)
        API.get(newStockflowLiveBird(date))
            .then(res => {
                if (res.data.data.newMonthlyStockLiveBird._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.livebird.response.sukses.create')}`)
                    fetchDataLiveBird(yearLiveBird)
                    setVisible(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.livebird.response.gagal.create')}`)
                setLoading(false)
                setVisible(false)
            })
    }

    const createConfirm = () => {
        confirm({
            title: `${t('pages.stockflow.periode.livebird.textCreate')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                createData()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
    const closePeriode = (_id, periode) => {
        confirm({
            title: `${t('pages.stockflow.periode.livebird.textClose')}${periode}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                close(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const close = (_id) => {
        setLoading(true)
        API.post(lockPeriodeStokLiveBird(_id))
            .then(res => {
                if (res.data.data.lockMonthlyStockLiveBird._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.livebird.response.sukses.close')}`)
                    fetchDataLiveBird(yearLiveBird)
                } else if (res.data.data.lockMonthlyStockLiveBird.ok !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.livebird.response.sukses.reclose')}`)
                    fetchDataLiveBird(yearLiveBird)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.livebird.response.gagal.close')}`)
                setLoading(false)
            })
    }

    const openPeriode = (_id, periode) => {
        confirm({
            title: `${t('pages.stockflow.periode.livebird.textOpen')}${periode}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                open(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const open = (_id) => {
        setLoading(true)
        API.post(reLockPeriodeStokLiveBird(_id))
            .then(res => {
                if (res.data.data.reOpenMonthlyStockLiveBird._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.livebird.response.sukses.open')}`)
                    fetchDataLiveBird(yearLiveBird);
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.livebird.response.gagal.open')}`)
                setLoading(false)
            })
    }
    const deleteData = (_id) => {
        setLoading(true)
        API.delete(delStockflowLiveBird(_id))
            .then(res => {
                if (res.data.data.deleteMonthlyStockLiveBird.deleted === true) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.delete')}`)
                    fetchDataLiveBird(yearLiveBird)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.material.response.gagal.delete')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.stockflow.periode.livebird.textDel')}${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const detailPeriode = useCallback((m, y) => {
        getMonthPeriode(m)
        getYearPeriode(y)
        props.navigate(CONSTANTS.DETAIL_PERIODE_LIVEBIRD_KEY)
    }, [props, getMonthPeriode, getYearPeriode])

    const columns = [
        {
            title: `${t('pages.stockflow.periode.table.periode')}`,
            dataIndex: 'periode',
            key: 'periode',
            ...getColumnSearchProps('periode', `${t('pages.stockflow.periode.table.periode')}`),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status', 'status'),
        },
        {
            title: `${t('pages.stockflow.periode.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.recording.button.detail")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }}
                            onClick={() => detailPeriode(row.month, row.year)}
                        ><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={row.locked === false ?
                        `${t('pages.stockflow.periode.table.lock')}` :
                        `${t('pages.stockflow.periode.table.unlock')}`}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: row.locked === false ? '#ff4a3d' : '#03a5fc',
                            borderRadius: '5px',
                        }} onClick={row.locked === false ? () => closePeriode(row.key, row.periode) :
                            () => openPeriode(row.key, row.periode)} >
                            <FontAwesomeIcon icon={row.locked === false ? faLock : faLockOpen} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.periode)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = liveBird.map(({ _id, locked, month, year, createdAt }, index) => ({
        key: _id,
        id: _id,
        month: month,
        year: year,
        periode: month + ' ' + year,
        locked: locked,
        status: locked === true ?
            `${t('pages.stockflow.periode.table.locked')}` :
            `${t('pages.stockflow.periode.table.unlocked')}`,
        createdAt: createdAt
    }))

    return (
        <PeriodeLiveBirdComponent
            navigate={props.navigate}
            columns={columns}
            loading={loading}
            visible={visible} setVisible={setVisible}
            data={data} createNewData={createNewData}
            yearRangeLiveBird={yearRangeLiveBird}
            yearLiveBird={yearLiveBird}
            setYearLiveBird={setYearLiveBird}
            t={t} createConfirm={createConfirm}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getMonthPeriode, getYearPeriode
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PeriodeLiveBirdPage)
export default page