import {API} from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import * as actionType from './layout-action-type'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

const startGetDataLayouts = () => ({
    type : actionType.START_GET_DATA
})

const finishGetDataLayouts = () => ({
    type : actionType.FINISH_GET_DATA
})

const setDataKandang = payload => ({
    type : actionType.SET_DATA_KANDANG,
    payload,
})

const setDataPeriode = payload => ({
    type : actionType.SET_DATA_PERIODE,
    payload,
})

const setIdPeriode = payload => ({
    type : actionType.SET_ID_PERIODE,
    payload,
})
const setIdRearing= payload => ({
    type : actionType.SET_ID_REARING,
    payload,
})
const setIdKandang = payload => ({
    type : actionType.SET_ID_KANDANG,
    payload,
})
const setKandang = payload => ({
    type : actionType.SET_KANDANG,
    payload,
})
const setPeriode = payload => ({
    type : actionType.SET_PERIODE,
    payload,
})
const setChickInDate = payload => ({
    type : actionType.SET_CHICKINDATE,
    payload,
})

const setDatePeriode = payload => ({
    type : actionType.SET_DATE_PERIODE,
    payload,
})

const setTotalKandang = payload => ({
    type : actionType.SET_TOTAL_KANDANG,
    payload
})

const setIsTourOpen = payload => ({
    type : actionType.SET_IS_TOUR,
    payload
})

const setLocales = payload => ({
    type : actionType.SET_LOCALES,
    payload
})

const setIdDevice = payload => ({
    type: actionType.SET_ID_DEVICE,
    payload,
})
const setDeviceType = payload => ({
    type: actionType.SET_DEVICE_TYPE,
    payload,
})

export const getIdDevice = (id_device) => (dispatch) => {
    dispatch(setIdDevice(id_device))
}
export const getDeviceType = (type) => (dispatch) => {
    dispatch(setDeviceType(type))
}

export const getIdPeriode = (id_periode) => (dispatch) => {
    dispatch(setIdPeriode(id_periode));
}
export const getIdRearing = (id_rearing) => (dispatch) => {
    dispatch(setIdRearing(id_rearing));
}
export const getIdKandang = (id_kandang) => (dispatch) => {
    dispatch(setIdKandang(id_kandang))
}
export const getKandang = (kandang) => (dispatch) => {
    dispatch(setKandang(kandang))
}
export const getPeriode = (periode) => (dispatch) => {
    dispatch(setPeriode(periode))
}

export const getDatePeriode = (date_periode) => (dispatch) => {
    dispatch(setDatePeriode(date_periode));
}

export const setIsTour = () => (dispatch)=> {
    dispatch(setIsTourOpen(false));
}

export const getChickInDate = (chickinDate) => (dispatch) => {
    dispatch(setChickInDate(chickinDate))
}

export const getListKandang = (params) => (dispatch) => {
    dispatch(startGetDataLayouts())
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.houses.totalCount !== 0){
            dispatch(setDataKandang(res.data.data.houses.houses))
            dispatch(getIdKandang(res.data.data.houses.houses[0]._id))
            dispatch(setTotalKandang(res.data.data.houses.totalCount))
            dispatch(setIsTourOpen(false))
            if(res.data.data.houses.houses[0].rearing.length !== 0 ){
                dispatch(setDataPeriode(res.data.data.houses.houses[0].rearing))
                dispatch(getIdPeriode(res.data.data.houses.houses[0].rearing[0]._id))
                dispatch(getPeriode(res.data.data.houses.houses[0].rearing[0].name))
                dispatch(getKandang(res.data.data.houses.houses[0].name))
                dispatch(getChickInDate(moment(new Date(parseInt(res.data.data.houses.houses[0].rearing[0].chickInDate))).format("YYYY-MM-DD")))
            }else{
                dispatch(setDataPeriode([]))
                dispatch(getIdPeriode(null))
                dispatch(getChickInDate(null))
            }
        } else if(res.data.data.houses.totalCount === 0){
            dispatch(setTotalKandang(res.data.data.houses.totalCount))
            dispatch(setDataKandang([]))
            dispatch(getIdKandang(null))
            dispatch(setDataPeriode([]))
            dispatch(getIdPeriode(null))
            dispatch(getChickInDate(null))
            dispatch(setIsTourOpen(true))
        } else {
            validationMessage('error', 'Koneksi Gagal')
        }
        dispatch(finishGetDataLayouts());
    })
}

export const getListPeriodeKandang = (params) => (dispatch) => {
    dispatch(startGetDataLayouts())
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.rearingHouses.totalCount === 0){
            dispatch(setDataPeriode([]))
            dispatch(getIdPeriode(null))
            dispatch(getChickInDate(null))
        } 
        else if(res.data.data.rearingHouses !== null){
            dispatch(setDataPeriode(res.data.data.rearingHouses.rearings))
            dispatch(getIdPeriode(res.data.data.rearingHouses.rearings[0]._id))
            dispatch(getPeriode(res.data.data.rearingHouses.rearings[0].name))
            dispatch(getKandang(res.data.data.rearingHouses.rearings[0].house.warehouseName))
            dispatch(getChickInDate(moment(new Date(parseInt(res.data.data.rearingHouses.rearings[0].chickInDate))).format("YYYY-MM-DD")))
        } else if(res.data.data.rearingHouses === null){
            dispatch(setDataPeriode([]))
            dispatch(getIdPeriode(null))
            dispatch(getChickInDate(null))
        }else {
            validationMessage('error', 'Koneksi Gagal')
        }
        dispatch(finishGetDataLayouts());
    })
}

export const getListPeriode = (params) => (dispatch) => {
    dispatch(startGetDataLayouts());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.rearings.rearings){
            dispatch(setDataPeriode(res.data.data.rearings.rearings))
        } else {
            validationMessage('error', 'Koneksi Gagal')
        }
        dispatch(finishGetDataLayouts());
    })
}

export const getLocales = (locales) => (dispatch) => {
    dispatch(setLocales(locales))
}