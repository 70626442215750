import React from 'react'
import { Modal,Form,Row,Col,InputNumber } from 'antd'

function EditPeriodeMaterial(props) {
    const {visible,handleSubmit,data,handleCancel,t} = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel}) => {
        const [form] = Form.useForm()
        return (
          <Modal
            visible={visible}
            title={t('pages.stockflow.periode.material.titleEdit')}
            okText="Edit"
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  handleSubmit(values)
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                });
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        bgnQty : data.bgnQty,
                        bgnPrice : data.bgnPrice,
                    }}
                >
                     <Row gutter={[16,8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.periode.material.qty')}</span>
                            <Form.Item
                                name="bgnQty" rules={[{ required: true, message: `${t('pages.stockflow.periode.material.msgQty')}` }]}
                            >
                               <InputNumber 
                                    name='qtyMaterial'
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.periode.material.price')}</span>
                            <Form.Item
                                name="bgnPrice" rules={[{ required: true, message: `${t('pages.stockflow.periode.material.msgPrice')}` }]}
                            >
                                <InputNumber 
                                    name='priceMaterial'
                                    placeholder="7000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        )
    }
    return ( 
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}
 
export default EditPeriodeMaterial