import React, {useState,useCallback,useEffect} from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import Highlighter from 'react-highlight-words';
import { Space, Tooltip, Button, Modal,Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import PakanComponent from '../../../../modules/dashboard-peternak/master-component/pakan-component/pakan-component';
 
import {getPakan,deletePakan,detailPakan,updatePakan} from './pakan-aksi-page/query-pakan';
import { API } from '../../../../common/api';
import { validationMessage } from '../../../../common/component/notification/notification';

import {useTranslation} from 'react-i18next'

function PakanPage(props) {
    const { confirm } = Modal;
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('');
    const [pagination, setPagination] = useState(10);
    const [visible, setVisible] = useState(false);
    const [detail, setDetail] = useState('');
    const [idData, setIdData] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [object, setObject] = useState([]);
     
    const {t} = useTranslation()

    /**Handle seacrh in row antd design */
    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`${t('general.search')} ${title}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                {t('general.search')}
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
            ) : (
            text
        ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSeacrhedColumn(dataIndex);
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };
    /**End handle seacrh in row antd design */

    /**Handle get data */
    const fetchData = () => {
        setLoading(true);
        API.get(getPakan('',0,0))
        .then(res => {
           setObject(res.data.data.feeds.feeds);
           setLoading(false);
        }).catch((error) => {
            console.log(error);
            setObject([])
            setLoading(false);
        })
    }

    useEffect(() => {
       fetchData();
    }, []);
    /**End handle get data */

    /**Handle delete data */
    const deleteData = (_id) => {
        setLoading(true);
        API.delete(deletePakan(_id))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading (false);
            }else if(res.data.data.deleteFeed.deleted === true){
                validationMessage('success', `${t('pages.master.pakan.response.sukses.hapus')}`)
                fetchData();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.pakan.response.gagal.hapus')}`)
            setLoading(false);
        })
    }

    const hapusConfirm = (_id,nama)=> {
        confirm({
            title: `${t('pages.master.pakan.textHapus')}${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    /**End handle delete data */

    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if(idData){
                API.get(detailPakan(idData))
                .then(res => {        
                    setDetail(res.data.data.feed)
                    setVisible(true);
                }).catch((error) => {
                    console.log(error);
                    setDetail(null);
                    setVisible(false);
                })
            }
        }
        getDetailData();
    }, [idData]);

    const showModal = useCallback((value) => {
        if(value === idData)
            setVisible(true)
        else
            setIdData(value);
    },[idData]);

    const handleCancel = useCallback(() => {
        setVisible(false);
    }, []);

    const updatedData =  (values,year) => {
        API.post(updatePakan(idData,values,year))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading (false);
            }else if(res.data.data.updateFeed._id){
                validationMessage('success', `${t('pages.master.pakan.response.sukses.edit')}`)
                fetchData();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.pakan.response.gagal.edit')}`);
            setLoading(false);
        })
    }

    const handleSubmit = (values) => {
        updatedData(values,values['date_picker'].format('YYYY'));
        setVisible(false);
        setLoading(true);
        setIdData(null);
    };
    /**End handle updated data */

    const columns = [
        {
            title: `${t('pages.master.pakan.table.kode')}`,
            dataIndex: 'kode',
            key: 'kode',
            ...getColumnSearchProps('kode',`${t('pages.master.pakan.table.kode')}`),
        },
        {
            title: `${t('pages.master.pakan.table.nama')}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama',`${t('pages.master.pakan.table.nama')}`),
        },
        {
            title: `${t('pages.master.pakan.table.perusahaan')}`,
            dataIndex: 'perusahaan',
            key: 'perusahaan',
            ...getColumnSearchProps('perusahaan',`${t('pages.master.pakan.table.perusahaan')}`),
        },
        {
            title: `${t('pages.master.pakan.table.tahun')}`,
            dataIndex: 'tahun',
            key: 'tahun',
            ...getColumnSearchProps('tahun',`${t('pages.master.pakan.table.tahun')}`),
        },
        {
            title: `${t('pages.master.pakan.table.jenis')}`,
            dataIndex: 'jenis',
            key: 'jenis',
            ...getColumnSearchProps('jenis',`${t('pages.master.pakan.table.jenis')}`),
        },
        {
            title: `${t('pages.master.pakan.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 130,
            align: 'center',
            render: (value,row,index) => (
            <Space size="middle">
                <Tooltip placement="top" title='Edit'>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FFA903',
                        borderRadius:'5px'
                    }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                </Tooltip>
                <Tooltip placement="top" title={t('general.del')}>
                    <Button style={{
                        color:'white',
                        backgroundColor:'#FF0303',
                        borderRadius:'5px'
                    }} onClick={() => hapusConfirm(row.key,row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                </Tooltip>
            </Space>
            ),
        },
    ];

    const data = object.map( ({_id,code,name,producer,type,year}, index) => ({
        key: _id,
        kode : code,
        nama : name,
        perusahaan : producer,
        tahun : year,
        jenis : type,
    }));

    const changePagination = useCallback((pageSize) => {
        setPagination(pageSize);
    }, []);

    return ( 
        <PakanComponent
            navigate={props.navigate}
            pagination = {pagination}
            changePagination = {changePagination}
            showModal = {showModal}
            handleCancel = {handleCancel}
            columns = {columns}
            idData = {idData}
            visible = {visible}
            data = {data}
            detail = {detail}
            loading = {loading}
            handleSubmit = {handleSubmit}
            fetchData = {fetchData}
            setLoading = {setLoading}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(PakanPage);
export default page