import * as actionType from './stok-pakan-action-type';

const initialState = {
    idStokPakan : null,
    dataStokPakan : [],
    loading : false,
    namaPakan : null,
}

const handler = (currentState) => {
    const setDataStokPakan = {
        startGetDataStokPakans : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataStokPakans : () => ({
            ...currentState,
            loading: false,
        }),

        setIdStokPakan : data => ({
            ...currentState,
            idStokPakan: data
        }),

        setDataStokPakan : data => ({
            ...currentState,
            dataStokPakan : data
        }),

        setDataNamaPakan : data => ({
            ...currentState,
            namaPakan : data
        })
    }

    return {
        ...setDataStokPakan
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataStokPakans();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataStokPakans();
        case actionType.SET_ID_STOK_PAKAN:
            return handler(state).setIdStokPakan(payload);
        case actionType.SET_STOK_PAKAN:
            return handler(state).setDataStokPakan(payload);
        case actionType.SET_NAMA_PAKAN:
            return handler(state).setDataNamaPakan(payload);

        default:
            return state;
    }
}