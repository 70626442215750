import React from 'react'
import { Modal,Form,Row,Col,Input,Select } from 'antd'

function EditStokPakan(props) {
    const { Option } = Select
    const {visibleEdit,handleSubmit,data,gudangPakan,pakan,handleCancel, locales} = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel}) => {
        const [form] = Form.useForm()
        return (
          <Modal
            visible={visible}
            title={locales('pages.stok.pakan.modal.titleEdit')}
            okText='Edit'
            width={800}
            cancelText={locales('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  handleSubmit(values)
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                        gudangPakan : data.feedWarehouse,
                        pakan :data.feed,
                        namaPengadaan : data.name,
                        hargaSatuan : data.price,
                    }}
                >
                      <Row gutter={[16,8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{locales('pages.stok.pakan.modal.gudang')}</span>
                            <Form.Item
                                name="gudangPakan" rules={[{ required: true, message: 'Silahkan pilih gudang pakan!' }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih Gudang Pakan"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >  
                                    {
                                        gudangPakan.map( data =>     
                                            <Option
                                            key={data.name.toString()}
                                            value={data._id}
                                            >{data.name}</Option>
                                            )
                                    }
                                </Select>
                                </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">{locales('pages.stok.pakan.modal.pakan')}</span>
                            <Form.Item
                                name="pakan" rules={[{ required: true, message: 'Silahkan pilih pakan!' }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih Pakan"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >  
                                     {
                                        pakan.map( data =>     
                                            <Option
                                            key={data.name.toString()}
                                            value={data._id}
                                            >{data.name}</Option>
                                            )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales('pages.stok.pakan.modal.namaPengadaan')}</span>
                            <Form.Item
                                name="namaPengadaan" rules={[{ required: true, message: `${locales('pages.stok.pakan.modal.msgNamaPengadaan')}` }]}
                            >
                                <Input
                                    name='namaPengadaan'
                                    placeholder={locales('pages.stok.pakan.modal.phNamaPengadaan')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        )
    }
    return ( 
        <div>
            <CollectionCreateForm
                visible={visibleEdit}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}
 
export default EditStokPakan