import React, { useCallback, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { navigate } from '../../../common/store/action'
import { Space, Tooltip, Button, Modal, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import RearingRecordComponent from '../../../modules/dashboard-peternak/rearing-record-component/rearing-record-component'
import CONSTANTS from '../../../common/utils/Constants'
import { API } from '../../../common/api'
import { useLocation } from "react-router-dom"

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { getRearingRecord, deleteRearingRecord } from './rearing-record-aksi-page/query-rearing-record'
import { getIdRearingRecord, getDateRearingRecord, getAgeRearingRecord } from '../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { validationMessage } from '../../../common/component/notification/notification'

import { useTranslation } from "react-i18next"

function RearingRecordPage(props) {
    const { confirm } = Modal
    const { idPeriode, getIdRearingRecord, getDateRearingRecord, getAgeRearingRecord } = props
    const [visibleCreate, setVisibleCreate] = useState(false)
    const [visibleEdit, setVisibleEdit] = useState(false)
    const [rearing, setRearing] = useState([])
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [periode, setPeriode] = useState('')
    const [house, setHouse] = useState('')
    const { t } = useTranslation()
    const location = useLocation()

    const fetchData = (idPeriode) => {
        setLoading(true);
        API.get(getRearingRecord('', 0, 0, idPeriode))
            .then(res => {
                if (res.data.data.calculatedRearingRecords) {
                    rearingObject(res.data.data.calculatedRearingRecords.calculatedArr)
                    setPeriode(res.data.data.calculatedRearingRecords.periode)
                    setHouse(res.data.data.calculatedRearingRecords.house)
                    setLoading(false)
                    console.log(idPeriode)
                } else {
                    rearingObject([])
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        fetchData(idPeriode)
        // eslint-disable-next-line
    }, [idPeriode])

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteRearingRecord(_id))
            .then(res => {
                if (res.data.data.deleteRearingRecord.deleted === true) {
                    validationMessage('success', `${t("pages.recording.response.sukses.hapus")}`)
                    fetchData(idPeriode)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.response.gagal.hapus")}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.recording.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t("general.cancel")}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const showModal = useCallback((value) => {
        if (value === 'create')
            setVisibleCreate(true)
        else {
            setIdData(value)
            setVisibleEdit(true)
        }
    }, [])

    const handleOk = useCallback((e) => {
        setVisibleCreate(false)
    }, [])

    const handleCancel = useCallback((value) => {
        if (value === 'create') {
            setVisibleCreate(false)
        }
        else {
            setVisibleEdit(false)
        }
    }, [])

    const detailRearing = useCallback((value, tanggal, age) => {
        getIdRearingRecord(value)
        getDateRearingRecord(tanggal)
        getAgeRearingRecord(age)
        props.navigate(CONSTANTS.DETAIL_REARING_RECORD_MENU_KEY)
    }, [props, getIdRearingRecord, getDateRearingRecord, getAgeRearingRecord])

    const rearingObject = (object) => {
        let rearing = []
        let cumFeed = 0
        let cumOvk = 0
        let cumMaterial = 0
        let totalBiaya = 0
        let total = 0
        let totalBiayaStok = 0
        let totalStok = 0
        for (let i = 0; i < object.length; i++) {
            cumFeed += object[i].hppPakan
            cumOvk += object[i].ovkTotal
            cumMaterial += object[i].hppMaterial
            totalBiaya = object[i].totalDoc + cumFeed + cumOvk + cumMaterial + object[i].bopKandang
            totalBiayaStok = object[i].totalDoc + cumFeed + cumOvk + cumMaterial
            total = + totalBiaya
            totalStok = + totalBiayaStok
            rearing.push({
                umur: object[i].umur,
                _id: object[i]._id,
                tanggal: object[i].tanggal,

                culling: object[i].culling,
                mati: object[i].mati,
                panen: object[i].panen,
                deplesiPerHari: object[i].deplesiPerHari,
                persenDeplesiHarian: parseFloat(object[i].persenDeplesiHarian.toFixed(2)),
                persenKematian: object[i].persenKematian,
                cumDeplesi: object[i].cumDeplesi,
                standardDp: parseFloat((object[i].standardDp)?.toFixed(2)),
                ketDeplesi: object[i].ketDeplesi,
                total: object[i].total,
                totalBeratPanen: object[i].totalBeratPanen,

                cumBeratPanen: object[i].cumBeratPanen,

                saldo: object[i].saldo,
                panenCum: object[i].cumPanen,

                bw: object[i].bw,
                standardBw: object[i].standardBw,
                abw: object[i].abw,
                adg: i === 0 ? object[i].adg : object[i].bw - object[i - 1].bw,
                totalBerat: object[i].totalBerat,

                pakanPerHari: object[i].pakanPerHari,
                pakanTotal: object[i].pakanTotal,
                feedIntake: object[i].feedIntake,
                standardFi: object[i].standardFi,
                standardFiCum: object[i].standardFiCum,
                feedIntakeCum: object[i].fiCum,
                devFi: object[i].devFi,

                fcr: object[i].cumFcr,
                standardFcr: object[i].standardFcr,

                totalHppPakan: cumFeed,
                totalHppOvk: cumOvk,
                totalHppMaterial: cumMaterial,
                totalDoc: object[i].totalDoc,
                totalHpp: parseFloat(total.toFixed(0)),
                bopKandang: parseFloat((object[i].bopKandang).toFixed(0)),

                ovkTotal: object[i].ovkTotal,
                indeksPerformance: object[i].indeksPerformance,
                hpp: object[i].hpp,

                hppPerEkor: parseFloat((total / (object[i].saldo + object[i].cumPanen)).toFixed(0)),
                hppPerKg: parseFloat(( total / (object[i].cumBeratPanen + (object[i].saldo  * (object[i].bw / 1000)))).toFixed(0)),
                hppStok: parseFloat((totalStok / (object[i].saldo + object[i].cumPanen)).toFixed(0)),
            })
        }
        setRearing(rearing)
    }
    const data = rearing.map(({
        _id, umur, tanggal, bw, culling, panen, panenCum, cumDeplesi, fcr, hppPerEkor, ketDeplesi, devFi,
        mati, standardDp, standardBw, standardFcr, feedIntakeCum, totalBeratPanen, cumBeratPanen,
        standardFiCum, standardFi, persenDeplesiHarian, pakanPerHari, pakanTotal, totalBerat,
        abw, adg, persenKematian, saldo, deplesiPerHari, total, feedIntake, indeksPerformance,
        totalHppPakan, totalDoc, totalHppOvk, totalHppMaterial, totalHpp, hppPerKg, bopKandang, hppStok
    }, index) => ({
        key: _id,
        id: _id,
        umur: umur,
        tanggal: moment(new Date(parseInt(tanggal))).format("DD-MM-YYYY"),
        tanggalRearing: moment(new Date(parseInt(tanggal))),
        culling: culling,
        panen: <NumberFormat value={panen} displayType={'text'} thousandSeparator={true} />,
        mati: mati,
        jumlahDeplesi: deplesiPerHari,
        cumDeplesi: cumDeplesi,
        persenPerHari: persenDeplesiHarian,
        persenKumulatif: persenKematian?.toFixed(2),
        kumulatifDeplesi: <NumberFormat value={total?.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
        stdDeplesi: <NumberFormat value={standardDp.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
        ketDeplesi: <Tag color={ketDeplesi === 'Aman' ? "#027d17" : "#ff5100"}>{ketDeplesi}</Tag>,
        totalBeratPanen: <NumberFormat value={totalBeratPanen} displayType={'text'} thousandSeparator={true} />,
        kumPanen: <NumberFormat value={panenCum.toFixed(0)} displayType={'text'} thousandSeparator={true} />,
        kumBeratPanen: <NumberFormat value={cumBeratPanen.toFixed(1)} displayType={'text'} thousandSeparator={true} />,
        sisa: <NumberFormat value={saldo} displayType={'text'} thousandSeparator={true} />,
        sisaAyam: <NumberFormat value={saldo} displayType={'text'} thousandSeparator={true} />,
        abw: <NumberFormat value={abw} displayType={'text'} thousandSeparator={true} />,
        adg: <NumberFormat value={adg} displayType={'text'} thousandSeparator={true} />,
        totalBerat: <NumberFormat value={totalBerat} displayType={'text'} thousandSeparator={true} />,
        bw: <NumberFormat value={bw} displayType={'text'} thousandSeparator={true} />,
        stdBw: <NumberFormat value={standardBw} displayType={'text'} thousandSeparator={true} />,
        deviasiBw: isNaN((bw - standardBw) * 100 / standardBw) ? 0 : ((bw - standardBw) * 100 / standardBw).toFixed(1),
        pakan: <NumberFormat value={pakanPerHari} displayType={'text'} thousandSeparator={true} />,
        kumulatifPakan: <NumberFormat value={pakanTotal} displayType={'text'} thousandSeparator={true} />,
        fi: <NumberFormat value={feedIntake} displayType={'text'} thousandSeparator={true} />,
        stdfi: <NumberFormat value={standardFi} displayType={'text'} thousandSeparator={true} />,
        ketFi: feedIntake < standardFi ? <Tag color="#ff5100">Warning</Tag> : <Tag color="#027d17">Aman</Tag>,
        stdfiCum: <NumberFormat value={standardFiCum} displayType={'text'} thousandSeparator={true} />,
        ficum: <NumberFormat value={feedIntakeCum} displayType={'text'} thousandSeparator={true} />,
        deviasi: <NumberFormat value={devFi?.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
        fcr: <NumberFormat value={fcr?.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
        stdfcr: <NumberFormat value={standardFcr} displayType={'text'} thousandSeparator={true} />,
        indeksPerformance: <NumberFormat value={indeksPerformance} displayType={'text'} thousandSeparator={true} />,
        hppPakan: <NumberFormat value={totalHppPakan} displayType={'text'} thousandSeparator={true} />,
        hppOvk: <NumberFormat value={totalHppOvk} displayType={'text'} thousandSeparator={true} />,
        hppMaterial: <NumberFormat value={totalHppMaterial} displayType={'text'} thousandSeparator={true} />,
        doc: <NumberFormat value={totalDoc} displayType={'text'} thousandSeparator={true} />,
        bopKandang: <NumberFormat value={bopKandang} displayType={'text'} thousandSeparator={true} />,
        hppTotal: <NumberFormat value={totalHpp} displayType={'text'} thousandSeparator={true} />,
        hppPerEkor: <NumberFormat value={hppPerEkor} displayType={'text'} thousandSeparator={true} />,
        hpp: <NumberFormat value={hppPerKg} displayType={'text'} thousandSeparator={true} />,
        hppStok: <NumberFormat value={hppStok} displayType={'text'} thousandSeparator={true} />,
    }))

    const columns = [
        location.pathname === '/peternak/recording' ? {
            title: `${t("pages.recording.table.pilihan")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            fixed: 'left',
            align: 'left',
            width: 120,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.recording.button.detail")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }} onClick={() => detailRearing(row.key, row.tanggalRearing, row.umur)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.recording.button.hapus")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            ),
        } : {},
        {
            title: `${t("pages.recording.table.umur")}`,
            dataIndex: 'umur',
            key: 'umur',
            className: 'center',
            fixed: 'left',
            width: 50,
        },
        {
            title: `${t("pages.recording.table.tanggal")}`,
            dataIndex: 'tanggal',
            className: 'center',
            width: 110,
            key: 'tanggal',
        },
        {
            title: `${t("pages.recording.table.pakan.title")}`,
            className: 'pakan',
            children: [
                {
                    title: `${t("pages.recording.table.pakan.pakan/hari")}`,
                    dataIndex: 'pakan',
                    key: 'pakan',
                    className: 'center-pakan',
                    width: 80,
                },
                {
                    title: 'CUM',
                    dataIndex: 'kumulatifPakan',
                    key: 'kumulatifPakan',
                    className: 'center-pakan',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.pakan.fiAct")}`,
                    dataIndex: 'fi',
                    key: 'fi',
                    className: 'center-pakan',
                    width: 85,
                },
                {
                    title: `${t("pages.recording.table.pakan.fiStd")}`,
                    dataIndex: 'stdfi',
                    key: 'stdfi',
                    className: 'center-pakan',
                    width: 85,
                },
                {
                    title: `${t("pages.recording.table.pakan.keterangan")}`,
                    dataIndex: 'ketFi',
                    key: 'ketFi',
                    className: 'center-pakan',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.pakan.actCum")}`,
                    dataIndex: 'ficum',
                    key: 'ficum',
                    className: 'center-pakan',
                    width: 85,
                },
                {
                    title: `${t("pages.recording.table.pakan.stdFiCum")}`,
                    dataIndex: 'stdfiCum',
                    key: 'stdfiCum',
                    className: 'center-pakan',
                    width: 90,
                },
                {
                    title: `${t("pages.recording.table.pakan.deviasi")}`,
                    dataIndex: 'deviasi',
                    key: 'deviasi',
                    className: 'center-pakan',
                    width: 70,
                },
            ],
        },
        {
            title: `${t("pages.recording.table.deplesi.title")}`,
            className: 'deplesi',
            children: [
                {
                    title: `${t("pages.recording.table.deplesi.culling")}`,
                    dataIndex: 'culling',
                    key: 'culling',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.deplesi.mati")}`,
                    dataIndex: 'mati',
                    key: 'mati',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.deplesi.jumlah")}`,
                    dataIndex: 'jumlahDeplesi',
                    key: 'jumlahDeplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.deplesi.cum")}`,
                    dataIndex: 'cumDeplesi',
                    key: 'cumDeplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.deplesi.persenHari")}`,
                    dataIndex: 'persenPerHari',
                    key: 'persenPerHari',
                    className: 'center-deplesi',
                    width: 60,
                },
                {
                    title: `${t("pages.recording.table.deplesi.persenCum")}`,
                    dataIndex: 'persenKumulatif',
                    key: 'persenKumulatif',
                    className: 'center-deplesi',
                    width: 60,
                },
                {
                    title: `${t("pages.recording.table.deplesi.std")}`,
                    dataIndex: 'stdDeplesi',
                    key: 'stdDeplesi',
                    className: 'center-deplesi',
                    width: 50,
                },
                {
                    title: `${t("pages.recording.table.deplesi.keterangan")}`,
                    dataIndex: 'ketDeplesi',
                    key: 'ketDeplesi',
                    className: 'center-deplesi',
                    width: 70,
                },
            ],
        },
        {
            title: `${t("pages.recording.table.panen.title")}`,
            className: 'panen',
            children: [
                {
                    title: `${t("pages.recording.table.panen.total")}`,
                    dataIndex: 'panen',
                    key: 'panen',
                    className: 'center-panen',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.panen.berat")}`,
                    dataIndex: 'totalBeratPanen',
                    key: 'totalBeratPanen',
                    className: 'center-panen',
                    width: 80,
                },
                {
                    title: `CUM (ekor)`,
                    dataIndex: 'kumPanen',
                    key: 'kumPanen',
                    className: 'center-panen',
                    width: 80,
                },
                {
                    title: `CUM  (kg)`,
                    dataIndex: 'kumBeratPanen',
                    key: 'kumBeratPanen',
                    className: 'center-panen',
                    width: 80,
                },
                {
                    title: `${t("pages.recording.table.panen.sisaAyam")}`,
                    dataIndex: 'sisa',
                    key: 'sisa',
                    className: 'center-panen',
                    width: 120,
                },
            ],
        },
        {
            title: 'Bw',
            className: 'bw',
            children: [
                {
                    title: `${t("pages.recording.table.bw.sampling")}`,
                    dataIndex: 'bw',
                    key: 'bw',
                    className: 'center-bw',
                    width: 90,
                },
                {
                    title: `${t("pages.recording.table.bw.std")}`,
                    dataIndex: 'stdBw',
                    key: 'stdBw',
                    className: 'center-bw',
                    width: 85,
                },
                {
                    title: `${t("pages.recording.table.bw.deviasi")}`,
                    dataIndex: 'deviasiBw',
                    key: 'deviasiBw',
                    className: 'center-bw',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.bw.adg")}`,
                    dataIndex: 'adg',
                    key: 'adg',
                    className: 'center-bw',
                    width: 60,
                },
                {
                    title: `${t("pages.recording.table.bw.totalBerat")}`,
                    dataIndex: 'totalBerat',
                    key: 'totalBerat',
                    className: 'center-bw',
                    width: 90,
                },
                {
                    title: `${t("pages.recording.table.bw.abw")}`,
                    dataIndex: 'abw',
                    key: 'abw',
                    className: 'center-bw',
                    width: 60,
                },
            ],
        },
        {
            title: 'Fcr',
            className: 'fcr',
            children: [
                {
                    title: `${t("pages.recording.table.fcr.actCum")}`,
                    dataIndex: 'fcr',
                    key: 'fcr',
                    className: 'center-fcr',
                    width: 70,
                },
                {
                    title: `${t("pages.recording.table.fcr.std")}`,
                    dataIndex: 'stdfcr',
                    key: 'stdfcr',
                    className: 'center-fcr',
                    width: 60,
                },
            ],
        },
        {
            title: 'IP',
            dataIndex: 'indeksPerformance',
            key: 'indeksPerformance',
            className: 'center',
            width: 100,
        },
        {
            title: 'DOC (Rp)',
            dataIndex: 'doc',
            key: 'doc',
            className: 'center',
            width: 140,
        },
        {
            title: `${t("pages.recording.table.biaya.pakan")}`,
            dataIndex: 'hppPakan',
            key: 'hppPakan',
            className: 'center',
            width: 140,
        },
        {
            title: `${t("pages.recording.table.biaya.ovk")}`,
            dataIndex: 'hppOvk',
            key: 'hppOvk',
            className: 'center',
            width: 140,
        },
        {
            title: `${t("pages.recording.table.biaya.material")}`,
            dataIndex: 'hppMaterial',
            key: 'hppMaterial',
            className: 'center',
            width: 140,
        },
        {
            title: 'Overhead (Rp)',
            dataIndex: 'bopKandang',
            key: 'bopKandang',
            className: 'center',
            width: 140,
        },
        {
            title: `${t("pages.recording.table.biaya.hpp")}`,
            dataIndex: 'hppTotal',
            key: 'hppTotal',
            className: 'center',
            width: 140,
        },
        {
            title: 'Hpp/Ekor',
            dataIndex: 'hppPerEkor',
            key: 'hppPerEkor',
            className: 'center',
            width: 140,
        },
        {
            title: 'Hpp Stok/Ekor',
            dataIndex: 'hppStok',
            key: 'hppStok',
            className: 'center',
            width: 140,
        },
        {
            title: 'Hpp/Kg (Rp)',
            dataIndex: 'hpp',
            key: 'hpp',
            className: 'center',
            width: 140,
        },
    ]

    return (
        <RearingRecordComponent
            navigate={props.navigate}
            showModal={showModal}
            handleOk={handleOk}
            handleCancel={handleCancel}
            columns={columns}
            idData={idData}
            visibleCreate={visibleCreate}
            visibleEdit={visibleEdit}
            data={data}
            loading={loading}
            periode={periode} house={house}
            fetchData={fetchData}
            setLoading={setLoading}
            locales={t} location={location}
        />
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdRearingRecord,
    getDateRearingRecord,
    getAgeRearingRecord
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RearingRecordPage)
export default page