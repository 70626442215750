import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import * as actionType from './pakan-action-type';

const startGetDataPakans = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataPakans = () => ({
    type : actionType.FINISH_GET_DATA
})

const setDataPakan = payload => ({
    type : actionType.SET_DATA_PAKAN,
    payload,
});

export const getListPakan = (params) => (dispatch) => {
    dispatch(startGetDataPakans());
    API.get(params)
    .then(res => {
        console.log(res)
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.feeds.feeds){
            dispatch(setDataPakan(res.data.data.feeds.feeds))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataPakans());
    })
}