import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import ModalCreateKandang from '../../../../app/dashboard-peternak/master-page/kandang-page/kandang-aksi-page/create-kandang-page'
import ModalEditKandang from '../../../../app/dashboard-peternak/master-page/kandang-page/kandang-aksi-page/edit-kandang-page'

function KandangComponent(props) {
    const { Content } = Layout;
    const { columns, data, handleOk,
        handleCancel, visible, idData, fetchData, loading, detail,
        handleSubmit, setLoading, keyword, limit, skip, t } = props

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.kandang.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateKandang fetchData={fetchData} setLoading={setLoading}
                            keyword={keyword} limit={limit} skip={skip} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                className="table-dashboard"
                                scroll={{ y: '70vh' }}
                            />
                        </Loading>
                    </Col>
                    <ModalEditKandang visible={visible} handleOk={handleOk} idData={idData}
                        handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    );
}

export default KandangComponent;