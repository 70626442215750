import * as actionType from './stok-material-action-type';

const initialState = {
    idStokMaterial : null,
    loading : false,
    dataStokMaterial : [],
    namaMaterial : null,
}

const handler = (currentState) => {
    const setDataStokMaterial = {
        startGetDataStokMaterials : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataStokMaterials : () => ({
            ...currentState,
            loading: false,
        }),

        setIdStokMaterial : data => ({
            ...currentState,
            idStokMaterial: data
        }),

        setDataStokMaterial : data => ({
            ...currentState,
            dataStokMaterial : data
        }),

        setDataNamaMaterial : data => ({
            ...currentState,
            namaMaterial : data
        }),
    }

    return {
        ...setDataStokMaterial
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataStokMaterials();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataStokMaterials();
        case actionType.SET_ID_STOK_MATERIAL:
            return handler(state).setIdStokMaterial(payload);
        case actionType.SET_STOK_MATERIAL:
            return handler(state).setDataStokMaterial(payload);
        case actionType.SET_NAMA_MATERIAL:
            return handler(state).setDataNamaMaterial(payload);

        default:
            return state;
    }
}