import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import ModalCreateStokPakan from '../../../../app/dashboard-peternak/stok-page/stok-pakan-page/stok-pakan-aksi-page/create-stok-pakan-page'
import ModalTambahStokPakan from '../../../../app/dashboard-peternak/stok-page/stok-pakan-page/stok-pakan-aksi-page/tambah-stok-pakan-page'
import ModalEditStokPakan from '../../../../app/dashboard-peternak/stok-page/stok-pakan-page/stok-pakan-aksi-page/edit-stok-pakan-page'

function StokPakanComponent(props) {
    const { Content } = Layout
    const { columns, data, handleSubmit, handleCancel, fetchData, visible, loading,
        handleCancelEdit, handleSubmitEdit, visibleEdit, detail, setLoading, locales } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{locales("pages.stok.pakan.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <ModalCreateStokPakan fetchData={fetchData} setLoading={setLoading} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{y:'70vh'}}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalTambahStokPakan visible={visible} handleSubmit={handleSubmit}
                        handleCancel={handleCancel} locales={locales} />
                    <ModalEditStokPakan visibleEdit={visibleEdit} handleSubmit={handleSubmitEdit}
                        handleCancel={handleCancelEdit} detail={detail} locales={locales} />
                </Row>
            </Content>
        </Layout>
    )
}

export default StokPakanComponent