import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import EditPertumbuhanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/edit-detail-rearing-component/edit-pertumbuhan-component';

import { useTranslation } from "react-i18next";

function EditPertumbuhanPage(props) {
    const {visible,handleCancel,detail,handleSubmit} = props;
    const [data,setData] = useState(detail)
    const { t } = useTranslation();

    useEffect(() => {
        function setDetailData() {
            if(detail === ""){
                setData("")
            }
            else{
                setData(detail)
            }
        }   
        setDetailData();
    }, [detail]);

    return ( 
        <EditPertumbuhanComponent
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            locales = {t}
            data = {data}
            growingParam = {props.growingParam}
        />
    );
}

const mapStateToProps = state => ({
    growingParam : state.rearingRecord.dataGrowingParams,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditPertumbuhanPage);
export default page