import React from 'react'
import { Layout, Row, Col, Table, Typography } from 'antd'
import NumberFormat from 'react-number-format'
import '../../../../assets/scss/dashboard-peternak/periode-pemeliharaan.scss'
import Loading from '../../../../common/component/loading/loading-container'

function TableRearing(props) {
    const { columns, data, loading } = props
    const { Text } = Typography

    return (
        <Layout style={{ backgroundColor: "white" }}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={data.length === 0 || loading === true ? true : false}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={false}
                            scroll={{ y: '40vh' }}
                            summary={pageData => {
                                let totalSisa = 0
                                pageData.forEach(({
                                    sisa
                                }) => {
                                    totalSisa += parseFloat(sisa)
                                })
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell>
                                                <Text className='float-right'><NumberFormat value={totalSisa} displayType={'text'} thousandSeparator={true} /></Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell colSpan={7}></Table.Summary.Cell>

                                        </Table.Summary.Row>
                                    </>
                                )
                            }}
                            className="table-dashboard"
                        />
                    </Loading>
                </Col>
            </Row>
        </Layout>
    );
}

export default TableRearing;