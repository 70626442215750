import * as actionType from './layout-action-type';

const initialState = {
    dataKandang: [],
    dataPeriode: [],
    idPeriode: null,
    idRearing: null,
    idKandang: null,
    kandang: null,
    periode: null,
    chickInDate: null,
    datePeriode: null,
    totalKandang: null,
    isTourOpen: false,
    loading: false,
    locales: 'id',
    idDevice: null,
    deviceType: null,
}

const handler = (currentState) => {
    const setDataLayout = {
        startGetDataLayouts: () => ({
            ...currentState,
            loading: true,
        }),

        finishGetDataLayouts: () => ({
            ...currentState,
            loading: false,
        }),

        getDataKandangs: data => ({
            ...currentState,
            dataKandang: data
        }),

        getDataPeriode: data => ({
            ...currentState,
            dataPeriode: data
        }),

        setIdPeriode: data => ({
            ...currentState,
            idPeriode: data
        }),

        setIdRearing: data => ({
            ...currentState,
            idRearing: data
        }),

        setIdKandang: data => ({
            ...currentState,
            idKandang: data
        }),

        setKandang: data => ({
            ...currentState,
            kandang: data
        }),

        setPeriode: data => ({
            ...currentState,
            periode: data
        }),

        setChickInDate: data => ({
            ...currentState,
            chickInDate: data
        }),

        setDatePeriode: data => ({
            ...currentState,
            datePeriode: data
        }),

        setTotalKandang: data => ({
            ...currentState,
            totalKandang: data
        }),

        setIsTourOpen: data => ({
            ...currentState,
            isTourOpen: data
        }),

        setLocales: data => ({
            ...currentState,
            locales: data
        }),

        setIdDevice: data => ({
            ...currentState,
            idDevice: data
        }),
        setDeviceType: data => ({
            ...currentState,
            deviceType: data
        }),
    }

    return {
        ...setDataLayout
    }
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataLayouts()
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataLayouts()
        case actionType.SET_DATA_KANDANG:
            return handler(state).getDataKandangs(payload)
        case actionType.SET_DATA_PERIODE:
            return handler(state).getDataPeriode(payload)
        case actionType.SET_ID_PERIODE:
            return handler(state).setIdPeriode(payload)
        case actionType.SET_ID_REARING:
            return handler(state).setIdRearing(payload)
        case actionType.SET_ID_KANDANG:
            return handler(state).setIdKandang(payload)
        case actionType.SET_KANDANG:
            return handler(state).setKandang(payload)
        case actionType.SET_PERIODE:
            return handler(state).setPeriode(payload)
        case actionType.SET_CHICKINDATE:
            return handler(state).setChickInDate(payload)
        case actionType.SET_DATE_PERIODE:
            return handler(state).setDatePeriode(payload)
        case actionType.SET_TOTAL_KANDANG:
            return handler(state).setTotalKandang(payload)
        case actionType.SET_IS_TOUR:
            return handler(state).setIsTourOpen(payload)
        case actionType.SET_LOCALES:
            return handler(state).setLocales(payload)
        case actionType.SET_ID_DEVICE:
            return handler(state).setIdDevice(payload)
        case actionType.SET_DEVICE_TYPE:
            return handler(state).setDeviceType(payload)
        default:
            return state
    }
}