export const getStandarEnvironment = (keyword,limit,skip) => {
    return (`
        query{
            standardEnvironments(keyword:"${keyword}",limit:${limit},skip:${skip}){
              totalCount,
              standardEnvironments{
                  _id
                  day
                  temperature
                  humidity
                  ammonia
              }
            }
        }
    `)
}

export const createStandarEnvironment = (value,strain) => {
    return {  
        query : 
        `mutation{
            createStandarEnvironment(standarEnvironmentInput:{
              day: ${value.day}
              temperature :  ${parseInt(value.temperature)}
              humidity :  ${parseInt(value.humidity)}
              ammonia :  ${parseInt(value.ammonia)}
            }){
              _id
            }
          }
          `
    }
}

export const detailStandarEnvironment = (_id) => {
    return (`
    query{
        standardEnvironment(_id:"${_id}"){
          _id
          day
          temperature
          humidity
          ammonia
        }
      }
    `)
}

export const updateStandarEnvironment = (_id,value) => {
    return {
        query : `
        mutation{
            updateStandarEnvironment(_id: "${_id}",updateStandarEnvironmentInput:{
              day: ${value.day}
              temperature :  ${parseInt(value.temperature)}
              humidity :  ${parseInt(value.humidity)}
              ammonia :  ${parseInt(value.ammonia)}
            }){
              _id
            }
          }
          `
    }
}

export const deleteStandarEnvironment = (_id) => {
    return (`
        mutation{
            deleteStandarEnvironment(_id:"${_id}"){
            deleted
            }
        }
    `)
}