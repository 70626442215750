import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreateStandarSuhuComponent from '../../../../../modules/dashboard-peternak/master-component/standar-lingkungan-component/standar-lingkungan-aksi-component/create-standar-lingkungan-component'
import { createStandarEnvironment } from './query-standar-lingkungan-page'

function CreateStandardLingkungan(props) {
    const { fetchData, setLoading, t } = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createStandarEnvironment(values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createStandarEnvironment._id) {
                    validationMessage('success', `${t('pages.master.lingkungan.response.sukses.tambah')}`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.master.lingkungan.response.gagal.tambah')}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateStandarSuhuComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStandardLingkungan)
export default page