import * as actionType from './gudang-material-action-type';

const initialState = {
    dataGudangMaterial: [],
    idGudangMaterial : null,
    loading : false,
}

const handler = (currentState) => {
    const setDataGudangMaterial = {
        startGetDataGudangMaterials : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetDataGudangMaterials : () => ({
            ...currentState,
            loading: false,
        }),

        getDataGudangMaterials : data => ({
            ...currentState,
            dataGudangMaterial: data
        }),

        setIdGudangMaterial : data => ({
            ...currentState,
            idGudangMaterial : data
        }),
    }

    return {
        ...setDataGudangMaterial
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetDataGudangMaterials();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataGudangMaterials();
        case actionType.SET_DATA_GUDANG_MATERIAL:
            return handler(state).getDataGudangMaterials(payload);
        case actionType.SET_ID_GUDANG_MATERIAL:
            return handler(state).setIdGudangMaterial(payload);

        default:
            return state;
    }
}