/**
 * @author spindyzel
 * @since 28 Desember 2019
*/

const CONSTANTS = {
    COOKIE_TOKEN_KEY: 'AUTHENTICATION-KEY',
    ORIGINAL_TOKEN_KEY: 'AUTHORIZATION-KEY',
    HOME_MENU_KEY: 'HOME',
    DASHBOARD_PETERNAK_MENU_KEY : 'DASHBOARD_PETERNAK',
    DETAIL_REARING_KEY : 'DETAIL_REARING',
    DETAIL_REARING_CLOSE_MENU_KEY : 'DETAIL_REARING_CLOSE',
    REARING_RECORD_MENU_KEY : 'REARING_RECORD',
    DETAIL_REARING_RECORD_MENU_KEY : 'DETAIL_REARING_RECORD',
    DETAIL_PERIODE_PAKAN_KEY : 'DETAIL_PERIODE_PAKAN',
    DETAIL_PERIODE_OVK_KEY : 'DETAIL_PERIODE_OVK',
    DETAIL_PERIODE_MATERIAL_KEY : 'DETAIL_PERIODE_MATERIAL',
    DETAIL_PERIODE_LIVEBIRD_KEY : 'DETAIL_PERIODE_LIVEBIRD',
    TRANSAKSI_STOK_PAKAN_MENU_KEY : 'TRANSAKSI_STOK_PAKAN',
    TRANSAKSI_STOK_OVK_MENU_KEY : 'TRANSAKSI_STOK_OVK',
    TRANSAKSI_STOK_MATERIAL_MENU_KEY : 'TRANSAKSI_STOK_MATERIAL',
    DETAIL_DEVICE_RECORD_MENU_KEY : 'DETAIL_DEVICE_RECORD',
    DETAIL_DEVICE_KEY : 'DETAIL_DEVICE',
    STANDAR_ENV_DETAIL_KEY: 'STANDAR_ENV_DETAIL',
    STANDAR_STRAIN_MENU_KEY : 'STANDAR_STRAIN',
    CREATE_STOK_OVK_MENU_KEY : 'CREATE_STOK_OVK',
    CREATE_MASTER_OVK_MENU_KEY : 'CREATE_MASTER_OVK',
    EDIT_MASTER_OVK_MENU_KEY : 'EDIT_MASTER_OVK',
    MASTER_OVK_MENU_KEY : 'MASTER_OVK',
    ADJUSMENT_FEED_MENU_KEY : 'ADJUSMENT_FEED',
    ADJUSTMENT_OVK_MENU_KEY : 'ADJUSTMENT_OVK',
    ADJUSTMENT_MATERIAL_MENU_KEY : 'ADJUSTMENT_MATERIAL',
    STOK_OVK_MENU_KEY : 'STOK_OVK',
    CREATE_PEMAKAIAN_UMUM_MENU_KEY : 'CREATE_PEMAKAIAN_UMUM',
    CREATE_PENYAKIT_RECORDING_KEY : 'CREATE_PENYAKIT_RECORDING',
    EDIT_PENYAKIT_RECORDING_MENU_KEY : 'EDIT_PENYAKIT_RECORDING',
    FOTO_PENYAKIT_RECORDING_MENU_KEY : 'FOTO_PENYAKIT_RECORDING',
    RECORDING_MENU_KEY : 'RECORDING',
    PEMAKAIAN_UMUM_MENU_KEY : 'PEMAKAIAN_UMUM',
    STANDAR_SUHU_MENU_KEY : 'STANDAR_SUHU',
    PROFILE_MENU_KEY : 'PROFILE',
    EXAMPLE_MENU_KEY: 'EXAMPLE',
    CHILD_MENU_KEY: 'CHILD',
    DASHBOARD_MENU_KEY: 'DASHBOARD',
    LIST_MENU_KEY: 'LIST',
}

export default CONSTANTS