import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import * as actionType from './gudang-pakan-action-type';

const startGetDataGudangPakans = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataGudangPakans = () => ({
    type : actionType.FINISH_GET_DATA
})

const setDataGudangPakan = payload => ({
    type : actionType.SET_DATA_GUDANG_PAKAN,
    payload,
});

const setIdGudangPakan = payload => ({
    type : actionType.SET_ID_GUDANG_PAKAN,
    payload,
});

export const getIdGudangPakan = (id_gudang) => (dispatch) => {
    dispatch(setIdGudangPakan(id_gudang))
}

export const getListGudangPakan = (params) => (dispatch) => {
    dispatch(startGetDataGudangPakans());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.feedWarehouses.feedWarehouses){
            dispatch(setDataGudangPakan(res.data.data.feedWarehouses.feedWarehouses))
            dispatch(getIdGudangPakan(res.data.data.feedWarehouses.feedWarehouses[0]._id))
        } else {
            validationMessage('error', 'Data Error')
        }
        dispatch(finishGetDataGudangPakans());
    })
}