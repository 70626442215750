import { Menu } from 'antd'
import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { API } from '../../../../common/api'
import DetailRearingRecordComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-rearing-record-component'

import CreateDeplesi from '../create-detail-rearing-page/create-deplesi-page'
import CreatePanen from '../create-detail-rearing-page/create-panen-page'
import CreateOvk from '../create-detail-rearing-page/create-ovk-page'
import CreatePakan from '../create-detail-rearing-page/create-pakan-page'
import CreatePertumbuhan from '../create-detail-rearing-page/create-pertumbuhan-page'
import CreateMaterial from '../create-detail-rearing-page/create-material-page'
import CreateOverhead from '../create-detail-rearing-page/create-overhead-page'

import { getRecordDeplesi } from '../rearing-record-aksi-page/query-rearing-record'
import { getRecordOvk } from '../rearing-record-aksi-page/query-rearing-record'
import { getRecordPakan } from '../rearing-record-aksi-page/query-rearing-record'
import { getRecordPanen } from '../rearing-record-aksi-page/query-rearing-record'
import { getRecordPertumbuhan } from '../rearing-record-aksi-page/query-rearing-record'
import { getRecordMaterial } from '../rearing-record-aksi-page/query-rearing-record'
import { getRecordPenyakit } from '../rearing-record-aksi-page/query-rearing-record'
import { getRecordOverhead } from '../rearing-record-aksi-page/query-rearing-record'
import { getOverhead } from '../../master-page/overhead-page/overhead-aksi-page/query-overhead'
import { getMonthlyStockFeeds } from '../../stock-flow-page/pakan-page/pakan-aksi-page/query-stock-flow-pakan'
import { getMonthlyStockOvks } from '../../stock-flow-page/ovk-page/ovk-aksi-page/query-stock-flow-ovk'
import { getMonthlyStockMaterials } from '../../stock-flow-page/material-page/material-aksi-page/query-stock-flow-material'
import CONSTANTS from '../../../../common/utils/Constants'

import moment from 'moment'
import { useTranslation } from "react-i18next"

function DetailRearingRecordPage(props) {
    const { idRearingRecord, dateRearing } = props
    const [activeKey, setActiveKey] = useState("1")
    const [loading, setLoading] = useState(false)
    const [deplesi, setDeplesi] = useState([])
    const [ovk, setOvk] = useState([])
    const [pakan, setPakan] = useState([])
    const [panen, setPanen] = useState([])
    const [pertumbuhan, setPertumbuhan] = useState([])
    const [material, setMaterial] = useState([])
    const [penyakit, setPenyakit] = useState([])
    const [overhead, setOverhead] = useState([])
    const [overheading, setOverheading] = useState([])
    const [feed, setFeed] = useState([])
    const [monthlyOvk, setMonthlyOvk] = useState([])
    const [monthlyMaterial, setMonthlyMaterial] = useState([])
    const { t } = useTranslation()

    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    const handleButtonClick = useCallback((value) => { }, [])
    const handleMenuClick = useCallback((value) => { }, [])

    const fetchDataDeplesi = (idRearingRecord) => {
        setLoading(true);
        API.get(getRecordDeplesi('', 0, 0, idRearingRecord))
            .then(res => {
                setDeplesi(res.data.data.mutations.mutations)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setDeplesi([])
                setLoading(false)
            })
    }

    const fetchDataOvk = (idRearingRecord) => {
        setLoading(true)
        API.get(getRecordOvk('', 0, 0, idRearingRecord))
            .then(res => {
                setOvk(res.data.data.ovkTransactionsRecord.ovkTransactions)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setOvk([])
                setLoading(false)
            })
    }
    const fetchMonthlyOvk = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockOvks('', 0, 0, month, year))
            .then(res => {
                setMonthlyOvk(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setMonthlyOvk([])
                setLoading(false)
            })
    }

    const fetchDataPakan = (idRearingRecord) => {
        setLoading(true)
        API.get(getRecordPakan('', 0, 0, idRearingRecord))
            .then(res => {
                setPakan(res.data.data.feedingRecords.feedings)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setPakan([])
                setLoading(false)
            })
    }
    const fetchMonthlyFeed = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockFeeds('', 0, 0, month, year))
            .then(res => {
                setFeed(res.data.data.monthlyStockFeeds.calculateMonthlyStocks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setFeed([])
                setLoading(false)
            })
    }

    const fetchDataPanen = (idRearingRecord) => {
        setLoading(true);
        API.get(getRecordPanen('', 0, 0, idRearingRecord))
            .then(res => {
                setPanen(res.data.data.harvests.mutations)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setPanen([])
                setLoading(false)
            })
    }

    const fetchDataPertumbuhan = (idRearingRecord) => {
        setLoading(true);
        API.get(getRecordPertumbuhan('', 0, 0, idRearingRecord))
            .then(res => {
                setPertumbuhan(res.data.data.growings.growings)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setPertumbuhan([])
                setLoading(false)
            })
    }

    const fetchDataMaterial = (idRearingRecord) => {
        setLoading(true)
        API.get(getRecordMaterial('', 0, 0, idRearingRecord))
            .then(res => {
                setMaterial(res.data.data.materialTransactionsRecord.materialTransactions)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setMaterial([])
                setLoading(false)
            })
    }

    const fetchMonthlyMaterial = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockMaterials('', 0, 0, month, year))
            .then(res => {
                setMonthlyMaterial(res.data.data.monthlyStockMaterials.calculateMonthlyStocks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setMonthlyMaterial([])
                setLoading(false)
            })
    }

    const fetchDataPenyakit = (idRearingRecord) => {
        setLoading(true)
        API.get(getRecordPenyakit('', 0, 0, idRearingRecord))
            .then(res => {
                setPenyakit(res.data.data.diseaseRecords.diseaseRecords)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setPenyakit([])
                setLoading(false)
            })
    }
    const fetchOverhead = () => {
        setLoading(true);
        API.get(getOverhead('', 0, 0))
            .then(res => {
                setOverhead(res.data.data.overheads.overheads)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setOverhead([])
                setLoading(false)
            })
    }
    const fetchDataOverhead = (idRearingRecord) => {
        setLoading(true);
        API.get(getRecordOverhead('', 0, 0, idRearingRecord))
            .then(res => {
                setOverheading(res.data.data.overheadings.overheadings)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setOverheading([])
                setLoading(false)
            })
    }
    useEffect(() => {
        const month = moment(dateRearing).format('MMMM')
        const year = moment(dateRearing).format('YYYY')
        if (activeKey === "1") {
            fetchDataDeplesi(idRearingRecord)
        } else if (activeKey === "5") {
            fetchDataOvk(idRearingRecord)
            fetchMonthlyOvk(month, year)
        } else if (activeKey === "4") {
            fetchDataPakan(idRearingRecord)
            fetchMonthlyFeed(month, year)
        } else if (activeKey === "2") {
            fetchDataPanen(idRearingRecord)
        } else if (activeKey === "3") {
            fetchDataPertumbuhan(idRearingRecord)
        } else if (activeKey === "6") {
            fetchDataMaterial(idRearingRecord)
            fetchMonthlyMaterial(month, year)
        } else if (activeKey === "7") {
            fetchDataPenyakit(idRearingRecord)
        } else if (activeKey === "8") {
            fetchOverhead()
            fetchDataOverhead(idRearingRecord)
        }
        // eslint-disable-next-line
    }, [idRearingRecord, dateRearing, activeKey])

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">
                <CreateDeplesi loading={loading} setLoading={setLoading} fetchDataDeplesi={fetchDataDeplesi} />
            </Menu.Item>
            <Menu.Item key="2">
                <CreatePanen loading={loading} setLoading={setLoading} fetchDataPanen={fetchDataPanen} />
            </Menu.Item>
            <Menu.Item key="3">
                <CreatePertumbuhan loading={loading} setLoading={setLoading} fetchDataPertumbuhan={fetchDataPertumbuhan} />
            </Menu.Item>
            <Menu.Item key="4">
                <CreatePakan loading={loading} setLoading={setLoading} fetchDataPakan={fetchDataPakan} feed={feed} />
            </Menu.Item>
            <Menu.Item key="5">
                <CreateOvk loading={loading} setLoading={setLoading} fetchDataOvk={fetchDataOvk} monthlyOvk={monthlyOvk} />
            </Menu.Item>
            <Menu.Item key="6">
                <CreateMaterial loading={loading} setLoading={setLoading} fetchDataMaterial={fetchDataMaterial} monthlyMaterial={monthlyMaterial} />
            </Menu.Item>
            <Menu.Item key="7">
                <span onClick={() => props.navigate(CONSTANTS.CREATE_PENYAKIT_RECORDING_KEY)}>{t("pages.recording.detail.penyakit.button.tambah")}</span>
            </Menu.Item>
            <Menu.Item key="8">
                <CreateOverhead loading={loading} setLoading={setLoading} overhead={overhead} fetchDataOverhead={fetchDataOverhead} />
            </Menu.Item>
        </Menu>
    )

    return (
        <DetailRearingRecordComponent
            navigate={props.navigate}
            changeKey={changeKey}
            activeKey={activeKey}
            umur={props.age}
            handleButtonClick={handleButtonClick} menu={menu}
            loading={loading} setLoading={setLoading}
            deplesi={deplesi} etchDataDeplesi={fetchDataDeplesi}
            ovk={ovk} fetchDataOvk={fetchDataOvk} monthlyOvk={monthlyOvk}
            pakan={pakan} fetchDataPakan={fetchDataPakan} feed={feed}
            panen={panen} fetchDataPanen={fetchDataPanen}
            pertumbuhan={pertumbuhan} fetchDataPertumbuhan={fetchDataPertumbuhan}
            material={material} fetchDataMaterial={fetchDataMaterial} monthlyMaterial={monthlyMaterial}
            penyakit={penyakit} fetchDataPenyakit={fetchDataPenyakit}
            fetchDataOverhead={fetchDataOverhead} overheading={overheading} overhead={overhead}
            locales={t}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    age: state.rearingRecord.age,
    dateRearing: state.rearingRecord.dateRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailRearingRecordPage)
export default page