import React, { useState, useEffect } from 'react'
import { API } from '../../../../common/api'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import { navigate } from '../../../../common/store/action'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import DetailFotoPenyakitComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-foto-penyakit-component'
import { validationMessage } from '../../../../common/component/notification/notification'
import { getDetailRecordPenyakit } from '../rearing-record-aksi-page/query-rearing-record'
import { useTranslation } from "react-i18next"

function CreatePenyakitPage(props) {
    const { idRearingRecord, idDiseaseRecord } = props
    const [fileList, setFileList] = useState([])
    const [detailFoto, setDetailFoto] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const { confirm } = Modal

    const postData = (_id, fileUpload) => {
        const params = new FormData()
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                params.append(`file[${i}]`, fileUpload[i])
            }
        }
        setLoading(true)
        API.updateRest(`/pic-penyakit/${_id}`, params)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.success === true) {
                        validationMessage('success', `${t("pages.recording.detail.penyakit.response.sukses.tambah")}`)
                        fetchDetailPenyakit(_id)
                        setFileList([])
                        setFileUpload([])
                        setLoading(false)
                    } else {
                        validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.tambah")}`)
                        setLoading(false)
                    }
                } else {
                    validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.tambah")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.tambah")}`)
                setLoading(false)
            })
    }
    const handleSubmit = () => {
        postData(idDiseaseRecord, fileUpload)
    }
    const propsPicture = {
        name: 'file',
        multiple: true,
        onChange(info) {
            if (info.file.status === 'done') {
                let arrayPicture = []
                for (let i = 0; i < info.fileList.length; i++) {
                    arrayPicture.push(info.fileList[i].originFileObj)
                }
                setFileList(info.fileList)
                setFileUpload(arrayPicture)
            } else if (info.file.status === 'error') {
                console.log('err', info.file, info.fileList)
            }
        }
    }
    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }
    const fetchDetailPenyakit = (idDiseaseRecord) => {
        setLoading(true)
        API.get(getDetailRecordPenyakit(idDiseaseRecord))
            .then(res => {
                setDetailFoto(res.data.data.diseaseRecord.pictureDisease)
                setLoading(false)
            }).catch((error) => {
                setDetailFoto([])
                setLoading(false)
            })
    }

    const deleteData = (_id) => {
        setLoading(true)
        API.deleteRest(`/hapus-pic-penyakit/${_id}`)
            .then(res => {
                if (res.data.success === true) {
                    validationMessage('success', `${t("pages.recording.detail.penyakit.response.sukses.hapus")}`)
                    fetchDetailPenyakit(idDiseaseRecord)
                    setFileList([])
                    setFileUpload([])
                    setLoading(false)
                } else {
                    validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.hapus")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.detail.penyakit.response.gagal.hapus")}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.recording.detail.penyakit.textHapus")}`,
            icon: <ExclamationCircleOutlined />,
            onOk: () => { deleteData(_id) },
            onCancel() { console.log('Cancel') }
        })
    }

    useEffect(() => {
        if (idDiseaseRecord) {
            fetchDetailPenyakit(idDiseaseRecord)
        }
        // eslint-disable-next-line
    }, [idDiseaseRecord])

    return (
        <DetailFotoPenyakitComponent
            navigate={props.navigate}
            age={props.age} 
            t={t}
            loading={loading}
            hapusConfirm={hapusConfirm}
            detailFoto={detailFoto}
            idRearingRecord={idRearingRecord}
            dummyRequest={dummyRequest}
            fileList={fileList}
            propsPicture={propsPicture}
            onPreview={onPreview}
            handleSubmit={handleSubmit}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    idDiseaseRecord : state.rearingRecord.idDiseaseRecord,
    penyakit: state.penyakit.dataPenyakit,
    age: state.rearingRecord.age
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePenyakitPage)
export default page