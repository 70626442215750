import * as actionType from './stok-ovk-action-type';
import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';

const startGetDataStokOvks = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataStokOvks = () => ({
    type : actionType.FINISH_GET_DATA
});

const setIdStokOvk = payload => ({
    type : actionType.SET_ID_STOK_OVK,
    payload,
});

const setDataStokOvk = payload => ({
    type : actionType.SET_STOK_OVK,
    payload,
});

const setDataSatuanOvk = payload => ({
    type : actionType.SET_SATUAN_OVK,
    payload
});
const setDataSatuanOvkRecording = payload => ({
    type : actionType.SET_SATUAN_RECORDING_OVK,
    payload
});

const setDataNamaOvk = payload => ({
    type : actionType.SET_NAMA_OVK,
    payload
});


export const getIdStokOvk = (id_stok) => (dispatch) => {
    dispatch(setIdStokOvk(id_stok));
}

export const getNamakOvk = (nama_ovk) => (dispatch) => {
    dispatch(setDataNamaOvk(nama_ovk));
}

export const getSatuanOvk = (satuan) => (dispatch) => {
    dispatch(setDataSatuanOvk(satuan))
}
export const getSatuanRecordingOvk = (satuan) => (dispatch) => {
    dispatch(setDataSatuanOvkRecording(satuan))
}

export const getListOvk = (params) => (dispatch) => {
    dispatch(startGetDataStokOvks());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.ovkStocks.ovkStocks){
            dispatch(setDataStokOvk(res.data.data.ovkStocks.ovkStocks))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataStokOvks());
    })
}