import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Input, Select, Button, Divider } from 'antd'
import NumberFormat from 'react-number-format'

function EditAdjusmentOvk(props) {
    const { Option } = Select
    const { visible, handleSubmit, data, handleCancel, satuan, object, hs, qty, dif, t } = props
    const [form] = Form.useForm()
    const [harga, setHs] = useState(hs)
    const [jum, setQty] = useState(qty)
    const [sel, setDif] = useState(dif)
    const [unit, setUnit] = useState(satuan)
    const [ovk, setOvk] = useState(data.ovk)
    const [jumlah, setNum] = useState(data.qty)
    const [visibleDif, setVisible] = useState(false)

    useEffect(() => {
        function setDetailData() {
            if (data === null) {
                setNum(0)
                setOvk(0)
            }
            else {
                setNum(data.qty)
                setOvk(data.ovk)
            }
            if (qty === null) { setQty(0) } else { setQty(qty) }
            if (hs === null) { setHs(0) } else { setHs(hs) }
            if (dif === null) { setDif(0) } else { setDif(dif) }
            if (satuan=== null) { setUnit('') } else { setUnit(satuan) }
        }
        setDetailData()
    }, [data, qty, hs, dif,satuan])

    const sak = (e, value) => {
        const hsSak = object.filter(d => d?.idOvk === value)[0].hargaPengeluaran
        const qtySak = object.filter(d => d?.idOvk === value)[0].sisaStok
        const unitOvk = object.filter(d => d?.idOvk === value)[0].satuan
        setHs(hsSak)
        setQty(qtySak)
        setUnit(unitOvk)
        setOvk(e)
        setVisible(true)
    }
    const diff = (e) => {
        const value = e.target.value
        let difference = value - jum
        setDif(difference)
        setNum(value)
        setVisible(false)
    }

    return (
        <Modal
            visible={visible}
            title={t('pages.stockflow.periode.penyesuaian.ovk.edit')}
            footer={null}
            width={800} 
            onCancel={() => handleCancel()}
        >
            <Form
                form={form}
                onFinish={() => {
                    form
                        .validateFields()
                        .then(() => {
                            const values = { ovk, jumlah }
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
                layout="vertical"
                name="form_in_modal"
            >
                <Row gutter={[16, 8]} >
                    <Col span={12}>
                        <span className="black font-semi-bold font-title-content">OVK</span>
                            <Select
                                name="ovk" value={ovk}
                                showSearch
                                labelInValue
                                placeholder="Pilih Ovk"
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular mt-5"
                                onChange={(value) => sak(value, value.value)}
                            >
                                {
                                    object.filter(d=>d!==null).map(data =>
                                        <Option
                                            key={data.namaOvk.toString()}
                                            value={data.idOvk}
                                        >{data.namaOvk}</Option>
                                    )
                                }
                            </Select>
                    </Col>
                    <Col span={12}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.periode.penyesuaian.jumlah')} </span>
                            <Input
                                name='jumlah' value={jumlah}
                                type='number' required
                                placeholder="10..."
                                className="input-suffix mt-5"
                                onChange={(value) => diff(value)}
                                suffix={unit ? unit : satuan}
                            />
                    </Col>
                    <Col span={6}>
                        <span>{t('pages.stockflow.periode.penyesuaian.jumlah')}: <b><NumberFormat value={jum} displayType={'text'} thousandSeparator={true} suffix={' kg'} /></b></span>
                    </Col>
                    <Col span={6}>
                        <span>HS: <b><NumberFormat value={harga} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /></b></span>
                    </Col>
                    <Col span={6}>
                        <span>{t('pages.stockflow.periode.penyesuaian.difference')}: <b><NumberFormat hidden={visibleDif} value={Math.abs(sel || sel === 0 ? sel : dif)} displayType={'text'} thousandSeparator={true} suffix={' kg'} /></b></span>
                    </Col>
                    <Col span={6}>
                        <span>HS: <b><NumberFormat value={harga} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /></b></span>
                    </Col>
                    <Divider/>
                    <Col span={8} offset={16}>
                        <Button className='float-right'
                            type="primary"
                            htmlType="submit"
                            style={{ marginLeft: 8, marginTop: 0 }}
                        >
                            Edit
                        </Button>
                        <Button onClick={() => handleCancel()} className='float-right'>
                            {t('general.cancel')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default EditAdjusmentOvk