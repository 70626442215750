import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { navigate } from '../../../../common/store/action/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CONSTANTS from '../../../../common/utils/Constants'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { faInfoCircle, faLock, faLockOpen, faSlidersH, faTrash } from '@fortawesome/free-solid-svg-icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import PeriodeMaterialComponent from '../../../../modules/dashboard-peternak/stock-flow-component/periode-component/periode-material-component'
import { createStockflowMaterial, newStockflowMaterial, lockPeriodeStokMaterial, reLockPeriodeStokMaterial, delStockflowMaterial } from './periode-aksi-page/query-periode'
import { getIdMonthlyStock } from '../../../../modules/dashboard-peternak/adjustment-stock-component/material-component/store/adjustment-material-action'
import { getMonthPeriode, getYearPeriode, } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'

function PeriodeMaterialPage(props) {
    const { confirm } = Modal
    const { setLoading, loading, material, yearRangeMaterial, yearMaterial, setYearMaterial, 
        getMonthPeriode,getYearPeriode, getIdMonthlyStock, fetchDataMaterial, t } = props
    const [visible, setVisible] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }
    const createData = () => {
        setLoading(true)
        API.get(createStockflowMaterial())
            .then(res => {
                if (res.data.data.createMonthlyStockMaterial._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.create')}`)
                    fetchDataMaterial(yearMaterial)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.material.response.gagal.create')}`)
                setLoading(false)
            })
    }
    const createNewData = (date) => {
        setLoading(true)
        API.get(newStockflowMaterial(date))
            .then(res => {
                if (res.data.data.newMonthlyStockMaterial._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.create')}`)
                    fetchDataMaterial(yearMaterial)
                    setVisible(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.material.response.gagal.create')}`)
                setLoading(false)
                setVisible(false)
            })
    }

    const createConfirm = () => {
        confirm({
            title: `${t('pages.stockflow.periode.material.textCreate')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                createData()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }
    const closePeriode = (_id, periode) => {
        confirm({
            title: `${t('pages.stockflow.periode.material.textClose')}${periode}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                close(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const close = (_id) => {
        setLoading(true)
        API.post(lockPeriodeStokMaterial(_id))
            .then(res => {
                if (res.data.data.lockMonthlyStockMaterial._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.close')}`)
                    fetchDataMaterial(yearMaterial)
                } else if (res.data.data.lockMonthlyStockMaterial.ok !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.reclose')}`)
                    fetchDataMaterial(yearMaterial)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.material.response.gagal.close')}`)
                setLoading(false)
            })
    }

    const openPeriode = (_id, periode) => {
        confirm({
            title: `${t('pages.stockflow.periode.material.textOpen')}${periode}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                open(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const open = (_id) => {
        setLoading(true)
        API.post(reLockPeriodeStokMaterial(_id))
            .then(res => {
                if (res.data.data.reOpenMonthlyStockMaterial._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.open')}`)
                    fetchDataMaterial(yearMaterial);
                }
            }).catch((error) => {
                console.log(error);
                validationMessage('error', `${t('pages.stockflow.periode.material.response.gagal.open')}`)
                setLoading(false);
            })
    }
    const deleteData = (_id) => {
        setLoading(true)
        API.delete(delStockflowMaterial(_id))
            .then(res => {
                if (res.data.data.deleteMonthlyStockMaterial.deleted === true) {
                    validationMessage('success', `${t('pages.stockflow.periode.material.response.sukses.delete')}`)
                    fetchDataMaterial(yearMaterial)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.periode.material.response.gagal.delete')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.stockflow.periode.material.textDel')}${nama}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            onOk: () => {
                deleteData(_id)
            },
            onCancel() {
                console.log('Cancel')
            },
        })
    }
    const detailPeriode = useCallback((m, y) => {
        getMonthPeriode(m)
        getYearPeriode(y)
        props.navigate(CONSTANTS.DETAIL_PERIODE_MATERIAL_KEY)
    },[props,getMonthPeriode,getYearPeriode])

    const adjustmentMaterial = useCallback((value, m, y) => {
        getIdMonthlyStock(value)
        getMonthPeriode(m)
        getYearPeriode(y)
        props.navigate(CONSTANTS.ADJUSTMENT_MATERIAL_MENU_KEY)
    }, [props, getIdMonthlyStock,getMonthPeriode, getYearPeriode])

    const columns = [
        {
            title: `${t('pages.stockflow.periode.table.periode')}`,
            dataIndex: 'periode',
            key: 'periode',
            ...getColumnSearchProps('periode', `${t('pages.stockflow.periode.table.periode')}`),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status', 'status'),
        },
        {
            title: `${t('pages.stockflow.periode.table.pilihan')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.recording.button.detail")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }}
                            onClick={() => detailPeriode(row.month, row.year)}
                        ><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('pages.stockflow.periode.table.penyesuaian')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#02d456',
                            borderRadius: '5px'
                        }} onClick={() => adjustmentMaterial(row.key, row.month, row.year)}><FontAwesomeIcon icon={faSlidersH} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={row.locked === false ?
                        `${t('pages.stockflow.periode.table.lock')}` :
                        `${t('pages.stockflow.periode.table.unlock')}`}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: row.locked === false ? '#ff4a3d' : '#03a5fc',
                            borderRadius: '5px',
                        }} onClick={row.locked === false ? () => closePeriode(row.key, row.periode) :
                            () => openPeriode(row.key, row.periode)} >
                            <FontAwesomeIcon icon={row.locked === false ? faLock : faLockOpen} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.periode)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = material.map(({ _id, locked, month, year, createdAt }, index) => ({
        key: _id,
        id: _id,
        periode: month + ' ' + year,
        month: month,
        year: year,
        locked: locked,
        status: locked === true ?
            `${t('pages.stockflow.periode.table.locked')}` :
            `${t('pages.stockflow.periode.table.unlocked')}`,
        createdAt: createdAt
    }))

    return (
        <PeriodeMaterialComponent
            navigate={props.navigate}
            columns={columns}
            loading={loading}
            data={data}
            yearRangeMaterial={yearRangeMaterial}
            yearMaterial={yearMaterial}
            setYearMaterial={setYearMaterial}
            t={t} createConfirm={createConfirm}
            createNewData={createNewData}
            visible={visible} setVisible={setVisible}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdMonthlyStock,
    getMonthPeriode, getYearPeriode
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PeriodeMaterialPage)
export default page