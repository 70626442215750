import React from 'react'
import { Modal, Form, Row, Col, Select, InputNumber } from 'antd'

function EditOvk(props) {
    const { Option } = Select
    const { visible, handleSubmit, data, handleCancel, stokOvk, monthlyOvk, t } = props

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        const onChangeOvk = (value) => {
            let idOvk = stokOvk.filter(d => d._id === value.value)[0].ovk._id
            let price = monthlyOvk.filter(d => d.idOvk === idOvk)[0]?.hargaPengeluaran
            form.setFieldsValue({ price: price })
        }

        return (
            <Modal
                visible={visible}
                title={t('pages.pemakaianUmum.edit.title')}
                okText="Edit"
                width={500}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        _id: data._id,
                        ovk: data.ovkStock,
                        jumlah: data.number,
                        price: data.priceOvkTransaction,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >OVK</span>
                            <Form.Item
                                name="ovk" rules={[{ required: true, message: `${t('pages.pemakaianUmum.tambah.msgOvk')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    onChange={val => onChangeOvk(val)}
                                    placeholder="Pilih Ovk"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        stokOvk.map(data =>
                                            <Option
                                                key={data.ovk.name.toString()}
                                                value={data._id}
                                            >{data.ovk.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.pemakaianUmum.edit.jumlah')}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${t('pages.pemakaianUmum.tambah.msgJumlah')}` }]}
                            >
                                <InputNumber
                                    name='jumlah'
                                    placeholder="100..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Harga</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                            >
                                <InputNumber
                                    placeholder="100..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}

export default EditOvk;