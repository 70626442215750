import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { navigate } from '../../../common/store/action';
import ProfilComponent from '../../../modules/dashboard-peternak/profil-component/profil-component';

import {getProfile,updateProfile,changePassword} from './query-profil';
import {getListProfil,updateProfil,updatePassword} from '../../../modules/dashboard-peternak/profil-component/store/profil-action';

import {useTranslation} from 'react-i18next'

function ProfilPage(props) {
    const [form] = Form.useForm();
    const {getListProfil,updateProfil,updatePassword} = props;
    const [disabled,setDisabled] = useState(true);
    const [passChange,setPassChange] = useState(false);

    const {t} = useTranslation()

    /**Handle get data */
    useEffect(() => {
        getListProfil(getProfile());
    }, [getListProfil]);
    /**End handle get data */

    const handleSubmit = (values) => {
        updateProfil(updateProfile(values),`${t('pages.profil.response.sukses.profil')}`,`${t('pages.profil.response.gagal.profil')}`);
        setDisabled(true);
    };

    const handleSubmitPassword = (values) => {
        updatePassword(changePassword(values),`${t('pages.profil.response.sukses.password')}`,`${t('pages.profil.response.gagal.password')}`);
        setPassChange(false);
        form.resetFields();
    };

    return ( 
        <ProfilComponent
            navigate={props.navigate}
            disabled = {disabled}
            setDisabled = {setDisabled}
            handleSubmit = {handleSubmit}
            handleSubmitPassword = {handleSubmitPassword}
            profil = {props.profil}
            loading = {props.loading}
            passChange = {passChange}
            setPassChange = {setPassChange}
            form = {form} t={t}
        />
    );
}

const mapStateToProps = state => ({
    profil : state.profil.dataProfil,
    loading : state.profil.loading,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListProfil,
    updateProfil,
    updatePassword
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(ProfilPage);
export default page