import React from 'react'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import { Modal, Form, Row, Col, Input, DatePicker, Select, InputNumber } from 'antd'

function CreateRearing(props) {
    const { TextArea } = Input
    const { Option } = Select
    const { visible, handleSubmit, setVisible, showModal, kandang, strain, locales } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={locales("pages.periode.modal.titleTambah")}
                okText={locales("pages.periode.modal.create")}
                width={800} centered
                cancelText={locales("pages.periode.modal.cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal" initialValues={{keterangan: ' '}}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.periode.modal.namaPeriode")}</span>
                            <Form.Item
                                name="nama_periode" rules={[{ required: true, message: `${locales("pages.periode.modal.msgNamaPeriode")}` }]}
                            >
                                <Input
                                    name='nama_periode'
                                    placeholder={locales("pages.periode.modal.phNamaPeriode")}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.periode.modal.tanggalPeriode")}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${locales("pages.periode.modal.msgTglPeriode")}` }]}
                            >
                                <DatePicker
                                    placeholder={locales("pages.periode.modal.phTanggalPeriode")}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.periode.modal.populasiHpp")}</span>
                            <Form.Item
                                name="populasiHpp" rules={[{ required: true, message: `${locales("pages.periode.modal.msgPopulationHpp")}` }]}
                            >
                                <Input
                                    name='populasiHpp'
                                    placeholder="1000..."
                                    className="input-suffix mt-5"
                                    suffix={locales("pages.periode.modal.suffixPopulasi")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.periode.modal.populasi")}</span>
                            <Form.Item
                                name="populasiAwal" rules={[{ required: true, message: `${locales("pages.periode.modal.msgPopulation")}` }]}
                            >
                                <Input
                                    name='populasiAwal'
                                    placeholder="1000..."
                                    className="input-suffix mt-5"
                                    suffix={locales("pages.periode.modal.suffixPopulasi")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">{locales("pages.periode.modal.kandang")}</span>
                            <Form.Item
                                name="kandang" rules={[{ required: true, message: `${locales("pages.periode.modal.msgKandang")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={locales("pages.periode.modal.phKandang")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        kandang.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{locales("pages.periode.modal.strain")}</span>
                            <Form.Item
                                name="strain" rules={[{ required: true, message: `${locales("pages.periode.modal.msgStrain")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={locales("pages.periode.modal.phStrain")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        strain.map(data =>
                                            <Option
                                                key={data.name.toString()}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                       
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.periode.modal.harga")}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, type: 'number', message: `${locales("pages.periode.modal.msgHarga")}` }]}
                            >
                                <InputNumber
                                    name='harga'
                                    placeholder="5..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.periode.modal.bobot")}</span>
                            <Form.Item
                                name="bobot" rules={[{ required: true, message: `${locales("pages.periode.modal.msgBobot")}` }]}
                            >
                                <Input
                                    type='number'
                                    placeholder="5..."
                                    className="input-suffix mt-5"
                                    suffix="gram"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">Usia Indukan</span>
                            <Form.Item
                                name="parentAge" rules={[{ required: true, message: `Mohon masukkan usia indukan` }]}
                            >
                                <Input
                                    type='number'
                                    placeholder="3..."
                                    className="input-suffix mt-5"
                                    suffix="minggu"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{locales("pages.periode.modal.ppl")}</span>
                            <Form.Item
                                name="ppl" rules={[{ required: true, message: `${locales("pages.periode.modal.msgPpl")}` }]}
                            >
                                <Input
                                    name='ppl'
                                    placeholder="Irfan..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{locales("pages.periode.modal.keterangan")}</span>
                            <Form.Item
                                name="keterangan"
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={locales("pages.periode.modal.phKet")}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={locales("pages.periode.periodeBerjalan.button.tambah")}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                kandang={kandang}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

export default CreateRearing