import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { Link } from 'react-router-dom'
import ModalCreateAdjusmentMaterial from '../../../../app/dashboard-peternak/adjustment-stock-page/material-page/material-aksi-page/create-adjustment-material-page'
import ModalEditAdjustmentMaterial from '../../../../app/dashboard-peternak/adjustment-stock-page/material-page/material-aksi-page/edit-adjustment-material-page'

function MaterialComponent(props) {
    const { Content } = Layout
    const { columns, data, loading, fetchData, setLoading, visible, handleCancel, handleSubmit, idData, detail, object, locked, month, year, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={16}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><Link to='/peternak/stock-flow-periode'><span>{t('pages.stockflow.periode.title')}</span></Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{t('pages.stockflow.periode.penyesuaian.material.title')} - {month} {year}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={8}>
                        <ModalCreateAdjusmentMaterial fetchData={fetchData} setLoading={setLoading} object={object} locked={locked} t={t} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                                bordered
                                className="table-dashboard"
                                scroll={{ y: '70vh' }}
                            />
                        </Loading>
                    </Col>
                </Row>
            </Content>
            <ModalEditAdjustmentMaterial visible={visible} idData={idData} handleCancel={handleCancel}
                detail={detail} handleSubmit={handleSubmit} fetchData={fetchData} object={object} locked={locked} t={t} />
        </Layout>
    )
}

export default MaterialComponent