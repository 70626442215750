import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import EditOvkComponent from '../../../../modules/dashboard-peternak/rearing-record-component/edit-detail-rearing-component/edit-ovk-component'
import { useTranslation } from "react-i18next"

function EditOvkPage(props) {
    const { visible, handleCancel, detail, handleSubmit, suffix, monthlyOvk } = props
    const [data, setData] = useState(detail)
    const { t } = useTranslation()

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    return (
        <EditOvkComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            detail={detail}
            data={data}
            suffix={suffix}
            locales={t} monthlyOvk={monthlyOvk}
            stokOvk={props.stokOvk}
        />
    )
}

const mapStateToProps = state => ({
    stokOvk: state.stokOvk.dataStokOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditOvkPage)
export default page