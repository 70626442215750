import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { API } from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification';
import { navigate } from '../../../../../common/store/action';
import CreatePakanComponent from '../../../../../modules/dashboard-peternak/master-component/pakan-component/pakan-aksi-component/create-pakan-component';

import {createPakan} from './query-pakan';

function CreatePakanPage(props) {
    const {fetchData,setLoading,t} = props;
    const [visible, setVisible] = useState(false);

    const showModal = useCallback(() => {
        setVisible(true);
    },[]);

    const postData = (values,year) => {
        API.post(createPakan(values,year))
        .then(res => {
            if(res.data.errors){
                validationMessage('error', res.data.errors[0].message)
                setLoading (false);
            }else if(res.data.data.createFeed._id){
                validationMessage('success', `${t('pages.master.pakan.response.sukses.tambah')}`)
                fetchData();
            }
        }).catch((error) => {
            console.log(error);
            validationMessage('error', `${t('pages.master.pakan.response.gagal.tambah')}`)
            setLoading (false);
        })
    }

    const handleSubmit = (values) => {
        postData( values ,values['year-picker'].format('YYYY'));
        setVisible(false);
        setLoading(true);
    };

    return ( 
        <CreatePakanComponent
            showModal = {showModal}
            navigate={props.navigate}
            visible = {visible}
            handleSubmit = {handleSubmit}
            setVisible = {setVisible}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePakanPage);
export default page