import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { API } from '../../../common/api';
import CONSTANS from '../../../common/utils/Constants';
import { navigate } from '../../../common/store/action';
import { checkSessionTimes } from '../../../common/component/function/component';
import { errMessage, validationMessage } from '../../../common/component/notification/notification';
import LayoutComponent from '../../../modules/dashboard-peternak/layout-component/layout-component';
import { notification } from 'antd'
import socketIOClient from 'socket.io-client'

import { createKandang, queryListKandang } from '../master-page/kandang-page/kandang-aksi-page/query-kandang';
import { getPeriodeKandang } from '../rearing-page/rearing-aksi-page/query-rearing';

import {
    getListKandang, getIdPeriode, getKandang, getPeriode,
    getListPeriodeKandang, getIdKandang, setIsTour, getChickInDate,
    getIdDevice
} from '../../../modules/dashboard-peternak/layout-component/store/layout-action';
import { getGudangMaterial } from '../master-page/gudang-material-page/gudang-material-aksi-page/query-gudang-material';
import { queryGudangPakan } from '../master-page/gudang-pakan-page/gudang-pakan-aksi-page/query-gudang-pakan';
import { getGudangOvk } from '../master-page/gudang-ovk-page/gudang-ovk-aksi-page/query-gudang-ovk';
import { detailPeriode } from '../rearing-page/rearing-aksi-page/query-rearing';

import { getListGudangOvk, getIdGudangOvk } from '../../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-action';
import { getListGudangPakan, getIdGudangPakan } from '../../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-action';
import { getListGudangMaterial, getIdGudangMaterial } from '../../../modules/dashboard-peternak/master-component/gudang-material-component/store/gudang-material-action';

import { getProfile, updateNotifReceive } from '../../../app/dashboard-peternak/profil-page/query-profil'
import { getListProfil } from '../../../modules/dashboard-peternak/profil-component/store/profil-action'
import { clearUnread, getNotifications } from '../dashboard-page/query-dashboard-page'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */
import i18n from '../../../translations/i18n'
import { useTranslation } from "react-i18next"

function LayoutPage(props) {
    const {
        getListKandang, getIdPeriode, getListPeriodeKandang, getIdKandang, getKandang, getPeriode,
        setIsTour, getListGudangOvk, getListGudangPakan, getListGudangMaterial,
        getIdGudangOvk, getIdGudangMaterial, getIdGudangPakan, getListProfil, getChickInDate,
        getIdDevice, profil
    } = props
    const [current, setCurrent] = useState('')
    const [loading, setLoading] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const [pathName] = useState('')
    const [width, setWidth] = useState(null)
    const [visible, setVisible] = useState(false)
    const location = useLocation()
    const { t } = useTranslation()
    // const [lang] = useState(Cookies.get("locale") === undefined ? "id" : Cookies.get("locale"))
    const [lang] = useState(i18n.language)

    const [isNotif, setIsNotif] = useState(false)
    const [notifications, setNotification] = useState([])
    const [notifLoading, setNotifLoading] = useState(false)
    const [limitNotif, setLimitNotif] = useState(10)
    const [notifCount, setNotifCount] = useState(0)
    const [unreadNotif, setUnreadNotif] = useState(0)
    const [loadReceiveNotif, setLoadReceiveNotif] = useState(false)
    const endpoint = process.env.REACT_APP_SOCKET_HOST
    const ref = useRef(null)

    useEffect(() => {
        if (checkSessionTimes()) {
            validationMessage('info', 'Your session has expired')
            Cookies.remove('token', { path: '/', domain: 'localhost' })
            Cookies.remove('loginTimes', { path: '/', domain: 'localhost' })
            props.navigate(CONSTANS.HOME_MENU_KEY)
        }
        let pathArray = window.location.pathname.split('/')
        let pathName = pathArray[2]
        pathName === '' ? setCurrent('/peternak') : setCurrent(pathName)
    }, [props])

    useEffect(() => {
        getListKandang(queryListKandang('', 0, 0))
    }, [getListKandang])

    useEffect(() => {
        getListProfil(getProfile())
        fetchNotification(limitNotif)

    }, [getListProfil, limitNotif])

    useEffect(() => {
        getListGudangOvk(getGudangOvk('', 0, 0))
        getListGudangPakan(queryGudangPakan('', 0, 0))
        getListGudangMaterial(getGudangMaterial('', 0, 0))
    }, [getListGudangOvk, getListGudangPakan, getListGudangMaterial])

    useEffect(() => {
        if (window.innerWidth === 360) {
            setWidth(0)
        } else if (collapsed === true) {
            setWidth(80)
        } else if (collapsed === false) {
            setWidth(260)
        }
    }, [collapsed])

    const handleLogout = useCallback((value) => {
        const query = {
            query:
                `query{
                logout {
                    signedOut
                }
            }`,
        }
        setLoading(true)
        getIdDevice(null)
        API.post(query)
            .then(res => {
                if (res === undefined) { validationMessage('error', 'Connection error') }
                else if (res.data.data.logout.signedOut === true) {
                    Cookies.remove('token', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                    Cookies.remove('loginTimes', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                    props.navigate(CONSTANS.HOME_MENU_KEY)
                    setLoading(false)
                }
            })
    }, [props, getIdDevice])

    const handleChangePeriode = useCallback((value) => {
        getIdPeriode(value.value)
        getPeriode(value.label)
        fetchDataChickInDate(value.value)
        // eslint-disable-next-line
    }, [getIdPeriode, getPeriode])

    const handleChangeKandang = useCallback((value) => {
        getIdKandang(value.value)
        getKandang(value.label)
        getListPeriodeKandang(getPeriodeKandang('', 0, 0, value.value))
    }, [getListPeriodeKandang, getIdKandang, getKandang])

    const handleChangeGudangPakan = useCallback((value) => {
        getIdGudangPakan(value.value)
    }, [getIdGudangPakan]);

    const handleChangeGudangMaterial = useCallback((value) => {
        getIdGudangMaterial(value.value)
    }, [getIdGudangMaterial])

    const handleChangeGudangOvk = useCallback((value) => {
        getIdGudangOvk(value.value)
    }, [getIdGudangOvk])

    const clickedMenu = useCallback((e) => {
        setCurrent(e.key)
    }, [])

    const handleLocales = useCallback((e) => {
        if (i18n.language !== e) {
            i18n.changeLanguage(e)
        }
    }, [])

    const toggle = useCallback(() => {
        setCollapsed(!collapsed)
    }, [collapsed])

    const fetchDataChickInDate = (idPeriode) => {
        if (idPeriode) {
            API.get(detailPeriode(idPeriode))
                .then(res => {
                    if (res.data.data.rearing !== null) {
                        getChickInDate(moment(new Date(parseInt(res.data.data.rearing.chickInDate))).format("YYYY-MM-DD"));
                    }
                }).catch((error) => {
                    getChickInDate(null)
                })
        } else {
            getChickInDate(null)
        }
    }

    const postData = (values) => {
        API.post(createKandang(values))
            .then(res => {
                if (res.data.data.createHouse._id) {
                    validationMessage('success', 'Berhasil Menambahkan Kandang')
                    getListKandang(queryListKandang('', 0, 0))
                }
            }).catch((error) => {
                console.log(error);
                validationMessage('error', 'Gagal Menambahkan Kandang')
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
    };

    const handleAdd = () => {
        setIsTour()
        setVisible(true)
    }
    /**End Create House */

    //NOTIFICATION
    const fetchNotification = async (limit) => {
        setNotifLoading(true)
        await API.get(getNotifications('', limit, 0))
            .then(res => {
                if (res.data.data.notifications !== null) {
                    setNotification(res.data.data.notifications.notifications)
                    setLimitNotif(res.data.data.notifications.totalCount)
                    setNotifCount(res.data.data.notifications.totalAll)
                    setUnreadNotif(res.data.data.notifications.totalUnread)
                    setNotifLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setNotification([])
                setNotifLoading(false)
            })
    }

    const clearUnreadNotif = async (limit) => {
        await API.get(clearUnread(limit))
            .then(res => {
                if (res.data.data.clearUnread !== null) {
                    setNotification(res.data.data.clearUnread.notifications)
                    setUnreadNotif(0)
                    setNotifLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setNotification([])
                setNotifLoading(false)
            })
    }

    const loadMoreNotif = () => {
        if (notifLoading) {
            return
        }
        setNotifLoading(true)
        fetchNotification(limitNotif + 10)
    }

    const changeReceiveNotif = async (e) => {
        setLoadReceiveNotif(true)
        await API.post(updateNotifReceive(e))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', res.data.errors[0].message)
                    setLoadReceiveNotif(false)
                } else if (res.data.data.updateNotifReceive !== null) {
                    getListProfil(getProfile())
                    setLoadReceiveNotif(false)
                }
            }).catch((error) => {
                console.log(error.message)
            })
    }

    const openNotifTemp = useCallback((data) => {
        if (data.id !== ref.current) {
            notification.info({
                message: <b>{data.title}</b>,
                description: data.message
            })
        }
        ref.current = data.id
    }, [])

    const openNotifHum = useCallback((data) => {
        if (data.id !== ref.current) {
            notification.info({
                message: <b>{data.title}</b>,
                description: data.message
            })
        }
        ref.current = data.id
    }, [])

    const openNotifCo2 = useCallback((data) => {
        if (data.id !== ref.current) {
            notification.info({
                message: <b>{data.title}</b>,
                description: data.message
            })
        }
        ref.current = data.id
    }, [])


    useEffect(() => {
        const socket = socketIOClient(endpoint)

        socket.on(`ews-${profil._id}`, data => {
            fetchNotification(limitNotif)
        })

        socket.on(`temp-${profil._id}`, data => {
            openNotifTemp(data)
        })

        socket.on(`hum-${profil._id}`, data => {
            openNotifHum(data)
        })

        socket.on(`co2-${profil._id}`, data => {
            openNotifCo2(data)
        })

        return () => socket.disconnect()
    }, [profil, endpoint, openNotifTemp, openNotifHum, openNotifCo2, limitNotif])

    return (
        <LayoutComponent
            navigate={props.navigate}
            handleLogout={handleLogout}
            toggle={toggle}
            collapsed={collapsed}
            setCurrent={setCurrent}
            current={current}
            loading={loading}
            pathName={pathName}
            clickedMenu={clickedMenu}
            kandang={props.kandang}
            periode={props.periode}
            idPeriode={props.idPeriode}
            idKandang={props.idKandang}
            width={width}
            handleChangePeriode={handleChangePeriode}
            handleChangeKandang={handleChangeKandang}
            handleSubmit={handleSubmit}
            visible={visible}
            setVisible={setVisible}
            isTourOpen={props.isTourOpen}
            setIsTour={setIsTour}
            handleAdd={handleAdd}
            gudangPakan={props.gudangPakan}
            gudangOvk={props.gudangOvk}
            gudangMaterial={props.gudangMaterial}
            handleChangeGudangPakan={handleChangeGudangPakan}
            handleChangeGudangOvk={handleChangeGudangOvk}
            handleChangeGudangMaterial={handleChangeGudangMaterial}
            idGudangMaterial={props.idGudangMaterial}
            idGudangPakan={props.idGudangPakan}
            idGudangOvk={props.idGudangOvk}
            location={location}
            role={props.role}
            handleLocales={handleLocales}
            locales={t} t={t}
            lang={lang}

            isNotif={isNotif} setIsNotif={setIsNotif} notifications={notifications}
            loadMoreNotif={loadMoreNotif} notifCount={notifCount} unreadNotif={unreadNotif}
            clearUnreadNotif={clearUnreadNotif} limitNotif={limitNotif} notifLoading={notifLoading}
            changeReceiveNotif={changeReceiveNotif} loadReceiveNotif={loadReceiveNotif}
        />
    );
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
    idKandang: state.layout.idKandang,
    kandang: state.layout.dataKandang,
    periode: state.layout.dataPeriode,
    totalKandang: state.layout.totalKandang,
    isTourOpen: state.layout.isTourOpen,
    gudangPakan: state.gudangPakan.dataGudangPakan,
    idGudangPakan: state.gudangPakan.idGudangPakan,
    gudangOvk: state.gudangOvk.dataGudangOvk,
    idGudangOvk: state.gudangOvk.idGudangOvk,
    gudangMaterial: state.gudangMaterial.dataGudangMaterial,
    idGudangMaterial: state.gudangMaterial.idGudangMaterial,
    role: state.auth.dataUser.role,
    profil: state.profil.dataProfil,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListKandang,
    getListPeriodeKandang,
    getIdPeriode,
    getIdKandang,
    getKandang, getPeriode,
    setIsTour,
    getListGudangOvk,
    getListGudangPakan,
    getListGudangMaterial,
    getIdGudangOvk,
    getIdGudangMaterial,
    getIdGudangPakan,
    getListProfil,
    getChickInDate,
    getIdDevice
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
export default page