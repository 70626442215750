import React from 'react';
import { Modal, Form, Row, Col, Input, Select } from 'antd';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import ButtonDashboard from '../../../../../common/component/button/button-dashboard';

function CreateCustomer(props) {
    const { TextArea } = Input;
    const { visible, handleSubmit, setVisible, showModal, t } = props;
    const { Option } = Select;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={t('pages.master.supplier.modal.titleTambah')}
                okText={t('general.create')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.supplier.modal.nama')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.supplier.modal.msgNama')}` }]}
                            >
                                <Input
                                    name='name'
                                    placeholder={t('pages.master.supplier.modal.phNama')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.supplier.modal.alamat')}</span>
                            <Form.Item
                                name="address" rules={[{ required: true, message: `${t('pages.master.supplier.modal.msgAlamat')}` }]}
                            >
                                <Input
                                    name='address'
                                    placeholder={t('pages.master.supplier.modal.phAlamat')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.supplier.modal.kode')}</span>
                            <Form.Item
                                name="codeSupplier" rules={[{ required: true, message: `${t('pages.master.supplier.modal.msgKode')}` }]}
                            >
                                <Input
                                    name='codeSupplier'
                                    placeholder={t('pages.master.supplier.modal.phKode')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.supplier.modal.tipe')}</span>
                            <Form.Item
                                name="typeSupplier" rules={[{ required: true, message: `${t('pages.master.supplier.modal.msgTipe')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.supplier.modal.phTipe')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    <Option key="Pakan" value="Pakan">Pakan</Option>
                                    <Option key="Ovk" value="Ovk">Ovk</Option>
                                    <Option key="Material" value="Material">Material</Option>
                                    <Option key="Doc" value="Doc">Doc</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.supplier.modal.kontak')}</span>
                            <Form.Item
                                name="contact" rules={[{ required: true, message: `${t('pages.master.supplier.modal.msgKontak')}` }]}
                            >
                                <Input
                                    name='contact'
                                    placeholder="085..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} xs={{ order: 1 }} sm={{ order: 2 }} md={{ order: 3 }} lg={{ order: 4 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.supplier.modal.informasi')}</span>
                            <Form.Item
                                name="otherInformation"
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t('pages.master.supplier.modal.phInformasi')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };
    return (
        <div>
            <ButtonDashboard
                text={t('pages.master.supplier.button.tambah')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false);
                }}
            />
        </div>
    );
}

export default CreateCustomer;