import React from 'react';
import { Layout, Breadcrumb, Row, Col, Tabs, } from 'antd';
import { Link } from 'react-router-dom';
import PenambahanMaterial from '../../../../../app/dashboard-peternak/stok-page/stok-material-page/transaksi-stok-material-page/penambahan-stok-material-page';
import PenggunaanMaterial from '../../../../../app/dashboard-peternak/stok-page/stok-material-page/transaksi-stok-material-page/penggunaan-stok-material-page';

function TransaksiStokMaterialComponent(props) {
    const { Content } = Layout;
    const { TabPane } = Tabs;
    const { changeKey, loading, setLoading, activeKey, fetchDataPenambahan, penambahan, penggunaan, fetchDataPenggunaan, namaMaterial, t } = props;
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to='/peternak/stok-material'><span>{t('pages.stok.material.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.stok.material.detail.title')} {namaMaterial}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={t('pages.stok.material.detail.penambahan.title')} key="1">
                                    <PenambahanMaterial activeKey={activeKey} loading={loading} setLoading={setLoading}
                                        penambahan={penambahan} fetchDataPenambahan={fetchDataPenambahan} t={t} />
                                </TabPane>
                                <TabPane tab={t('pages.stok.material.detail.penggunaan.title')} key="2">
                                    <PenggunaanMaterial activeKey={activeKey} loading={loading} setLoading={setLoading}
                                        penggunaan={penggunaan} fetchDataPenggunaan={fetchDataPenggunaan} t={t} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default TransaksiStokMaterialComponent;