export const getProfile = () => {
    return (
        `query{
            getProfile{
                name
                email
                username
                foto
                address
                phone
                receivedNotification
                accessshouse{
                    _id
                    name
                }
                company{
                    _id
                    name
                }
            }
        }`
    );
}

export const updateProfile = (value) => {
    return {
        query : `
            mutation {
                updateUser(updateUserInput: {
                    address: "${value.alamat}", 
                    username: "${value.username}", 
                    email: "${value.email}",
                    phone: "${value.phone}", 
                    name: "${value.namaLengkap}"
                }){
                _id
                }
            }
        `
    }
}

export const changePassword = (value) => {
    return {
        query : `
            mutation {
                changePassword(updatePasswordInput:{
                oldPassword: "${value.oldPass}"
                newPassword: "${value.newPass}"
            })
                {
                    name
                    password
                }
            }
        `
    }
}

export const updateNotifReceive = (value) => {
    return {
        query : `
            mutation {
                updateNotifReceive(updateUserInput: {
                    receivedNotification: ${value}
                }){
                    _id
                    name
                    email
                    username
                    foto
                    address
                    phone
                    type
                    receivedNotification
                    accessshouse{
                        _id
                        name
                    }
                    isVoucher
                    company{
                        _id
                        name
                        type
                        typeUser
                        createdAt
                    }
                }
            }
        `
    }
}