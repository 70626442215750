import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import _ from 'lodash';
import './styles.scss'
import { withTranslation } from 'react-i18next'

class DiseaseChart extends Component {
    static propTypes = {
        chartId: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
                // label: PropTypes.string.isRequired,
                // value: PropTypes.number.isRequired,
            }).isRequired,
        ).isRequired,
        rtl: PropTypes.bool,
    };

    static defaultProps = {
        rtl: false
    }

    componentDidMount(){
        this.initChart();
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(prevProps.data, this.props.data)){
            if(this.chart._super){
                this.chart.dispose();
            }
            this.initChart();
        }
    }

    componentWillUnmount(){
        if(this.chart._super){
            this.chart.dispose();
        }
    }

    initChart(){
        const { chartId,t } = this.props;
        this.chart = am4core.create(chartId, am4charts.XYChart);
        this.chart.exporting.menu = new am4core.ExportMenu();
        this.chart.scrollbarX = new am4core.Scrollbar();
        this.chart.exporting.menu.align = "left";
        this.chart.exporting.menu.verticalAlign = "top";

        // Create axes
        var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "penyakit";
        categoryAxis.title.text = `${t('pages.dashboard.penyakit')}`;

        var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = `${t('pages.dashboard.jumlah')}`;

        // Create series
        var series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "jumlah";
        series.dataFields.categoryX = "penyakit";
        series.name = `${t('pages.dashboard.jumlah')}`;
        series.tooltipText = "Penyakit: {categoryX}\nTotal: {valueY}";
        series.fill = am4core.color("#f04f26"); // fill

        // add data
        this.chart.data = this.props.data;
        
        // Add cursor
        this.chart.cursor = new am4charts.XYCursor();

        // Add legend
        this.chart.legend = new am4charts.Legend();
    }

    render(){
        return <div id={this.props.chartId} className={this.props.className} />
    }
}

export default withTranslation()(DiseaseChart);