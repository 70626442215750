import * as actionType from './adjustment-material-action-type';

const initialState = {
    idMonthlyStockMaterial : null,
    loading : false,
}

const handler = (currentState) => {
    const setDataMonthlyStock = {
        startGetMonthlyStockMaterials : () => ({
            ...currentState,
            loading : true,
        }),

        finishGetMonthlyStockMaterials : () => ({
            ...currentState,
            loading: false,
        }),

        setIdMonthlyStockMaterial : data => ({
            ...currentState,
            idMonthlyStockMaterial: data
        }),
    }

    return {
        ...setDataMonthlyStock
    };
}

export default (state = initialState, action) => {
    const { payload, type} = action;
    switch(type){
        case actionType.START_GET_DATA:
            return handler(state).startGetMonthlyStockMaterials();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetMonthlyStockMaterials();
        case actionType.SET_ID_MONTHLY_STOCK_MATERIAL:
            return handler(state).setIdMonthlyStockMaterial(payload);

        default:
            return state;
    }
}