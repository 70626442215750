/**
 * @author spindyzel
 * @since 28 Desember 2019
*/

const menu = {
    EXAMPLE: {
        link: '/example',
    },
    LOGIN : {
        link : '/login',
    },
    DASHBOARD_PETERNAK: {
        link : '/peternak/dashboard'
    },
    DETAIL_REARING: {
        link : '/peternak/dashboard/detail-rearing'
    },
    DETAIL_REARING_CLOSE:{
        link : '/peternak/rearing/detail-rearing'
    },
    REARING_RECORD : {
        link : '/peternak/recording'
    },
    DETAIL_REARING_RECORD: {
        link : '/peternak/recording/detail'
    },
    DETAIL_PERIODE_OVK: {
        link : '/peternak/stock-flow-periode/ovk'
    },
    DETAIL_PERIODE_MATERIAL: {
        link : '/peternak/stock-flow-periode/material'
    },
    DETAIL_PERIODE_LIVEBIRD: {
        link : '/peternak/stock-flow-periode/livebird'
    },
    DETAIL_PERIODE_PAKAN: {
        link : '/peternak/stock-flow-periode/pakan'
    },
    TRANSAKSI_STOK_PAKAN : {
        link : '/peternak/stok-pakan/transaksi'
    },
    TRANSAKSI_STOK_OVK : {
        link : '/peternak/stok-ovk/transaksi'
    },
    TRANSAKSI_STOK_MATERIAL : {
        link : '/peternak/stok-material/transaksi'
    },
    DETAIL_DEVICE_RECORD : {
        link : '/peternak/monitor-alat/detail-record'
    },
    STOK_OVK : {
        link : '/peternak/stok-ovk'
    },
    MASTER_OVK : {
        link : '/peternak/master-ovk'
    },
    CREATE_MASTER_OVK : {
        link : '/peternak/master-ovk/create-ovk'
    },
    EDIT_MASTER_OVK : {
        link : '/peternak/master-ovk/edit-ovk'
    },
    CREATE_STOK_OVK : {
        link : '/peternak/buat-stok',
    },
    ADJUSMENT_FEED : {
        link : '/peternak/stock-flow-periode/penyesuaian-pakan'
    },
    ADJUSTMENT_OVK : {
        link : '/peternak/stock-flow-periode/penyesuaian-ovk'
    },
    ADJUSTMENT_MATERIAL : {
        link : '/peternak/stock-flow-periode/penyesuaian-material'
    },
    STANDAR_STRAIN : {
        link : '/peternak/master-strain/standar-strain'
    },
    RECORDING : {
        link : '/peternak/recording/detail'
    },
    CREATE_PENYAKIT_RECORDING : {
        link : '/peternak/recording/detail/tambah-penyakit'
    },
    EDIT_PENYAKIT_RECORDING : {
        link : '/peternak/recording/detail/edit-penyakit'
    },
    FOTO_PENYAKIT_RECORDING : {
        link : '/peternak/recording/detail/foto-penyakit'
    },
    CREATE_PEMAKAIAN_UMUM: {
        link : '/peternak/pemakaian-umum/buat-pemakaian-umum'
    },
    PEMAKAIAN_UMUM : {
        link : '/peternak/pemakaian-umum'
    },
    STANDAR_SUHU : {
        link : '/peternak/master-kandang/standar-suhu'
    },
    PROFILE : {
        link : '/peternak/profil'
    },
    HOME: {
        link: '/',
    },
    CHILD: {
        link: '/modul/',
    },
    DASHBOARD: {
        link: '/modul/dashboard',
    },
    LIST: {
        link: '/modul/list',
    },
    DETAIL_DEVICE : {
        link : '/peternak/device/detail-env'
    },
    STANDAR_ENV_DETAIL : {
        link : '/peternak/ews/detail-ews'
    },
};

export default menu;